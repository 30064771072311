try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXSatelliteBrowserController.$inject = ["$window", "$rootScope", "$scope", "$timeout", "$farmXApi", "$farmXEntitiesCache", "$farmXSatelliteService", "$farmXUtilities", "$log"];

angular
  .module('farmx-directives-sidenav')
  .directive('farmxSatelliteBrowser', FarmXSatelliteBrowserDirective)
  .controller('fzSatelliteBrowserController', FarmXSatelliteBrowserController);


function FarmXSatelliteBrowserDirective() {
  return {
    restrict: 'E',
    scope: {
      selected: '=',
      satelliteId: '@satelliteid',
    },
    templateUrl: 'satelliteBrowser/satelliteBrowser.template.html',
    controller: 'fzSatelliteBrowserController',
    controllerAs: 'ctrl',
    link: function link(scope, element, attrs, controller, transcludeFn) {

    },
  };
}

function FarmXSatelliteBrowserController($window, $rootScope, $scope, $timeout, $farmXApi, $farmXEntitiesCache, $farmXSatelliteService, $farmXUtilities, $log) {
  var ctrl = this;

  $scope.hasData = false;
  $scope.loadingData = true;
  ctrl.initialLoad = true;

  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;

  angular.element($window).bind('resize', function() {
    $scope.$apply(function () {
      ctrl.width = $window.innerWidth;
      ctrl.height = $window.innerHeight;
    });
  });

  this.$onInit = function() {
    ctrl.visible = false;
    if (ctrl.state == undefined) {
      ctrl.state = {
        availableDates: [],
        date: null,
        dataType: 'NDVI',
      };
    }
    ctrl.initialLoad = true;
    _updateSelected($scope.selected);
  };

  // ctrl.dataType = 'NDVI';
  ctrl.dataTypes = [
    {
      name: 'NDVI',
      code: 'NDVI'
    },
    {
      name: "NDVI Adv",
      code: "NDVI_Histogram_Equalization",
    },
    {
      name: 'NDWI SWIR',
      code: 'NDWI_SWIR'
    },
    {
      name: "NDWI SWIR Adv",
      code: "NDWI_SWIR_Histogram_Equalization",
    },
    {
      name: 'CHL Red_Edge',
      code: 'CHL_Red_Edge'
    },
    {
      name: 'CHL Red Edge Adv',
      code: 'CHL_Red_Edge_Histogram_Equalization'
    },
    {
      name: 'Dry Biomass Index',
      code: 'DryBiomassIndex'
    },
    {
      name: 'Dry Biomass Index Adv',
      code: 'DryBiomassIndex_Histogram_Equalization'
    },
    {
      name: 'True Color',
      code: 'TrueColor'
    },
  ];

  $scope.sortedData = {};

  $scope.$watch("ctrl.state.date", function (newValue, oldValu) {
    $loadData();
  }, true);

  $scope.$watch("ctrl.state.dataType", function (newValue, oldValue) {
    if (newValue != oldValue) {
      $loadData();
    }
  });

  ctrl.toggleSatellite = function () {
    ctrl.visible = !ctrl.visible;

    if (ctrl.visible == true) {
      _hideSatellite();
      $loadData();
    } else {
      _hideSatellite();
    }
  }

  function _hideSatellite() {
    const satelliteId = $scope.satelliteId;
    $rootScope.$broadcast('farmx.satellite.selected', {
      "type": null,
      "value": null,
      "satelliteId": satelliteId,
    });
  }

  function _updateSelected(selected) {
    _hideSatellite();

    if (selected === undefined || selected.type === undefined) return;

    if (selected.type == "Block")
      _selectBlock(selected.value[1]);
    else if (selected.type == "Ranch")
      _selectRanch(selected.value[0]);
    else
      _selectNone(null);

    ctrl.initialLoad = false;
  }

  $scope.$on('farmx.sidenav.selected', function(event, selected) {
    $timeout(function() {
      _updateSelected(selected);
    }, 0);
  });

  $scope.$on('farmx.map.selected', function(event, selected) {
    _updateSelected(selected);
  });

  function _selectBlock(selected) {
    ctrl.state.block = selected;
    ctrl.state.ranch = null;
    //if (!ctrl.initialLoad)
    //  ctrl.visible = (selected !== null);
    $loadAvailableData();
  }

  function _selectRanch(selected) {
    ctrl.state.ranch = selected;
    ctrl.state.block = null;
    //if (!ctrl.initialLoad)
    //  ctrl.visible = (selected !== null);
    $loadAvailableData();
  }

  function _selectNone(selected) {
    ctrl.visible = false;
    ctrl.state.block = null;
    ctrl.state.ranch = null;
  }

  function _setAvailableDates(dates) {
    // TODO: if date is set, but not in available dates, need to init
    // possibly set it to nearest?
    ctrl.state.availableDates = dates;
    if (ctrl.state.date == null && dates.length > 0) {
      ctrl.state.date = dates.slice(-1)[0];
    } else {

    }

    if (!ctrl.initialLoad)
      $loadData();
    ctrl.initialLoad = false;
  }

  function $loadAvailableData() {
    ctrl.loadingData = true;
    if (ctrl.state.block != null) {
      $farmXSatelliteService.getAvailableDatesBlock(ctrl.state.block.id, ctrl.state.dataType)
      .then(function(data) {
        _setAvailableDates(data);
        //$dataLoaded(data, "block");
      }, function(error) {
        $processDataFailure(error);
      });
    } else if (ctrl.state.ranch != null) {
      $farmXSatelliteService.getAvailableDatesRanch(ctrl.state.ranch.id, ctrl.state.dataType)
      .then(function(data) {
        _setAvailableDates(data);
        //$dataLoaded(data, "ranch");
      }, function(error) {
        $processDataFailure(error);
      });
    }
  }

  function $loadData() {
    if (ctrl.state.date == null) return;

    if (ctrl.state.block != null) {
      $farmXSatelliteService.loadSatelliteBlockDataForDate(ctrl.state.block.id, ctrl.state.date, ctrl.state.dataType)
      .then(function(data) {
        $dataLoaded(data, "block");
      }, function(error) {
        $processDataFailure(error);
      });
    } else if (ctrl.state.ranch != null) {
      $farmXSatelliteService.loadSatelliteRanchDataForDate(ctrl.state.ranch.id, ctrl.state.date, ctrl.state.dataType)
      .then(function(data) {
        $dataLoaded(data, "ranch");
      }, function(error) {
        $processDataFailure(error);
      });
    }
  }

  function $dataLoaded(data, type) {
    const blockType = (type == 'block' ? 'SatelliteDataBlock' : 'SatelliteDataRanch');
    const satelliteId = $scope.satelliteId;
    $timeout(function() {
      $rootScope.$broadcast('farmx.satellite.selected', {
        'type': blockType,
        'value': data,
        'visible': ctrl.visible,
        "satelliteId": satelliteId,
      });
    }, 0);
}

  function $processDataFailure(error) {
    ctrl.loadingData = false;
  }
}
