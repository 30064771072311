try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

/* SatellitePlotter */
angular
  .module('farmx-directives-sidenav')
  .directive('farmxSatellitePlotter', FarmXSatellitePlotterDirective)
  .controller('fzSatellitePlotterController', FarmXSatellitePlotterController);

FarmXSatellitePlotterController.$inject = ['$window', '$rootScope', '$scope', '$timeout', '$log', '$farmXSatelliteService'];

function FarmXSatellitePlotterDirective() {
  return {
    restrict: 'E',
    scope: {
      state: '='
    },
    templateUrl: 'satelliteViewer/satellitePlotter.template.html',
    controller: 'fzSatellitePlotterController',
    controllerAs: 'ctrl'
  };
}

function FarmXSatellitePlotterController($window, $rootScope, $scope, $timeout, $log, $farmXSatelliteService) {
  var ctrl = this;
  var scope = $scope;
  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;

  ctrl.getTitle = _getTitle;
  ctrl.itemSelected = _itemSelected;

  ctrl.range = 2;
  ctrl.imagery = [];
  ctrl.dataKey = null;

  ctrl.getImageClass = function(image) {
    if (image.source == "TerrAvion") {
      return "aerial-imagery";
    }
    return "";
  };

  angular.element($window).bind('resize', function() {
    $scope.$apply(function () {
      ctrl.width = $window.innerWidth;
      ctrl.height = $window.innerHeight;
    });
  });

  $scope.$watch("state.date", function(newValue, oldValue) {
    if (newValue != oldValue) {
      console.log("date " + $scope.state.date);
      var notFound = true;
      angular.forEach(ctrl.imagery, function(element, index) {
        if (element.date != null) {
          if (element.date.isSame($scope.state.date)) {
              notFound = false;
          }
        }
      });

      if (notFound) {
        $loadImagery();
      }
    }
  });

  $scope.$watch("state.selectedDate", function(newValue, oldValue) {
    if (newValue != oldValue) {
      console.log("selectedDate " + $scope.state.selectedDate);
      angular.forEach(ctrl.imagery, function(element, index) {
        if (element.date === $scope.state.selectedDate) {
          ctrl.imagery[index].selected = true;
          ctrl.dataKey = _getDataKey(ctrl.imagery[index]);
        } else {
          ctrl.imagery[index].selected = false;
        }
      });
    }
  });

  $scope.$watch("state.availableDates", function(newValue, oldValue) {
    if (newValue != oldValue) {
      $loadImagery();
    }
  }, false);

  $scope.$watch("state.selected", function(newValue, oldValue) {
    if (newValue != oldValue) {
      $loadImagery();
    }
  });

  function _getDataKey(image) {
    return {
      url: image.key_url,
      minValue: image.data_min_value,
      maxValue: image.data_max_value,
      unit: image.data_unit_short,
    };
  }

  function _getTitle(image) {
    if (image.date != null) {
      if (ctrl.width > 550) {
        return image.date.format("D MMMM YYYY");
      } else {
        return image.date.format("D/MMM/YY");
      }
    }

    return "";
  }

  function _itemSelected(image) {
    // console.log("Plotter itemSelected");
    if (image.date != null) {
      $scope.state.date = image.date;
      $scope.state.selectedDate = image.date;
    }
  }

  function $loadImagery() {
    if ($scope.state.availableDates.length === 0)
      return;

    var index = $scope.state.availableDates.findIndex(function(ele) {
      return ele.valueOf() >= $scope.state.date.valueOf()
    });

    var offsetRight = 0;
    for(var i = 1; i <= ctrl.range; i++) {
      if ($scope.state.availableDates[index + i] === undefined) {
        offsetRight = ctrl.range - i + 1;
        break;
      }
    }

    var offsetLeft = 0;
    for(var i = 1; i <= ctrl.range; i++) {
      if ($scope.state.availableDates[index - 1] === undefined) {
        offsetLeft = ctrl.range - i + 1;
        break;
      }
    }

    for(var i=0; i < 2 * ctrl.range + 1; i++) {
      var centerOffset = i - ctrl.range - offsetRight + offsetLeft;
      var dateIndex = index + centerOffset;
      var date = $scope.state.availableDates[dateIndex];
      $updateImage(date, i);
    }
  }

  function $updateImage(date, index) {
    if (date != null) {
      console.log("Plotter $updateImage " + date.valueOf() + " " + date.toString() + " " + index);

      var image = ctrl.imagery[index];
      ctrl.imagery[index].date = date;
      ctrl.imagery[index].name = "";
      ctrl.imagery[index].url = null;

      if ($scope.state.selected.type === "Block") {
        console.log($scope.state.selected.value[1].id + " " + date + " " + $scope.state.dataType);
        $farmXSatelliteService.loadSatelliteBlockDataForDate($scope.state.selected.value[1].id, date, $scope.state.dataType).then(function(success) {
          if (success.length > 0) {
            if (ctrl.imagery[index].date === image.date) {
              ctrl.imagery[index].url = success[0].png_url;
              ctrl.imagery[index].key_url = success[0].data_key_url;
              ctrl.imagery[index].data_min_value = success[0].data_min_value;
              ctrl.imagery[index].data_max_value = success[0].data_max_value;
              ctrl.imagery[index].data_unit_short = success[0].data_unit_short;
              ctrl.dataKey = _getDataKey(ctrl.imagery[index]);

              if (success[0].scene_id.includes("TerrAvion")) {
                ctrl.imagery[index].source = "TerrAvion";
              } else {
                ctrl.imagery[index].source = success[0].source[0];
              }
            }
          } else {
            if (ctrl.imagery[index].date === image.date) {
              ctrl.imagery[index].url = null;
            }
          }

          if ($scope.state.selectedDate != null) {
            $scope.state.selectedDate = $scope.state.date;
          }
        })
      } else if ($scope.state.selected.type === "Ranch") {
        console.log($scope.state.selected.value[0].id + " " + date + " " + $scope.state.dataType);
        $farmXSatelliteService.loadSatelliteRanchDataForDate($scope.state.selected.value[0].id, date, $scope.state.dataType).then(function(success) {
          if (success.length > 0) {
            if (ctrl.imagery[index].date === image.date) {
              ctrl.imagery[index].url = success[0].png_url;
              ctrl.imagery[index].key_url = success[0].data_key_url;
              ctrl.imagery[index].data_min_value = success[0].data_min_value;
              ctrl.imagery[index].data_max_value = success[0].data_max_value;
              ctrl.imagery[index].data_unit_short = success[0].data_unit_short;
              ctrl.dataKey = _getDataKey(ctrl.imagery[index]);

              if (success[0].scene_id.includes("TerrAvion")) {
                ctrl.imagery[index].source = "TerrAvion";
              } else {
                ctrl.imagery[index].source = success[0].source[0];
              }
            }
          } else {
            if (ctrl.imagery[index].date === image.date) {
              ctrl.imagery[index].url = null;
            }
          }
          if ($scope.state.selectedDate != null) {
            $scope.state.selectedDate = $scope.state.date;
          }
        });
      }
    } else {
      ctrl.imagery[index].date = null;
      ctrl.imagery[index].name = "";
      ctrl.imagery[index].url = null;
    }
  }

  this.$onInit = function() {
    for(var ctr=0;ctr < 5;ctr++) {
      ctrl.imagery.push({
        name: "Loading",
        url: null
      });
    }
  }
}
