(function(window, undefined) {
  'use strict';

  angular.module('farmx.widget.blockSummary', ['adf.provider'])
    .config(["dashboardProvider", function(dashboardProvider){
      dashboardProvider
        .widget('blockSummary', {
          title: 'blockSummary',
          description: 'FarmX blockSummary Widget',
          templateUrl: '{widgetsPath}/farmx-widget-blockSummary/src/view.html',
          titleTemplateUrl: '{widgetsPath}/farmx-widget-blockSummary/src/title.html',
          controller: 'blockSummaryController',
          controllerAs: 'ctrl',
          config: {
            ranch: null,
            block: null
          }
        });
    }])
    .controller('blockSummaryController', ["$scope", "$interval", "config", "$farmXEntitiesCache", "$farmXUtilities", function($scope, $interval, config, $farmXEntitiesCache, $farmXUtilities){
      var ctrl = this;
      var blockObject = $farmXEntitiesCache.findBlockById(config.block);
      var ranchObject = $farmXEntitiesCache.findRanchById(config.ranch);

      $scope.$parent.model.controller = ctrl;

      ctrl.selected = {
        value: []
      };
      ctrl.selected.type = "Block";
      ctrl.selected.value.push(ranchObject);
      ctrl.selected.value.push(blockObject);

      ctrl.getTitle = _getTitle;
      ctrl.getTitleFillColor = _getTitleFillColor;
      ctrl.getTitleTextColor = _getTitleTextColor;
      ctrl.viewInMap = _viewInMap;

      function _getTitle() {
        return blockObject.name;
      }

      function _getTitleFillColor() {
        return $farmXUtilities.stateColorCode[blockObject.state.state].fillColor;
      }

      function _getTitleTextColor() {
        return $farmXUtilities.stateColorCode[blockObject.state.state].textColor;
      }

      function _viewInMap() {
        $rootScope.$broadcast("farmx.header.tabSelected", { tabType: "map", params: {ranch: config.ranch, block: config.block }});
      }
    }]);

  angular.module('farmx.widget.blockSummary').run(['$templateCache', function($templateCache) {
    $templateCache.put('{widgetsPath}/farmx-widget-blockSummary/src/title.html',
      '<div layout=row layout-align="start center" class=farmx-widget-title ng-style="{ \'background-color\': definition.controller.getTitleFillColor() }">' +
      '  <span class=farmx-widget-title-left ng-style="{ color: definition.controller.getTitleTextColor() }">{{ definition.controller.getTitle(); }}</span>' +
      '  <span style="outline: none" ng-style="{ color: definition.controller.getTitleTextColor() }" ng-click="definition.controller.viewInMap()">View in map</span>' +
      '</div>'
    );
    $templateCache.put('{widgetsPath}/farmx-widget-blockSummary/src/view.html',
      '<div class=farmx-framed-panel><div class=farmx-framed-area>' +
      '  <div layout=column>' +
      '    <div layout=row layout-align="start stretch">' +
      '      <div class=col-md-2 layout=row>' +
      '        <h3 layout=row layout-align="center center">32</h3>' +
      '        <span layout=row layout-align="center center">%</span>' +
      '      </div>' +
      '      <div class=col-md-4 layout=row>' +
      '        <span layout=row layout-align="center center">Soil moisture at rootzone</span>' +
      '      </div>' +
      '      <div class=col-md-2 layout=row>' +
      '        <h3 layout=row layout-align="center center">20</h3>' +
      '      </div>' +
      '      <div class=col-md-4 layout=row>' +
      '        <span layout=row layout-align="center center">psi</span>' +
      '      </div>' +
      '    </div>' +
      '    <div layout=row layout-align="start stretch">' +
      '      <div class=col-md-2 layout=row layout-align="center center">' +
      '        <i class="fa fa-leaf" style="font-size: 24px" aria-hidden=true></i>' +
      '      </div>' +
      '      <div class=col-md-4 layout=row>' +
      '        <span layout=row layout-align="center center">[plan stress description]</span>' +
      '      </div>' +
      '      <div class=col-md-2 layout=row>'  +
      '        <h3 layout=row layout-align="center center">0.3</h3>' +
      '      </div>' +
      '      <div class=col-md-4 layout=row>' +
      '        <span layout=row layout-align="center center">weekly ETc</span>' +
      '      </div>' +
      '    </div>' +
      '    <div layout=row layout-align="start stretch">'  +
      '      <div class=col-md-2 layout=row layout-align="center center">' +
      '        <i class="fa fa-bell" style="font-size: 24px" aria-hidden=true></i>'  +
      '      </div>'  +
      '      <div class=col-md-4 layout=row>'  +
      '        <h3 layout=row layout-align="center center">6</h3>'  +
      '      </div>' +
      '    </div>' +
      '  </div>' +
      '</div>'
    );
  }]);
})(window);
