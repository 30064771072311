try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXSatellitePickerController.$inject = ['$scope', '$farmXSatelliteService', '$log'];

angular
  .module('farmx-directives-sidenav')
  .directive('farmxSatellitePicker', FarmXSatellitePickerDirective)
  .controller('fzSatellitePickerController', FarmXSatellitePickerController);

function FarmXSatellitePickerDirective() {
  return {
    restrict: 'E',
    scope: {
      state: '=',
    },
    templateUrl: 'satelliteBrowser/satellitePicker.template.html',
    controller: 'fzSatellitePickerController',
    controllerAs: 'ctrl',
  };
}

function FarmXSatellitePickerController($scope, $farmXSatelliteService, $log) {
  var ctrl = this;
  var scope = $scope;

  ctrl.getAlt = _getAlt;
  ctrl.getTitle = _getTitle;
  ctrl.itemClicked = _itemClicked;

  ctrl.range = 2;
  ctrl.imagery = [
    {
      name: "Loading",
      url: null,
    },
    {
      name: "Loading",
      url: null,
    },
    {
      name: "Loading",
      url: null,
    },
    {
      name: "Loading",
      url: null,
    },
    {
      name: "Loading",
      url: null,
    }
  ]

  $scope.$watch("state.date", function(newValue, oldValue) {
    if (newValue == oldValue) return;
    _loadImagery();
  });

  $scope.$watch("state.dataType", function(newValue, oldValue) {
    if (newValue == oldValue) return;
    _loadImagery();
  });

  $scope.$watch("state.block", function(newValue, oldValue) {
    if (newValue == oldValue) return;
    _loadImagery();
  });

  $scope.$watch("state.ranch", function(newValue, oldValue) {
    if (newValue == oldValue) return;
    _loadImagery();
  });

  function _getAlt(image) {
    if (!image.date) return "";
    return image.date.format("D MMMM YYYY");
  }

  function _getTitle(image) {
    if (!image.date) return "";
    return image.date.format("D MMMM YYYY");
  }

  function _itemClicked(item) {
    if (!item.date) return;
    _selectImagery(item);
  }

  function _selectImagery(image) {
    $scope.state.date = image.date;
  }

  function _loadImagery() {
    _clearImagery();

    if ($scope.state.date == null) return;

    if ($scope.state.block != null) {
      $farmXSatelliteService.getAvailableDatesBlock($scope.state.block.id, $scope.state.dataType)
      .then(function(data) {
        _loadImageryFromDates(data);
      });
    } else if ($scope.state.ranch != null) {
      $farmXSatelliteService.getAvailableDatesRanch($scope.state.ranch.id, $scope.state.dataType)
      .then(function(data) {
        _loadImageryFromDates(data);
      });
    }
  }

  function _clearImagery() {
    var emptyImagery = {
      name: "Loading",
      url: null,
    }
    //ctrl.imagery = [];
    for (var i = 0; i < 2 * ctrl.range + 1; i++) {
      //ctrl.imagery.push(Object.assign({}, emptyImagery));
      ctrl.imagery[i].selected = false;
    }
  }

  function _updateImageUrl(image, data, index) {
    if (ctrl.imagery[index].date != image.date) return;
    ctrl.imagery[index].url = data.png_url;
    if (Math.abs(ctrl.imagery[index].date.diff($scope.state.date, 'hours')) < 24)
      ctrl.imagery[index].selected = true;
  }

  function _updateImage(date, index) {
    if (date === undefined) return;
    var image = ctrl.imagery[index];
    ctrl.imagery[index].date = date;
    ctrl.imagery[index].name = "";
    //image.date = date;
    //image.name = "";
    if ($scope.state.block != null) {
      $farmXSatelliteService.loadSatelliteBlockDataForDate($scope.state.block.id, date, $scope.state.dataType)
      .then(function(data) {
        _updateImageUrl(image, data[0], index);
      });
    } else if ($scope.state.ranch != null) {
      $farmXSatelliteService.loadSatelliteRanchDataForDate($scope.state.ranch.id, date, $scope.state.dataType)
      .then(function(data) {
        _updateImageUrl(image, data[0], index);
      });
    }

  }

  function _getNearest(arr, value) {
    var curr = 0;
    for (var i = 1; i < arr.length; i++) {
      if (Math.abs(value - arr[i]) < Math.abs(value - arr[curr]))
        curr = i;
    }
    return curr;
  }

  function _loadImageryFromDates(dates) {
    var index = _getNearest(dates.concat(), $scope.state.date);
    $log.log(index + " " + dates.length + " " + dates[index].format("MMM D Y") + " " + $scope.state.date.format("MMM D Y"));

    // avoid showing blank spaces if selected date has no data after
    offsetRight = 0;
    for (var i = 1; i <= ctrl.range; i++) {
      if (dates[index + i] === undefined) {
        offsetRight = ctrl.range - i + 1;
        break;
      }
    }

    // avoid showing blank spaces if selected date has no data before
    offsetLeft = 0;
    for (var i = 1; i <= ctrl.range; i++) {
      if (dates[index - i] === undefined) {
        offsetLeft = ctrl.range - i + 1;
        break;
      }
    }

    for (var i = 0; i < 2 * ctrl.range + 1; i++) {
      var centerOffset = i - ctrl.range - offsetRight + offsetLeft;
      var dateIndex = index + centerOffset;
      var date = dates[dateIndex];
      _updateImage(date, i);
    }
  }

  this.$onInit = function() {
  };
}
