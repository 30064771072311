(function(window, undefined) {
  'use strict';

  angular.module('farmx.widget.ranchWeather', ['adf.provider'])
    .config(["dashboardProvider", function(dashboardProvider) {
      dashboardProvider
        .widget('ranchWeather', {
          title: 'ranchWeather',
          description: 'FarmX ranchWeather Widget',
          templateUrl: '{widgetsPath}/farmx-widget-ranchWeather/src/view.html',
          controller: 'ranchWeatherController',
          controllerAs: 'ctrl',
          frameless: true,
          config: {
            selected: null
          }
        });
    }]).controller('ranchWeatherController', ["$scope", "$interval", "config", "$farmXEntitiesCache", function($scope, $interval, config, $farmXEntitiesCache){
      var ctrl = this;
      ctrl.id = uuid.v4()

      ctrl.dailyPrecipitationConfig = {
        title: {
          visible: false,
          text: ''
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false,
          visible: false
        },
        chart: {
          type: 'column',
          marginBottom: 5
        },
        xAxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ],
          crosshair: true,
          visible: false
        },
        yAxis: {
          min: 0,
          visible: false
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
        },
        series: [{
          name: 'Tokyo',
          data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
        }
      ]};

      ctrl.dailyTemperatureConfig = {
        title: {
          visible: false,
          text: ''
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false,
          visible: false
        },
        chart: {
          type: 'line',
          marginBottom: 5
        },
        xAxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ],
          crosshair: true,
          visible: false
        },
        yAxis: {
          min: 0,
          visible: false
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
        },
        series: [{
          name: 'Tokyo',
          data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
        }
      ]};

      this.$onInit = function() {
        console.log("Testing");
      }
    }]);

  angular.module('farmx.widget.ranchWeather').run(['$templateCache', function($templateCache) {
    $templateCache.put('{widgetsPath}/farmx-widget-ranchWeather/src/view.html',
      '<div class="farmx-frameless-panel panel panel-default ng-scope">' +
      '  <div class="farmx-frameless-area">' +
      '    <div class="layout-column layout-fill">' +
      '      <div id="weatherInfo-{{ctrl.id}}" class="flex-33 layout-row">' +
      '        <div class="flex-60 layout-row layout-align-start-center">' +
      '          <i class="fa fa-sun-o" aria-hidden="true" style="font-size: 36px; margin-left: 10px"></i>' +
      '          <div style="margin-left: 20px" class="flex-100 layout-column layout-align-start-center">' +
      '            <span style="font-size: 24px">101<sup style="font-size: 16px">º F</sup></span>' +
      '            <span>Sunny</span>' +
      '          </div>' +
      '        </div>' +
      '        <div class="flex-40 layout-row layout-align-start-center">' +
      '          <span style="font-size: 32px">356</span>' +
      '          <div style="margin-left: 10px" class="flex-100 layout-column layout-align-start-start">' +
      '            <span>chill</span>' +
      '            <span>hours</span>' +
      '          </div>' +
      '        </div>' +
      '      </div>' +
      '      <div id="dailyTemperature-{{ctrl.id}}" class="flex-33 layout-column">' +
      '        <span style="font-weight: bold; font-size: 12px">Daily Temperature</span>' +
      '        <div class="layout-row layout-align-start-stretch flex-100">' +
      '          <highchart class="flex-100" config="ctrl.dailyTemperatureConfig"></highchart>' +
      '          <div class="layout-column layout-align-start-start">' +
      '            <div class="layout-row layout-align-start-center">' +
      '              <span>103</span>' +
      '              <span style="margin-left: 5px; font-size: 10px">high</span>' +
      '            </div>' +
      '            <div class="layout-row layout-align-start-center">' +
      '              <span>100</span>' +
      '              <span style="margin-left: 5px; font-size: 10px">avg</span>' +
      '            </div>' +
      '            <div class="layout-row layout-align-start-center">' +
      '              <span>82</span>' +
      '              <span style="margin-left: 5px; font-size: 10px">low</span>' +
      '            </div>' +
      '          </div>' +
      '        </div>' +
      '      </div>' +
      '      <div id="dailyPrecipitation-{{ctrl.id}}" class="flex-33 layout-column">' +
      '        <span style="font-weight: bold; font-size: 12px">Daily Precipitation</span>' +
      '        <div class="layout-row layout-align-start-stretch flex-100">' +
      '          <highchart class="flex-100" config="ctrl.dailyPrecipitationConfig"></highchart>' +
      '          <div class="layout-row layout-align-start-center">' +
      '            <div class="layout-column">' +
      '              <span>2.1in</span>' +
      '              <span>total</span>' +
      '            </div>' +
      '            <div style="margin-left: 5px" class="layout-column">' +
      '              <i style="margin-right: 5px" class="fa fa-tint" aria-hidden="true"></i>' +
      '            </div>' +
      '          </div>' +
      '        </div>' +
      '      </div>' +
      '    </div>' +
      '  </div>' +
      '</div>'
    );
  }]);
})(window);
