(function(angular){
  'use strict';

  try {
    angular.module('farmx-directives-sidenav');
  } catch (err) {
    angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
  }

  angular.module('farmx-directives-sidenav')
  .directive('farmxFlowTableView', function($compile) {
    return {
      restrict: 'EC',
      scope: {
        ranch:     '=ranch',
        startdate:  '=startdate',
        enddate:    '=enddate',
      },
      templateUrl: 'irrigation/flowTable.template.html',
      controller: 'fzFlowTableViewController',
      controllerAs: 'ctrl'
    };
  }).controller('fzFlowTableViewController', function ($rootScope, $scope, $element, $timeout, $filter, $farmXEntitiesCache, $farmXUtilities, $farmXScheduleService, $farmXApi, $log) {
    var ctrl = this;

    ctrl.ranch = null;
    ctrl.blockStats = null;
    ctrl.loadingCumulative = true;
    ctrl.loadingCurrent = true;

    $scope.$watch("ranch", function(newValue, oldValue) {
        ctrl.ranch = newValue;
        _loadData();
    });

    this.$onInit = function() {};

    function _loadData() {
        ctrl.loadingCumulative = true;
        ctrl.loadingCurrent = true;
        $farmXApi.getRanchCurrentFlowStats(ctrl.ranch.id).then(
            function(data) {
                ctrl.ranch.currentFlowStats = data.currentFlowStats;
                ctrl.loadingCurrent = false;
                ctrl.mergeBlockStats(data.currentFlowStats);
            }
        );
    
        $farmXApi.getRanchCumulativeFlowStats(ctrl.ranch.id).then(
            function(data) {
                ctrl.ranch.cumulativeFlowStats = data.cumulativeFlowStats;
                ctrl.loadingCumulative = false;
                ctrl.mergeBlockStats(data.cumulativeFlowStats);
            }
        );
    }

    ctrl.mergeBlockStats = function(stats) {
      if (!ctrl.blockStats) ctrl.blockStats = stats.blockStats;
      for (var i = 0; i < ctrl.blockStats.length; i++) {
        ctrl.blockStats[i]
        for (var attrname in stats.blockStats[i]) {
          ctrl.blockStats[i][attrname] = stats.blockStats[i][attrname];
        }
      }
    };

    var unitOptions = {
      flowRate: [
        {
          key: 'gph',
          name: 'GPH',
          decimals: 0,
        },
        {
          key: 'mgph',
          name: 'M GPH',
          decimals: 0,
        },
        {
          key: 'acrein/hr',
          name: 'Ac. in / hr',
          decimals: 2,
        },
        {
          key: 'acreft/hr',
          name: 'Ac. ft / hr',
          decimals: 1,
        },
        {
          key: 'feet3/hr',
          name: 'CFH',
          decimals: 0,
        },
        {
          key: 'feet3/min',
          name: 'CFM',
          decimals: 0,
        }
      ],
      pressure: [
        {
          key: 'psi',
          name: 'psi',
          decimals: 1,
        },
        {
          key: 'pascal',
          name: 'Pa',
          decimals: 0,
        }
      ],
      flow: [
        {
          key: 'gallons',
          name: 'Gal',
          decimals: 0,
        },
        {
          key: 'mgallons',
          name: 'M Gal',
          decimals: 1,
        },
        {
          key: 'acrein',
          name: 'Acre in',
          decimals: 1,
        },
        {
          key: 'acreft',
          name: 'Acre ft',
          decimals: 1,
        },
        {
          key: 'feet3',
          name: 'Cubic Ft',
          decimals: 0,
        }
      ]
    };
  
    ctrl.units = {
      flow: {
        options: unitOptions.flow,
        selected: unitOptions.flow[0],
      },
      pressure: {
        options: unitOptions.pressure,
        selected: unitOptions.pressure[0],
      },
      flowRate: {
        options: unitOptions.flowRate,
        selected: unitOptions.flowRate[0],
      }
    };
  
    function _convertPressure(psi, units) {
      switch(units) {
        case 'psi':
          return psi;
        case 'pascal':
          return psi * 6894.75729;
        default:
          return null;
      }
    }
  
    function _convertFlowRate(gph, units) {
      switch(units) {
        case 'gph':
          return gph;
        case 'mgph':
          return gph / 1000000;
        case 'acrein/hr':
          return 0.000036828 * gph;
        case 'acreft/hr':
          return 0.000003069 * gph;
        case 'feet3/hr':
          return 0.133681 * gph;
        case 'feet3/min':
          return 0.002228009259258553 * gph;
        default:
          return null;
      }
    }
  
    function _convertFlow(gallons, units) {
      switch(units) {
        case 'gallons':
          return gallons;
        case 'mgallons':
          return gallons / 1000000;
        case 'acrein':
          return 0.000036826598951644 * gallons;
        case 'acreft':
          return 0.0000030688832459704 * gallons;
        case 'feet3':
          return 0.133681 * gallons;
        default:
          return null;
      }
    }
  
    function _convertUnits(value, units, newUnits) {
      switch(units) {
        case 'psi':
          return _convertPressure(value, newUnits);
        case 'gph':
            return _convertFlowRate(value, newUnits);
        case 'gallons':
            return _convertFlow(value, newUnits);
        default:
          return null;
      }
    }
  
    ctrl.convertUnits = function (value, units, newUnits) {
      var newValue = _convertUnits(value, units, newUnits.key);
      return $filter('number')(newValue, newUnits.decimals);
    };

  });
}(angular));
