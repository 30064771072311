try {
    angular.module('farmx-directives-sidenav');
  } catch (err) {
    angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
  }
  
  FarmXSatelliteCompareController.$inject = ["$window", "$rootScope", "$scope", "$timeout", "$log"];
  
  angular
    .module('farmx-directives-sidenav')
    .directive('farmxSatelliteCompare', FarmXSatelliteCompareDirective)
    .controller('fzSatelliteCompareController', FarmXSatelliteCompareController);
  
  
  function FarmXSatelliteCompareDirective() {
    return {
      restrict: 'E',
      scope: {
        selected: '=',
      },
      templateUrl: 'satelliteBrowser/satelliteCompare.template.html',
      controller: 'fzSatelliteCompareController',
      controllerAs: 'ctrl',
      link: function link(scope, element, attrs, controller, transcludeFn) {
      },
    };
  }
  
  function FarmXSatelliteCompareController($window, $rootScope, $scope, $timeout, $log) {
    var ctrl = this;

    ctrl.width = $window.innerWidth;
    ctrl.height = $window.innerHeight;
    ctrl.first = true;

    ctrl.toggleTab = function() {
        ctrl.first = !ctrl.first;
        $rootScope.$broadcast('farmx.map.tab.toggle', {});
    };

    angular.element($window).bind('resize', function() {
        $scope.$apply(function () {
            ctrl.width = $window.innerWidth;
            ctrl.height = $window.innerHeight;
        });
    });

    /*ctrl.getSelected = _getSelected();

    function _getSelected() {
        return $scope.selected;
    }*/

  }
  

  