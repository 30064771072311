try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXAccountSettingsController.$inject = ["$rootScope", "$window", "$scope", "$http", "$q", "$timeout", "$mdDialog", "$farmXApi", "$log"];

angular
  .module('farmx-directives-sidenav')
  .service('$farmxAccountSettings', ["$rootScope", "$mdDialog",
    function($rootScope, $mdDialog, $farmXApi) {
      this.show = function() {
        var result = $mdDialog.show({
          title: 'Preferences',
          templateUrl: 'accountSettings/accountSettings.template.html',
          controller: FarmXAccountSettingsController
        });

        return result;
      }
    }
  ]);

function FarmXAccountSettingsController($rootScope, $window, $scope, $http, $q, $timeout, $mdDialog, $farmXApi, $log) {
  var ctrl = this;

  $scope.profile = {
    email: '',
    phone_number: '',
    email_notifications: true,
    text_notifications: true
  };

  $scope.password = {
    new_password: '',
    old_password: ''
  };

  $scope.cancel = function() {
    $mdDialog.cancel();
  }

  $scope.selected = function(events) {
    var result = $($('div #' + events.currentTarget.id)[0].parentElement).children();

    angular.forEach(result, function(elem) {
      $($('#' + elem.id).children()[0]).removeClass('acSelected');
      $('#' + $('#' + elem.id).data('selected')).removeClass('acSelectedArrow');
      $('#' + $('#' + elem.id).data('selected') + "Page").removeClass('acShowPage');
      $('#' + $('#' + elem.id).data('selected') + "Page").addClass('acHidePage');
    });

    $($(events.currentTarget).children()[0]).addClass('acSelected');
    $('#' + $(events.currentTarget).data('selected')).addClass('acSelectedArrow');
    $('#' + $(events.currentTarget).data('selected') + "Page").removeClass('acHidePage');
    $('#' + $(events.currentTarget).data('selected') + "Page").addClass('acShowPage');
  };

  $scope.saveChangePassword = function() {
    $farmXApi.changePassword($scope.password).then(
      function(data) {
      },
      function(error) {
      }
    );
  }

  $scope.saveProfile = function() {
    $farmXApi.setUserProfile($scope.profile).then(
      function(data) {
      },
      function(error) {
      }
    );
  }

  $scope.cancelProfile = function() {
    $farmXApi.getUserProfile().then(
      function(data) {
        $scope.profile = data;
      }, function(error) {
      }
    )
  }

  this.$onInit = function() {
    $farmXApi.getUserProfile().then(
      function(data) {
        $scope.profile = data;
      }, function(error) {
      }
    )
  };
}
