(function(angular){
    'use strict';
  
    try {
      angular.module('farmx-directives-sidenav');
    } catch (err) {
      angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
    }
  
    angular.module('farmx-directives-sidenav')
    .service('$farmXDashboardService', ["$rootScope", "$mdDialog", "$farmXSensorInfo",
      function($rootScope, $mdDialog, $farmXSensorInfo) {
        this.showScheduleEvent = function(config) {
          return $mdDialog.show({
            parent: angular.element(document.body),
            clockOutsideToClose: true,
            locals: {
              "config": config,
            },
            template: '<md-dialog style="width: 450px; height: 390px;" layout="column" layout-align="start stretch">' +
                      '  <md-toolbar>' +
                      '    <div class="md-toolbar-tools">' +
                      '      <h2>Turn On VFD</h2>' +
                      '    </div>' +
                      '  </md-toolbar>' +
                      '  <md-dialog-content flex="100">' +
                      '    <div class="md-dialog-content" style="padding: 10px">' +
                      '      <div flex="100" layout="column" layout-align="start stretch">' +
                      '        <div flex="100" layout="column" style="margin-top: 10px; margin-bottom: 20px">' +
                      '          <div flex="100" layout="row" layout-align="start center">' +
                      '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 2px 0px 2px; min-width: 30px">From</label>' +
                      '            <md-datepicker ng-model="startDate" md-placeholder="Enter Start Date" md-open-on-focus md></md-datepicker>' +
                      '            <md-input-container class="noErrors" style="left: 10px">' +
                      '              <input ng-model="startHour" type="number" step="1" min="1" max="12">' +
                      '            </md-input-container>' +
                      '            <md-input-container class="noErrors" style="left: 10px">' +
                      '              <input ng-model="startMinute" type="number" step="1" min="1" max="60">' +
                      '            </md-input-container>' +
                      '            <md-input-container class="noErrors" style="left: 10px">' +
                      '              <md-select ng-model="startAMPM">' +
                      '                <md-option value="AM">AM</md-option>' +
                      '                <md-option value="PM">PM</md-option>' +
                      '              </md-select>' +
                      '            </md-input-container>' +
                      '          </div>' +
                      '          <br>' +
                      '          <br>' +
                      '          <div flex="100" layout="row" layout-align="start center">' +
                      '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 2px 0px 2px; min-width: 30px">To</label>' +
                      '            <md-datepicker ng-model="endDate" md-placeholder="Enter End Date" md-open-on-focus></md-datepicker>' +
                      '            <md-input-container class="noErrors" style="left: 10px">' +
                      '              <input ng-model="endHour" type="number" step="1" min="1" max="12">' +
                      '            </md-input-container>' +
                      '            <md-input-container class="noErrors" style="left: 10px">' +
                      '              <input ng-model="endMinute" type="number" step="1" min="1" max="60">' +
                      '            </md-input-container>' +
                      '            <md-input-container class="noErrors" style="left: 10px">' +
                      '              <md-select ng-model="endAMPM">' +
                      '                <md-option value="AM">AM</md-option>' +
                      '                <md-option value="PM">PM</md-option>' +
                      '              </md-select>' +
                      '            </md-input-container>' +
                      '          </div>' +
                      '        </div>' +
                      '      </div>' +
                      '    </div>' +
                      '  </md-dialog-content>' +
                      '  <md-dialog-actions layout="row">' +
                      '    <md-button ng-click="cancel(true)">' +
                      '      Cancel' +
                      '    </md-button>' +
                      '    <span flex></span>' +
                      '    <md-button md-raised md-warn ng-click="submit(true)">' +
                      '      Turn On' +
                      '    </md-button>' +
                      '  </md-dialog-actions>' +
                      '</md-dialog>',
            controller: FarmXVFDScheduleEvent,
            onComplete: function(scope, element) {
              scope.$onInit();
            }
          })
        }
  
        function FarmXVFDScheduleEvent($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $farmXApi, $log, config) {
          var ctrl = this;
  
          $scope.cancel = function() {
            $mdDialog.hide({action: "none", data: config});
          }
  
          $scope.submit = function() {
            var result = Object.assign({}, config);
  
            result.startDate = moment({y: $scope.startDate.getFullYear(),
              M: $scope.startDate.getMonth(),
              d: $scope.startDate.getDate(),
              h: $scope.startAMPM === "PM" ? ($scope.startHour === 12 ? 12 : $scope.startHour + 12) : ($scope.startHour === 12 ? 0 : $scope.startHour),
              m: $scope.startMinute,
              s: 0});
            result.endDate = moment({y: $scope.endDate.getFullYear(),
              M: $scope.endDate.getMonth(),
              d: $scope.endDate.getDate(),
              h: $scope.endAMPM === "PM" ? ($scope.endHour === 12 ? 12 : $scope.endHour + 12) : ($scope.endHour === 12 ? 0 : $scope.endHour),
              m: $scope.endMinute,
              s: 0});
  
            $mdDialog.hide({action: "update", data: result});
          }
  
          $scope.$onInit = function() {
            $scope.startDate = new Date(config.startDate);
            $scope.endDate = new Date(config.endDate);
            $scope.startHour = $scope.startDate.getHours() > 12 ? $scope.startDate.getHours() - 12 : ($scope.startDate.getHours() === 0 ? 12 : $scope.startDate.getHours());
            $scope.endHour = $scope.endDate.getHours() > 12 ? $scope.endDate.getHours() - 12 : ($scope.endDate.getHours() === 0 ? 12 : $scope.endDate.getHours());
            $scope.startMinute = $scope.startDate.getMinutes();
            $scope.endMinute = $scope.endDate.getMinutes();
            $scope.startAMPM = $scope.startDate.getHours() >= 12 ? 'PM' : 'AM';
            $scope.endAMPM = $scope.endDate.getHours() >= 12 ? 'PM' : 'AM';
          }
        }
        this.showStopVFDConfirm = function() {
          return $mdDialog.show({
            parent: angular.element(document.body),
            clockOutsideToClose: true,
            locals: {
              "config": {},
            },
            template: '<md-dialog style="width: 450px; height: 190px;" layout="column" layout-align="start stretch">' +
                      '  <md-toolbar>' +
                      '    <div class="md-toolbar-tools">' +
                      '      <h2>Turn Off VFD</h2>' +
                      '    </div>' +
                      '  </md-toolbar>' +
                      '  <md-dialog-content flex="100">' +
                      '    <div class="md-dialog-content" style="padding: 10px">' +
                      '      <div flex="100" layout="column" layout-align="start stretch">' +
                      '        <p>Confirm turning off vfd.<p>' +
                      '      </div>' +
                      '    </div>' +
                      '  </md-dialog-content>' +
                      '  <md-dialog-actions layout="row">' +
                      '    <md-button ng-click="cancel(true)">' +
                      '      Cancel' +
                      '    </md-button>' +
                      '    <span flex></span>' +
                      '    <md-button md-raised md-warn ng-click="submit(true)">' +
                      '      Turn Off' +
                      '    </md-button>' +
                      '  </md-dialog-actions>' +
                      '</md-dialog>',
            controller: FarmXVFDOffConfirm,
            onComplete: function(scope, element) {
              scope.$onInit();
            }
          })
        }
  
        function FarmXVFDOffConfirm($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $farmXApi, $log) {
          var ctrl = this;
  
          $scope.cancel = function() {
            $mdDialog.hide({action: "none", data: null});
          }
  
          $scope.submit = function() {
            $mdDialog.hide({action: "update", data: null});
          }

          $scope.$onInit = function() {

          }

        }
      }
    ]);
  })(angular);
  