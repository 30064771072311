try {
    angular.module('farmx-directives-sidenav');
} catch (err) {
    angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXAnomalyBrowserController.$inject = ["$window", "$rootScope", "$scope", "$timeout", "$farmXApi", "$farmXEntitiesCache", "$farmXAnomalyService", "$farmXUtilities", "$log"];
FarmXAnomalyService.$inject = ["$rootScope", "$farmXApi", '$timeout'];

angular
    .module('farmx-directives-sidenav')
    .directive('farmxAnomalyBrowser', FarmXAnomalyBrowserDirective)
    .controller('fzAnomalyBrowserController', FarmXAnomalyBrowserController)
    .service('$farmXAnomalyService', FarmXAnomalyService);

function FarmXAnomalyBrowserDirective() {
    return {
        restrict: 'E',
        scope: {
            selected: '=',
        },
        templateUrl: 'satelliteBrowser/anomalyBrowser.template.html',
        controller: 'fzAnomalyBrowserController',
        controllerAs: 'ctrl',
        link: function link(scope, element, attrs, controller, transcludeFn) {},
    };
}

function FarmXAnomalyBrowserController($window, $rootScope, $scope, $timeout, $farmXApi, $farmXEntitiesCache, $farmXAnomalyService, $farmXUtilities, $log) {
    var ctrl = this;
    ctrl.selectedAnomaly = null;
    ctrl.editing = false;
    ctrl.creating = false;

    ctrl.getDirectionsURL = _getDirectionsURL;
    ctrl.openDirections = _openDirections;

    ctrl.createAnomaly = _createAnomaly;
    ctrl.editAnomaly = _editAnomaly;
    ctrl.cancelEditing = _cancelEditing;
    ctrl.finishEditing = _finishEditing;

    ctrl.toggleZoom = _toggleZoom;

    $scope.$watch("selected", function (newValue, oldValue) {
        ctrl.selectedAnomaly = null;
        if (newValue == null) {
            $rootScope.$broadcast('farmx.anomaly.loaded', {});
            return;
        }
        if ($scope.selected.type == "Block") {
            $farmXApi.loadBlockAnomalies($scope.selected.value[1])
            .then(function(data) {
                $rootScope.$broadcast('farmx.anomaly.loaded', {});
            });
        } else if ($scope.selected.type == "Ranch") {
            var ranch = $scope.selected.value[0];
            angular.forEach(ranch.blocks, function(block) {
                $farmXApi.loadBlockAnomalies(block)
                .then(function(data) {
                    $rootScope.$broadcast('farmx.anomaly.loaded', {});
                });
            });
        }
    });

    $scope.$watch("ctrl.selectedAnomaly", function (newValue, oldValue) {
        $log.log("selected anomaly changed");
        $rootScope.$broadcast('farmx.anomaly.selected', {
            // "type": "Ranch",
            "value": ctrl.selectedAnomaly,
          });
    });

    $scope.$on('farmx.anomaly.clicked', function(event, anomaly) {
        $log.log("on anomaly clicked");
        ctrl.selectedAnomaly = anomaly.value;
        //$scope.selected = anomaly.value.blockObject;
    });

    $scope.$on("ctrl.anomaly.created", function(event) {
      ctrl.creating = false;
    });

    ctrl.enabled = function () {
        return $scope.selected != null && $scope.selected.type == "Block";
    };

    ctrl.getAnomalyList = function () {
        if ($scope.selected == null) return [];
        if ($scope.selected.type == "Block") return $scope.selected.value[1].anomalies;
        if ($scope.selected.type == "Ranch") {
            var blocks = $scope.selected.value[0].blocks;
            var anomalies = [];
            angular.forEach(blocks, function(block) {
                if (block.anomalies)
                    anomalies = anomalies.concat(block.anomalies);
            });
            return anomalies;
        }
        return [];
    };

    function _openDirections() {
        $window.open(_getDirectionsURL(), '_blank');
    }

    function _getDirectionsURL() {
        if (ctrl.selectedAnomaly == null) return null;
        var baseURL = "https://www.google.com/maps/dir/?api=1&destination=";
        var lat = ctrl.selectedAnomaly.center.coordinates[1];
        var lng = ctrl.selectedAnomaly.center.coordinates[0];
        return baseURL + lat + "," + lng;
    }

    function _createAnomaly() {
        ctrl.selectedAnomaly = null;
        ctrl.creating = true;
        $rootScope.$broadcast('farmx.anomaly.draw.start', {
            //"value": null,
        });
    }

    function _editAnomaly() {
        ctrl.editing = true;
        $rootScope.$broadcast('farmx.anomaly.edit.start', {
            "value": ctrl.selectedAnomaly,
        });
    }

    function _finishEditing() {
        if (ctrl.editing) {
            ctrl.editing = false;
            $rootScope.$broadcast('farmx.anomaly.edit.stop', {});
        } else if (ctrl.creating) {
            ctrl.creating = false;
            $rootScope.$broadcast('farmx.anomaly.draw.stop', {});
        }
    }

    function _cancelEditing() {
        if (ctrl.editing) {
            ctrl.editing = false;
            $rootScope.$broadcast('farmx.anomaly.edit.cancel', {});
        } else if (ctrl.creating) {
            ctrl.creating = false;
            $rootScope.$broadcast('farmx.anomaly.draw.cancel', {});
        }
    }

    function _toggleZoom() {
        $rootScope.$broadcast('farmx.anomaly.zoom', {});
    }
}

function FarmXAnomalyService($rootScope, $farmXApi, $timeout) {

}
