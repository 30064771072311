module.exports = [
  'jquery',
  'angular',
  'bootstrap',
  'lodash',
  '../../bower_components/angular-animate/angular-animate',
  '../../bower_components/angular-aria/angular-aria',
  '../../bower_components/angular-material/angular-material',
  '../../bower_components/angular-messages/angular-messages',
  '../../bower_components/angular-sanitize/angular-sanitize',
  '../../bower_components/angular-ui-router/release/angular-ui-router',
  '../../bower_components/angular-bootstrap/ui-bootstrap-tpls',
  '../../bower_components/angular-daterangepicker/js/angular-daterangepicker',
  '../../bower_components/angular-chosen-localytics/dist/angular-chosen',

  '../../bower_components/angular-cookies/angular-cookies',
  "../../bower_components/sprintf/dist/angular-sprintf.min",
  "../../bower_components/angular-xeditable/dist/js/xeditable",
  "../../bower_components/angular-dashboard-framework/dist/angular-dashboard-framework-tpls",
  "../../bower_components/angular-bootstrap-datetimepicker-directive/angular-bootstrap-datetimepicker-directive",
  '../../bower_components/angularjs-slider/dist/rzslider',
];
