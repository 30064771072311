module.exports = function($rootScope, $scope, $window, $state, $farmXEntitiesCache, $farmXApi, $farmXSensorInfo, $farmXUtilities, $mdToast, $mdSidenav, $mdDialog, $mdMedia, $mdComponentRegistry, $interval, $timeout, $log, $i18next, $location, $anchorScroll) {
  var ctrl = this;

  ctrl.searchField = "";
  ctrl.sortedByState = false;
  ctrl.sortedByName = false;
  ctrl.filteredEntities = [];
  ctrl.selected = {};
  ctrl.dataLoading = true;
  ctrl.sidenavOpen = true;
  ctrl.ranches = {};
  ctrl.blocks = {};

  ctrl.isLoading = _isLoading;
  ctrl.onRanchClick = _onRanchClick;
  ctrl.onBlockClick =_onBlockClick;
  ctrl.onSensorClick = _onSensorClick;
  ctrl.getStateColor = _getStateColor;
  ctrl.closeAllRanches = _closeAllRanches;
  ctrl.openAllRanches = _openAllRanches;
  ctrl.getBlockCropType = _getBlockCropType;

  ctrl.ranchNameChanged = _ranchNameChanged;
  ctrl.blockNameChanged = _blockNameChanged;
  ctrl.sensorNameChanged = _sensorNameChanged;

  ctrl.scrollId = null;

  angular.element($window).bind('resize', function() {
    ctrl.sidenavOpen = $mdMedia("gt-md");
  });

  $scope.$on('farmx.urlparams.read', function(event, value) {
    if($state.firstTime) {
      selectionBasedOnURLParams();
    }
  });

  $scope.$on('farmx.ranches.update', function(event, value) {
    if (!Object.keys(ctrl.ranches).length) ctrl.ranches = value;
  });

  $scope.$on('farmx.blocks.update', function(event, value) {
    if (!Object.keys(ctrl.blocks).length) ctrl.blocks = value;
  });

  $scope.$on('farmx.entitiesCache.updated', function(event, value) {
    ctrl.filteredEntities = $farmXEntitiesCache.getEntities();

    ctrl.dataLoading = false;

    $mdDialog.cancel();

    // Add condition to restrict default selection when values present in URL
    if ((!$location.url().includes('ranchId') && !$location.url().includes('blockId')) && 
        (!$state.ranchId && !$state.blockId)) {
      if (angular.equals(ctrl.selected, {}) && ctrl.filteredEntities[0].ranches[0] !== undefined) {
        $timeout(function() {
          selectRanch(ctrl.filteredEntities[0].ranches[0]);
        }, 0);
        ctrl.filteredEntities[0].ranches[0].opened = true;
      }
    } else {
      // Add logic to set ranch opened = true   
      var ranchIdFromURL = $state.ranchId ? $state.ranchId : $location.search().ranchId;
      angular.forEach(ctrl.filteredEntities,function(eEntity, iEntity) {
        angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
          if (Number(ranchIdFromURL) === eRanch.id) {
            eRanch.opened = true;
          }
        });
      });
    }
  });

  $scope.$on("farmx.entitiesCache.internal.update.ranch.state", function(event, value) {
    angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
      angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
        if (value[0] === eRanch.id) {
          eRanch.state = value[1];
          eRanch.weather = value[2];
        }
      });
    });
  });

  $scope.$on("farmx.entitiesCache.internal.update.block.state", function(event, value) {
    angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
      angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
        angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
          if (value[0] === eBlock.id) {
            eBlock.state = value[1];
          }
        });
      });
    });
  });

  $scope.$on("farmx.entitiesCache.internal.update.state", function(event, value) {
    angular.forEach(value, function(eEntity, iEntity) {
      var filteredEntity = ctrl.filteredEntities.find(function(eFilteredEntity) {
        return eFilteredEntity.id === eEntity.id;
      });

      angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
        var filteredRanch = filteredEntity.ranches.find(function(eFilteredRanch) {
          return eFilteredRanch.id === eRanch.id;
        });

        filteredRanch.state = eRanch.state;
        filteredRanch.weather = eRanch.weather;

        angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
          var filteredBlock = filteredRanch.blocks.find(function(eFilteredBlock) {
            return eFilteredBlock.id === eBlock.id;
          });

          filteredBlock.state = eBlock.state;
        });
      });
    });
    $anchorScroll(ctrl.scrollId);
  });

  $scope.$watch('ctrl.searchField', function(newValue, oldValue) {
    if(newValue !== oldValue)
      $farmXEntitiesCache.setEntitiesSearchFilter(newValue);
  });

  $scope.$watch('ctrl.sortedByState', function(newValue, oldValue) {
    if(newValue !== oldValue)
      $farmXEntitiesCache.sortEntitiesByState(newValue);
  });

  $scope.$watch('ctrl.sortedByName', function(newValue, oldValue) {
    if(newValue !== oldValue)
      $farmXEntitiesCache.sortEntitiesByName(newValue);
  });

  $scope.$on('farmx.sidenav.selected', function(event, value) {
    // ctrl.selected = angular.copy(value);
    ctrl.selected = value;
  });

  $scope.$on('farmx.graph.selected', function(event, value) {
    if (value.type === ctrl.selected.type) {
      if (value.value[value.value.length - 1].id === ctrl.selected.value[ctrl.selected.value.length - 1].id) {
        return;
      }
    }

    if (value.type === "Block") {
      angular.forEach(ctrl.filteredEntities, function(eEntity, iEntity) {
        angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
          if (eRanch.id === value.value[0].id) {
            eRanch.opened = true;
          }
        });
      });
    }

    if (value.type === "Sensor") {
      angular.forEach(ctrl.filteredEntities, function(eEntity, iEntity) {
        angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
          if (eRanch.id === value.value[0].id) {
            eRanch.opened = true;
          }
          angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
            if (eBlock.id === value.value[1].id) {
              eBlock.opened = true;
            }
          });
        });
      });
    }

    // ctrl.selected = angular.copy(value);
    ctrl.selected = value;
  });

  $scope.$on('farmx.map.selected', function(event, value) {
    if (value.type === "Block") {
      angular.forEach(ctrl.filteredEntities, function(eEntity, iEntity) {
        angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
          if (eRanch.id === value.value[0].id) {
            eRanch.opened = true;
          }
        });
      });
    }

    if (value.type === "Sensor") {
      angular.forEach(ctrl.filteredEntities, function(eEntity, iEntity) {
        angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
          if (eRanch.id === value.value[0].id) {
            eRanch.opened = true;
          }
          angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
            if (eBlock.id === value.value[1].id) {
              eBlock.opened = true;
            }
          });
        });
      });
    }

    // ctrl.selected = angular.copy(value);
    ctrl.selected = value;
  });

  function _ranchNameChanged(data) {
    $timeout(function() {$farmXApi.updateRanchName(data);}, 100);
  }

  function _blockNameChanged(data) {
    $timeout(function() {$farmXApi.updateBlockName(data);}, 100);
  }

  function _sensorNameChanged(data) {
    $timeout(function() {$farmXApi.updateSensorName(data);}, 100);
  }

  function _getBlockCropType(block) {
    if ($farmXSensorInfo.cropType[block.crop] === undefined || $farmXSensorInfo.cropType[block.crop] === null) {
      return $farmXSensorInfo.cropType.none.icon;
    }

    return $farmXSensorInfo.cropType[block.crop].icon;
  }

  function _closeAllRanches(event, entity) {
    angular.forEach(entity.ranches, function(eRanch, iRanch) {
      eRanch.opened = false;
      angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
        eBlock.opened = false;
      });
    });
  }

  function _openAllRanches(event, entity) {
    angular.forEach(entity.ranches, function(eRanch, iRanch) {
      eRanch.opened = true;
      angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
        eBlock.opened = true;
      });
    });
  }

  function selectRanch(ranch) {
    $rootScope.$broadcast('farmx.sidenav.selected', {
      "type": "Ranch",
      "value": [ranch],
      "startTime": new Date().getTime()
    });
  }

  function _onRanchClick(event, ranch) {
    $location.url($location.path());
    if ($(event.target).closest('.editable-text').length > 0) {
      // do nothing
    } else if ($(event.target).closest('.ranch-item-dropdown').length > 0) {
      ranch.opened = !ranch.opened;
    } else {
      selectRanch(ranch);
    }
  }

  function _onBlockClick(event, ranch, block) {
    $location.url($location.path());
    if ($(event.target).closest('.editable-text').length > 0) {
      $log.log("clicking edit text");
    } else if ($(event.target).closest('.block-item-dropdown').length > 0) {
      block.opened = !block.opened;
    } else {
      $rootScope.$broadcast('farmx.sidenav.selected', {
        "type": "Block",
        "value": [ranch, block],
        "startTime": new Date().getTime()
      });
    }
  }

  function _onSensorClick(event, ranch, block, sensor) {
    if ($(event.target).closest('.editable-text').length > 0) {
      $log.log("clicking edit text");
    } else {
      $rootScope.$broadcast('farmx.sidenav.selected', {
        "type": "Sensor",
        "value": [ranch, block, sensor],
        "startTime": new Date().getTime()
      });
    }
  }

  $scope.$on('farmx.sidenav.selected',  function(event, selected) {
    $rootScope.$broadcast('farmx.nav.close');
  });

  function _isLoading() {
    return ctrl.dataLoading;
  }

  function _getStateColor(stateCode) {
    if (stateCode === undefined) {
      return {
        "background-color": $farmXUtilities.stateColorCode[0].fillColor
      };
    }
    return {
      "background-color": $farmXUtilities.stateColorCode[stateCode].fillColor
    };
  }

  // Temporarily commented until testing completed
  //function scrollTodiv(id) { 
      // $location.hash(id);
      //$anchorScroll(); 
  //}

  function selectionBasedOnURLParams() {
    var location = $location;  
    if ((location.search() && location.search().ranchId && !location.search().blockId) || 
        ($state.ranchId && !$state.blockId)) {
      var ranchFromURL = $state.ranchId ? $state.ranchId : location.search().ranchId;
      // clear URL parameter after consumption
      if(location.search().ranchId)
        location.search('ranchId',null);
      if (ranchFromURL) {
        var ranchArray = [ctrl.ranches[ranchFromURL]];
        selectRanch(ranchArray[0]);
        // Temporarily commented until testing completed
        //scrollTodiv("ranch-item-" + ranchFromURL);
        ctrl.scrollId = "ranch-item-" + ranchFromURL;
      }
    }
    if ((location.search() && location.search().ranchId && location.search().blockId) || 
        ($state.ranchId && $state.blockId)) {
      var blockFromURL = $state.blockId ? $state.blockId : location.search().blockId;
      var ranchIdFromURL = $state.ranchId ? $state.ranchId : location.search().ranchId;
      if (blockFromURL && ranchIdFromURL) {
        var ranchAndBlockArray = [ctrl.ranches[ranchIdFromURL], ctrl.blocks[blockFromURL]];
        // clear URL parameters after consumption
        if(location.search().ranchId)
          location.search('ranchId',null);
        if(location.search().blockId)
          location.search('blockId',null);
        $rootScope.$broadcast('farmx.sidenav.selected', {
          "type": "Block",
          "value": ranchAndBlockArray,
          "startTime": new Date().getTime()
        });
        // Temporarily commented until testing completed
        //scrollTodiv("block-item-" + blockFromURL);
        ctrl.scrollId = "block-item-" + blockFromURL;
      }
    }
  }

  this.$onInit = function() {
    //$anchorScrollProvider.disableAutoScrolling();
    if ($state.ranchId || $state.blockId) $state.firstTime = true;
    else $state.firstTime = false;

    ctrl.dataLoading = true;

    $farmXApi.logoutOldApi();
    ctrl.is_admin = $farmXApi.getJWTInfo().admin;

    // loadingSpinner handler with ajax
    $(document).ajaxStart(function(){
      $('#loadingSpinner').show();
    }).ajaxStop(function(){
      $('#loadingSpinner').hide();
    });

    // Below logic is to handle URL params
    if(localStorage.getItem('entities')){
      $farmXEntitiesCache.getPersistedEntities();
    } else {
      $mdDialog.show({
        parent: angular.element(document.body),
        onComplete: function(scope, element) {
          $('.md-dialog-container').css('z-index', 10000);
        },
        template:
          '<md-dialog aria-label="' + $i18next.t('Loading Data') + '">' +
          '  <md-dialog-content>' +
          '    <span style="line-height: 36px; padding: 10px">' + $i18next.t('Loading Data') + '</span>' +
          '  </md-dialog-content>' +
          '</md-dialog>'
      });
    }

    if(!$state.firstTime) {
      selectionBasedOnURLParams();
    }
    $farmXEntitiesCache.cacheInitialize();
    ctrl.sortedByState = true;
    ctrl.sortedByName = true;
  };
};
