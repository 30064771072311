module.exports = function($rootScope, $window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $farmXApi, $farmXEntitiesCache, $farmXUtilities, $log) {
  var ctrl = this;

  ctrl.selected = {};
  ctrl.ranch = undefined;
  ctrl.blocks = [];
  ctrl.blockStates = {};
  ctrl.headerModel = {
    "title": " ",
    "rows": [
      {
        "columns": [
          {
            "styleClass": "col-md-3",
            "widgets": [
              {
                "type": "ranchSummary",
                "config": {
                  "selected": ctrl.selected
                }
              },
            ]
          },
          {
            "styleClass": "col-md-3",
            "widgets": [
              {
                "type": "ranchDailyETC",
                "config": {
                  "selected": ctrl.selected
                }
              }
            ]
          },
          {
            "styleClass": "col-md-3",
            "widgets": [
              {
                "type": "ranchWeather",
                "config": {
                  "selected": ctrl.selected
                }
              }
            ]
          },
          {
            "styleClass": "col-md-3",
            "widgets": [
              {
                "type": "ranchMap",
                "config": {
                  "selected": ctrl.selected
                }
              }
            ]
          }
        ]
      }
    ]
  };
  ctrl.footerModel = {
    "title": " ",
    "rows": [
      {
        "columns": [
          {
            "styleClass": "col-md-3",
            "widgets": [
            ]
          },
          {
            "styleClass": "col-md-3",
            "widgets": [
            ]
          },
          {
            "styleClass": "col-md-3",
            "widgets": [
            ]
          },
          {
            "styleClass": "col-md-3",
            "widgets": [
            ]
          },
        ]
      }
    ]
  };
  ctrl.graphSelected = {
    "summary": true,
    "soil": true,
    "weather": true,
    "irrigation": true,
    "map": true
  };
  ctrl.searchField = "";

  ctrl.getRanchBackgroundColor = _getRanchBackgroundColor;
  ctrl.getRanchColor = _getRanchColor;
  ctrl.getBlockCountForState = _getBlockCountForState;
  ctrl.getStates = _getStates;
  ctrl.viewInMap = _viewInMap;

  $scope.$watch('ctrl.graphSelected', function(newValue, oldValue) {
    if (newValue.summary !== oldValue.summary ||
      newValue.soil !== oldValue.soil ||
      newValue.weather !== oldValue.weather ||
      newValue.irrigation !== oldValue.irrigation ||
      newValue.map !== oldValue.map) {
      $filterBlocks(ctrl.selected.blocks);
    }
  }, true);

  $scope.$watch('ctrl.searchField', function(newValue, oldValue) {
    var blocks;
    if (newValue !== oldValue) {
      if (ctrl.ranch !== undefined && ctrl.ranch.blocks !== undefined) {
        if (newValue === undefined || newValue === null || newValue === "") {
          blocks = ctrl.ranch.blocks;
        } else {
          blocks = ctrl.ranch.blocks.filter(function(eBlock) {
            return eBlock.name.indexOf(newValue) > -1;
          });
        }

        var blockIds = [];

        angular.forEach(blocks, function(eBlock, iBlock) {
          blockIds.push(eBlock.id);
        });

        ctrl.selected.blocks = blockIds;

        $filterBlocks(ctrl.selected.blocks);
      }
    }
  });

  $scope.$on('farmx.sidenav.selected', function(event, value) {
    $updateSelected(value);
  });

  $scope.$on('ctrl.selected', function(event, value) {
    $filterBlocks(value.blocks);
  });

  function _viewInMap() {
    $rootScope.$broadcast("farmx.header.tabSelected", { tabType: "map", params: {ranch: ctrl.selected.ranch, block: null }});
  }

  function _getRanchBackgroundColor() {
    return $farmXUtilities.stateColorCode[ctrl.ranch.state.state].fillColor;
  }

  function _getRanchColor() {
    return $farmXUtilities.stateColorCode[ctrl.ranch.state.state].textColor;
  }

  function _getBlockCountForState(state) {
    var result = 0;

    angular.forEach(ctrl.blocks, function(eBlock, iBlock) {
      if (eBlock.state.state === state) {
        result++;
      }
    });

    return result;
  }

  function _getStates() {
    return $farmXUtilities.stateColorCode;
  }

  function $updateSelected(value) {
    if (value.type === "Ranch") {
      ctrl.ranch = value.value[0];

      ctrl.selected = {
        ranch: value.value[0].id,
        blocks: [],
        type: "Ranch"
      };

      angular.forEach(value.value[0].blocks, function(eBlock, iBlock) {
        ctrl.selected.blocks.push(eBlock.id);
      });
    } else if (value.type === "Block") {
      ctrl.ranch = value.value[0];

      ctrl.selected = {
        ranch: value.value[0].id,
        blocks: [value.value[1].id],
        type: "Block"
      };
    } else if (value.type === undefined) {
      ctrl.ranch = undefined;
      ctrl.selected = {};
    }

    angular.forEach(ctrl.headerModel.rows, function(eRow, iRow) {
      angular.forEach(eRow.columns, function(eColumn, iColumn) {
        angular.forEach(eColumn.widgets, function(eWidget, iWidget) {
          if (eWidget.config.selected !== undefined) {
            eWidget.config.selected = ctrl.selected;
          }
        });
      });
    });

    if (ctrl.selected.type !== undefined) {
      $filterBlocks(ctrl.selected.blocks);
    }
  }

  function $filterBlocks(blocks) {
    if (blocks === undefined) {
      blocks = [];
    }

    ctrl.blocks = [];
    var ctr = 0;

    angular.forEach(ctrl.footerModel.rows[0].columns, function(eColumn, iColumn) {
      eColumn.widgets = [];
    });

    angular.forEach(ctrl.selected.blocks, function(eBlock, iBlock) {
      ctrl.blocks.push($farmXEntitiesCache.findBlockById(eBlock));
      var selected = {
        "ranch": ctrl.selected.ranch,
        "blocks": [eBlock],
        "type": "Block"
      };

      if (ctrl.graphSelected.summary) {
        ctrl.footerModel.rows[0].columns[ctr % 4].widgets.push({
          "type": "blockSummary",
          "config": {
            "ranch": ctrl.selected.ranch,
            "block": eBlock
          }
        });
        ctr += 1;
      }

      if (ctrl.graphSelected.soil) {
        ctrl.footerModel.rows[0].columns[ctr % 4].widgets.push({
          "type": "blockSoil",
          "config": {
            "ranch": ctrl.selected.ranch,
            "block": eBlock
          }
        });
        ctr += 1;
      }

      if (ctrl.graphSelected.weather) {
        ctrl.footerModel.rows[0].columns[ctr % 4].widgets.push({
          "type": "blockWeather",
          "config": {
            "ranch": ctrl.selected.ranch,
            "block": eBlock
          }
        });
        ctr += 1;
      }

      if (ctrl.graphSelected.irrigation) {
        ctrl.footerModel.rows[0].columns[ctr % 4].widgets.push({
          "type": "blockIrrigation",
          "config": {
            "ranch": ctrl.selected.ranch,
            "block": eBlock
          }
        });
        ctr += 1;
      }

      if (ctrl.graphSelected.map) {
        ctrl.footerModel.rows[0].columns[ctr % 4].widgets.push({
          "type": "blockMap",
          "config": {
            "ranch": ctrl.selected.ranch,
            "block": eBlock
          }
        });
        ctr += 1;
      }
    });
  }

  this.$onInit = function() {
    if ($scope.$parent.ctrl.selected.type !== undefined) {
      $updateSelected($scope.$parent.ctrl.selected);
    }
  };
};
