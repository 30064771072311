try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXThreedController.$inject = ["$scope", "$element", "$sce", "$farmXApi", "$timeout", "$interval", "$log"];

angular
  .module('farmx-directives-sidenav')
  .directive('farmxThreedView', FarmXThreedViewDirective)
  .controller('fzThreedController', FarmXThreedController);

function FarmXThreedViewDirective() {
  return {
    restrict: 'E',
    scope: {
      toggle:     '=toggle',
      blockId:    '@blockId',
    },
    templateUrl: 'threed/threed.template.html',
    controller: 'fzThreedController',
    controllerAs: 'ctrl',
  };
}

function FarmXThreedController($scope, $element, $sce, $farmXApi, $timeout, $interval, $log) {
  var ctrl = this;
  this.scope = $scope;
  this.element = $element;
  ctrl.close = _close;
  ctrl.showSettings = _showSettings;

  ctrl.pause = _pause;
  ctrl.initRenderer = _initRenderer;
  ctrl.initScene = _initScene;
  ctrl.initCamera = _initCamera;
  ctrl.initLights = _initLights;
  ctrl.initSky = _initSky;
  ctrl.initGround = _initGround;
  ctrl.onDepthChangeListener = _onDepthChangeListener;
  ctrl.onStartDateChange = _onStartDateChange;
  ctrl.onEndDateChange = _onEndDateChange;
  // ctrl.$$loadData = $$loadData;

  $scope.isAnimateRunning = false;
  $scope.notificationText = $('#threed-container #notificationText');

  $scope.$on('farmx.sidenav.selected', function(event, selected) {
    $timeout(function() {
      $scope.renderer.clear();
      $('#threed-container canvas').remove();
      $scope.notificationText.css("display", "");
      notificationText.innerHTML = "Loading 3D Data";

      $$init();

      $scope.ctrl.initRenderer();
      $scope.ctrl.initScene();

      $scope.ctrl.initCamera();
      $scope.ctrl.initLights();

      $scope.ctrl.initGround();
      $$initData();
    }, 0);
  });

  $scope.$watch('settingsEnabled', function(newValue, oldValue) {
    if (newValue != oldValue) {
      if (newValue) {
        $timeout(function() {
          var elem = $("#key-slider")[0];
          window.noUiSlider.create(elem, {
            start: [0.0, 0.5],
            connect: [true, true, true],
            orientation: "horizontal",
            range: {
              'min': 0.0,
              'max': 0.5
            },
            pips: {
              mode: 'count',
              values: 4,
              density: 8,
              format: wNumb({
                decimals: 1
              })
            }
          });

          var classes = ['c-1-color', 'c-2-color', 'c-3-color' ];
          $("#key-slider .noUi-connect").each(function(index) {
            $(this).addClass(classes[index]);
          });

          elem.noUiSlider.on('update', function(values, handle) {
            minValue = parseFloat(values[0]);
             maxValue = parseFloat(values[1]);
             if ($scope.ranchObject)
                 $scope.ranchObject.setDataRange(minValue, maxValue);
          });
        }, 1000);
      }
    }
  });

  function _onStartDateChange() {
    $$loadData([ $scope.startDate, $scope.endDate ]);
  }

  function _onEndDateChange() {
    $$loadData([ $scope.startDate, $scope.endDate ]);
  }

  function _pause() {
    $scope.paused = !$scope.paused;
  }

  function _onDepthChangeListener(sliderId) {
    if ($scope.ranchObject) {
      $scope.ranchObject.setSelectedDepth(($scope.depth.value / 8) - 1);
    }
  }

  function _close() {
    $scope.toggle = !$scope.toggle;
  }

  function _showSettings() {
    $scope.settingsEnabled = !$scope.settingsEnabled;
  }

  function _initRenderer() {
    if (global.isWebGLEnabled) {
      $scope.renderer = new THREE.WebGLRenderer( {antialias:true, logarithmicDepthBuffer: false} );
    } else {
      $scope.renderer = new THREE.CanvasRenderer();
    }

    $scope.container = $('#threed-container');
    $scope.renderer.setSize($scope.container.innerWidth(), $scope.container.innerHeight());
    $scope.renderer.setClearColor(0x000000);
    $scope.renderer.autoClear = false;
    $scope.renderer.gammaInput = true;
    $scope.renderer.gammaOutput = true;
    $scope.renderer.shadowMap.enabled = true;

    $scope.container.append($scope.renderer.domElement);
  }

  function _initScene() {
    $scope.raycaster = new THREE.Raycaster();

    $scope.scene = new THREE.Scene();
    $scope.sceneRTT = new THREE.Scene();
    $scope.tileScene = new THREE.Scene();
    $scope.sensorScene = new THREE.Scene();

    $scope.texturesRTT = [];
    $scope.scene.fog = new THREE.Fog( 0xffffff, .0135, 100 );
  }

  function _initCamera() {
    $scope.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 50000 * Geometry.SCALING_FACTOR);

    $scope.controls = new THREE.TrackballControls($scope.camera, $scope.renderer.domElement);
    $scope.controls.minDistance = 1;
    $scope.controls.maxDistance = 10;
    $scope.controls.keys = [ 65, 83, 68 ];
    $scope.controls.staticMoving = true;

    $scope.cam = new THREE.AnimatedCamera($scope.camera, $scope.controls);

    $scope.cameraRTT = new THREE.OrthographicCamera( window.innerWidth/ -2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, -10000, 10000 );
    $scope.cameraRTT.position.x = 0;
    $scope.cameraRTT.position.y = 0;
    $scope.cameraRTT.position.z = 10;
    $scope.cameraRTT.up = new THREE.Vector3(0,1,0);
    $scope.cameraRTT.lookAt(new THREE.Vector3(0,0,0));
  }

  function _initLights() {
    var ambLight = new THREE.AmbientLight( 0xFFFFFF, .1 );
    $scope.tileScene.add(ambLight);
  }

  function _initSky() {
    $scope.sky = new THREE.Sky();

    var uniforms = $scope.sky.uniforms;
    uniforms.turbidity.value = 10;
    uniforms.reileigh.value = 2;
    uniforms.luminance.value = 1;
    uniforms.mieCoefficient.value = 0.005;
    uniforms.mieDirectionalG.value = 0.8;
    var inclination = 0.2;
    var azimuth = 0.25;

    var theta = Math.PI * ( inclination - 0.5 );
    var phi = 2 * Math.PI * ( azimuth - 0.5 );

    var distance = 400000;
    var position = new THREE.Vector3();
    position.x = distance * Math.cos( phi );
    position.y = distance * Math.sin( phi ) * Math.sin( theta );
    position.z = distance * Math.sin( phi ) * Math.cos( theta );

    uniforms.sunPosition.value.copy( position );
  }

  function _initGround() {
    $scope.ground = new THREE.Ground();
    $scope.ground.mesh.position.z = -.001;
  }

  function $$initData() {
    var axisHelper = new THREE.AxisHelper( 5 );
    $scope.font = new THREE.Font(global.THREEDFONT);

    var block = $scope.$parent.$parent.ctrl.getSelected();
    $farmXApi.getBlock3D(block.value[1].id).then(function(data) {
      $scope.blockData = data;

      if (!data.ranches || !data.ranches.length || !data.ranches[0].sensors.length) {
          notificationText.innerHTML = "3D not available for this block";
          return;
      }

      data.ranches[0].sensors = data.ranches[0].sensors.filter( function(item) {
          return item.data[0] !== undefined;
      });

      $scope.startDate = moment(data.ranches[0].sensors[0].dates[0]);
      $scope.endDate = moment(data.ranches[0].sensors[0].dates[1]);

      var intervalHandler = $interval(function() {
        if ($scope.isAnimateRunning === false) {
          $scope.stopAnimate = false;
          $$animate();
          $interval.cancel(intervalHandler);
        }
      }, 1000);

      $$initGeometry();
    }, function(error) {
    });
  }

  function $$loadTiles(zoom, offset, ranchCenter) {
    var z = zoom;
    var radius = 6;

    var center = Geometry.getPositionFromLocal(ranchCenter);
    var centerCoords = Geometry.getTileCoords(center, z);

    var width = radius * 2 + 1;
    for (var xi = 0; xi < radius*2+1; xi++) {
      var xeven = (xi+1) % 2;
      var x = (xeven-1) * (xi+1)/2 + xeven * xi/2;

      var xPos = centerCoords.x + x;
      for (var yi = 0; yi < radius*2+1; yi++) {
        var yeven = (yi+1) % 2;
        var y = (yeven-1) * (yi+1)/2 + yeven * yi/2;
        var yPos = centerCoords.y + y;

        var tile = new Tile(xPos, yPos, z);
        tile.load(tileImage(tile.zoom, Math.floor(tile.x), Math.floor(tile.y)), $scope.renderer);
        tile.mesh.material.uniforms.fade.value = 1.0;

        tile.mesh.material.uniforms.fadeCenter.value = ranchCenter;
        tile.mesh.material.uniforms.saturation.value = 0.3;

        $scope.tileScene.add(tile.mesh);
        tile.mesh.position.z += offset;

        $scope.tiles.push(tile);
      }
    }
  };

  function $$calcCameraDist(ranch) {
      var width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

      var height = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;

      ranch.mesh.geometry.computeBoundingSphere();
      var objectSize = ranch.mesh.geometry.boundingSphere.radius * 1.2;

      // Convert camera fov degrees to radians
      var fov = $scope.camera.fov * ( Math.PI / 180 );
      var paddingW = 400;
      if (width < 800) {
          paddingW = 0;
      }

      // Calculate the camera distance
      var distance = Math.abs( objectSize * height / (width-paddingW) / Math.sin( fov / 2 ) );
      var distance2 = ranch.getHeight() * 10;

      return distance;
  };

  function $$renderTileTexture(minCoord, maxCoord, shape, zoom) {
      var minTile = Geometry.getTileCoords(Geometry.getPositionFromLocal(minCoord), zoom);
      var maxTile = Geometry.getTileCoords(Geometry.getPositionFromLocal(maxCoord), zoom);

      var width = maxTile.x - minTile.x + 1;
      var height = maxTile.y - minTile.y + 1;

      if (width % 2 != 0) {
          width += 1;
          maxTile.x += 1;
      }
      if (height % 2 != 0) {
          height += 1;
          maxTile.y += 1;
      }

      if (width > height) {
          var diff = width - height;
          height += diff;
          maxTile.y += diff;
      } else if (width < height) {
          var diff = height - width;
          width += diff;
          maxTile.x += diff;
      }

      // TODO: convert to local coords
      var scaleFactor = Math.pow(2, MAX_ZOOM-zoom);
      var centerX = ((minTile.x + maxTile.x + 1) / 2 * scaleFactor - SCALING_OFFSET.x) * SCALING_FACTOR;
      var centerY = ((minTile.y + maxTile.y + 1) / 2 * scaleFactor - SCALING_OFFSET.y) * SCALING_FACTOR;
      var viewSize = width * scaleFactor * SCALING_FACTOR;

      $scope.cameraRTT = new THREE.OrthographicCamera( -viewSize/2, viewSize / 2, viewSize / 2, viewSize / - 2, -10000, 10000 );
      $scope.cameraRTT.position.x = centerX;
      $scope.cameraRTT.position.y = -centerY;
      $scope.cameraRTT.position.z = 10;
      $scope.cameraRTT.up = new THREE.Vector3(0,1,0);
      $scope.cameraRTT.lookAt(new THREE.Vector3(centerX,-centerY,0));

      var numTiles = width * height;

      var textureSize = 512;
      var size = textureSize * width;

      // TODO check this line for scaling
      var minX = (minTile.x * scaleFactor - SCALING_OFFSET.x) * SCALING_FACTOR;
      var maxX = ((maxTile.x+1) * scaleFactor - SCALING_OFFSET.x) * SCALING_FACTOR;
      var minY = ((-maxTile.y -1)*scaleFactor + SCALING_OFFSET.y) * SCALING_FACTOR;
      var maxY = (-(minTile.y) * scaleFactor + SCALING_OFFSET.y) * SCALING_FACTOR;

      shape.recalculateUvs(minX, maxX, minY, maxY);

      for (var x = minTile.x; x < maxTile.x+1; x++) {
          for (var y = minTile.y; y < maxTile.y+1; y++) {
              var tile = new Tile(x, y, zoom);
              tile.shaderMaterial.uniforms.saturation.value = 1.0;
              tile.load(tileImage(tile.zoom, Math.floor(tile.x), Math.floor(tile.y)), $scope.renderer);
              $scope.sceneRTT.add(tile.mesh);
          }
      }

      var tileTexture = new THREE.WebGLRenderTarget(size, size, { format: THREE.RGBFormat } );
      tileTexture.anisotropy = $scope.renderer.getMaxAnisotropy();

      $scope.renderedTexture = tileTexture;
      return tileTexture;
  };

  function $$initGeometry() {
    var center = $$loadRanch();
    $$loadTiles(15, -0.001, center);
  }

  var tileImage = function (z, x, y) {
      //return 'https://mt.google.com/vt/lyrs=s&hl=en&x=' + x + '&y=' + y + '&z=' + z + '&s=Ga';
      //return '/api/soil/wms/?x=' + x + '&y=' + y + '&z=' + z;
      return 'https://api.mapbox.com/v4/mapbox.satellite/' + z + '/'+ x + '/' + y + '@2x.png?access_token=pk.eyJ1IjoiZmFybXgiLCJhIjoiY2lnODkydzJpMDFlanR6bTdjOXB6MDJyMSJ9.w2lYGZBvj_OP5jbiK4KRAw'
  };

  var $$showGround = function(visible) {
      for (var i = 0; i < $scope.tiles.length; i++) {
          if (visible) {
              $scope.tiles[i].setOpacity(1.0);
          } else {
              $scope.tiles[i].setOpacity(.1);
          }
      }

      $scope.ground.mesh.visible = visible;
      $scope.ranchObject.mesh.visible = visible;
  }

  var $$updateInfoBox = function() {
    var hour = ($scope.time % ($scope.numTimeSteps/2));
    $timeout(function() {
      $scope.currentTime = moment($scope.startDate).add(hour * 60 * 60, 'seconds');
      $scope.timePosition.value = hour * 60;
    }, 0);

    var vector = $scope.camera.getWorldDirection();
    var angleDeg = (Math.atan2(vector.y, vector.x) - Math.atan2(1, 0)) * 180 / Math.PI;

    $('#compass').rotate(angleDeg);
  }

  var $$animate = function() {
    if ($scope.toggle === false)
      return;

    $scope.notificationText.css("display", "none");

    $scope.cam.update();

    if ($scope.ranchObject) {
        if ($scope.ranchObject.selectedDepth != null) {
            $$showGround(false);
        } else {
            $$showGround(true);
        }
    }

    var curTime = Date.now();
    var delta = curTime - $scope.prevTime;

    if (!$scope.paused) {
        $scope.time += delta / 1000;  // used to be 600
    }

    $$updateInfoBox();

    $scope.renderer.clear();

    if ($scope.renderedTexture) {
        $scope.renderer.render($scope.sceneRTT, $scope.cameraRTT, $scope.renderedTexture, true);
    }

    $scope.renderer.render($scope.sensorScene, $scope.camera);
    $scope.renderer.render($scope.tileScene, $scope.camera);
    $scope.prevTime = curTime;

    var vector = $scope.camera.getWorldDirection();
    var angleDeg = (Math.atan2(vector.y, vector.x) - Math.atan2(1, 0)) * 180 / Math.PI;
    angleDeg += 45;
    if (angleDeg < 0) angleDeg += 360;
    var ruler = 0;
    if (angleDeg >= 90) {
        ruler = 1;
    }
    if (angleDeg >= 180) {
        ruler = 2;
    }
    if (angleDeg >= 270) {
        ruler = 3;
    }

    if ($scope.ranchObject)
      $scope.ranchObject.showRuler(ruler);

    if ($scope.stopAnimate === false) {
      $scope.isAnimateRunning = true;
      requestAnimationFrame($$animate);
    } else {
      $scope.isAnimateRunning = false;
      $scope.notificationText.css("display", "");
      notificationText.innerHTML = "Loading 3D Data";
    }
  }

  function $$loadRanch() {
    var ranchData = $scope.blockData;
    $scope.ranchObject = new RanchObject(ranchData, $scope.font);
    $scope.tileScene.add($scope.ranchObject.objects);

    var center = $scope.ranchObject.getCenter();
    $scope.cam.center = center;
    $scope.cam.zoom = $$calcCameraDist($scope.ranchObject);
    $scope.cam.focusPointSide(center);

    $scope.ground.uniforms.groundPosition.value = center;
    $scope.ground.mesh.position.x = center.x;
    $scope.ground.mesh.position.y = center.y;

    var zoomLevel = 18;
    var minCoord = {x: $scope.ranchObject.bbox.min.x, y: $scope.ranchObject.bbox.max.y};
    var maxCoord = {x: $scope.ranchObject.bbox.max.x, y: $scope.ranchObject.bbox.min.y};

    var tileTexture = $$renderTileTexture(minCoord, maxCoord, $scope.ranchObject, 18);
    $scope.ranchObject.setTexture(tileTexture);

    var mc = new Hammer.Manager($scope.renderer.domElement);
    var swipe = new Hammer.Swipe();
    mc.add([swipe]);
    mc.get('swipe').set({ direction: Hammer.DIRECTION_ALL });

    mc.on('swipeleft', function(ev) {
        $scope.cam.rotateRight();
    });
    mc.on('swiperight', function(ev) {
        $scope.cam.rotateLeft();
    });
    mc.on('swipedown', function(ev) {
        $scope.cam.tiltUp();
        $scope.ranchObject.setOverheadView(true);
        autoMode = false;
    });
    mc.on('swipeup', function(ev) {
        $scope.cam.tiltDown();
        $scope.ranchObject.setOverheadView(false);
    });

    return center;
  }

  function $$loadData(dates) {
    var start = dates[0];
    var end = dates[1];

    if (!start || !end)
      return;

    start = start.toISOString();
    end = end.toISOString();

    var block = $scope.$parent.$parent.ctrl.getSelected();
    $farmXApi.getBlock3DData(block.value[1].id, start, end).then(function(data) {
      if ($scope.blockData != null && $scope.blockData.ranches != null) {
        $scope.blockData.ranches[0].sensors = data;

        $scope.startDate = moment($scope.blockData.ranches[0].sensors[0].dates[0]);
        $scope.endDate = moment($scope.blockData.ranches[0].sensors[0].dates[1]);

        $scope.time = 0;

        $scope.tileScene.remove($scope.ranchObject.objects);
        $$loadRanch();
      }
    }, function(error) {
    });
  }

  function $$init() {
    $scope.font = undefined;
    $scope.settingsEnabled = false;
    $scope.startDate = moment().subtract(7, 'days');
    $scope.endDate = moment();
    $scope.currentTime = undefined;
    $scope.timePosition = {
      value: 0,
      options: {
        floor: 0,
        ceil: ($scope.endDate.valueOf() - $scope.startDate.valueOf()) / 60000,
        showTicks: false,
        showTicksValues: false,
        step: 8,
        readOnly: true
      }
    }
    $scope.depth = {
      value: 0,
      options: {
        floor: 0,
        ceil: 48,
        step: 8,
        showTicks: true,
        showTicksValues: true,
        onChange: ctrl.onDepthChangeListener
      }
    };
    $scope.color = {
      minValue: 0.2,
      maxValue: 0.5,
      options: {
        floor: 0.2,
        ceil: 0.5,
        step: 0.1,
        precision: 1,
        showTicks: true,
        showTicksValues: true,
        showSelectionBar: true,
        selectionBarGradient: {
          from: '#E61919',
          to: '#1919E6'
        },
        ticksArray: [
          0.2,
          0.3,
          0.4,
          0.5
        ]
      }
    };
    $scope.blockData = undefined;
    $scope.prevTime = Date.now();
    $scope.paused = false;
    $scope.time = 0;
    $scope.numTimeSteps = 24 * 7 * 2;

    $scope.renderer = undefined;
    $scope.raycaster = undefined;
    $scope.camera = undefined;
    $scope.scene = undefined;
    $scope.sceneRTT = undefined;
    $scope.tileScene = undefined;
    $scope.sensorScene = undefined;
    $scope.texturesRTT = undefined;
    $scope.controls = undefined;
    $scope.cam = undefined;
    $scope.cameraRTT = undefined;
    $scope.sky = undefined;
    $scope.ground = undefined;
    $scope.tiles = [];
    $scope.renderedTexture = undefined;
    $scope.ranchObject = undefined;
    $scope.stopAnimate = true;
  }

  this.$onInit = function() {
    $timeout(function() {
      $$init();
      $scope.ctrl.initRenderer();
      $scope.ctrl.initScene();

      $scope.ctrl.initCamera();
      $scope.ctrl.initLights();

      $scope.ctrl.initGround();

      $$initData();
    }, 0);
  }
}
