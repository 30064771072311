(function(window, undefined) {
  'use strict';

  angular.module('farmx.widget.ranchSummary', ['adf.provider'])
    .config(["dashboardProvider", function(dashboardProvider){
      dashboardProvider
        .widget('ranchSummary', {
          title: 'ranchSummary',
          description: 'FarmX ranchSummary Widget',
          templateUrl: '{widgetsPath}/farmx-widget-ranchSummary/src/view.html',
          frameless: true,
        });
    }]);

  angular.module('farmx.widget.ranchSummary').run(['$templateCache', function($templateCache) {
    $templateCache.put('{widgetsPath}/farmx-widget-ranchSummary/src/view.html',
      '<div class="farmx-frameless-panel panel panel-default ng-scope">' +
      '  <div class="farmx-frameless-area">' +
      '    <div layout="column" class="layout-column">' +
      '      <div layout="row" layout-align="start stretch" class="layout-align-start-stretch layout-row">' +
      '        <div class="col-md-2 layout-row" layout="row">' +
      '          <h3 layout="row" layout-align="center center" class="layout-align-center-center layout-row">32</h3>' +
      '          <span layout="row" layout-align="center center" class="layout-align-center-center layout-row">%</span>' +
      '        </div>' +
      '        <div class="col-md-4 layout-row" layout="row">' +
      '          <span layout="row" layout-align="center center" class="layout-align-center-center layout-row">Soil moisture at rootzone</span>' +
      '        </div>' +
      '        <div class="col-md-2 layout-row" layout="row">' +
      '          <h3 layout="row" layout-align="center center" class="layout-align-center-center layout-row">20</h3>' +
      '        </div>' +
      '        <div class="col-md-4 layout-row" layout="row">' +
      '          <span layout="row" layout-align="center center" class="layout-align-center-center layout-row">psi</span>' +
      '        </div>' +
      '      </div>' +
      '      <div layout="row" layout-align="start stretch" class="layout-align-start-stretch layout-row">' +
      '        <div class="col-md-2 layout-align-center-center layout-row" layout="row" layout-align="center center">' +
      '          <i class="fa fa-leaf" style="font-size: 24px" aria-hidden="true"></i>' +
      '        </div>' +
      '        <div class="col-md-4 layout-row" layout="row">' +
      '          <span layout="row" layout-align="center center" class="layout-align-center-center layout-row">[plan stress description]</span>' +
      '        </div>' +
      '        <div class="col-md-2 layout-row" layout="row">' +
      '          <h3 layout="row" layout-align="center center" class="layout-align-center-center layout-row">0.3</h3>' +
      '        </div>' +
      '        <div class="col-md-4 layout-row" layout="row">' +
      '          <span layout="row" layout-align="center center" class="layout-align-center-center layout-row">weekly ETc</span>' +
      '        </div>' +
      '      </div>' +
      '      <div layout="row" layout-align="start stretch" class="layout-align-start-stretch layout-row">' +
      '        <div class="col-md-2 layout-align-center-center layout-row" layout="row" layout-align="center center">' +
      '          <i class="fa fa-bell" style="font-size: 24px" aria-hidden="true"></i>' +
      '        </div>' +
      '        <div class="col-md-4 layout-row" layout="row">' +
      '          <h3 layout="row" layout-align="center center" class="layout-align-center-center layout-row">6</h3>' +
      '        </div>' +
      '      </div>' +
      '    </div>' +
      '  </div>' +
      '</div>'
  );
}]);})(window);
