
try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXSidenavTogglerController.$inject = ["$window", "$rootScope", "$scope", "$element", "$state", "$mdSidenav", "$log", "$timeout"];

angular.module('farmx-directives-sidenav').directive('farmxSidenavToggler', FarmXSidenavTogglerDirective)
.controller('fzSidenavTogglerController', FarmXSidenavTogglerController);

function FarmXSidenavTogglerDirective() {
  return {
    restrict: 'E',
    scope: {
      vposition:  '@vposition',
      hposition:  '@hposition',
      sidenavId:  '@sidenavId',
      contentId:  '@contentId',
    },
    templateUrl: 'sidenavToggler/sidenavToggler.template.html',
    controller: 'fzSidenavTogglerController',
    controllerAs: 'ctrl',
    link: function link(scope, element, attrs, controller, transcludeFn) {
      controller.initialize();
    },
  };
}

function FarmXSidenavTogglerController($window, $rootScope, $scope, $element, $state, $mdSidenav, $log, $timeout) {
  var ctrl = this;

  ctrl.hposition = 'left';
  ctrl.vposition = 'bottom';
  ctrl.open = true;
  ctrl.visible = true;

  ctrl.initialize = _initialize;
  ctrl.toggle = _toggle;

  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;

  $mdSidenav($scope.sidenavId).onClose(function() {
    ctrl.open = false;
    $timeout(function() {
      $rootScope.$broadcast("farmx.sidenav.open", false);
    }, 0);
  });

  angular.element($window).bind('resize', function() {
    var width = $window.innerWidth;
    var height = $window.innerHeight;

    if (width !== ctrl.width || height !== ctrl.height) {
      ctrl.width = width;
      ctrl.height = height;

      _resizeWindow();
    }
  });

  $scope.$on('farmx.tab.selected', function(event, tab) {
    _updatePagePosition();
  });

  $scope.$on('farmx.nav.close', function(event) {
      if (ctrl.width < 415)
        ctrl.open = false;
  });

  $scope.$watch("ctrl.open", function(newValue, oldValue) {
    if (newValue) {
      $mdSidenav($scope.sidenavId).open();
      _updatePagePosition();
    } else {
      $mdSidenav($scope.sidenavId).close();
      _updatePagePosition();
    }

    $timeout(function() {
      $rootScope.$broadcast("farmx.sidenav.open", newValue);
    }, 0);
  });

  $scope.$watch("ctrl.visible", function(newValue, oldValue) {
    if (newValue) {
      angular.element($element).css('display','block');
    } else {
      angular.element($element).css('display','none');
    }
  });

  function _resizeWindow() {
    if (ctrl.width < 1024) {
      ctrl.open = false;
      ctrl.visible = true;
    }

    if (ctrl.width >= 1024 && ctrl.width < 1920) {
      ctrl.open = true;
      ctrl.visible = true;
    }

    if (ctrl.width >= 1920) {
      ctrl.open = true;
      ctrl.visible = false;
    }

    _updatePagePosition();
  }

  function _updatePagePosition() {
    var sidenavToggler = angular.element($element);
    var firstChild = angular.element($element[0].firstChild);
    var sidenav = angular.element('#' + $scope.sidenavId);
    var content = angular.element(angular.element('#' + $scope.contentId));

    if (ctrl.open) {
      if (ctrl.hposition === 'left') {
        firstChild.css('left', sidenav.width()).css('right', 'auto');
        if(ctrl.width < 1024) {
          content.css('display', 'none');
        } else {
          content.css('left',sidenav.width()).css('right', 0).css('width', $window.innerWidth - sidenav.width()).css('height', sidenav.height());
        }
      } else if (ctrl.hposition === 'right') {
        firstChild.css('right', sidenav.width()).css('left', 'auto');
        if (ctrl.width < 1024) {
          content.css('display', 'none');
        } else {
          content.css('right', sidenav.width()).css('left', 0).css('width', $window.innerWidth - sidenav.width()).css('height', sidenav.height());
        }
      }
    } else {
      firstChild.css('right', 'auto').css('left', 'auto');
      content.css('left', 0).css('right', 0).css('width', $window.innerWidth).css('height', sidenav.height()).css('display', '');
    }
  }

  function _initialize() {
    ctrl.vposition = $scope.vposition === undefined ? ctrl.vposition : $scope.vposition;
    ctrl.hposition = $scope.hposition === undefined ? ctrl.hposition : $scope.hposition;
    ctrl.open = $scope.open === undefined ? ctrl.open : $scope.open;
    ctrl.visible = $scope.visible === undefined ? ctrl.visible : $scope.visible;

    _resizeWindow();
    _updatePagePosition();
  }

  function _toggle() {
    $timeout(function() {
      ctrl.open = !ctrl.open;

      _updatePagePosition();
    }, 0);
  }
}
