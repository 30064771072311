(function(window, undefined) {'use strict';


angular.module('farmx.widget.blockMap', ['adf.provider'])
  .config(["dashboardProvider", function(dashboardProvider){
    dashboardProvider
      .widget('blockMap', {
        title: 'blockMap',
        description: 'FarmX blockMap Widget',
        templateUrl: '{widgetsPath}/farmx-widget-blockMap/src/view.html',
        titleTemplateUrl: '{widgetsPath}/farmx-widget-blockMap/src/title.html',
        controller: 'blockMapController',
        controllerAs: 'ctrl',
        config: {
          ranch: null,
          block: null
        }
      });
  }]).controller('blockMapController', ["$scope", "$interval", "config", "$farmXEntitiesCache", "$farmXUtilities", function($scope, $interval, config, $farmXEntitiesCache, $farmXUtilities){
    var ctrl = this;
    var blockObject = $farmXEntitiesCache.findBlockById(config.block);
    var ranchObject = $farmXEntitiesCache.findRanchById(config.ranch);

    $scope.$parent.model.controller = ctrl;
    ctrl.selected = {
      value: []
    };
    ctrl.mapViewConfig = {};
    ctrl.selected.type = "Block";
    ctrl.selected.value.push(ranchObject);
    ctrl.selected.value.push(blockObject);

    ctrl.getTitle = _getTitle;
    ctrl.getTitleFillColor = _getTitleFillColor;
    ctrl.getTitleTextColor = _getTitleTextColor;

    function _getTitle() {
      return blockObject.name;
    }

    function _getTitleFillColor() {
      return $farmXUtilities.stateColorCode[blockObject.state.state].fillColor;
    }

    function _getTitleTextColor() {
      return $farmXUtilities.stateColorCode[blockObject.state.state].textColor;
    }

    function _viewInMap() {
      $rootScope.$broadcast("farmx.header.tabSelected", { tabType: "map", params: {ranch: config.ranch, block: config.block }});
    }
  }]);

  angular.module('farmx.widget.blockMap').run(['$templateCache', function($templateCache) {
    $templateCache.put('{widgetsPath}/farmx-widget-blockMap/src/title.html',
      '<div layout=row layout-align="start center" class=farmx-widget-title ng-style="{ \'background-color\': definition.controller.getTitleFillColor() }">' +
      '  <span class=farmx-widget-title-left ng-style="{ color: definition.controller.getTitleTextColor() }">{{ definition.controller.getTitle(); }}</span>' +
      '  <span style="outline: none" ng-style="{ color: definition.controller.getTitleTextColor() }" ng-click="definition.controller.viewInMap()">View in map</span>' +
      '</div>'
    );

    $templateCache.put('{widgetsPath}/farmx-widget-blockMap/src/view.html',
      '<div class="farmx-frameed-panel panel panel-default">' +
      '  <div class=farmx-frameed-area>' +
      '    <farmx-satellite-map-view config=ctrl.mapViewConfig selected=ctrl.selected scrollwheelzoom=false soilLayer="false"></farmx-satellite-map-view>' +
      '  </div>' +
      '</div>'
    );
  }]);
})(window);
