try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

angular
  .module('farmx-directives-sidenav')
  .directive('farmxSatelliteViewer', FarmXSatelliteViewerDirective)
  .controller('fzSatelliteViewerController', FarmXSatelliteViewerController);

FarmXSatelliteViewerController.$inject = ['$window', '$rootScope', '$scope', '$timeout', '$log', '$farmXSatelliteService'];

function FarmXSatelliteViewerDirective() {
  return {
    restrict: 'E',
    scope: {
      viewerId: '@viewerid',
      selected: '=',
    },
    templateUrl: 'satelliteViewer/satelliteViewer.template.html',
    controller: 'fzSatelliteViewerController',
    controllerAs: 'ctrl'
  };
}

function FarmXSatelliteViewerController($window, $rootScope, $scope, $timeout, $log, $farmXSatelliteService) {
  var ctrl = this;
  var scope = $scope;

  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;

  angular.element($window).bind('resize', function() {
    $scope.$apply(function() {
      ctrl.width = $window.innerWidth;
      ctrl.height = $window.innerHeight;
    });
  });

  this.$onInit = function() {
    ctrl.state = {
      availableDates: [],
      date: null,
      selectedDate: null,
      dataType: "NDVI",
      dataSources: [
        { id: "satellite", label: "Satellite" },
        { id: "aerial", label: "Aerial" },
      ],
      visible: false,
      selected: $scope.selected,
      showSelector: true,
    };

    if ($scope.selected != null) {
      $loadAvailableData(ctrl.state.selected, ctrl.state.dataType);
    }
  }

  $scope.$watch("ctrl.state.selectedDate", function(newValue, oldValue) {
    if (newValue != oldValue) {
      if (newValue != null) {
        ctrl.state.visible = true;

        $rootScope.$broadcast("farmx.satelliteViewerSelector." + $scope.viewerId + ".update.visible", {
          visible: ctrl.state.visible
        });
      }

      $updateDate();
    }
  });

  $scope.$on('farmx.sidenav.selected', function(eventName, value) {
    if (ctrl.state.dataType != null) {
      $loadAvailableData(value, ctrl.state.dataType);
    }
  });

  $scope.$on('farmx.map.selected', function(eventName, value) {
    if (ctrl.state.dataType != null) {
      $loadAvailableData(value, ctrl.state.dataType);
    }
  });

  $scope.$on("farmx.satelliteViewerSelector." + $scope.viewerId + ".visible", function(eventName, value) {
    ctrl.state.visible = value.visible;

    if (ctrl.state.visible === false) {
      ctrl.state.selectedDate = null;
    } else {
      ctrl.state.selectedDate = ctrl.state.date;
    }
  });

  $scope.$on("farmx.satelliteViewerSelector." + $scope.viewerId + ".dataType", function(eventName, value) {
    ctrl.state.dataType = value.dataType;

    if (ctrl.state.dataType != null) {
      $loadAvailableData(ctrl.state.selected, ctrl.state.dataType);
    }
  });

  $scope.$on("farmx.satelliteViewerSelector." + $scope.viewerId + ".dataSource", function(eventName, value) {
    ctrl.state.dataSources = value.dataSource;
    $loadAvailableData(ctrl.state.selected, ctrl.state.dataType);
  });

  function $dataLoaded(data, dataFor) {
    if (ctrl.state.visible) {
      $rootScope.$broadcast('farmx.satellite.selected', {
        'type': 'SatelliteData' + dataFor,
        'value': data,
        'viewerId': $scope.viewerId,
        'visible': ctrl.state.visible
      });
    } else {
      $rootScope.$broadcast('farmx.satellite.selected', {
        'type': null,
        'value': null,
        'viewerId': $scope.viewerId,
        'visible': ctrl.state.visible
      });
    }
  }

  function $updateDate() {
    if (ctrl.state.date != null) {
      if (ctrl.state.selected.type === "Block") {
        $farmXSatelliteService.loadSatelliteBlockDataForDate(ctrl.state.selected.value[1].id, ctrl.state.date, ctrl.state.dataType).then(function(success) {
          $dataLoaded(success, 'Block');
        }, function(error) {
        });
      } else if (ctrl.state.selected.type === "Ranch") {
        $farmXSatelliteService.loadSatelliteRanchDataForDate(ctrl.state.selected.value[0].id, ctrl.state.date, ctrl.state.dataType).then(function(success) {
          $dataLoaded(success, 'Ranch');
        }, function(error) {
        });
      }
    }
  }

  function $getClosest(originalDate) {
    var result = null;

    angular.forEach(ctrl.state.availableDates, function(value, index) {
      if (value.valueOf() >= originalDate.valueOf()) {
        if (result === null)
          result = value;
      }
    });

    if (result === null) {
      result = ctrl.state.availableDates.slice(-1)[0];
    }

    return result;
  }

  function $filterDatesForDataTypes(dates, dataSources, dataType) {
    var filteredDates = dates.filter(function(el) {
      var result = false;

      angular.forEach(dataSources, function(dataSource) {
        if (el.datatypes.includes(dataSource.id + ":" + dataType)) {
          result = true;
        }
      });

      return result;
    });

    return filteredDates.map(a => a.date).map(ts => moment.unix(ts));
  }

  function $loadAvailableData(selected, dataType) {
    if (selected != null && selected.type != null) {
      if (selected.type === "Block") {
        $farmXSatelliteService.getAvailableDatesBlock(selected.value[1].id, dataType).then(function(success) {
          ctrl.state.availableData = success;
          ctrl.state.availableDates = $filterDatesForDataTypes(success, ctrl.state.dataSources, ctrl.state.dataType);

          ctrl.state.selected = selected;
          ctrl.state.dataType = dataType;

          if (ctrl.state.date == null) {
            ctrl.state.date = ctrl.state.availableDates.slice(-1)[0];
          } else {
            ctrl.state.date = $getClosest(ctrl.state.date);
          }

          $updateDate();
        }, function(error) {
        });
      } else if (selected.type === "Ranch") {
        $farmXSatelliteService.getAvailableDatesRanch(selected.value[0].id, dataType).then(function(success) {
          ctrl.state.availableData = success;
          ctrl.state.availableDates = $filterDatesForDataTypes(success, ctrl.state.dataSources, ctrl.state.dataType);

          ctrl.state.selected = selected;
          ctrl.state.dataType = dataType;

          if (ctrl.state.date == null) {
            ctrl.state.date = ctrl.state.availableDates.slice(-1)[0];
          } else {
            ctrl.state.date = $getClosest(ctrl.state.date);
          }

          $updateDate();
        }, function(error) {
          reject("Error");
        });
      }
    }
  }
}
