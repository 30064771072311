module.exports = function($rootScope, $window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $farmXApi, $farmXEntitiesCache, $farmXSensorInfo, $farmXScheduleService, $log) {
  var ctrl = this;
  var scope = $scope;

  ctrl.tab = {};
  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;
  ctrl.previousBlock = _previousBlock;
  ctrl.nextBlock = _nextBlock;
  ctrl.changeInterval = _changeInterval;
  ctrl.changeFilter = _changeFilter;
  ctrl.addEvent = _addEvent;

  angular.element($window).bind('resize', function() {
    scope.$apply(function () {
      ctrl.width = $window.innerWidth;
      ctrl.height = $window.innerHeight;

      if (ctrl.tab.dateRange) {
        $updateScheduleWindow();
      }
    });
  });

  $scope.$on('farmx.map.selected', function(event, selected) {
    if (selected.type !== undefined)
      $selectScheduleFor(selected);
  });

  $scope.$on('farmx.sidenav.selected', function(event, selected) {
    if (selected.type !== undefined)
      $selectScheduleFor(selected);
  });

  function _changeInterval(tab, number, units) {
    tab.dateRange.shiftBy.number = number;
    tab.dateRange.shiftBy.units = units;

    tab.dateRange.endDate = moment().hour(23).minute(59).second(59).millisecond(999);
    if (units !== "months") {
      tab.dateRange.startDate = moment().subtract(number - 1, units).hour(0).minute(0).second(0).millisecond(0);
    } else {
      tab.dateRange.startDate = moment().subtract(number, units).hour(0).minute(0).second(0).millisecond(0);
    }

    $updateScheduleWindow();
  }

  function _previousBlock(tab) {
    tab.dateRange.endDate.subtract(tab.dateRange.shiftBy.number, tab.dateRange.shiftBy.units).hour(23).minute(59).second(59).millisecond(999);
    tab.dateRange.startDate.subtract(tab.dateRange.shiftBy.number, tab.dateRange.shiftBy.units).hour(0).minute(0).second(0).millisecond(0);

    $updateScheduleWindow();
  }

  function _nextBlock(tab) {
    tab.dateRange.endDate.add(tab.dateRange.shiftBy.number, tab.dateRange.shiftBy.units).hour(23).minute(59).second(59).millisecond(999);
    tab.dateRange.startDate.add(tab.dateRange.shiftBy.number, tab.dateRange.shiftBy.units).hour(0).minute(0).second(0).millisecond(0);

    $updateScheduleWindow();
  }

  function _changeFilter(tab) {
    $farmXScheduleService.showDateFilter(tab).then(
      function(config) {
        tab = config;

        $updateScheduleWindow();
      },
      function(error) {
      }
    );
  }

  function _addEvent(event) {
    if (event.isBlank) {
      var startTimePos = event.data.coords[0] - (event.data.index * event.data.blockWidth);
      var startTimeMs = (startTimePos / event.data.blockWidth) * 24 * 60 * 60 * 1000;
      var startTime = event.data.date.date.add(startTimeMs, 'ms');

      $farmXScheduleService.showAddEvent(startTime).then(
        function(config) {
          $farmXApi.setBlockSchedule(event.data.blockId, config.startDate, config.endDate).then(
            function(success) {
              $updateScheduleWindow();
            },
            function(error) {
            }
          );
        }, function(error) {
        }
      );
    } else {
      d3.select(event.scheduledRect).attr("filter","url(#sofGlow)");
      $farmXScheduleService.showModifyEvent(event.data).then(
        function(config) {
          if (config.action === "update") {
            $farmXApi.updateBlockSchedule(config.data.id, config.data.blockId, config.data.startDate, config.data.endDate).then(
              function(success) {
                d3.select(event.scheduledRect).attr("filter","");
                $updateScheduleWindow();
              }, function(error) {
              }
            );
          } else if (config.action === "delete") {
            $farmXApi.deleteBlockSchedule(config.data.id). then(
              function(success) {
                d3.select(event.scheduledRect).attr("filter","");
                $updateScheduleWindow();
              }, function(error) {
              }
            );
          }
        }, function(ignored) {
          d3.select(event.scheduledRect).attr("filter","");
        }
      );
    }
  }

  function $selectScheduleFor(tabFor) {
    var tab = {
      "id": uuid.v4(),
      "isActive": true,
      "tabFor": tabFor,
      "dateRange": {
        "startDate": moment().subtract(6, 'days').hour(0).minute(0).second(0).millisecond(0),
        "endDate": moment().add(7, 'days').hour(23).minute(59).second(59).millisecond(999),
        "shiftBy" : {
          "number": 14,
          "units": 'days'
        }
      },
      "scheduleViewConfig": {},
    };

    if (tabFor.type === "Ranch") {
      tab.ranch = angular.copy(tabFor.value[0]);
      tab.name = tab.ranch.name;
    } else if (tabFor.type === "Block") {
      tab.ranch = angular.copy(tabFor.value[0]);
      tab.ranch.blocks = [
        angular.copy(tabFor.value[1])
      ];
      tab.name = tab.ranch.blocks[0].name;
    }

    ctrl.tab = tab;

    $updateScheduleWindow();

    return tab;
  }

  function $updateScheduleWindow() {
    var width = ctrl.width - 108;
    var dateWidth = width / ctrl.tab.dateRange.shiftBy.number;
    var startTime = ctrl.tab.dateRange.startDate.clone();
    var skipCount = 1;

    if (dateWidth < 50) {
      skipCount = Math.ceil(50 / dateWidth);
    }

    ctrl.tab.dateRange.timeRanges = [];

    while(startTime.isSameOrBefore(ctrl.tab.dateRange.endDate)) {
      var newStartDate = startTime.clone();
      var dateCount = ctrl.tab.dateRange.endDate.diff(newStartDate, 'days') + 1;

      if (dateCount < skipCount) {
        ctrl.tab.dateRange.timeRanges.push(
          {
            "date": startTime.clone(),
            "size": dateCount
          }
        );
      } else {
        ctrl.tab.dateRange.timeRanges.push(
          {
            "date": startTime.clone(),
            "size": skipCount
          }
        );
      }

      startTime.add({'days': skipCount });
    }

    $rootScope.$broadcast("farmx.schedule.redraw", { startdate: ctrl.tab.dateRange.startDate, enddate: ctrl.tab.dateRange.endDate });
  }

  function $initialize() {
    $farmXApi.getAllConfig().then(function(config) {
      var parentSelected = $scope.$parent.ctrl.selected;
      ctrl.entities = $farmXEntitiesCache.getEntities();

      if (ctrl.entities.length > 0) {
        if (parentSelected.type !== undefined) {
          $selectScheduleFor(parentSelected);
        } else {
          $selectScheduleFor({
            "type": "Ranch",
            "value": [
              ctrl.entities[0].ranches[0]
            ]
          });
        }
      }
    }, function() {
      if (ctrl.entities.length > 0) {
        if (parentSelected.type !== undefined) {
          $$selectScheduleFor(parentSelected);
        } else {
          $$selectScheduleFor({
            "type": "Ranch",
            "value": [
              ctrl.entities[0].ranches[0]
            ]
          });
        }
      }
    });
  }

  this.$onInit = function() {
    if ($scope.$parent.ctrl.isLoading() === false) {
      $initialize();
    } else {
      $scope.$on('farmx.entitiesCache.updated', $initialize);
    }

    var userInfo = $farmXApi.getJWTInfo();
    mixpanel.track("Schedule loaded", {"distinct_id": userInfo.user_id});
  };
};
