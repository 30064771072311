module.exports = function($window, $rootScope, $scope, $log, $farmXApi, $farmXSatelliteService) {
  var ctrl = this;
  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;

  ctrl.selected = null;
  ctrl.selectedAnomaly = null;
  ctrl.anomalies = null;
  ctrl.editMode = false;

  ctrl.getSelected = _getSelected;
  ctrl.getAnomalyList = _getAnomalyList;
  ctrl.createAnomaly = _createAnomaly;
  ctrl.editAnomaly = _editAnomaly;
  ctrl.deleteAnomaly = _deleteAnomaly;
  ctrl.zoomMap = _zoomMap;
  ctrl.navigateMap = _navigateMap;
  ctrl.clearAnomaly = _clearAnomaly;
  ctrl.saveEdit = _saveEdit;
  ctrl.cancelEdit = _cancelEdit;

  angular.element($window).bind('resize', function() {
      $scope.$apply(function () {
        ctrl.width = $window.innerWidth;
        ctrl.height = $window.innerHeight;
      });
  });

  $scope.$on('farmx.sidenav.selected', function(event, selected) {
      ctrl.selected = selected;
  });

  $scope.$on('farmx.map.selected', function(event, selected) {
      ctrl.selected = selected;
  });

  $scope.$watch('ctrl.selectedAnomaly', function(newValue, oldValue) {
    $rootScope.$broadcast('farmx.anomaly.selected', {
      "value": ctrl.selectedAnomaly,
    });
  });

  $scope.$on('farmx.anomaly.clicked', function(event, selected) {
    ctrl.selectedAnomaly = selected.value;
  });

  $rootScope.$on('farmx.anomaly.edit.complete', function(event, updatedAnomaly) {
    // Call Server
    var anomaly = {
      id: updatedAnomaly.id,
      anomaly_type: updatedAnomaly.anomaly_type,
      block: updatedAnomaly.block,
      bounds: {
        type: updatedAnomaly.bounds.type,
        coordinates: updatedAnomaly.bounds.coordinates[0]
      },
      center: updatedAnomaly.center,
      name: updatedAnomaly.name
    };

    $farmXApi.updateAnomaly(anomaly).then(function(success) {
      console.log("Update Success" + anomaly);
      $loadData();
    }, function(error) {
      console.log("Update Error " + anomaly);
    });
  });

  $scope.$watch('ctrl.selected', function(newValue, oldValue) {
    $loadData();
  });

  function _getSelected() {
      return ctrl.selected;
  }

  function _getAnomalyList() {
    return ctrl.anomalies;
  }

  function _createAnomaly() {
    $farmXSatelliteService.createAnomaly().then(
      function(anomalyEntry) {
        $rootScope.$broadcast('farmx.anomaly.draw.start', {});

        $rootScope.$on('farmx.anomaly.draw.complete', function(event, layer) {
          var anomaly = {
            "name": anomalyEntry.name,
            "anomaly_type": anomalyEntry.type,
            "bounds": {
              "coordinates": layer.toGeoJSON().geometry.coordinates,
              "type": "Polygon"
            },
            "center": {
              "coordinates": [
                layer.getBounds().getCenter().lng,
                layer.getBounds().getCenter().lat
              ],
              "type": "Point"
            },
            "block": ctrl.selected.value[1].id
          };

          // Call Server
          $farmXApi.createAnomaly(anomaly).then(function(success) {
            $loadData();

            console.log("Created Success" + anomaly);
          }, function(error) {
            console.log("Created Error " + anomaly);
          });
        });
      },
      function(ignore) {
      }
    );
  }

  function _editAnomaly() {
    if (ctrl.selectedAnomaly != null) {
      ctrl.editMode = true;
      $rootScope.$broadcast('farmx.anomaly.edit.start', ctrl.selectedAnomaly);
    }
  }

  function _deleteAnomaly() {
    if (ctrl.selectedAnomaly != null) {
      console.log("Updated " + ctrl.selectedAnomaly);

      $farmXApi.deleteAnomaly(ctrl.selectedAnomaly).then(function(success) {
        $loadData();

        ctrl.selectedAnomaly = null;

        $rootScope.$broadcast('farmx.anomaly.deleted', ctrl.selectedAnomaly);
      }, function(error) {
        console.log("Delete Error " + ctrl.selectedAnomaly);
      });
    }
  }

  function _zoomMap() {
    if (ctrl.selectedAnomaly != null) {
      $rootScope.$broadcast('farmx.anomaly.zoom', ctrl.selectedAnomaly);
    }
  }

  function _navigateMap() {
    if (ctrl.selectedAnomaly == null)
      return null;
    var baseURL = "https://www.google.com/maps/dir/?api=1&destination=";
    var lat = ctrl.selectedAnomaly.center.coordinates[1];
    var lng = ctrl.selectedAnomaly.center.coordinates[0];
    $window.open(baseURL + lat + "," + lng, '_blank');
  }

  function _saveEdit() {
    ctrl.editMode = false;

    $rootScope.$broadcast('farmx.anomaly.edit.stop', {});
  }

  function _cancelEdit() {
    ctrl.editMode = false;

    $rootScope.$broadcast('farmx.anomaly.edit.cancel', {});
  }

  function _clearAnomaly() {
    ctrl.selectedAnomaly = null;
  }

  function $loadData() {
    ctrl.selectedAnomaly = null;
    ctrl.anomalies = [];

    if (ctrl.selected.type == "Block") {
      $farmXApi.loadBlockAnomalies(ctrl.selected.value[1])
      .then(function(success) {
        ctrl.anomalies = ctrl.selected.value[1].anomalies;

        $rootScope.$broadcast('farmx.map.tab.toggle', {});
      }, function(error) {
      });
    } else if (ctrl.selected.type == "Ranch") {
      var ranch = ctrl.selected.value[0];
      angular.forEach(ranch.blocks, function(block) {
        $farmXApi.loadBlockAnomalies(block)
        .then(function(success) {
          if (ctrl.anomalies === null) {
            ctrl.anomalies = [];
          }

          ctrl.anomalies = ctrl.anomalies.concat(block.anomalies);
        }, function(error) {
        });
      });

      $rootScope.$broadcast('farmx.map.tab.toggle', {});
    }
  }

  this.$onInit = function() {
    ctrl.selected = $scope.$parent.ctrl.selected;
  };
};
