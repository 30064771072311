(function(angular){
  'use strict';

  try {
    angular.module('farmx-directives-sidenav');
  } catch (err) {
    angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
  }

  angular.module('farmx-directives-sidenav')
  .directive('farmxMapView', function($compile) {
    // var mapId = global.uuid.v4();

    return {
      restrict: 'E',
      scope: {
        config:     '=config',
        selected:   '=selected',
        scrollWheelZoom: '=scrollwheelzoom'
      },
      templateUrl: 'map/map.template.html',
      controller: 'fzMapViewController',
      controllerAs: 'ctrl',
    };
  }).controller('fzMapViewController', function ($rootScope, $scope, $element, $state, $timeout, leafletData, $window, $farmXEntitiesCache, $farmXUtilities, $farmXSensorInfo, $farmXApi, $log, $compile, $sce, $i18next) {
    var ctrl = this;

    $scope.mapId = global.uuid.v4();
    $scope.events = {
      map: {
        enable: [ 'click', 'dblclick', 'mousedown', 'mouseup', 'mouseover', 'mouseout', 'mousemove', 'contextmenu', 'focus', 'blur', 'preclick', 'load', 'unload', 'viewreset', 'movestart', 'move', 'moveend', 'dragstart', 'drag', 'dragend', 'zoomstart', 'zoomend', 'zoomlevelschange', 'resize', 'autopanstart', 'layeradd', 'layerremove', 'baselayerchange', 'overlayadd', 'overlayremove', 'locationfound', 'locationerror', 'popupopen', 'popupclose' ],
        logic: 'emit'
      }
    };

    $scope.mapDefaults = {
      maxZoom:20,
      maxNativeZoom:20,
      showOnSelector: false,
      editable: true,
      attributionControl: false,
      zoomControl: false,
      zoomControlPosition: 'bottomright',
    };
    $scope.mapControls = {};
    $scope.mapLayers = {
      baselayers: {
        mapbox_light: {
          name: 'FarmMap',
          url:'https://api.mapbox.com/styles/v1/farmx/cinjdgdic0015adnjk1bl7775/tiles/{z}/{x}/{y}?access_token={apikey}',
          type: 'xyz',
          layerOptions: {
            apikey: 'pk.eyJ1IjoiZmFybXgiLCJhIjoiY2lnODkydzJpMDFlanR6bTdjOXB6MDJyMSJ9.w2lYGZBvj_OP5jbiK4KRAw',
            mapid: 'farmx.cinjdgdic0015adnjk1bl7775',
            maxNativeZoom:18,
            maxZoom:20,
          },
          layerParams: {
            showOnSelector: false
          }
        },
      },
      overlays: {
        satData: {
          name: 'satData',
          type: 'group',
          visible: true,
          layerParams: {
            showOnSelector: false
          }
        },
        selected: {
          name: 'selected',
          type: 'group',
          visible: true,
          layerParams: {
            showOnSelector: false
          }
        },
        ranches: {
          name: 'ranches',
          type: 'group',
          visible: true,
          layerParams: {
            showOnSelector: false
          }
        },
        blocks: {
          name: 'blocks',
          type: 'group',
          visible: true,
          layerParams: {
            showOnSelector: false
          }
        },
        stations: {
          name: 'stations',
          type: 'group',
          visible: true,
          layerParams: {
          showOnSelector: false
          }
        },
        features: {
          name: 'features',
          type: 'group',
          visible: true,
          layerParams: {
            showOnSelector: false
          }
        },
        nodes: {
          name: 'sensors',
          type: 'group',
          visible: true,
          layerParams: {
            showOnSelector: false
          }
        },
        ranchMarkers: {
          name: 'ranchMarkers',
          type: 'group',
          visible: true,
          layerParams: {
            showOnSelector: false
          }
        },
        blockMarkers: {
          name: 'blockMarkers',
          type: 'group',
          visible: true,
          layerParams: {
            showOnSelector: false
          }
        },
      },
    };

    ctrl.map = null;
    ctrl.layers = null;
    ctrl.zoomLevel = 1;
    ctrl.selected = {};
    ctrl.viewModel = "Ranch";
    ctrl.homeBound = [];
    ctrl.satelliteImageryOpacity = 0.8;
    ctrl.satelliteVisible = false;
    ctrl.previousSelectedType = null;
    ctrl.satelliteDataArray = [];

    angular.element($window).bind('resize', function() {
      $scope.$apply(function () {
        leafletData.getMap($scope.mapId).then(function(map) {
          map.invalidateSize();

          showLayer(false);
        });
      });
    });

    $scope.$on("leafletDirectiveMap." + $scope.mapId + ".click", function(eventName, toSend) {
      var clickHandler = $(toSend.leafletEvent.originalEvent.target.closest('.leaflet-clickable')).data('control-id');

      if (clickHandler != null) {
        angular.forEach($scope.config.controls, function(value, key) {
          if (key === clickHandler) {
            value.widgetConfig.onClick(ctrl.map, toSend.leafletEvent);
          }
        });

        $rootScope.$broadcast('farmx.nav.close');
      }
      ctrl.sensorMarkerGroup.refresh();
    });

    $scope.$watch('ctrl.zoomLevel', function(newLevel, oldLevel) {
      if (ctrl.layers === null || ctrl.layers === undefined)
        return;

      // if ((newLevel > 13 && oldLevel <= 13) || (newLevel <= 13 && oldLevel > 13))
        showLayer(true);
    });

    $scope.$on('farmx.entitiesCache.updated', function(event, value) {
      addDataToMap();
    });

    $scope.$on("farmx.entitiesCache.internal.update.ranch.state", function(event, value) {
      angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
        angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
          if (value[0] === eRanch.id) {
            eRanch.state = value[1];
            eRanch.weather = value[2];
          }
        });
      });
    });

    $scope.$on("farmx.entitiesCache.internal.update.block.state", function(event, value) {
      angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
        angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
          angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
            if (value[0] === eBlock.id) {
              eBlock.state = value[1];
            }
          });
        });
      });
    });

    $scope.$on("farmx.entitiesCache.internal.update.state", function(event, value) {
      leafletData.getMap($scope.mapId).then(function(map) {
        leafletData.getLayers().then(function(layers) {
          addDataToMap();
        });
      });
    });

    $scope.$on('farmx.sidenav.selected', function(event, selected) {
      ctrl.previousSelectedType = ctrl.selected.type;
      ctrl.selected = selected;

      if (ctrl.selected.type !== undefined) {
        $rootScope.$broadcast('farmx.map.viewmodechanged', ctrl.selected.type);
      }

      showLayer(false);
    });

    $scope.$on('farmx.map.selected', function(event, selected) {
      ctrl.previousSelectedType = ctrl.selected.type;
      ctrl.selected = selected;

      if (ctrl.selected.type !== undefined) {
        $rootScope.$broadcast('farmx.map.viewmodechanged', ctrl.selected.type);
      } else {
        $rootScope.$broadcast('farmx.map.viewmodechanged', "Ranch");
      }

      leafletData.getMap($scope.mapId).then(function(map) {
        map.invalidateSize();

        showLayer(false);
      });
    });

    $scope.$on('farmx.sidenav.open', function(event, status) {
      leafletData.getMap($scope.mapId).then(function(map) {
        map.invalidateSize();

        showLayer(false);
      });
    });

    $scope.$on('farmx.map.viewmodechanged', function(event, viewMode) {
      ctrl.viewMode = viewMode;
    });

    $scope.$on('farmx.satellite.selected', function(event, satelliteObject) {
      var satelliteDataArray = satelliteObject.value;

      $log.log("satellite selected " + satelliteObject);

      if (ctrl.layers != undefined)
        ctrl.layers.overlays.satData.clearLayers();

      ctrl.satelliteDataArray = satelliteDataArray;

      if (satelliteDataArray == null) {
        ctrl.satelliteVisible = false;
      } else {
        ctrl.satelliteVisible = satelliteObject.visible;
      }

      if (ctrl.viewMode === "Ranch") {
        angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
          angular.forEach(eEntity.ranches, function(eRanch, iRanch) {

            // set block name visibility
            // if (ctrl.zoomLevel < 13) {
            //   angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
            //     ctrl.layers.overlays.blocks.removeLayer(eBlock.layerTitle);
            //   });
            // } else {
            //   angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
            //     ctrl.layers.overlays.blocks.addLayer(eBlock.layerTitle);
            //   });
            // }

            // control ranch borders
            if (ctrl.zoomLevel >= 10) {
              ctrl.layers.overlays.ranches.addLayer(eRanch.layer);
              /* select ranch border */
              if (ctrl.selected.value !== undefined) {
                var ranchOpacity = ctrl.satelliteVisible ? 0.0 : 1.0;
                if (eRanch.id === ctrl.selected.value[0].id) {
                  eRanch.layer.setStyle($layerStyleSelected(eRanch.state, 4, 1, 0.0));
                } else {
                  eRanch.layer.setStyle($layerStyle(eRanch.state, 3, 1, 0.0));
                }
              }
            }

            // set block border visibility and ranch marker visibility
            if (ctrl.zoomLevel < 11) {
            } else {
              var blockOpacity = ctrl.satelliteVisible ? 0.0 : 0.3;
              /* show block titles and borders */
              angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
                eBlock.layer.setStyle($layerStyle(eBlock.state, 3, 0.5, blockOpacity));
              });
            }
          });
        });
      } else if (ctrl.viewMode === "Block" || ctrl.viewMode === "Sensor") {
        angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
          angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
            eRanch.layer.setStyle($layerStyle(eRanch.state, 2, 0.5, 0.0));
            var blockOpacity = ctrl.satelliteVisible ? 0.0 : 0.2;
            var selectedBlockOpacity = ctrl.satelliteVisible ? 0.0 : 0.3;

            angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
              eBlock.layer.setStyle($layerStyle(eBlock.state, 3, 0.5, blockOpacity));

              if (ctrl.selected.value !== undefined) {
                if (eBlock.id === ctrl.selected.value[1].id) {
                  eBlock.layer.setStyle($layerStyleSelected(eBlock.state, 4, 1, selectedBlockOpacity));
                }
              }
            });
          });
        });
      }

      if (ctrl.satelliteVisible && ctrl.satelliteDataArray) {
          angular.forEach(ctrl.satelliteDataArray, function(satelliteData, satelliteDataIndex) {
            var latlng = L.GeoJSON.coordsToLatLngs(JSON.parse(satelliteData.bounds).coordinates,1);
            var bounds = new L.LatLngBounds(latlng);
            var imageUrl = satelliteData.png_url;
            var options = {
              opacity: ctrl.satelliteImageryOpacity,
              alt: "satellite imagery",
            };
            var imageLayer = new L.imageOverlay(imageUrl, bounds, options);
            ctrl.layers.overlays.satData.addLayer(imageLayer);
          });
      }
      // showLayer(false);
    });

    $scope.$on('farmx.map.opacitysliderchanged', function (event, opacity) {
      ctrl.satelliteImageryOpacity = opacity / 100;

      angular.forEach(ctrl.layers.overlays.satData.getLayers(), function(layer) {
        layer.setOpacity(ctrl.satelliteImageryOpacity);
      });
    });

    ctrl.popoutData = function(eSensor) {
      console.error("popping out");

    };

    function addDataToMap() {
      leafletData.getMap($scope.mapId).then(function(map) {
        map.on('popupopen', function(e) {
         $('div.leaflet-popup.leaflet-zoom-animated > .leaflet-popup-content-wrapper > .leaflet-popup-content > div.sensor-meta-data > .md-button').one("click", function(e) {
           console.log("button clicked");
            var parent = $(e.currentTarget).data('parent-id');
            var identifier = $(e.currentTarget).data('identifier');
            var block = $farmXEntitiesCache.findBlockById(parent);

            $timeout(function() {
              $rootScope.$broadcast('farmx.sidenav.selected', {
                "type": "Block",
                "value": [ block.getParent(), block],
                "startTime": new Date().getTime()
              });

              $timeout(function() {
                var selectedSensor = block.sensors.find(function(sensor) {
                  return sensor.identifier === identifier.toString();
                });

                $rootScope.$broadcast("farmx.header.tabSelected", {tabType: "graph", params: {ranch: block.getParent().id, block: block.id, sensorType: selectedSensor.sensor_type, sensorId: selectedSensor.identifier }} );
              }, 0);
            }, 0);
          });
        });

        map.on('popupclose', function(e) {
        });

        leafletData.getLayers().then(function(layers) {
          ctrl.layers = layers;
          ctrl.map = map;

          angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
            angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
              eRanch.layer = new L.GeoJSON(eRanch.layerGeoJSON);
              eRanch.layer.setStyle($layerStyle(eRanch.state, 3, 1, 0.0));

              eRanch.layerTitle = $layerTitle("Ranch", eRanch, null);

              eRanch.marker = $layerMarker(eRanch.markerGeoJSON.geometry.latLng, "icon-ranch", "circle", eRanch.state);
              eRanch.marker.on('click', function(e) {
                $rootScope.$broadcast('farmx.map.selected', {
                  "type": "Ranch",
                  "value": [eRanch],
                });
              });

              var blockOpacity = ctrl.satelliteVisible ? 0.0 : 0.3;
              angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
                eBlock.layer = new L.GeoJSON(eBlock.layerGeoJSON);
                eBlock.layer.setStyle($layerStyle(eBlock.state, 3, 0.5, blockOpacity));
                eBlock.layer.on('click', function(e) {
                  $rootScope.$broadcast("farmx.map.selected", {
                    "type": "Block",
                    "value": [eRanch, eBlock]
                  });
                });

                eBlock.layerTitle = $layerTitle("Block", eRanch, eBlock);

                eBlock.marker = $layerMarker(eBlock.markerGeoJSON.geometry.latLng, "icon-block", "circle", eBlock.state);

                angular.forEach(eBlock.gateways, function(eGateway, iGateway) {
                  eGateway.marker = $layerMarker(eGateway.markerGeoJSON.geometry.latLng, eGateway.has_weather ? "icon-radio-tower" : "icon-radio-tower", "square");
                  var popup = "<div>" + eGateway.name + "</div>";
                  eGateway.marker.bindPopup(popup);
                });

                angular.forEach(eBlock.stations, function(eStation, iStations) {
                  eStation.marker = $layerMarker(eStation.markerGeoJSON.geometry.latLng, eStation.has_weather ? "icon-ios-rainy" : "icon-radio-tower", "square");
                  var popup = "<div>" + eStation.name + "</div>";
                  eStation.marker.bindPopup(popup);
                });

                angular.forEach(eBlock.sensors, function(eSensor, iSensors) {
                  var latLng = eSensor.markerGeoJSON.geometry.latLng;
                  if (latLng.lat == 0 || latLng.lng == 0) {
                    latLng = eBlock.markerGeoJSON.geometry.latLng;
                  }
                  if (eSensor.state && eSensor.state.state !== undefined) {
                    var state = eSensor.state.state + 2; // convert from soil state to overall state
                    eSensor.marker = $layerMarker(latLng, $farmXUtilities.iconForSensorType(eSensor.sensor_type), "circle", {state:state});
                  } else {
                    eSensor.marker = $layerMarker(latLng, $farmXUtilities.iconForSensorType(eSensor.sensor_type), "circle");
                  }

                  var popup = "";
                  if (eSensor.name != null && $farmXSensorInfo.sensorType[eSensor.sensor_type] != null) {
                    if (latLng) {
                      try {
                        var latLngStr = latLng.lat + ", " + latLng.lng;
                        var directionsLink = 'https://www.google.com/maps/search/' + latLngStr.replace(" ","")
                      } catch(err) {}
                    }

                    var scheduleLink = 'https://web.farmx.co/schedule';
                    var controlLink = 'https://web.farmx.co/control';
                    var ranch = eSensor.getParent().getParent();
                    var irrigationBlock = undefined;
                    var vfd = undefined;

                    if (ranch !== undefined && ranch.id !== undefined) {
                      controlLink = 'https://web.farmx.co/control?ranchId=' + ranch.id;
                    }
                    
                    if (eSensor.irrigation_block) {
                      try {
                        scheduleLink = 'https://web.farmx.co/schedule?irrigationTypes=scheduled&blockId=' + eSensor.irrigation_block;
                      } catch(err) {
                        scheduleLink = 'https://web.farmx.co/schedule?irrigationTypes=scheduled';
                      }
                      irrigationBlock = $farmXEntitiesCache.findBlockById(eSensor.irrigation_block);
                      const vfdObj = irrigationBlock.vfd;
                      if (vfdObj) {
                        vfd = $farmXEntitiesCache.findSensor(vfdObj.type, vfdObj.identifier);
                      }
                    }

                    if (eSensor.sensor_type == 'valve' || eSensor.sensor_type == 'vfd') {
                      $farmXApi.getSensorStatus(eSensor.sensor_type, eSensor.identifier).then(
                        function(data) {
                          eSensor.status = data;
                        }
                      );
                    }

                    var blockNames = "";
                    if (eSensor.sensor_type == 'vfd') {
                      var blockIds = ranch.blocks.map(function(elem){
                        return elem.id;
                      }).join(",");
                      blockNames = ranch.blocks.filter(function(elem) {
                        return elem.vfd;
                      }).map(function(elem){
                        return elem.name;
                      }).join(", ");
                      scheduleLink = 'https://web.farmx.co/schedule?irrigationTypes=scheduled&blockId=' + blockIds;
                    }

                    // if soil sensors
                    if (
                      (eSensor.sensor_type == 'aquacheck_soil' || eSensor.sensor_type == 'pixl')
                      && eSensor.block
                    ) {
                      scheduleLink = 'https://web.farmx.co/schedule?irrigationTypes=scheduled&blockId=' + eSensor.block;
                    }

                    popup = L.popup.angular({
                      template: `
                         <div class="popup-title">{{ popup.$content.name }}</div>
                         <div class='layout-row layout-align-start-stretch sensor-meta-data'>
                            <div class="flex-100 layout-col layout-column layout-align-start-stretch">
                              <span ng-i18next>{{ popup.$content.typeName }}</span>
                              <div ng-if='popup.$content.directionsLink.length'><a href='{{ popup.$content.directionsLink }}' target='_blank'>{{ popup.$content.latLngStr }}</a></div>
                            </div>
                            <md-button data-parent-id='{{ popup.$content.parentId }}' data-identifier='{{ popup.$content.sensorIdentifier }}' class='md-icon-button md-graph-popup' aria-label=${$i18next.t('More')}>
                              <span class='fa fa-bar-chart'></span></md-icon>
                            </md-button>
                         </div>
                         <div ng-if="popup.$content.type == 'aquacheck_soil'">
                            <div class="popup-subtitle">${$i18next.t("Weekly Soil Data")}</div>
                            <div class="" ng-click="popup.popoutData()">${$i18next.t("Popout")}</div>
                            <image class="aquacheck-sensor-data-small" ng-if="popup.imageSrc != null" src="{{ popup.imageSrc }}" ng-mousemove="popup.showDetails($event)" ng-mouseleave="popup.hideDetails($event)">
                            <div class="img-placeholder-small" ng-if="popup.imageSrc == null">${$i18next.t("Loading...")}</div>
                            <div class="data-label">{{ popup.dataValue }}</div>
                            <div class="data-label">{{ popup.dataDate }}</div>
                         </div>
                         <div ng-if="popup.$content.type == 'aquacheck_soil' || popup.$content.type == 'pixl'">
                          <md-button class="md-raised" href="{{ popup.$content.scheduleLink }}">
                            <span class='fa fa-calendar'></span></md-icon> <span>${$i18next.t("Schedule Irrigation")}</span>
                          </md-button>
                         </div>
                         <div ng-if="popup.$content.type == 'valve'">
                          <div>
                            <div class="sensor-popup-status" ng-if="popup.$content.sensor.status">
                              ${$i18next.t("Valve State")}:
                              <div class="ant-tag">
                                {{ popup.$content.sensor.status.controlStatus.state | uppercase }}
                              </div>
                            </div>
                            <div ng-if="popup.$content.irrigationBlock">
                              <span>${$i18next.t("Downstream Block")}: <strong>{{ popup.$content.irrigationBlock.name }}</strong></span>
                            </div>
                            <div ng-if="popup.$content.vfd">
                              <span>${$i18next.t("Pump Control")}: <strong>{{ popup.$content.vfd.name }}</strong></span>
                            </div>
                            <hr>
                            <md-button class="md-raised" href="{{ popup.$content.scheduleLink }}">
                              <span class='fa fa-calendar'></span></md-icon> <span>${$i18next.t("Schedule")}</span>
                            </md-button>
                            <md-button class="md-raised" ng-href="{{ popup.$content.controlLink }}">
                              <span class='fa fa-power-off'></span></md-icon> <span>${$i18next.t("Control")}</span>
                            </md-button>
                          </div>
                         </div>
                         <div ng-if="popup.$content.type == 'vfd'">
                          <div>
                            <div class="sensor-popup-status" ng-if="popup.$content.sensor.status">
                              ${$i18next.t("Pump State")}:
                              <div class="ant-tag">
                                {{ popup.$content.sensor.status.controlStatus.status | uppercase }}
                              </div>
                            </div>
                            <div ng-if="popup.$content.blockNames">
                              <span>${$i18next.t("Supplies")}: {{ popup.$content.blockNames }}</span>
                            </div>
                            <hr>
                            <md-button class="md-raised" href="{{ popup.$content.scheduleLink }}">
                              <span class='fa fa-calendar'></span></md-icon> <span>${$i18next.t("Schedule")}</span>
                            </md-button>
                            <md-button class="md-raised" ng-href="{{ popup.$content.controlLink }}">
                              <span class='fa fa-power-off'></span></md-icon> <span>${$i18next.t("Control")}</span>
                            </md-button>
                          </div>
                         </div>
                      `,
                      controllerAs: 'popup',
                      controller: ['$content', '$farmXSoilDataService', '$sce', function($content, $farmXSoilDataService, $sce){
                        var ctrl = this;
                        var helpText = $i18next.t("Hover data to view value");
                        ctrl.dataValue = helpText;
                        ctrl.dataDate = " ";

                        ctrl.showDetails = function(event) {
                            if (!ctrl.imageSrc) return;
                            var pos = $farmXSoilDataService.getMousePos(event);
                            var date = $farmXSoilDataService.getDateFromCoord(pos.x);
                            var value = $farmXSoilDataService.getValueFromCoords(pos);
                            var depth = $farmXSoilDataService.getDepthFromCoord(pos.y);
                            var dateString = date.format('ddd DD MMM hh:mmA');
                            var depthString = depth.toFixed(1) + " " + $i18next.t("in.");
                            var valueString = (value*100).toFixed(1) + "% vwc";
                            ctrl.dataValue = valueString + " @ " + depthString;
                            ctrl.dataDate = dateString;
                        };

                        ctrl.popoutData = function() {
                          console.error("inner popout");
                          var winId = Math.random().toString(36).substr(2, 5);
                          //var scope = $scope.$new(true);
                          //scope.winId = "test;"
                          var uid = ctrl.$content.sensorId;
                          var name = ctrl.$content.sensor.name;
                          $scope.$ctrl = {winId: winId};
                          $scope.winId = winId;
                          //<farmx-soil-browser sensor=20 class="soil-browser-cont"></farmx-soil_browser>
                          var temp = `
                            <div class="k-widget k-window" data-role="draggable" style="padding-top: 35px; width: 410px; height: 350px; top: 356px; left: 347px; z-index: 10003;" aria-hidden="false">
                              <div class="k-window-titlebar k-header" style="margin-top: -35px;">
                                <span class="k-window-title">`+name+`</span>
                                <div class="k-window-actions">
                                  <a role="button" href="#" class="k-button k-bare k-button-icon k-window-action" aria-label="Close">
                                    <span class="k-icon k-i-close">x</span>
                                  </a>
                                </div>
                              </div>
                              <div kendo-window="`+winId+`" k-title="'`+ name.replace("\"", "") +`'" data-role="window" class="k-window-content k-content" tabindex="0">
                                <farmx-soil-browser sensor=`+uid+` class="soil-browser-cont"></farmx-soil_browser>
                              </div>
                              <div class="k-resize-handle k-resize-n" style="display: block;"></div>
                              <div class="k-resize-handle k-resize-e" style="display: block;"></div>
                              <div class="k-resize-handle k-resize-s" style="display: block;"></div>
                              <div class="k-resize-handle k-resize-w" style="display: block;"></div>
                              <div class="k-resize-handle k-resize-se" style="display: block;"></div>
                              <div class="k-resize-handle k-resize-sw" style="display: block;"></div>
                              <div class="k-resize-handle k-resize-ne" style="display: block;"></div>
                              <div class="k-resize-handle k-resize-nw" style="display: block;"></div>
                            </div>
                          `;
                          var trustedHtml = angular.element(temp);
                          var linkFn = $compile(trustedHtml);
                          var content = linkFn($scope);
                          angular.element(document.body).append(content[0]);
                        };

                        ctrl.hideDetails = function(event) {
                            ctrl.dataValue = helpText;
                            ctrl.dataDate = " ";
                        };

                        $content.on(function(content){
                          ctrl.sensorId = content.sensorId;
                          $farmXSoilDataService.loadData(ctrl.sensorId)
                          .then(function(data) {
                              ctrl.imageSrc = data.imgSrc;
                          });
                        });
                      }]
                    }).setLatLng(latLng).setContent({
                      'name': eSensor.name,
                      'type': eSensor.sensor_type,
                      'typeName': $farmXSensorInfo.sensorType[eSensor.sensor_type].name,
                      'parentId': eSensor.getParent().id,
                      'sensorIdentifier': eSensor.identifier,
                      'latLngStr': latLngStr,
                      'directionsLink': directionsLink,
                      'sensorId': eSensor.uid,
                      'sensor': eSensor,
                      'controlLink': controlLink,
                      'scheduleLink': scheduleLink,
                      'irrigationBlock': irrigationBlock,
                      'vfd': vfd,
                      'blockNames': blockNames,
                    });
                  } else {
                    popup = "<div></div>";
                  }
                  eSensor.marker.bindPopup(popup);
                });
              });

              ctrl.homeBound = ctrl.homeBound.concat(L.GeoJSON.coordsToLatLngs(eRanch.bounds.coordinates,1));
            });
          });

          if (ctrl.layers !== undefined) {
            showLayer(false);
          }
        });
      });
    }

    function $layerStyle(state, weight, opacity, fillOpacity) {
      return function(feature) {
        return {
          "color": $farmXUtilities.stateColorCode[state.state].fillColor,
          "fillColor": $farmXUtilities.stateColorCode[state.state].fillColor,
          "opacity": opacity === undefined ? 1 : opacity,
          "fillOpacity": fillOpacity === undefined ? 0.2 : fillOpacity,
          "weight": weight,
          "dashArray": [],
        };
      };
    }

    function $layerStyleSelected(state, weight, opacity, fillOpacity) {
      return function(feature) {
        return {
          "color": $farmXUtilities.stateColorCode[state.state].fillColor,
          "fillColor": $farmXUtilities.stateColorCode[state.state].fillColor,
          "opacity": opacity === undefined ? 1.0 : opacity,
          "fillOpacity": fillOpacity === undefined ? 0.2 : fillOpacity,
          "weight": weight,
          "dashArray": [10, 10],
        };
      };
    }

    function $selectedLayerStyle(coordinates, state, weight, pulseColor) {
      return L.polyline.antPath(L.GeoJSON.coordsToLatLngs(coordinates,1)[0], {
        'delay': 1000,
        "dashArray": [10, 20],
        "color": $farmXUtilities.stateColorCode[state.state].iconColor,
        "pulseColor": pulseColor === undefined ? "#FFFFFF" : pulseColor,
        "weight": weight
      });
    }

    function $layerMarkerOld(latlng, icon, shape, state) {
      var markerIcon;

      if (state !== undefined) {
        markerIcon = L.ExtraMarkers.icon({
          "prefix": "fa",
          "icon": icon,
          "shape": shape,
          "iconColor": $farmXUtilities.stateColorCode[state.state].iconColor,
          "markerColor": $farmXUtilities.stateColorCode[state.state].markerColor,
        });
      } else {
        markerIcon = L.ExtraMarkers.icon({
          "prefix": "fa",
          "icon": icon,
          "shape": shape,
          "iconColor": $farmXUtilities.stateColorCode[0].iconColor,
          "markerColor": $farmXUtilities.stateColorCode[0].markerColor,
        });
      }

      return new L.Marker(latlng, { "icon": markerIcon });
    }

    function $layerMarker(latlng, icon, shape, state) {
      var markerIcon;

      if (state !== undefined) {
        markerIcon = L.VectorMarkers.icon({
          "prefix": 'icon',
          "icon": icon,
          "iconColor": $farmXUtilities.getStateIconColor(state.state),
          "markerColor": $farmXUtilities.stateColorCode[state.state].markerColor,
        });
      } else {
        markerIcon = L.VectorMarkers.icon({
          icon: icon,
          prefix: 'icon',
          markerColor: $farmXUtilities.stateColorCode[0].iconColor,
          iconColor: $farmXUtilities.stateColorCode[0].markerColor,
        });
      }

      var marker = new L.marker(latlng, { "icon": markerIcon });
      marker.state = state;
      return marker;
    }

    function $layerTitle(type, ranch, block) {
      var icon = new L.divIcon({
        "iconSize": L.point(200, 18),
        "className": "block-layer-title",
        "html": "<span>" + ( block === null ? ranch.name : block.name ) + "</span>"
      });

      var coordinates = block === null ? ranch.bounds.coordinates : block.bounds.coordinates;
      var result = L.marker(new L.LatLngBounds(L.GeoJSON.coordsToLatLngs(coordinates,1)).getCenter(), { "icon": icon });
      var value = type === "Ranch" ? [ ranch ] : [ ranch , block ];

      result.on('click', function(e) {
        $rootScope.$broadcast('farmx.map.selected', {
          "type": type,
          "value": value,
        });
      });

      return result;
    }

    function $createdMarkerGroup() {
      var shouldSpiderfy = function (marker, zoomLevel) {
				return marker.getChildCount() < 7;
      };

      var polygonDisabled = {
        stroke: false,
        fill: false,
      };
      
      ctrl.ranchMarkerGroup = L.markerClusterGroup({
        maxClusterRadius: 50,
        iconCreateFunction: createStateIcon,
        unspiderfyOnMapClick: false,
        polygonOptions: polygonDisabled,
      });
      ctrl.layers.overlays.ranchMarkers.addLayer(ctrl.ranchMarkerGroup);

      ctrl.sensorMarkerGroup = L.markerClusterGroup({
        maxClusterRadius: 50,
        unspiderfyOnMapClick: false,
				shouldSpiderfy: shouldSpiderfy,
				animate:false,
        iconCreateFunction: createStateIcon,
        spiderLegPolylineOptions: {
          weight: 1.5, color: '#eee', opacity: 0.8
        },
        polygonOptions: polygonDisabled,
      });
      /*ctrl.sensorMarkerGroup.on('clusterclick', function (a) {
        a.layer.spiderfy();
      });*/
      ctrl.layers.overlays.stations.addLayer(ctrl.sensorMarkerGroup);

      ctrl.blockMarkerGroup = L.markerClusterGroup({
        maxClusterRadius: 50,
        iconCreateFunction: createStateIcon,
        unspiderfyOnMapClick: false,
        polygonOptions: polygonDisabled,
      });
      ctrl.layers.overlays.blockMarkers.addLayer(ctrl.blockMarkerGroup);
    }

    function showLayer(onZoom) {
      $timeout(function() {
        $showLayer(onZoom);
      }, 0);
    }

    function showBlockSensors(eBlock) {
      angular.forEach(eBlock.stations, function(eStation, iStation) {
        ctrl.sensorMarkerGroup.addLayer(eStation.marker);
      });

      angular.forEach(eBlock.gateways, function(eGateway, iGateway) {
        ctrl.sensorMarkerGroup.addLayer(eGateway.marker);
      });

      angular.forEach(eBlock.sensors, function(eSensor, iSensor) {
        if (eSensor.visible) {
          ctrl.sensorMarkerGroup.addLayer(eSensor.marker);
        }
      });
    }

    function $showLayer(onZoom) {
      console.log("showLayer " + onZoom);

      var tStart = new Date().getTime();

      if (ctrl.layers === null || ctrl.layers === undefined)
        return;

      if (ctrl.ranchMarkerGroup === undefined) {
        $createdMarkerGroup();
      }

      // Clear Existing Layers
      ctrl.layers.overlays.ranches.clearLayers();
      ctrl.layers.overlays.ranchMarkers.clearLayers();
      ctrl.layers.overlays.blocks.clearLayers();
      ctrl.layers.overlays.blockMarkers.clearLayers();
      ctrl.layers.overlays.stations.clearLayers();
      ctrl.layers.overlays.selected.clearLayers();

      $createdMarkerGroup();

      if (onZoom === false)
        ctrl.layers.overlays.satData.clearLayers();

      if (ctrl.viewMode === "Ranch") {
        angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
          angular.forEach(eEntity.ranches, function(eRanch, iRanch) {

            // set block name visibility
            if (ctrl.zoomLevel < 13) {
              angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
                ctrl.layers.overlays.blocks.removeLayer(eBlock.layerTitle);
              });
            } else {
              angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
                ctrl.layers.overlays.blocks.addLayer(eBlock.layerTitle);
              });
            }

            // control ranch borders
            if (ctrl.zoomLevel < 10) {
              // dont show ranch borders
            } else {
              ctrl.layers.overlays.ranches.addLayer(eRanch.layer);
              /* select ranch border */
              if (ctrl.selected.value !== undefined) {
                var ranchOpacity = ctrl.satelliteVisible ? 0.0 : 1.0;
                if (eRanch.id === ctrl.selected.value[0].id) {
                  eRanch.layer.setStyle($layerStyleSelected(eRanch.state, 4, 1, 0.0));
                } else {
                  eRanch.layer.setStyle($layerStyle(eRanch.state, 3, 1, 0.0));
                }
              }
            }

            // set block border visibility and ranch marker visibility
            if (ctrl.zoomLevel < 11) {
              ctrl.ranchMarkerGroup.addLayer(eRanch.marker);
            } else {
              var blockOpacity = ctrl.satelliteVisible ? 0.0 : 0.3;
              /* show block titles and borders */
              angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
                eBlock.layer.setStyle($layerStyle(eBlock.state, 3, 0.5, blockOpacity));
                ctrl.layers.overlays.blocks.addLayer(eBlock.layer);
                showBlockSensors(eBlock);
              });
            }
          });
        });

        var blockTitles = $('.block-layer-title > span');
        if (blockTitles.length > 0) {
          angular.forEach(blockTitles, function(eBlockTitle, iBlockTitle) {
            var width = $(eBlockTitle).innerWidth() + 2;
            $(eBlockTitle).closest('.block-layer-title').css('width', width);
            $(eBlockTitle).closest('.block-layer-title').css('margin-left', (width / 2) * -1);
          });
        }
      } else if (ctrl.viewMode === "Block" || ctrl.viewMode === "Sensor") {
        angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
          angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
            eRanch.layer.setStyle($layerStyle(eRanch.state, 2, 0.5, 0.0));
            var blockOpacity = ctrl.satelliteVisible ? 0.0 : 0.2;
            var selectedBlockOpacity = ctrl.satelliteVisible ? 0.0 : 0.3;

            angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
              // Add Ranch
              ctrl.layers.overlays.ranches.addLayer(eRanch.layer);

              // Add Block
              ctrl.layers.overlays.blocks.addLayer(eBlock.layer);
              eBlock.layer.setStyle($layerStyle(eBlock.state, 3, 0.5, blockOpacity));

              if (ctrl.selected.value !== undefined) {
                if (eBlock.id === ctrl.selected.value[1].id) {
                  eBlock.layer.setStyle($layerStyleSelected(eBlock.state, 4, 1, selectedBlockOpacity));
                }
              }

              if (ctrl.zoomLevel < 11) {
                ctrl.blockMarkerGroup.addLayer(eBlock.marker);
              } else {
                showBlockSensors(eBlock);
              }
            });
          });
        });
      }

      if (onZoom === false) {
        var options = {};
        if ($("farmx-data-explorer").length > 0) {
          options = {
            padding: [0, 0]
          }
        }

        if (ctrl.selected.value !== undefined) {
          ctrl.map.fitBounds(new L.LatLngBounds(L.GeoJSON.coordsToLatLngs(ctrl.selected.value[ctrl.selected.value.length - 1].bounds.coordinates,1)), options);
        } else {
          if (ctrl.homeBound.length > 0) {
            ctrl.map.fitBounds(new L.LatLngBounds(ctrl.homeBound), options);
          } else {
            showLayer(true);
          }
        }
      }

      if (ctrl.satelliteVisible && ctrl.satelliteDataArray) {
          angular.forEach(ctrl.satelliteDataArray, function(satelliteData, satelliteDataIndex) {
            var latlng = L.GeoJSON.coordsToLatLngs(JSON.parse(satelliteData.bounds).coordinates,1);
            var bounds = new L.LatLngBounds(latlng);
            var imageUrl = satelliteData.png_url;
            var options = {
              opacity: ctrl.satelliteImageryOpacity,
              alt: "satellite imagery",
            };
            var imageLayer = new L.imageOverlay(imageUrl, bounds, options);
            ctrl.layers.overlays.satData.addLayer(imageLayer);
          });
      }

      ctrl.ranchMarkerGroup.refreshClusters();
      ctrl.sensorMarkerGroup.refresh();
    }

    this.$onInit = function() {
      ctrl.selected = $scope.selected;

      if (ctrl.selected.type !== undefined)
        ctrl.viewMode = ctrl.selected.type;
      else
        ctrl.viewMode = "Ranch";

      initVectorMarkers();

      leafletData.getMap($scope.mapId).then(function(map) {
        $timeout(function() {
          ctrl.map = map;

          L.Path.CLIP_PADDING = 1;

          map.invalidateSize();

          if ($scope.config && $scope.config.controls) {
            angular.forEach($scope.config.controls, function(value, key) {
              value.widgetConfig = value.control(value);

              if (value.widgetConfig.beforeRender)
                value.widgetConfig.beforeRender(ctrl.map);

              map.addControl(value.widgetConfig.control);

              if (value.widgetConfig.afterRender)
                value.widgetConfig.afterRender(ctrl.map);
            });
          }

          addDataToMap();

          map.on('zoomend', function(event) {
            ctrl.zoomLevel = event.target._zoom - 1;
          });
        }, 10);
      });
    };

    this.$onDestroy = function() {
      angular.forEach($farmXEntitiesCache.getEntities(), function(eEntity, iEntity) {
        angular.forEach(eEntity.ranches, function(eRanch, iRanch) {
          angular.forEach(eRanch.blocks, function(eBlock, iBlock) {
            delete eRanch.layer;
            delete eRanch.layerTitle;
            delete eRanch.marker;

            delete eBlock.layer;
            delete eBlock.layerTitle;
            delete eBlock.marker;

            angular.forEach(eBlock.gateways, function(eGateway, iGateway) {
              delete eGateway.marker;
            });

            angular.forEach(eBlock.stations, function(eStation, iStations) {
              delete eStation.marker;
            });

            angular.forEach(eBlock.sensors, function(eSensor, iSensors) {
              delete eSensor.marker;
            });
          });
        });
      });
    };

    var createStateIcon = function(cluster) {
      var markers = cluster.getAllChildMarkers();
      //var childCount = cluster.getChildCount();
      var markersCount = markers.length;

      var c = ' marker-cluster-unknown';
      var minState = -1;
      var cutoff = 1;
      for (var i = 0; i < markers.length; i++) {
        var marker = markers[i];

        var state = 0;
        if (marker.state) state = marker.state.state;

        if (minState <= cutoff || (state < minState && state > cutoff)) {
          minState = state;
        }
        /*if (minState < cutoff && minState != -1 && minState != 0) {
          break;
        }*/
      }

      if (minState == 1) {
        c = ' marker-cluster-offline';
      } else if (minState == 2) {
        c = ' marker-cluster-bad';
      } else if (minState == 3) {
        c = ' marker-cluster-warning';
      } else if (minState == 4) {
        c = ' marker-cluster-over';
      } else if (minState == 5) {
        c = ' marker-cluster-good';
      }

      return new L.DivIcon({
          html: '<div><span>' + markersCount + '</span></div>',
          className: 'marker-cluster' + c,
          iconSize: new L.Point(40, 40),
      });
    };

    function initVectorMarkers() {
      L.VectorMarkers = {};
      L.VectorMarkers.version = "1.0.0";
      L.VectorMarkers.MAP_PIN = 'M6.19,28.81a16,16,0,1,1,22.63,0L17.5,40.13Z';
      L.VectorMarkers.PIN_INNER = 'M7.9,27.1a13.58,13.58,0,1,1,19.21,0l-9.6,9.6Z';
      L.VectorMarkers.Icon = L.Icon.extend({
        options: {
          iconSize: [32, 40],
          iconAnchor: [16, 40],
          popupAnchor: [2, -40],
          shadowAnchor: [24, 24],
          shadowSize: [32, 40],
          className: "vector-marker",
          prefix: "fa",
          spinClass: "fa-spin",
          extraClasses: "",
          icon: "home",
          markerColor: "blue",
          iconColor: "white"
        },
        initialize: function(options) {
          return options = L.Util.setOptions(this, options);
        },
        createIcon: function(oldIcon) {
          var div, icon, options, pin_path, pin_inner;
          div = (oldIcon && oldIcon.tagName === "DIV" ? oldIcon : document.createElement("div"));
          options = this.options;
          if (options.icon) {
            icon = this._createInner();
          }
          pin_path = L.VectorMarkers.MAP_PIN;
          pin_inner = L.VectorMarkers.PIN_INNER;
          div.innerHTML = '<svg width="35px" height="40px" viewBox="0 0 35 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' + '<path class="pin-outer" d="' + pin_path + '" fill="' + options.markerColor + '"></path>' + '<path class="pin-inner" d="' + pin_inner + '" fill="' + options.markerColor + '"></path>' + icon + '</svg>';
          this._setIconStyles(div, "icon");
          this._setIconStyles(div, "icon-" + options.markerColor);
          return div;
        },
        _createInner: function() {
          var iconClass, iconColorClass, iconColorStyle, iconSpinClass, options;
          iconClass = void 0;
          iconSpinClass = "";
          iconColorClass = "";
          iconColorStyle = "";
          options = this.options;
          if (options.icon.slice(0, options.prefix.length + 1) === options.prefix + "-") {
            iconClass = options.icon;
          } else {
            iconClass = options.prefix + "-" + options.icon;
          }
          if (options.spin && typeof options.spinClass === "string") {
            iconSpinClass = options.spinClass;
          }
          if (options.iconColor) {
            if (options.iconColor === "white" || options.iconColor === "black") {
              iconColorClass = "icon-" + options.iconColor;
            } else {
              iconColorStyle = "style='color: " + options.iconColor + "' ";
            }
          }
          return "<i " + iconColorStyle + "class='" + options.extraClasses + " " + options.prefix + " " + iconClass + " " + iconSpinClass + " " + iconColorClass + "'></i>";
        },
        _setIconStyles: function(img, name) {
          var anchor, options, size;
          options = this.options;
          size = L.point(options[(name === "shadow" ? "shadowSize" : "iconSize")]);
          anchor = void 0;
          if (name === "shadow") {
            anchor = L.point(options.shadowAnchor || options.iconAnchor);
          } else {
            anchor = L.point(options.iconAnchor);
          }
          if (!anchor && size) {
            anchor = size.divideBy(2, true);
          }
          img.className = "vector-marker-" + name + " " + options.className;
          if (anchor) {
            img.style.marginLeft = (-anchor.x) + "px";
            img.style.marginTop = (-anchor.y) + "px";
          }
          if (size) {
            img.style.width = size.x + "px";
            return img.style.height = size.y + "px";
          }
        },
        createShadow: function() {
          var div;
          div = document.createElement("div");
          this._setIconStyles(div, "shadow");
          return div;
        }
      });
      return L.VectorMarkers.icon = function(options) {
        return new L.VectorMarkers.Icon(options);
      };
    }
  });
}(angular));
