(function(angular){
  'use strict';

  try {
    angular.module('farmx-directives-sidenav');
  } catch (err) {
    angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
  }

  angular.module('farmx-directives-sidenav')
  .directive('farmxScheduleView', function($compile) {
    return {
      restrict: 'E',
      scope: {
        id:         '=id',
        block:      '=block',
        startdate:  '=startdate',
        enddate:    '=enddate',
        ranges:     '=ranges',
        click:      '&'
      },
      templateUrl: 'schedule/schedule.template.html',
      controller: 'fzScheduleViewController',
      controllerAs: 'ctrl'
    };
  }).controller('fzScheduleViewController', function ($rootScope, $scope, $element, $timeout, leafletData, $farmXEntitiesCache, $farmXUtilities, $farmXScheduleService, $farmXApi, $log) {
    var ctrl = this;

    ctrl.id = undefined;
    ctrl.loading = true;
    ctrl.block = {};
    ctrl.addEvent = _addEvent;

    ctrl.selectDepth = false;
    ctrl.depthOptions = [
      {
        id: 0,
        name: "12 inches",
        multiplier: 0.7
      },
      {
        id: 1,
        name: "24 inches",
        multiplier: 1
      },
      {
        id: 2,
        name: "36 inches",
        multiplier: 1.2
      }
    ];
    ctrl.selectedDepth = ctrl.depthOptions[1];

    $scope.$watch("ctrl.selectedDepth", function(newValue, oldValue) {
      $timeout(function() {
        _updateGraph();
      }, 0);
    });

    $scope.$on("farmx.schedule.redraw", function(event, dateRange) {
      $scope.startdate = dateRange.startdate;
      $scope.enddate = dateRange.enddate;
      ctrl.block = Object.assign({}, $scope.block);

      $timeout(function() {
        _updateGraph();
      }, 0);
    });

    function _addEvent(event) {
      $farmXScheduleService.showAddEvent().then(
        function(config) {
          $farmXApi.setBlockSchedule(event.data.blockId, config.startDate, config.endDate).then(
            function(success) {
              $timeout(function() {
                _updateGraph();
              }, 0);
            },
            function(error) {
            }
          );
        }, function(error) {
        }
      );
    }

    function _updateGraph() {
      ctrl.block.schedule_stats = {
        past_stats: {
          season: null,
          period: null
        },
        recommended_stats: {
          season: null,
          period: null
        },
        scheduled_stats: {
          season: null,
          period: null
        }
      };

      let cumulativeR = 0;
      let cumulativeS = 0;
      let multiplier = 1;

      if (ctrl.selectDepth) {
        multiplier = ctrl.selectedDepth.multiplier;
      }

      var blockEl = $("#block-" + ctrl.id + "-schedule-area");
      var blockSVGEl = $("#block-" + ctrl.id + "-schedule-area svg")
      var chart = d3.gantt()
        .selector("#block-" + ctrl.id + "-schedule-area")
        .taskTypes(["Recommended", "Scheduled", "Actual"])
        .taskStatus({
          "ACTUAL": "actual",
          "RECOMMENDED": "recommended",
          "SCHEDULED": "scheduled"
        })
        .height(blockEl.height())
        .width(blockEl.width())
        .timeDomain([$scope.startdate.toDate(), $scope.enddate.toDate()])
        .tickFormat("%H:%M")
        .timeDomainMode("fixed")
        .margin({top: 0, right: 0, bottom: 0, left: 0})
        .onClick(function(event) {
          if ($scope.click !== undefined && $scope.click !== null) {
            $scope.click()(event);
          }
        })
        .draw($scope.ranges, ctrl.block.id);

      var dateStart = $scope.startdate.toISOString();
      var dateEnd = $scope.enddate.toISOString();
      $log.log(dateStart);
      $farmXApi.getBlockScheduleStats(ctrl.id, dateStart, dateEnd).then(function(response) {
        ctrl.block.schedule_stats = response.schedule_stats
      });

      $farmXApi.getBlockSchedule(ctrl.id).then(function(response) {
        ctrl.block.schedule = [];
        ctrl.loading = false;

        try {
          response.past_events.forEach(function(event) {
            var startDate = moment(event.start_date);
            var endDate = moment(event.stop_date);

            if (startDate.isSameOrBefore($scope.enddate) && endDate.isSameOrAfter($scope.startdate)) {
              ctrl.block.schedule.push({
                id: event.id,
                blockId: event.block,
                startDate: new Date(event.start_date),
                endDate: new Date(event.stop_date),
                taskName: "Actual",
                status: "ACTUAL"
              });
            }

            /*if (ctrl.block.scheduleStats.Past.totalSchedules !== "") {
              ctrl.block.scheduleStats.Past.averageTime = ctrl.block.scheduleStats.Past.totalTime === 0 ? 0 : ctrl.block.scheduleStats.Past.totalTime / ctrl.block.scheduleStats.Past.totalSchedules;
            }*/
          });

          response.recommended_events.forEach(function(event) {
            var startDate = moment(event.start_date);
            var endDate = moment(event.stop_date);

            if (ctrl.selectDepth) {
              if (endDate.isSameOrAfter(moment())) {
                let runTime = endDate - startDate;
                endDate = startDate.add(runTime * multiplier);                
                // adjust cumulative based on adjusted runTime
                cumulativeR += runTime * (1 - multiplier) / 1000.0 / 60 / 60;
              }
            }

            if (startDate.isSameOrAfter($scope.startdate) && endDate.isSameOrBefore($scope.enddate)) {
              ctrl.block.schedule.push({
                id: event.id,
                blockId: event.block,
                startDate: new Date(event.start_date),
                endDate: endDate.toDate(),
                taskName: "Recommended",
                status: "RECOMMENDED"
              });
            }
          });

          response.scheduled_events.forEach(function(event) {
            var startDate = moment(event.start_date);
            var endDate = moment(event.stop_date);

            if (ctrl.selectDepth) {
              if (endDate.isSameOrAfter(moment())) {
                let runTime = endDate - startDate;
                endDate = startDate.add(runTime * multiplier);
                cumulativeS += runTime * (1 - multiplier) / 1000.0 / 60 / 60;
              }
            }

            if (startDate.isSameOrAfter($scope.startdate) && endDate.isSameOrBefore($scope.enddate)) {
              ctrl.block.schedule.push({
                id: event.id,
                blockId: event.block,
                startDate: new Date(event.start_date),
                endDate: endDate.toDate(),
                taskName: "Scheduled",
                status: "SCHEDULED"
              });
            }
          });

          if (ctrl.selectDepth) {
            ctrl.block.schedule_stats.recommended_stats.period.total_hours -= cumulativeR;
            ctrl.block.schedule_stats.recommended_stats.season.total_hours -= cumulativeR;
            ctrl.block.schedule_stats.scheduled_stats.period.total_hours -= cumulativeS;
            ctrl.block.schedule_stats.scheduled_stats.season.total_hours -= cumulativeS;

            ctrl.block.schedule_stats.recommended_stats.period.acre_in = ctrl.block.schedule_stats.recommended_stats.period.acre_in_per_hour * ctrl.block.schedule_stats.recommended_stats.period.total_hours;
            ctrl.block.schedule_stats.recommended_stats.season.acre_in = ctrl.block.schedule_stats.recommended_stats.season.acre_in_per_hour * ctrl.block.schedule_stats.recommended_stats.season.total_hours;
            ctrl.block.schedule_stats.scheduled_stats.period.acre_in = ctrl.block.schedule_stats.scheduled_stats.period.acre_in_per_hour * ctrl.block.schedule_stats.scheduled_stats.period.total_hours;
            ctrl.block.schedule_stats.scheduled_stats.season.acre_in = ctrl.block.schedule_stats.scheduled_stats.season.acre_in_per_hour * ctrl.block.schedule_stats.scheduled_stats.season.total_hours;
          }

          ctrl.block.schedule.sort(function(a, b) {
            return a.endDate - b.endDate;
          })

          $timeout(function() {
            chart.data(ctrl.block.schedule);
          }, 0);
        } catch (e) {
          $log.error(e);
        }
      }, function(error) {
      });
    }

    this.$onInit = function() {
      ctrl.id = $scope.id;
      ctrl.block = Object.assign({}, $scope.block);
      ctrl.block.schedule = [];

      ctrl.selectDepth = false;
      if (ctrl.block && ctrl.block.id == 736429) ctrl.selectDepth = true;

      $timeout(function() {
        _updateGraph();
      }, 0);
    }
  });
}(angular));
