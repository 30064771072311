module.exports = function($provide, $stateProvider, $urlRouterProvider, $locationProvider, $mdThemingProvider, $logProvider, dashboardProvider) {
  $mdThemingProvider.theme('default')
    .primaryPalette('blue')
    .accentPalette('grey');

  // $logProvider.debugEnabled(false);

  $provide.decorator("$rootScope", function($delegate) {
    var Scope = $delegate.constructor;
    var origBroadcast = Scope.prototype.$broadcast;
    var origEmit = Scope.prototype.$emit;

    Scope.prototype.$broadcast = function() {
      console.log("$broadcast " + arguments[0]);

      return origBroadcast.apply(this, arguments);
    };
    Scope.prototype.$emit = function() {
      return origEmit.apply(this, arguments);
    };

    return $delegate;
  });

  $locationProvider.html5Mode(true);

  dashboardProvider
  .structure('6-6', {
    rows: [{
      columns: [{
        styleClass: 'col-md-4'
      }, {
        styleClass: 'col-md-4'
      }]
    }]
  });

  // Initialize State Provider
  $urlRouterProvider.otherwise(
    function ($injector, $location) {
      return '/';
    }
  );

  $stateProvider
    .state('root', {
      url: '/',
      views: {
        "content@": {
          templateUrl: "components/root/initializing.content.html ",
        }
      }
    })
    .state('login', {
      url: '/login',
      views: {
        "content@": {
          templateUrl: "components/login/login.content.html",
        }
      }
    })
    .state('home', {
      url: '/home',
      views: {
        "content@": {
          templateUrl:  "components/landingPage/landingPage.content.html",
          controller:   require('../components/landingPage/landingPage.ctrl'),
          controllerAs: 'ctrl'
        },
      },
    })
    .state('home.map', {
      url: '/map',
      views: {
        "page": {
          templateUrl:  "components/map/map.content.html",
          controller:   require('../components/map/map.ctrl'),
          controllerAs: 'ctrl'
        },
      },
    })
    .state('home.map.index', {
      url: '/',
      params: {
        ranch: null,
        block: null,
        sensorType: null,
        sensorId: null
      }
    })
    .state('home.map.block', {
      url: '/',
      params: {
        ranch: null,
        block: null
      }
    })
    .state('home.graph', {
      url: '/graph',
      views: {
        "page": {
          templateUrl:  "components/graph/graph.content.html",
          controller:   require('../components/graph/graph.ctrl'),
          controllerAs: 'ctrl'
        }
      }
    })
    .state('home.graph.index', {
      url: '/',
      params: {
        ranch: null,
        block: null,
        sensorType: null,
        sensorId: null
      }
    })
    .state('home.graph.sensor', {
      url: '/:ranch/:block/:sensorType/:sensorId',
      params: {
        ranch: null,
        block: null,
        sensorType: null,
        sensorId: null
      },
    })
    .state('home.schedule', {
      url: '/schedule',
      views: {
        'page': {
          templateUrl: "components/schedule/schedule.content.html",
          controller: require('../components/schedule/schedule.ctrl'),
          controllerAs: 'ctrl'
        }
      }
    })
    .state('home.dashboard', {
      url: '/dashboard',
      views: {
        "page": {
          templateUrl:  "components/dashboard/dashboard.content.html",
          controller:   require('../components/dashboard/dashboard.ctrl'),
          controllerAs: 'ctrl'
        }
      }
    })
    .state('home.newdashboard', {
      url: '/newdashboard',
      views: {
        "page": {
          templateUrl: "components/newdashboard/newdashboard.content.html",
          controller: require('../components/newdashboard/newdashboard.ctrl'),
          controllerAs: 'ctrl'
        }
      }
    })
    .state('home.imaging', {
      url: '/imaging',
      views: {
        "page": {
          templateUrl:  "components/satellite/satellite.content.html",
          controller:   require('../components/satellite/satellite.ctrl'),
          controllerAs: 'ctrl'
        }
      }
    })
    ;
};
