(function(angular){
  'use strict';

  try {
    angular.module('farmx-directives-sidenav');
  } catch (err) {
    angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
  }

  angular.module('farmx-directives-sidenav')
  .service('$farmXScheduleService', ["$rootScope", "$mdDialog", "$farmXSensorInfo",
    function($rootScope, $mdDialog, $farmXSensorInfo) {
      this.showModifyEvent = function(config) {
        return $mdDialog.show({
          parent: angular.element(document.body),
          clockOutsideToClose: true,
          locals: {
            "config": config,
          },
          template: '<md-dialog style="width: 450px; height: 390px;" layout="column" layout-align="start stretch">' +
                    '  <md-toolbar>' +
                    '    <div class="md-toolbar-tools">' +
                    '      <h2>Modify Schedule</h2>' +
                    '    </div>' +
                    '  </md-toolbar>' +
                    '  <md-dialog-content flex="100">' +
                    '    <div class="md-dialog-content" style="padding: 10px">' +
                    '      <div flex="100" layout="column" layout-align="start stretch">' +
                    '        <div flex="100" layout="column" style="margin-top: 10px; margin-bottom: 20px">' +
                    '          <div flex="100" layout="row" layout-align="start center">' +
                    '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 2px 0px 2px; min-width: 30px">From</label>' +
                    '            <md-datepicker ng-model="startDate" md-placeholder="Enter Start Date" md-open-on-focus></md-datepicker>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <input ng-model="startHour" type="number" step="1" min="1" max="12">' +
                    '            </md-input-container>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <input ng-model="startMinute" type="number" step="1" min="1" max="60">' +
                    '            </md-input-container>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <md-select ng-model="startAMPM">' +
                    '                <md-option value="AM">AM</md-option>' +
                    '                <md-option value="PM">PM</md-option>' +
                    '              </md-select>' +
                    '            </md-input-container>' +
                    '          </div>' +
                    '          <br>' +
                    '          <br>' +                    
                    '          <div flex="100" layout="row" layout-align="start center">' +
                    '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">To</label>' +
                    '            <md-datepicker ng-model="endDate" md-placeholder="Enter End Date" md-open-on-focus></md-datepicker>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <input ng-model="endHour" type="number" step="1" min="1" max="12">' +
                    '            </md-input-container>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <input ng-model="endMinute" type="number" step="1" min="1" max="60">' +
                    '            </md-input-container>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <md-select ng-model="endAMPM">' +
                    '                <md-option value="AM">AM</md-option>' +
                    '                <md-option value="PM">PM</md-option>' +
                    '              </md-select>' +
                    '            </md-input-container>' +
                    '          </div>' +
                    '        </div>' +
                    '      </div>' +
                    '    </div>' +
                    '  </md-dialog-content>' +
                    '  <md-dialog-actions layout="row">' +
                    '    <md-button ng-click="delete(true)">' +
                    '      Delete' +
                    '    </md-button>' +
                    '    <span flex></span>' +
                    '    <md-button ng-click="submit(true)">' +
                    '      Update' +
                    '    </md-button>' +
                    '  </md-dialog-actions>' +
                    '</md-dialog>',
          controller: FarmXModifyScheduleEvent,
          onComplete: function(scope, element) {
            scope.$onInit();
          }
        })
      }

      function FarmXModifyScheduleEvent($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $farmXApi, $log, config) {
        var ctrl = this;

        $scope.delete = function() {
          $mdDialog.hide({action: "delete", data: config});
        }

        $scope.submit = function() {
          var result = Object.assign({}, config);

          result.startDate = moment({y: $scope.startDate.getFullYear(),
            M: $scope.startDate.getMonth(),
            d: $scope.startDate.getDate(),
            h: $scope.startAMPM === "PM" ? ($scope.startHour === 12 ? 12 : $scope.startHour + 12) : ($scope.startHour === 12 ? 0 : $scope.startHour),
            m: $scope.startMinute,
            s: 0});
          result.endDate = moment({y: $scope.endDate.getFullYear(),
            M: $scope.endDate.getMonth(),
            d: $scope.endDate.getDate(),
            h: $scope.endAMPM === "PM" ? ($scope.endHour === 12 ? 12 : $scope.endHour + 12) : ($scope.endHour === 12 ? 0 : $scope.endHour),
            m: $scope.endMinute,
            s: 0});

          $mdDialog.hide({action: "update", data: result});
        }

        $scope.$onInit = function() {
          $scope.startDate = new Date(config.startDate);
          $scope.endDate = new Date(config.endDate);
          $scope.startHour = $scope.startDate.getHours() > 12 ? $scope.startDate.getHours() - 12 : ($scope.startDate.getHours() === 0 ? 12 : $scope.startDate.getHours());
          $scope.endHour = $scope.endDate.getHours() > 12 ? $scope.endDate.getHours() - 12 : ($scope.endDate.getHours() === 0 ? 12 : $scope.endDate.getHours());
          $scope.startMinute = $scope.startDate.getMinutes();
          $scope.endMinute = $scope.endDate.getMinutes();
          $scope.startAMPM = $scope.startDate.getHours() >= 12 ? 'PM' : 'AM';
          $scope.endAMPM = $scope.endDate.getHours() >= 12 ? 'PM' : 'AM';
        }
      }

      this.showAddEvent = function(config) {
        return $mdDialog.show({
          parent: angular.element(document.body),
          clockOutsideToClose: true,
          locals: {
            "config": config,
          },
          template: '<md-dialog style="width: 450px; height: 390px;" layout="column" layout-align="start stretch">' +
                    '  <md-toolbar>' +
                    '    <div class="md-toolbar-tools">' +
                    '      <h2>Create Schedule</h2>' +
                    '    </div>' +
                    '  </md-toolbar>' +
                    '  <md-dialog-content flex="100">' +
                    '    <div class="md-dialog-content" style="padding: 10px">' +
                    '      <div flex="100" layout="column" layout-align="start stretch">' +
                    '        <div flex="100" layout="column" style="margin-top: 10px; margin-bottom: 20px">' +
                    '          <div flex="100" layout="row" layout-align="start center">' +
                    '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 2px 0px 2px; min-width: 30px">From</label>' +
                    '            <md-datepicker ng-model="startDate" md-placeholder="Enter Start Date" md-open-on-focus></md-datepicker>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <input ng-model="startHour" type="number" step="1" min="1" max="12">' +
                    '            </md-input-container>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <input ng-model="startMinute" type="number" step="1" min="1" max="60">' +
                    '            </md-input-container>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <md-select ng-model="startAMPM">' +
                    '                <md-option value="AM">AM</md-option>' +
                    '                <md-option value="PM">PM</md-option>' +
                    '              </md-select>' +
                    '            </md-input-container>' +
                    '          </div>' +
                    '          <br>' +
                    '          <br>' +  
                    '          <div flex="100" layout="row" layout-align="start center">' +
                    '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">To</label>' +
                    '            <md-datepicker ng-model="endDate" md-placeholder="Enter End Date" md-open-on-focus></md-datepicker>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <input ng-model="endHour" type="number" step="1" min="1" max="12">' +
                    '            </md-input-container>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <input ng-model="endMinute" type="number" step="1" min="1" max="60">' +
                    '            </md-input-container>' +
                    '            <md-input-container class="noErrors" style="left: 10px">' +
                    '              <md-select ng-model="endAMPM">' +
                    '                <md-option value="AM">AM</md-option>' +
                    '                <md-option value="PM">PM</md-option>' +
                    '              </md-select>' +
                    '            </md-input-container>' +
                    '          </div>' +
                    '        </div>' +
                    '      </div>' +
                    '    </div>' +
                    '  </md-dialog-content>' +
                    '  <md-dialog-actions layout="row">' +
                    '    <md-button ng-click="cancel(true)">' +
                    '      Cancel' +
                    '    </md-button>' +
                    '    <span flex></span>' +
                    '    <md-button ng-click="submit(true)">' +
                    '      Save' +
                    '    </md-button>' +
                    '  </md-dialog-actions>' +
                    '</md-dialog>',
          controller: FarmXAddScheduleEvent,
          onComplete: function(scope, element) {
            scope.$onInit();
          }
        })
      }

      function FarmXAddScheduleEvent($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $log, config) {
        var ctrl = this;

        $scope.submit = function() {
          var config = {};

          config.startDate = moment({y: $scope.startDate.getFullYear(),
            M: $scope.startDate.getMonth(),
            d: $scope.startDate.getDate(),
            h: $scope.startAMPM === "PM" ? ($scope.startHour === 12 ? 12 : $scope.startHour + 12) : ($scope.startHour === 12 ? 0 : $scope.startHour),
            m: $scope.startMinute,
            s: 0});
          config.endDate = moment({y: $scope.endDate.getFullYear(),
            M: $scope.endDate.getMonth(),
            d: $scope.endDate.getDate(),
            h: $scope.endAMPM === "PM" ? ($scope.endHour === 12 ? 12 : $scope.endHour + 12) : ($scope.endHour === 12 ? 0 : $scope.endHour),
            m: $scope.endMinute,
            s: 0});

          $mdDialog.hide(config);
        }

        $scope.cancel = function() {
          $mdDialog.hide({action: "none", data: config});
        }

        $scope.$onInit = function() {
          if (!config) {
            $scope.startDate = new Date();
          } else {
            $scope.startDate = config.toDate();
          }
          $scope.endDate = moment($scope.startDate).add(1, 'hours').toDate();
          $scope.startHour = $scope.startDate.getHours() > 12 ? $scope.startDate.getHours() - 12 : ($scope.startDate.getHours() === 0 ? 12 : $scope.startDate.getHours());
          $scope.endHour = $scope.endDate.getHours() > 12 ? $scope.endDate.getHours() - 12 : ($scope.endDate.getHours() === 0 ? 12 : $scope.endDate.getHours());
          $scope.startMinute = $scope.startDate.getMinutes();
          $scope.endMinute = $scope.endDate.getMinutes();
          $scope.startAMPM = $scope.startDate.getHours() >= 12 ? 'PM' : 'AM';
          $scope.endAMPM = $scope.endDate.getHours() >= 12 ? 'PM' : 'AM';
        }
      }

      this.showDateFilter = function(config) {
        return $mdDialog.show({
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            "config": config,
          },
          template: '<md-dialog style="width: 256px; height: 324px;" layout="column" layout-align="start stretch">' +
                    '  <md-toolbar>' +
                    '    <div class="md-toolbar-tools">' +
                    '      <h2>Select Dates</h2>' +
                    '    </div>' +
                    '  </md-toolbar>' +
                    '  <md-dialog-content flex="100">' +
                    '    <div class="md-dialog-content" style="padding: 10px">' +
                    '      <div flex="100" layout="column" layout-align="start stretch">' +
                    '        <div flex="100" layout="column" style="margin-top: 10px; margin-bottom: 20px">' +
                    '          <div flex="100" layout="row" layout-align="start center">' +
                    '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">From</label>' +
                    '            <md-datepicker ng-model="startDate" md-placeholder="Enter Start Date" md-open-on-focus></md-datepicker>' +
                    '          </div>' +
                    '          <div flex="100" layout="row" layout-align="start center">' +
                    '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">To</label>' +
                    '            <md-datepicker ng-model="endDate" md-placeholder="Enter End Date" md-open-on-focus></md-datepicker>' +
                    '          </div>' +
                    '        </div>' +
                    '        <div flex="100" layout="column" layout-align="start stretch">' +
                    '          <div flex="100" layout="row" layout-align="start stretch" style="margin-bottom: 10px">' +
                    '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="today()">' +
                    '              <span>Today</span>' +
                    '            </button>' +
                    '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px;" ng-click="yesterday()">' +
                    '              <span>Yesterday</span>' +
                    '            </button>' +
                    '          </div>' +
                    '          <div flex="50" layout="row" layout-align="start stretch" style="margin-bottom: 10px">' +
                    '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="lastDays(6)">' +
                    '              <span>Last 7 Days</span>' +
                    '            </button>' +
                    '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px;" ng-click="lastDays(30)">' +
                    '              <span>Last 30 Days</span>' +
                    '            </button>' +
                    '          </div>' +
                    '        </div>' +
                    '      </div>' +
                    '    </div>' +
                    '  </md-dialog-content>' +
                    '  <md-dialog-actions layout="row">' +
                    '    <span flex></span>' +
                    '    <md-button ng-click="submit(true)">' +
                    '      Filter' +
                    '    </md-button>' +
                    '  </md-dialog-actions>' +
                    '</md-dialog>',
          controller: FarmXScheduleSelectDate
        });
      }

      function FarmXScheduleSelectDate($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $log, config) {
        $scope.$startDate = config.dateRange.startDate;
        $scope.$endDate = config.dateRange.endDate;
        $scope.startDate = config.dateRange.startDate.toDate();
        $scope.endDate = config.dateRange.endDate.toDate();

        $scope.submit = function() {
          config.dateRange.startDate = moment($scope.startDate);
          config.dateRange.endDate = moment($scope.endDate);
          config.dateRange.shiftBy.number = config.dateRange.endDate.diff(config.dateRange.startDate, 'days') + 1;

          $mdDialog.hide(config);
        };

        $scope.today = function() {
          $scope.$startDate = moment().hour(0).minute(0).second(0).millisecond(0);
          $scope.$endDate = moment().hour(23).minute(59).second(59).millisecond(999);
          $scope.startDate = $scope.$startDate.toDate();
          $scope.endDate = $scope.$endDate.toDate();
        };

        $scope.yesterday = function() {
          $scope.$startDate = moment().subtract(1, 'days').hour(0).minute(0).second(0).millisecond(0);
          $scope.$endDate = moment().subtract(1, 'days').hour(23).minute(59).second(59).millisecond(999);
          $scope.startDate = $scope.$startDate.toDate();
          $scope.endDate = $scope.$endDate.toDate();
        };

        $scope.lastDays = function(days) {
          $scope.$startDate = moment().subtract(days, 'days').hour(0).minute(0).second(0).millisecond(0);
          $scope.$endDate = moment().hour(23).minute(59).second(59).millisecond(999);
          $scope.startDate = $scope.$startDate.toDate();
          $scope.endDate = $scope.$endDate.toDate();
        };
      }
    }
  ]);
})(angular);
