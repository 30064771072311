(function(window, undefined) {
  'use strict';

  angular.module('farmx.widget.ranchDailyETC', ['adf.provider'])
    .config(["dashboardProvider", function(dashboardProvider) {
      dashboardProvider
        .widget('ranchDailyETC', {
          title: 'ranchDailyETC',
          description: 'FarmX ranchDailyETC Widget',
          templateUrl: '{widgetsPath}/farmx-widget-ranchDailyETC/src/view.html',
          controller: 'ranchDailyETCController',
          controllerAs: 'ctrl',
          frameless: true,
          config: {
            selected: null
          }
        });
    }]).controller('ranchDailyETCController', ["$scope", "$interval", "config", "$farmXEntitiesCache", function($scope, $interval, config, $farmXEntitiesCache){
      var ctrl = this;
      ctrl.id = uuid.v4()

      ctrl.dailyETCConfig = {
        title: {
          visible: false,
          text: ''
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false,
          visible: false
        },
        chart: {
          type: 'column',
          marginBottom: 5
        },
        xAxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ],
          crosshair: true,
          visible: false
        },
        yAxis: {
          min: 0,
          visible: false
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
        },
        series: [{
          name: 'Tokyo',
          data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
        }
      ]};

      ctrl.rootZoneVWCConfig = {
        title: {
          visible: false,
          text: ''
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false,
          visible: false
        },
        chart: {
          type: 'line',
          marginBottom: 5
        },
        xAxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ],
          crosshair: true,
          visible: false
        },
        yAxis: {
          min: 0,
          visible: false
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
        },
        series: [{
          name: 'Tokyo',
          data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
        }
      ]};

      this.$onInit = function() {
        console.log("Testing");
      }
    }]);

  angular.module('farmx.widget.ranchDailyETC').run(['$templateCache', function($templateCache) {
    $templateCache.put('{widgetsPath}/farmx-widget-ranchDailyETC/src/view.html',
      '<div class="farmx-frameless-panel panel panel-default ng-scope">' +
      '  <div class=farmx-frameless-area>' +
      '    <div class="layout-column layout-fill">' +
      '      <div id="dailyETC-{{ctrl.id}}" class="flex-50 layout-column">' +
      '        <span style="font-weight: bold; font-size: 12px">Daily ETc</span>' +
      '        <highchart class="flex-100" config="ctrl.dailyETCConfig"></highchart>' +
      '      </div>' +
      '      <div id="rootZone-{{ctrl.id}}" class="flex-50 layout-column">' +
      '        <span style="font-weight: bold; font-size: 12px">Rootzone vwc</span>' +
      '        <highchart class="flex-100" config="ctrl.rootZoneVWCConfig"></highcharts>' +
      '      </div>' +
      '    </div>' +
      '  </div>' +
      '</div>'
    );
  }]);
})(window);
