(function(angular){
  'use strict';

  try {
    angular.module('farmx-directives-sidenav');
  } catch (err) {
    angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
  }

  angular.module('farmx-directives-sidenav').filter('positive', function() {
    return function(input) {
      if (input < 0) return 0;
      return input;
    };
  });

  angular.module('farmx-directives-sidenav').filter('percentage', ['$filter', function ($filter) {
    return function (input, decimals) {
      if (input === undefined) return undefined;
      return $filter('number')(input * 100, decimals) + '%';
    };
  }]);

  angular.module('farmx-directives-sidenav').filter('titleCase', function() {
    return function(input) {
      input = input || '';
      return input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    };
  });

  angular.module('farmx-directives-sidenav').filter('noDashes', function() {
    return function(input) {
      input = input || '';
      return input.replace('-', ' ');
    };
  });

  angular.module('farmx-directives-sidenav').filter('yesNo', function() {
    return function(input) {
      if (input === undefined) return input;
      return input ? 'yes' : 'no';
    };
  });

  angular.module('farmx-directives-sidenav').filter('onOff', function() {
    return function(input) {
      if (input === undefined) return input;
      return input ? 'on' : 'off';
    };
  });

  angular.module('farmx-directives-sidenav')
  .directive('farmxIrrigationTableView', function($compile) {
    return {
      restrict: 'EC',
      scope: {
        blocks:     '=blocks',
        startdate:  '=startdate',
        enddate:    '=enddate',
      },
      templateUrl: 'irrigation/irrigationTable.template.html',
      controller: 'fzIrrigationTableViewController',
      controllerAs: 'ctrl'
    };
  }).controller('fzIrrigationTableViewController', function ($rootScope, $scope, $element, $timeout, $filter, $farmXEntitiesCache, $farmXUtilities, $farmXScheduleService, $farmXApi, $log) {
    var ctrl = this;

    ctrl.blocks = [];
    ctrl.loading = true;

    $scope.$watch("blocks", function(newValue, oldValue) {
        ctrl.blocks = newValue;
        _loadData();
    });

    $scope.expandSensors = function(event) {
        var element = event.srcElement ? event.srcElement : event.target;
        angular.element(element).parent().parent().next().toggleClass('hide');
    };

    this.$onInit = function() {
      //ctrl.id = $scope.id;
      //ctrl.blocks = Object.assign([], $scope.blocks);

      //console.error(this.blocks);
      //console.error($scope.blocks);
    }

    function _loadData() {
        ctrl.loading = true;
        angular.forEach(ctrl.blocks, function(block) {
            $farmXApi.getBlockIrrigationStats(block.id, ctrl.startdate, ctrl.enddate).then(function(response) {
                console.error(response);
                if (response.irrigation_stats)
                    block.irrigationStats = response.irrigation_stats;
            });
        });
        ctrl.loading = false;
    }
  });
}(angular));
