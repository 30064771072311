try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXDateNavigatorController.$inject = ['$window', '$rootScope', '$scope', '$timeout', '$farmXApi', '$farmXEntitiesCache', '$farmXDateSelectService', '$farmXSensorInfo', '$farmXUtilities', '$log'];
FarmXDateSelectService.$inject = ["$rootScope", "$mdDialog", "$farmXSensorInfo"];

angular
  .module('farmx-directives-sidenav')
  .directive('farmxDateNavigator', FarmXDateNavigatorDirective)
  .controller('fzDateNavigatorController', FarmXDateNavigatorController)
  .service('$farmXDateSelectService', FarmXDateSelectService);

function FarmXDateNavigatorDirective() {
  return {
    restrict: 'E',
    scope: {
      state: '='
    },
    templateUrl: 'dateNavigator/dateNavigator.template.html',
    controller: 'fzDateNavigatorController',
    controllerAs: 'ctrl',
    link: function link(scope, element, attrs, controller, transcludeFn) {
      controller.getWidth = function () {
        var styles = window.getComputedStyle(element[0]);
        var padding = parseFloat(styles.paddingLeft) +
                parseFloat(styles.paddingRight);
        return element[0].clientWidth - padding;
      };
    },
  };
}

function FarmXDateNavigatorController($window, $rootScope, $scope, $timeout, $farmXApi, $farmXEntitiesCache, $farmXDateSelectService, $farmXSensorInfo, $farmXUtilities, $log) {
  var ctrl = this;
  var scope = $scope;

  ctrl.visible = false;

  ctrl.selectDateFromPicker = _selectDateFromPicker;

  ctrl.datePrevYear = _datePrevYear;
  ctrl.datePrevMonth = _datePrevMonth;
  ctrl.datePrev = _datePrev;
  ctrl.dateNext = _dateNext;
  ctrl.dateNextMonth = _dateNextMonth;
  ctrl.dateNextYear = _dateNextYear;

  ctrl.entities = undefined;
  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;

  angular.element($window).bind('resize', function() {
    scope.$apply(function () {
      ctrl.width = $window.innerWidth;
      ctrl.height = $window.innerHeight;
    });
  });

  function _selectDateFromPicker() {
    $farmXDateSelectService.showDateFilter($scope.state).then(
      function(selectedDate) {
        _dateTarget(selectedDate, $scope.state.date, true);
      },
      function(error) {
        console.log(error);
      }
    );
  }

  function _getClosest(arr, target, current, lessThan) {
    /* assumes arr is sorted ascending */
    if (lessThan == true) {
      var filtered = arr.filter(function(v){return v <= target});
      if (!filtered.length) {
        // if nothing was found return the smallest value less than current
        return arr.filter(function(v){return v < current})[0];
      }
      return filtered.slice(-1)[0];
    } else {
      var filtered = arr.filter(function(v){return v >= target});
      if (!filtered.length) {
        // if nothing was found return the largest value greater than current
        return arr.filter(function(v){return v > current}).slice(-1)[0];
      }
      return filtered[0];
    }
  }

  function _dateNextYear() {
    var config = $scope.state;
    var date = moment(config.date).add(1, 'years').hour(0).minute(0).second(0).millisecond(0);
    _dateTarget(date, config.date, false);
  }

  function _dateNextMonth() {
    var config = $scope.state;
    var date = moment(config.date).add(1, 'months').hour(0).minute(0).second(0).millisecond(0);
    _dateTarget(date, config.date, false);
  }

  function _dateNext() {
    var config = $scope.state;
    var date = moment(config.date).add(1, 'days').hour(0).minute(0).second(0).millisecond(0);
    _dateTarget(date, config.date, false);
  }

  function _datePrev() {
    var config = $scope.state;
    var date = moment(config.date).subtract(1, 'days').hour(0).minute(0).second(0).millisecond(0);
    _dateTarget(date, config.date, true);
  }

  function _datePrevMonth() {
    var config = $scope.state;
    var date = moment(config.date).subtract(1, 'months').hour(0).minute(0).second(0).millisecond(0);
    _dateTarget(date, config.date, true);
  }

  function _datePrevYear() {
    var config = $scope.state;
    var date = moment(config.date).subtract(1, 'years').hour(0).minute(0).second(0).millisecond(0);
    _dateTarget(date, config.date, true);
  }

  function _dateTarget(target, current, less) {
    /* Change to available date that is closest to target, but not current */
    var config = $scope.state;
    var date = _getClosest(config.availableDates, target, current, less);
    if (date == null) {
      return;
    }

    config.date = date;
    if (config.selectedDate != null) {
      config.selectedDate = date;
    }
  }
}

function FarmXDateSelectService($rootScope, $mdDialog, $farmXSensorInfo) {
  this.showDateFilter = function(config) {
    return $mdDialog.show({
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        "config": config
      },
      template: '<md-dialog layout="column" layout-align="start stretch">' +
                '  <md-toolbar>' +
                '    <div class="md-toolbar-tools">' +
                '      <h2>Select Date</h2>' +
                '    </div>' +
                '  </md-toolbar>' +
                '  <md-dialog-content flex="100">' +
                '    <md-calendar class="fixed-calendar" ng-model="date" md-date-filter="isAvailable"></md-calendar>' +
                '  </md-dialog-content>' +
                '  <md-dialog-actions layout="row">' +
                '    <span flex></span>' +
                '    <md-button ng-click="submit(true)">' +
                '      Done' +
                '    </md-button>' +
                '  </md-dialog-actions>' +
                '</md-dialog>',
      controller: FarmXSelectDate
    });
  };

  this.showMultiDateFilter = function(startDate, endDate) {
    return $mdDialog.show({
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        "startDate": startDate,
        "endDate": endDate,
      },
      template: '<md-dialog style="width: 256px; height: 324px;" layout="column" layout-align="start stretch">' +
                '  <md-toolbar>' +
                '    <div class="md-toolbar-tools">' +
                '      <h2>Select Dates</h2>' +
                '    </div>' +
                '  </md-toolbar>' +
                '  <md-dialog-content flex="100">' +
                '    <div class="md-dialog-content" style="padding: 10px">' +
                '      <div flex="100" layout="column" layout-align="start stretch">' +
                '        <div flex="100" layout="column" style="margin-top: 10px; margin-bottom: 20px">' +
                '          <div flex="100" layout="row" layout-align="start center">' +
                '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">From</label>' +
                '            <md-datepicker ng-model="startDate" md-placeholder="Enter Start Date" md-open-on-focus></md-datepicker>' +
                '          </div>' +
                '          <div flex="100" layout="row" layout-align="start center">' +
                '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">To</label>' +
                '            <md-datepicker ng-model="endDate" md-placeholder="Enter End Date" md-open-on-focus></md-datepicker>' +
                '          </div>' +
                '        </div>' +
                '        <div flex="100" layout="column" layout-align="start stretch">' +
                '          <div flex="100" layout="row" layout-align="start stretch" style="margin-bottom: 10px">' +
                '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="today()">' +
                '              <span>Today</span>' +
                '            </button>' +
                '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px;" ng-click="yesterday()">' +
                '              <span>Yesterday</span>' +
                '            </button>' +
                '          </div>' +
                '          <div flex="50" layout="row" layout-align="start stretch" style="margin-bottom: 10px">' +
                '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="lastDays(6)">' +
                '              <span>Last 7 Days</span>' +
                '            </button>' +
                '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px;" ng-click="lastDays(30)">' +
                '              <span>Last 30 Days</span>' +
                '            </button>' +
                '          </div>' +
                '          <div flex="50" layout="row" layout-align="start stretch" style="margin-bottom: 10px">' +
                '            <button type="button" flex="100" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="lastYear()">' +
                '              <span>Last Year</span>' +
                '            </button>' +
                '            <button type="button" flex="100" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="thisYear()">' +
                '              <span>This Year</span>' +
                '            </button>' +
                '          </div>' +
                '        </div>' +
                '      </div>' +
                '    </div>' +
                '  </md-dialog-content>' +
                '  <md-dialog-actions layout="row">' +
                '    <span flex></span>' +
                '    <md-button ng-click="submit(true)">' +
                '      Filter' +
                '    </md-button>' +
                '  </md-dialog-actions>' +
                '</md-dialog>',
      controller: FarmXSelectMultiDate
    });
  };

  function FarmXSelectDate($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $log, config) {
    $scope.maxDate = moment().toDate();

    $scope.$watch('config', function () {
      $scope.maxDate = moment(config.availableDates.slice(-1)[0]).hour(0).minute(0).second(0).millisecond(0).toDate();
      $scope.date = moment(config.date).toDate();
    });

    $scope.isAvailable = function (toCheck) {
      var toCheckMoment = moment(toCheck).hour(0).minute(0).second(0).millisecond(0);
      var available = false;

      angular.forEach(config.availableDates, function(value) {
        if (toCheckMoment.isSame(moment(value).hour(0).minute(0).second(0).millisecond(0))) {
          available = true;
          return;
        }
      });

      return available;
    };

    $scope.submit = function() {
      var tmp = moment($scope.date)
      var days = config.date.diff(tmp, 'days');
      var result = moment(config.date).subtract(days, 'days');
      $mdDialog.hide(result);
    };
  }

  function FarmXSelectMultiDate($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $log, startDate, endDate) {
    console.error("inputs", startDate, endDate);
    if (!startDate || !endDate) $scope.thisYear();

    $scope.$startDate = startDate;
    $scope.$endDate = endDate;
    $scope.startDate = startDate.toDate();
    $scope.endDate = endDate.toDate();

    $scope.submit = function() {
      var start = moment($scope.startDate);
      var end = moment($scope.endDate);

      $mdDialog.hide([start, end]);
    };

    $scope.today = function() {
      $scope.$startDate = moment().hour(0).minute(0).second(0).millisecond(0);
      $scope.$endDate = moment().hour(23).minute(59).second(59).millisecond(999);
      $scope.startDate = $scope.$startDate.toDate();
      $scope.endDate = $scope.$endDate.toDate();
    };

    $scope.yesterday = function() {
      $scope.$startDate = moment().subtract(1, 'days').hour(0).minute(0).second(0).millisecond(0);
      $scope.$endDate = moment().subtract(1, 'days').hour(23).minute(59).second(59).millisecond(999);
      $scope.startDate = $scope.$startDate.toDate();
      $scope.endDate = $scope.$endDate.toDate();
    };

    $scope.lastDays = function(days) {
      $scope.$startDate = moment().subtract(days, 'days').hour(0).minute(0).second(0).millisecond(0);
      $scope.$endDate = moment().hour(23).minute(59).second(59).millisecond(999);
      $scope.startDate = $scope.$startDate.toDate();
      $scope.endDate = $scope.$endDate.toDate();
    };

    $scope.thisYear = function() {
      $scope.$startDate = moment().startOf('year');
      $scope.$endDate = moment();
      $scope.startDate = $scope.$startDate.toDate();
      $scope.endDate = $scope.$endDate.toDate();
    };

    $scope.lastYear = function() {
      $scope.$startDate = moment().startOf('year').subtract(1, 'year');;
      $scope.$endDate = moment().startOf('year').subtract(1, 'days');
      $scope.startDate = $scope.$startDate.toDate();
      $scope.endDate = $scope.$endDate.toDate();
    };
  }
}
