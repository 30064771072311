module.exports = [
  'app/templates/templates',
  'service/apiService/apiService',
  'service/graphService/graphService',
  'service/scheduleService/scheduleService',
  'service/dashboardService/dashboardService',
  'service/accountSettings/accountSettings',
  'directives/gantt/d3.gantt',
  'directives/angular-leaflet-directive/angular-leaflet-directive',
  'directives/dataExplorer/dataExplorer',
  'directives/leaflet.bing-layer/leaflet.bing-layer',
  'directives/graph/graph',
  'directives/highcharts-ng/highcharts-ng',
  'directives/map/map',
  'directives/satelliteBrowser/satelliteCompare',
  'directives/satelliteBrowser/satelliteMap',
  'directives/satelliteBrowser/satelliteBrowser',
  'directives/satelliteBrowser/satellitePicker',
  'directives/satelliteBrowser/anomalyBrowser',
  'directives/soilBrowser/soilBrowser',
  'directives/sidenavToggler/sidenavToggler',
  'directives/schedule/schedule',
  'directives/irrigation/irrigationTable',
  'directives/irrigation/flowTable',
  'directives/ngHierarchicalSelector/ng-hierarchical-selector',
  'directives/threed/threed',
  'directives/dateNavigator/dateNavigator',
  'directives/satelliteViewer/satelliteViewer',
  'directives/satelliteViewer/satelliteCompare',
  'directives/satelliteViewer/satellitePlotter',
  'directives/satelliteViewer/satelliteViewerSelector',
  'app/widgets/adf-widget-clock/adf-widget-clock',
  'app/widgets/farmx-widget-blockIrrigation/farmx-widget-blockIrrigation',
  'app/widgets/farmx-widget-blockMap/farmx-widget-blockMap',
  'app/widgets/farmx-widget-blockSoil/farmx-widget-blockSoil',
  'app/widgets/farmx-widget-blockSummary/farmx-widget-blockSummary',
  'app/widgets/farmx-widget-blockWeather/farmx-widget-blockWeather',
  'app/widgets/farmx-widget-ranchDailyETC/farmx-widget-ranchDailyETC',
  'app/widgets/farmx-widget-ranchMap/farmx-widget-ranchMap',
  'app/widgets/farmx-widget-ranchSummary/farmx-widget-ranchSummary',
  'app/widgets/farmx-widget-ranchWeather/farmx-widget-ranchWeather',
];
