try {
    angular.module('farmx-directives-sidenav');
} catch (err) {
    angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXSoilBrowserController.$inject = ["$window", "$rootScope", "$scope", "$timeout", "$farmXApi", "$farmXEntitiesCache", "$farmXSoilDataService", "$farmXUtilities", "$log"];
FarmXSoilDataService.$inject = ["$rootScope", "$farmXApi", '$timeout'];

angular
    .module('farmx-directives-sidenav')
    .directive('farmxSoilBrowser', FarmXSoilBrowserDirective)
    .directive('tooltipFollowCursor', TooltipDirective)
    .controller('fzSoilBrowserController', FarmXSoilBrowserController)
    .service('$farmXSoilDataService', FarmXSoilDataService);

function FarmXSoilBrowserDirective() {
    return {
        restrict: 'E',
        scope: {
            sensor: '='
        },
        templateUrl: 'soilBrowser/soilBrowser.template.html',
        controller: 'fzSoilBrowserController',
        controllerAs: 'ctrl',
        link: function link(scope, element, attrs, controller, transcludeFn) {},
    };
}

function TooltipDirective() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
        var x, y;
        element.on('mousemove', function(e) {
            x = e.pageX, y = e.pageY;
            oX = e.target.x, oY = e.target.y;
            this.children[1].style.top = (y + oY + 130) + 'px';
            this.children[1].style.left = (x - oX + 30) + 'px';
        });
        }
    };
}

function FarmXSoilBrowserController($window, $rootScope, $scope, $timeout, $farmXApi, $farmXEntitiesCache,  $farmXSoilDataService, $farmXUtilities, $log) {
    var ctrl = this;
    var helpText = "Hover data to view value";
    ctrl.dataValue = helpText;
    ctrl.dataDate = " ";
    ctrl.cutoffMin = 0;
    ctrl.cutoffMax = 1;
    ctrl.sliderOptions = {
        floor: 0.0,
        ceil: 1.0,
        step: 0.01,
        precision: 2,
        pushRange: true,
        draggableRange: true,
        showOuterSelectionBars: true,
        onChange: cutoffValuesUpdated
    };

    ctrl.saturationPoint = null;
    ctrl.wiltingPoint = null;

    $scope.$watch("sensor", function () {
        if ($scope.sensor == null) return;
        $farmXSoilDataService.loadData($scope.sensor)
        .then(function(data) {
            console.log(data);
            ctrl.imageSrc = data.imgSrc;
            ctrl.wiltingPoint = data.lowerBound;
            ctrl.saturationPoint = data.upperBound;
            $timeout(function () {
                ctrl.resetCutoffs();
            }, 100);
        });
    });

    ctrl.showDetails = function(event) {
        if (!ctrl.imageSrc) return;
        //console.error(_getMousePos($scope.canvas, event));
        var pos = $farmXSoilDataService.getMousePos(event);
        var date = $farmXSoilDataService.getDateFromCoord(pos.x);
        var value = $farmXSoilDataService.getValueFromCoords(pos);
        if (value === undefined) return;
        var depth = $farmXSoilDataService.getDepthFromCoord(pos.y);
        var dateString = date.format('ddd DD MMM hh:mmA');
        var depthString = depth.toFixed(1) + "in";
        var valueString = (value*100).toFixed(1) + "% vwc";
        ctrl.dataValue = valueString + " @ " + depthString;
        ctrl.dataDate = dateString;
    };

    ctrl.hideDetails = function(event) {
        ctrl.dataValue = helpText;
        ctrl.dataDate = " ";
    };

    function cutoffValuesUpdated(sliderId, modelValue, highValue, pointerType) {
        $log.log(sliderId, modelValue, highValue, pointerType);
        ctrl.imageSrc = $farmXSoilDataService.updateImage(modelValue, highValue);
    }

    ctrl.resetCutoffs = function() {
        ctrl.cutoffMin = ctrl.wiltingPoint;
        ctrl.cutoffMax = ctrl.saturationPoint;
        ctrl.imageSrc = $farmXSoilDataService.updateImage(ctrl.cutoffMin, ctrl.cutoffMax);
    };
}

function FarmXSoilDataService($rootScope, $farmXApi, $timeout) {
    var service = this;

    this.getMousePos = _getMousePos;
    this.getDateFromCoord = _getDateFromCoord;
    this.getValueFromCoords = _getValueFromCoords;
    this.getDepthFromCoord = _getDepthFromCoord;
    this.loadData = _loadData;
    this.updateImage = _rebuildImage;

    function _getDepthFromCoord(coord) {
        var startDepth = 8;
        var endDepth = 48;
        var numDepths = 6;
        var depth = (endDepth-startDepth) * (coord / numDepths) + startDepth; 

        return depth;
    }

    function _getValueFromCoords(pos) {
        /*var c = $scope.canvas.getContext("2d");
        var point = c.getImageData(pos.x, pos.y, 1, 1).data;
        console.error("point", point, event);
        return point[0];*/
        var index = Math.floor(pos.y);
        var timeIndex = Math.floor(pos.x);
        var datum = _getDataPoint(this.data, timeIndex, index);
        return datum;
    }

    function _getDateFromCoord(coord) {
        var data = service.data.data;
        var dates = data.dates;
        var numDates = dates.length;
        var start = dates[0];
        var end = dates[numDates-1];
        var timestamp = (coord / numDates) * (end - start) + start;
        var date = moment.unix(timestamp);
        return date;
    }

    function _getMousePos(event) {
        var canvas = service.canvas;
        var target = event.target;
        //console.log(canvas, target);
        var scaleX = canvas.width / target.width,    // relationship bitmap vs. element for X
            scaleY = canvas.height / target.height;
        //console.log(rect, canvas);
        return {
            x: (event.offsetX) * scaleX,   // scale mouse coordinates after they have
            y: (event.offsetY) * scaleY     // been adjusted to be relative to element
        }
    }

    function _getRGB(vwc, lowerBound, upperBound) {
        var value = (vwc - lowerBound) / (upperBound - lowerBound);
        var v = 1;
    
        if (vwc > upperBound) {
            value = (vwc - upperBound) / (1 - upperBound);
            h = value / 3 + 1/3; 
        } else if (vwc < lowerBound) {
            h = 0;
            v = 1 - (lowerBound - vwc) / lowerBound;
        } else {
            h = value / 3;
        }
    
        var h = h,
            s = 1,
            v = v;
            
        var rgb = HSVtoRGB(h, s, v);
        return rgb
    }

    function _getDataPoint(data, x, y) {
        //console.log(x, y, data);
        var row = data.data['soil_moisture_' + (1+y)]
        if (row === undefined) {
            $log.log("data undefined", data, x, y);
            return undefined;
        }
        return row[x];
    }

    function _createImage(data, lowerBound, upperBound) {
        var height = 6;
        var width = data.data.dates.length;
        var buffer = new Uint8ClampedArray(width * height * 4); //TODO: reuse this buffer
        var rgb = { r: 0, g: 0, b: 0 };

        for(var y = 0; y < height; y++) {
            for(var x = 0; x < width; x++) {

                var datum = _getDataPoint(data, x, y);
                if (datum == "NaN") {
                    datum = 0;
                } else {
                    datum = parseFloat(datum);
                    rgb = _getRGB(datum, lowerBound, upperBound);
                }

                var pos = (y * width + x) * 4; // position in buffer based on x and y
                buffer[pos  ] = rgb.r;           // some R value [0, 255]
                buffer[pos+1] = rgb.g;           // some G value
                buffer[pos+2] = rgb.b;           // some B value
                buffer[pos+3] = 255;           // set alpha channel
            }
        }

        var canvas = _createImageCanvas(buffer, width, height);
        var dataUri = canvas.toDataURL();
        service.canvas = canvas;
        return dataUri;
    }

    function _createImageCanvas(buffer, width, height) {
        var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        // create imageData object
        var idata = ctx.createImageData(width, height);

        // set our buffer as source
        idata.data.set(buffer);

        // update canvas with new data
        ctx.putImageData(idata, 0, 0);
        return canvas;
    }

    function _rebuildImage(lower, upper) {
        var imgSrc = _createImage(service.data, lower, upper);
        return imgSrc;
    }

    function _loadData(sensorId) {
        return $farmXApi.getRecentSoilData(sensorId)
        .then(function(data) {
            service.data = data;
            var upperBound = service.data.upper_bound,
                lowerBound = service.data.lower_bound;
            var imgSrc = _createImage(data, lowerBound, upperBound);
            
            return {
                imgSrc: imgSrc,
                lowerBound: lowerBound,
                upperBound: upperBound
            };
        });
    }

    /* accepts parameters
    * r  Object = {r:x, g:y, b:z}
    * OR 
    * r, g, b
    */
    function RGBtoHSV(r, g, b) {
        if (arguments.length === 1) {
            g = r.g, b = r.b, r = r.r;
        }
        var max = Math.max(r, g, b), min = Math.min(r, g, b),
            d = max - min,
            h,
            s = (max === 0 ? 0 : d / max),
            v = max / 255;

        switch (max) {
            case min: h = 0; break;
            case r: h = (g - b) + d * (g < b ? 6: 0); h /= 6 * d; break;
            case g: h = (b - r) + d * 2; h /= 6 * d; break;
            case b: h = (r - g) + d * 4; h /= 6 * d; break;
        }

        return {
            h: h,
            s: s,
            v: v
        };
    }

    /* accepts parameters
    * h  Object = {h:x, s:y, v:z}
    * OR 
    * h, s, v
    */
    function HSVtoRGB(h, s, v) {
        var r, g, b, i, f, p, q, t;
        if (arguments.length === 1) {
            s = h.s, v = h.v, h = h.h;
        }
        i = Math.floor(h * 6);
        f = h * 6 - i;
        p = v * (1 - s);
        q = v * (1 - f * s);
        t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0: r = v, g = t, b = p; break;
            case 1: r = q, g = v, b = p; break;
            case 2: r = p, g = v, b = t; break;
            case 3: r = p, g = q, b = v; break;
            case 4: r = t, g = p, b = v; break;
            case 5: r = v, g = p, b = q; break;
        }
        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255)
        };
    }
}
