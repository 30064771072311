(function(window, undefined) {
  'use strict';

  angular.module('farmx.widget.blockSoil', ['adf.provider'])
    .config(["dashboardProvider", function(dashboardProvider){
      dashboardProvider
        .widget('blockSoil', {
          title: 'blockSoil',
          description: 'FarmX blockSoil Widget',
          templateUrl: '{widgetsPath}/farmx-widget-blockSoil/src/view.html',
          titleTemplateUrl: '{widgetsPath}/farmx-widget-blockSoil/src/title.html',
          controller: 'blockSoilController',
          controllerAs: 'ctrl',
          config: {
            ranch: null,
            block: null
          }
        });
    }]).controller('blockSoilController', ["$scope", "$interval", "config", "$farmXEntitiesCache", "$farmXUtilities", function($scope, $interval, config, $farmXEntitiesCache, $farmXUtilities){
      var ctrl = this;
      var blockObject = $farmXEntitiesCache.findBlockById(config.block);
      var ranchObject = $farmXEntitiesCache.findRanchById(config.ranch);

      $scope.$parent.model.controller = ctrl;

      ctrl.selected = {
        value: []
      };
      ctrl.selected.type = "Block";
      ctrl.selected.value.push(ranchObject);
      ctrl.selected.value.push(blockObject);

      ctrl.getTitle = _getTitle;
      ctrl.getTitleFillColor = _getTitleFillColor;
      ctrl.getTitleTextColor = _getTitleTextColor;
      ctrl.viewInMap = _viewInMap;

      function _getTitle() {
        return blockObject.name;
      }

      function _getTitleFillColor() {
        return $farmXUtilities.stateColorCode[blockObject.state.state].fillColor;
      }

      function _getTitleTextColor() {
        return $farmXUtilities.stateColorCode[blockObject.state.state].textColor;
      }

      function _viewInMap() {
        $rootScope.$broadcast("farmx.header.tabSelected", { tabType: "map", params: {ranch: config.ranch, block: config.block }});
      }
    }]);

  angular.module('farmx.widget.blockSoil').run(['$templateCache', function($templateCache) {
    $templateCache.put('{widgetsPath}/farmx-widget-blockSoil/src/title.html',
      '<div layout=row layout-align="start center" class=farmx-widget-title ng-style="{ \'background-color\': definition.controller.getTitleFillColor() }">' +
      '  <span class=farmx-widget-title-left ng-style="{ color: definition.controller.getTitleTextColor() }">{{ definition.controller.getTitle(); }}</span>' +
      '  <span style="outline: none" ng-style="{ color: definition.controller.getTitleTextColor() }" ng-click="definition.controller.viewInMap()">View in map</span>' +
      '</div>'
    );

    $templateCache.put('{widgetsPath}/farmx-widget-blockSoil/src/view.html',
      '<div class=farmx-framed-panel>' +
      '  <div class=farmx-framed-area>' +
      '    <span>Soil</span>' +
      '  </div>' +
      '</div>'
    );
  }]);
})(window);
