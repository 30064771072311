try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

/* SatelliteViewerSelector */
angular
  .module('farmx-directives-sidenav')
  .directive('farmxSatelliteViewerSelector', FarmXSatelliteViewerSelectorDirective)
  .controller('fzSatelliteViewerSelectorController', FarmXSatelliteViewerSelectorController);

FarmXSatelliteViewerSelectorController.$inject = ['$window', '$rootScope', '$scope', '$timeout', '$log'];

function FarmXSatelliteViewerSelectorDirective() {
  return {
    restrict: 'E',
    scope: {
      viewerId: '@viewerid'
    },
    templateUrl: 'satelliteViewer/satelliteViewerSelector.template.html',
    controller: 'fzSatelliteViewerSelectorController',
    controllerAs: 'ctrl'
  };
}

function FarmXSatelliteViewerSelectorController($window, $rootScope, $scope, $timeout, $log) {
  var ctrl = this;
  var scope = $scope;

  ctrl.toggleShow = _toggleShow;

  ctrl.$$dataTypes = [
    { id: "NDVI", label: "Plant Vigor (NDVI)", title: "Plant Vigor" },
    { id: "NDVI_Histogram_Equalization", label: "Plant Vigor (NDVI) enhanced", title: "Plant Vigor +" },
    { id: "NDWI_SWIR", label: "Water Content (NDWI)", title: "Water Content" },
    { id: "NDWI_SWIR_Histogram_Equalization", label: "Water Content (NDWI) enhanced", title: "Water Content +" },
    { id: "TrueColor", label: "True Color", title: "True Color" },
    { id: "Thermal", label: "Thermal", title: "Thermal"  },
    {
      id: 'CHL_Red_Edge',
      label: 'CHL Red Edge',
      title: 'CHL Red Edge',
    },
    {
      id: 'CHL_Red_Edge_Histogram_Equalization',
      label: 'CHL Red Edge enhanced',
      title: 'CHL Red Edge +',
    },
    {
      id: 'DryBiomassIndex',
      label: 'Dry Biomass Index',
      title: 'Dry Biomass',
    },
    {
      id: 'DryBiomassIndex_Histogram_Equalization',
      label: 'Dry Biomass Index enhanced',
      title: 'Dry Biomass +',
    },
  ];

  ctrl.$$dataSources = [
    { id: "satellite", label: "Satellite" },
    { id: "aerial", label: "Aerial" },
  ];

  $scope.dataSource = ctrl.$$dataSources;

  $scope.$watch("visible", function(newValue, oldValue) {
    if (newValue != oldValue) {
      $rootScope.$broadcast("farmx.satelliteViewerSelector." + $scope.viewerId + ".visible", { visible: newValue });
    }
  });

  $scope.$watch("dataType", function(newValue, oldValue) {
    if (newValue != oldValue) {
      $rootScope.$broadcast("farmx.satelliteViewerSelector." + $scope.viewerId + ".dataType", { dataType: $scope.dataType.id });
    }
  });

  $scope.$watch("dataSource", function(newValue, oldValue) {
    if (newValue != oldValue) {
      $rootScope.$broadcast("farmx.satelliteViewerSelector." + $scope.viewerId + ".dataSource", { dataSource: $scope.dataSource });
    }
  });

  $scope.$on("farmx.satelliteViewerSelector." + $scope.viewerId + ".update.visible", function(eventName, value) {
    $scope.visible = value.visible;
  });

  $scope.$on("farmx.satelliteViewerSelector." + $scope.viewerId + ".update.dataType", function(eventName, value) {
    angular.forEach(ctrl.$$dataTypes, function(element, index) {
      if (element.id === value.id) {
        $scope.dataType = element;
      }
    });
  });

  function _toggleShow() {
    $scope.visible = $scope.visible ? false : true;
  }

  this.$onInit = function() {
    $scope.visible = $window.innerWidth < 768 ? true : false;
    $scope.dataType = ctrl.$$dataTypes[0];
    $scope.dataSource = ctrl.$$dataSources;
  };
}
