(function(window, undefined) {
  'use strict';

  angular.module('farmx.widget.ranchMap', ['adf.provider'])
    .config(["dashboardProvider", function(dashboardProvider){
      dashboardProvider
        .widget('ranchMap', {
          title: 'ranchMap',
          description: 'FarmX ranchMap Widget',
          templateUrl: '{widgetsPath}/farmx-widget-ranchMap/src/view.html',
          controller: 'mapController',
          controllerAs: 'ctrl',
          frameless: true,
          config: {
            selected: null
          }
        });
    }]).controller('mapController', ["$scope", "$interval", "config", "$farmXEntitiesCache", function($scope, $interval, config, $farmXEntitiesCache){
      var ctrl = this;

      ctrl.selected = {
        value: []
      };
      ctrl.mapViewConfig = {};

      if (config.selected.type === "Ranch") {
        ctrl.selected.type = "Ranch";
        ctrl.selected.value.push(angular.copy($farmXEntitiesCache.findRanchById(config.selected.ranch)));
      } else if (config.selected.type === "Block") {
        ctrl.selected.type = "Block";
        ctrl.selected.value.push(angular.copy($farmXEntitiesCache.findRanchById(config.selected.ranch)));
        ctrl.selected.value.push($farmXEntitiesCache.findBlockById(config.selected.blocks[0]));
      }
    }]);

  angular.module('farmx.widget.ranchMap').run(['$templateCache', function($templateCache) {
    $templateCache.put('{widgetsPath}/farmx-widget-ranchMap/src/view.html',
      '<div class="farmx-frameless-panel panel panel-default">' +
      '  <div class=farmx-frameless-area>' +
      '    <farmx-satellite-map-view config=ctrl.mapViewConfig selected=ctrl.selected scrollwheelzoom=false soillayer=false></farmx-satellite-map-view>' +
      '  </div>' +
      '</div>');
  }]);
})(window);
