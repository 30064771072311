try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

angular
  .module('farmx-directives-sidenav')
  .directive('farmxSatellite', FarmXSatelliteViewerCompareDirective)
  .controller('fzSatelliteViewerCompareController', FarmXSatelliteViewerCompareController);

FarmXSatelliteViewerCompareController.$inject = ['$window', '$rootScope', '$scope', '$timeout', '$log', '$farmXSatelliteService'];

function FarmXSatelliteViewerCompareDirective() {
  return {
    restrict: 'E',
    scope: {
      selected: '='
    },
    templateUrl: 'satelliteViewer/satelliteCompare.template.html',
    controller: 'fzSatelliteViewerCompareController',
    controllerAs: 'ctrl'
  };
}

function FarmXSatelliteViewerCompareController($window, $rootScope, $scope, $timeout, $log, $farmXSatelliteService) {
  var ctrl = this;
  var scope = $scope;
}
