module.exports = function($scope, $rootScope, $state, $timeout, $farmXApi, $farmXEntitiesCache, $farmxAccountSettings, $log, $window, $mdDialog, $cookies, $location) {
  var ctrl = this;
  var scope = $scope;

  // Added default blank tab in index 0, to avoid redirect to map page issue
  // when loading any other page through URL
  // There is still a room for improvements to prevent onTabSelected being 
  // called twice for this usecase
  ctrl.tabs = [
    {
      "page_id": "",
      "page_label": "",
      "page_state": "",
      "active": false,
      "class":"hidden-tab",
    },
    {
      "page_id": "map",
      "page_label": "Map",
      "page_state": "home.map",
      "page_subState": [
        "index",
        "block"
      ],
      "page_params": {
        "ranch": null,
        "block": null
      },
      "active": false,
      "icon": "globe",
    },
    {
      "page_id": "graph",
      "page_label": "Graph",
      "page_state": "home.graph",
      "page_subState": [
        "index",
        "sensor"
      ],
      "page_params": {
        "ranch": null,
        "block": null,
        "sensorType": null,
        "sensorId": null
      },
      "active": false,
      "icon": "line-chart",
    },
    {
      "page_id": "dashboard",
      "page_label": "Dashboard",
      "page_state": "home.dashboard",
      "active": false,
      "icon": "dashboard",
    },
    {
      "page_id": "schedule",
      "page_label": "Schedule",
      "page_state": "home.schedule",
      "active": false,
      "icon": "schedule",
    },
    {
      "page_id": "imaging",
      "page_label": "Imaging",
      "page_state": "home.imaging",
      "active": false,
      "icon": "map",
    }
  ];

  ctrl.userName = null;
  ctrl.password = null;

  ctrl.login = _login;
  ctrl.logout = _logout;
  ctrl.showPreferences = _showPreferences;
  ctrl.onTabSelected = _onTabSelected;
  ctrl.onTabDeselected = _onTabDeselected;
  ctrl.openUserMenu = _openUserMenu;
  ctrl.getLoginStatus = _getLoginStatus;
  ctrl.resetLoginStatus = _resetLoginStatus;
  ctrl.onKeyPress = _onKeyPress;

  ctrl.width = $(window).innerWidth();
  ctrl.height = $(window).innerHeight();

  $(window).resize(function() {
    scope.$apply(function(){
        ctrl.width = $(window).innerWidth();
        ctrl.height = $(window).innerHeight();
    });

  });

  var loginStatus = '';

  $scope.$on('farmx.app.token.refresh.failed', function(event, data) {
    $state.go('login');
  });

  $scope.$on('farmx.header.tabSelected', function(event, data) {
    angular.forEach(ctrl.tabs, function(eTab, iTab) {
      if (eTab.page_id === data.tabType) {
        if (data.params != null) {
          eTab.page_params = data.params;
        }

        $timeout(function() {
          if (eTab.active === false) {
            eTab.active = true;
          }
        }, 1000);
      } else {
        eTab.active = false;
      }
    });
  });

  function _onKeyPress(event) {
    if (event.keyCode === 13) {
      _login();
    }
  }

  function _login() {
    $farmXApi.login(ctrl.userName, ctrl.password).then(
      function(data) {
        $farmXEntitiesCache.clearCache();
        // This code is to redirect to respective page url
        // called with expired token or no token
        // This works only for the url paths used in app-config,
        // for any other URLs this will not work 
        // and behaviour is unpredictable
        if($state.locationURL &&
            $state.locationURL != '/home/map' && 
            $state.locationURL != '/login'){
          var symbol = '#';
          if($state.locationURL.includes('?'))
            symbol = '?';
            
          var pageState = $state.locationURL.substring(1).split("/").join(".").split(symbol)[0];
          angular.forEach(ctrl.tabs, function(eTab, iTab) {
            if (pageState.startsWith(eTab.page_state)) {
              eTab.active = true;
            } else {
              eTab.active = false;
            }
          });
          $state.go(pageState);
        }else
          $state.go('home.map');
      },
      function(error) {
        loginStatus = "Invalid Username/Password";
      }
    );
  }

  function _logout() {
    $farmXApi.logout();

    $state.go('login');
  }

  function _showPreferences(event) {
    $farmxAccountSettings.show().then(
      function() {
      },
      function() {
      }
    );
  }

  function _onTabSelected(tab) {
    tab.active = true;

    if ($state.current.name.startsWith(tab.page_state) === false) {
      if (tab.page_id === "graph") {
        if (tab.page_params.ranch != null) {
          $state.go(tab.page_state + "." + tab.page_subState[1], tab.page_params);
        } else {
          $state.go(tab.page_state + "." + tab.page_subState[0]);
        }
      } else if (tab.page_id === "map") {
        if (tab.page_params.ranch != null) {
          $state.go(tab.page_state + "." + tab.page_subState[1], tab.page_params);
        } else {
          $state.go(tab.page_state + "." + tab.page_subState[0]);
        }
      } else {
          $state.go(tab.page_state);
      }

      $timeout(function() {
        $rootScope.$broadcast("farmx.tab.selected", tab);
      }, 100);
    }
  }

  function _onTabDeselected(tab) {
    tab.active = false;

    if (tab.page_params != null) {
      Object.keys(tab.page_params).forEach(function(key) {
        tab.page_params[key] = null;
      });
    }
  }

  function _openUserMenu($mdOpenMenu, $event) {
  }

  function _getLoginStatus() {
    return loginStatus;
  }

  function _resetLoginStatus() {
    loginStatus = '';
  }

  this.$onInit = function() {
    var jwtToken = $farmXApi.getJWTInfo();

    if($location.url().substring(1).split("/").join(".") !== '')
      $state.locationURL = $location.url();

    if ($location.url().includes('ranchId') || $location.url().includes('ranchId')) {
      if ($location.search().ranchId) $state.ranchId = $location.search().ranchId;
      if ($location.search().blockId) $state.blockId = $location.search().blockId;
    }

    if (jwtToken == null) {
      $state.go('login');
    } else {
      var user_id = jwtToken.user_id;

      var pageState = $location.url().substring(1).split("/").join(".");
      var pageParams = [];
      if (pageState.startsWith("home.graph")) {
        pageState = "home.graph";
      }

      $farmXApi.validateToken().then(
        function(success) {
          if (jwtToken !== undefined && jwtToken.admin) {
            ctrl.tabs.push({
              "page_id": "new_dashboard",
              "page_label": "New Dashboard",
              "page_state": "home.newdashboard",
              "active": false,
              "icon": "dashboard",
            });
          }

          angular.forEach(ctrl.tabs, function(eTab, iTab) {
            if (pageState.startsWith(eTab.page_state)) {
              eTab.active = true;
            } else {
              eTab.active = false;
            }
          });
        }, function(failed) {
          $cookies.remove("auth");
          $state.go("login");
        }
      );
    }
  };
};
