(function(window, undefined) {
  'use strict';

  angular.module('farmx.widget.blockIrrigation', ['adf.provider'])
    .config(["dashboardProvider", function(dashboardProvider){
      dashboardProvider
        .widget('blockIrrigation', {
          title: 'blockIrrigation',
          description: 'FarmX blockIrrigation Widget',
          templateUrl: '{widgetsPath}/farmx-widget-blockIrrigation/src/view.html',
          titleTemplateUrl: '{widgetsPath}/farmx-widget-blockIrrigation/src/title.html',
          controller: 'blockIrrigationController',
          controllerAs: 'ctrl',
          config: {
            ranch: null,
            block: null
          }
        });
    }]).controller('blockIrrigationController', ["$rootScope","$scope", "$interval", "config", "$farmXEntitiesCache", "$farmXUtilities", function($rootScope, $scope, $interval, config, $farmXEntitiesCache, $farmXUtilities){
      var ctrl = this;
      var blockObject = $farmXEntitiesCache.findBlockById(config.block);
      var ranchObject = $farmXEntitiesCache.findRanchById(config.ranch);

      $scope.$parent.model.controller = ctrl;

      ctrl.irrigationConfig = {
        title: {
          visible: false,
          text: ''
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false,
          visible: false
        },
        chart: {
          type: 'column',
          marginBottom: 5
        },
        xAxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ],
          crosshair: true,
          visible: false
        },
        yAxis: {
          min: 0,
          visible: false
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
        },
        series: [{
          name: 'Tokyo',
          data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
        }
      ]};

      ctrl.selected = {
        value: []
      };
      ctrl.selected.type = "Block";
      ctrl.selected.value.push(ranchObject);
      ctrl.selected.value.push(blockObject);

      ctrl.getTitle = _getTitle;
      ctrl.getTitleFillColor = _getTitleFillColor;
      ctrl.getTitleTextColor = _getTitleTextColor;
      ctrl.viewInMap = _viewInMap;

      function _getTitle() {
        return blockObject.name;
      }

      function _getTitleFillColor() {
        return $farmXUtilities.stateColorCode[blockObject.state.state].fillColor;
      }

      function _getTitleTextColor() {
        return $farmXUtilities.stateColorCode[blockObject.state.state].textColor;
      }

      function _viewInMap() {
        $rootScope.$broadcast("farmx.header.tabSelected", { tabType: "map", params: {ranch: config.ranch, block: config.block }});
      }
    }]);

  angular.module('farmx.widget.blockIrrigation').run(['$templateCache', function($templateCache) {
    $templateCache.put('{widgetsPath}/farmx-widget-blockIrrigation/src/title.html',
      '<div layout=row layout-align="start center" class=farmx-widget-title ng-style="{ \'background-color\': definition.controller.getTitleFillColor() }">' +
      '  <span class=farmx-widget-title-left ng-style="{ color: definition.controller.getTitleTextColor() }">{{ definition.controller.getTitle(); }}</span>' +
      '  <span style="outline: none" ng-style="{ color: definition.controller.getTitleTextColor() }" ng-click="definition.controller.viewInMap()">View in map</span>' +
      '</div>'
    );

    $templateCache.put('{widgetsPath}/farmx-widget-blockIrrigation/src/view.html',
      '<div class="farmx-framed-panel ng-scope">' +
      '  <div class="farmx-frameless-area">' +
      '    <div class="layout-column layout-fill">' +
      '      <div class="flex-50 layout-row layout-align-center-center">' +
      '        <div class="flex-50 layout-row layout-align-center-end">' +
      '          <span style="font-size: 36px; font-weight: bold;line-height: 36px">20</span>' +
      '          <span style="font-size: 12px; margin-left: 5px">psi</span>' +
      '        </div>' +
      '        <div class="flex-25 layout-column layout-align-center-center">' +
      '          <div class="layout-row layout-align-start-end">' +
      '            <span style="font-size: 32px; line-height: 32px">13</span>' +
      '            <span style="font-size: 12px">%</span>' +
      '          </div>' +
      '          <span style="font-size: 12px">variation</span>' +
      '        </div>' +
      '        <div class="flex-25 layout-column layout-align-center-center">' +
      '          <div class="flex-50 layout-row layout-align-start-end">' +
      '            <span style="font-size: 16px; font-weight: bold">17</span>' +
      '            <span style="font-size: 12px; margin-left: 5px">psi</span>' +
      '          </div>' +
      '          <div class="flex-50 layout-row layout-align-start-end">' +
      '            <span style="font-size: 16px; font-weight: bold">25</span>' +
      '            <span style="font-size: 12px; margin-left: 5px">psi</span>' +
      '          </div>' +
      '        </div>' +
      '      </div>' +
      '      <div class="flex-50 layout-row">' +
      '        <highchart config="ctrl.irrigationConfig"></highchart>' +
      '      </div>' +
      '    </div>' +
      '  </div>' +
      '</div>'
    );
  }]);
})(window);
