/**
 * @author Dimitry Kudrayvtsev
 * @version 2.1
 */

d3.gantt = function() {
  var FIT_TIME_DOMAIN_MODE = "fit";
  var FIXED_TIME_DOMAIN_MODE = "fixed";

  var margin = {
    top: 20,
    right: 40,
    bottom: 20,
    left: 150
  };
  var selector = 'body';
  var timeDomainStart = d3.time.day.offset(new Date(), -3);
  var timeDomainEnd = d3.time.hour.offset(new Date(), +3);
  var timeDomainMode = FIT_TIME_DOMAIN_MODE; // fixed or fit
  var taskTypes = [];
  var taskStatus = [];
  var height = document.body.clientHeight - margin.top - margin.bottom - 5;
  var width = document.body.clientWidth - margin.right - margin.left - 5;

  var tickFormat = "%H:%M";

  var keyFunction = function(d) {
    return d.startDate + d.taskName + d.endDate;
  };

  var rectTransform = function(d) {
    return "translate(" + x(d.startDate) + "," + y(d.taskName) + ")";
  };

  var x = d3.time.scale().domain([timeDomainStart, timeDomainEnd]).range([0, width]).clamp(true);

  var y = d3.scale.ordinal().domain(taskTypes).rangeRoundBands([0, height - margin.top - margin.bottom], .1);

  var xAxis = d3.svg.axis().scale(x).orient("bottom").tickFormat(d3.time.format(tickFormat)).tickSubdivide(true)
    .tickSize(8).tickPadding(8);

  var yAxis = d3.svg.axis().scale(y).orient("left").tickSize(0);

  var clickHandler;

  var initTimeDomain = function(tasks) {
    if (timeDomainMode === FIT_TIME_DOMAIN_MODE) {
      if (tasks === undefined || tasks.length < 1) {
        timeDomainStart = d3.time.day.offset(new Date(), -3);
        timeDomainEnd = d3.time.hour.offset(new Date(), +3);
        return;
      }
      tasks.sort(function(a, b) {
        return a.endDate - b.endDate;
      });
      timeDomainEnd = tasks[tasks.length - 1].endDate;
      tasks.sort(function(a, b) {
        return a.startDate - b.startDate;
      });
      timeDomainStart = tasks[0].startDate;
    }
  };

  var initAxis = function() {
    x = d3.time.scale().domain([timeDomainStart, timeDomainEnd]).range([0, width]).clamp(true);
    y = d3.scale.ordinal().domain(taskTypes).rangeRoundBands([0, height - margin.top - margin.bottom], .1);
    xAxis = d3.svg.axis().scale(x).orient("bottom").tickFormat(d3.time.format(tickFormat)).tickSubdivide(true)
      .tickSize(8).tickPadding(8);

    yAxis = d3.svg.axis().scale(y).orient("left").tickSize(0);
  };

  function gantt(tasks) {
    return gantt;
  }

  gantt.draw = function(ranges, blockId) {
    initAxis();

    d3.select(selector).select("svg").remove();

    // Create SVG
    var svg = d3.select(selector)
      .append("svg")
      .attr("class", "chart")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    var defs = svg.append("defs");
    var filter = defs.append("filter").attr("id","sofGlow").attr("height","300%").attr("width","300%").attr("x","-75%").attr("y","-75%");
    filter.append("feMorphology").attr("operator","dilate").attr("radius","1").attr("in","SourceAlpha").attr("result","thicken");
    filter.append("feGaussianBlur").attr("in","thicken").attr("stdDeviation","3").attr("result","blurred");
    filter.append("feFlood").attr("flood-color","rgb(0,0,255)").attr("result","glowColor");
    filter.append("feComposite").attr("in2","glowColor").attr("in2","blurred").attr("operator","in").attr("result","softGlow_colored");
    var feMerge = filter.append("feMerge");
    feMerge.append("feMergeNode").attr("in", "softGlow_colored");
    feMerge.append("feMergeNode").attr("in", "SourceGraphic");

    // Shading
    var shading = d3.select(selector)
      .select("svg")
      .append("g")
      .attr("id", "shading")
      .attr("display", "none");
    var w = ((width - margin.left - margin.right) / ranges.length);
    ranges.forEach(function(range, index) {
      if ((index % 2) === 0) {
        shading.insert("rect")
          .attr("x", index * w)
          .attr("y", 0)
          .attr("height", (height - margin.top - margin.bottom))
          .attr("width", w)
          .attr("style", "fill: #F5F5F5")
          .on("click", function() {
            if (clickHandler !== undefined && clickHandler !== null) {
              clickHandler({
                "isBlank": true,
                "data": {
                  blockId: blockId,
                  date: range,
                  index: index,
                  blockWidth: w,
                  coords: d3.mouse(this)
                }
              });
            }
          });
      } else {
        shading.insert("rect")
          .attr("x", index * w)
          .attr("y", 0)
          .attr("height", (height - margin.top - margin.bottom))
          .attr("width", w)
          .attr("style", "fill: #FFF")
          .on("click", function() {
            if (clickHandler !== undefined && clickHandler !== null) {
              clickHandler({
                "isBlank": true,
                "data": {
                  blockId: blockId,
                  date: range,
                  index: index,
                  blockWidth: w,
                  coords: d3.mouse(this)
                }
              });
            }
          });
      }
    });

    // header
    var header = d3.select(selector)
      .select("svg")
      .append("g")
      .attr("id", "header")
      .attr("display", "none");
    header.append("text")
      .attr("x", "5")
      .attr("y", "12")
      .attr("font-size", 10)
      .text("Recommended");
    header.append("text")
      .attr("x", "5")
      .attr("y", "66")
      .attr("font-size", 10)
      .text("Scheduled");
    header.append("text")
      .attr("x", "5")
      .attr("y", "120")
      .attr("font-size", 10)
      .text("Actual");
    header.append("line")
      .attr("stroke-dasharray","4")
      .attr("x1", 0)
      .attr("y1", "54")
      .attr("x2", (width - margin.left - margin.right))
      .attr("y2", "54")
      .attr("style", "stroke:#e3e3e3;stroke-width:2;");
    header.append("line")
      .attr("stroke-dasharray","4")
      .attr("x1", 0)
      .attr("y1", "108")
      .attr("x2", (width - margin.left - margin.right))
      .attr("y2", "108")
      .attr("style", "stroke:#e3e3e3;stroke-width:2;");
    header.append("line")
      .attr("stroke-dasharray","4")
      .attr("x1", 0)
      .attr("y1", "162")
      .attr("x2", (width - margin.left - margin.right))
      .attr("y2", "162")
      .attr("style", "stroke:#e3e3e3;stroke-width:2;");

    // loading
    var loading = d3.select(selector)
      .select("svg")
      .append("g")
      .attr("id","loading")
      .attr("width", w)
      .attr("height", (height - margin.top - margin.bottom))
      .attr("x", 0)
      .attr("y", 0);
    loading.append("text")
      .attr("x",692)
      .attr("y",85)
      .text("Loading");

    // Draw current time line
    var position1 = moment().diff(ranges[0].date, 'seconds');
    var totalPosition = ranges.length * 24 * 60 * 60;
    var xPos = (width + margin.left + margin.right) * (position1 / totalPosition);

    shading.insert("line")
      .attr("id", "currentTime")
      .attr("stroke-dasharray","4")
      .attr("x1", xPos)
      .attr("y1", "0")
      .attr("x2", xPos)
      .attr("y2", "162")
      .attr("style", "stroke:#d3d3d3;stroke-width:2;");

    // Charting
    var ganttChart = d3.select(selector)
      .select("svg")
      .append("g")
      .attr("id", "gantt")
      .attr("class", "gantt-chart")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

    return gantt;
  };

  gantt.data = function(tasks) {
    initTimeDomain(tasks);

    var svg = d3.select(selector).select("svg");

    svg.select("#shading").attr("display",null);
    svg.select("#header").attr("display",null);
    svg.select("#loading").attr("display", "none");

    svg.select("#gantt")
      .selectAll("rect")
      .data(tasks, keyFunction)
      .enter()
      .append("rect")
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("class", function(d) {
        if (taskStatus[d.status] == null) {
          return "bar";
        }
        return taskStatus[d.status];
      })
      .attr("y", 12)
      .attr("transform", rectTransform)
      .attr("height", function(d) {
        return y.rangeBand() - 15;
      })
      .attr("width", function(d) {
        return Math.max(1, (x(d.endDate) - x(d.startDate)));
      })
      .attr("data", function(d) {
        return d.id;
      })
      .on("click", function(d, i) {
        if (clickHandler !== undefined && clickHandler !== null && d3.select(this).attr("class") === "scheduled") {
          clickHandler({
            "isBlank": false,
            "data": d,
            "scheduledRect": this
          });
        }
        d3.event.stopPropagation();
      });
  }

  gantt.onClick = function(click) {
    clickHandler = click;

    return gantt;
  }

  gantt.margin = function(value) {
    if (!arguments.length)
      return margin;
    margin = value;
    return gantt;
  };

  gantt.timeDomain = function(value) {
    if (!arguments.length)
      return [timeDomainStart, timeDomainEnd];
    timeDomainStart = +value[0], timeDomainEnd = +value[1];
    return gantt;
  };

  /**
   * @param {string}
   *                vale The value can be "fit" - the domain fits the data or
   *                "fixed" - fixed domain.
   */
  gantt.timeDomainMode = function(value) {
    if (!arguments.length)
      return timeDomainMode;
    timeDomainMode = value;
    return gantt;

  };

  gantt.taskTypes = function(value) {
    if (!arguments.length)
      return taskTypes;
    taskTypes = value;
    return gantt;
  };

  gantt.taskStatus = function(value) {
    if (!arguments.length)
      return taskStatus;
    taskStatus = value;
    return gantt;
  };

  gantt.width = function(value) {
    if (!arguments.length)
      return width;
    width = +value;
    return gantt;
  };

  gantt.height = function(value) {
    if (!arguments.length)
      return height;
    height = +value;
    return gantt;
  };

  gantt.tickFormat = function(value) {
    if (!arguments.length)
      return tickFormat;
    tickFormat = value;
    return gantt;
  };

  gantt.selector = function(value) {
    if (!arguments.length)
      return selector;
    selector = value;
    return gantt;
  };

  return gantt;
};
