module.exports = (function() {
  var vendorList = require('./vendor-list');
  for(var i = 0; i < vendorList.length; i++) {
    try {
      require(vendorList[i]);
    } catch (er) {
      console.log('Unable to vendor load ' + vendorList[i] + " --- " + er);
    }
  }

  var appList = require('./app-list');
  for(i =0; i < appList.length; i++) {
    try {
      require(appList[i]);
    } catch (er) {
      console.log('Unable to appList load ' + appList[i] + " " + er);
    }
  }

  window.i18next
    .use(window.i18nextBrowserDetector)
    .use(window.Locize)
    .init({
      fallbackLng: "en-US", // use if detected lng is not available
      debug: true,
      backend: {
        projectId: '5f2bfc8f-9968-4f35-8158-d784beb22225',
        apiKey: 'abc97db1-48bf-4710-92e6-1047bb01aee4',
        referenceLng: 'en-US',
      },
      saveMissing: true, // send not translated keys to endpoint
      keySeparator: false, // we do not use keys in form messages.welcome
    });

  window.i18next.on('languageChanged', function(lng) {
    moment.locale(lng);
  });

  var app = angular.module('farmx.ui', [
      'ui.bootstrap',
      'ui.router',
      'ngAnimate',
      'ngCookies',
      'ngMaterial',
      'ngMessages',
      'ngSanitize',
      'jm.i18next',
      'kendo.directives',
      'leaflet-directive',
      'daterangepicker',
      'farmx-directives',
      'farmx-directives-sidenav',
      'farmx-templates',
      'highcharts-ng',
      'localytics.directives',
      'sprintf',
      'xeditable',
      'hierarchical-selector',
      'adf',
      'rzModule',
      'adf.widget.clock',
      'farmx.widget.blockIrrigation',
      'farmx.widget.blockMap',
      'farmx.widget.blockSoil',
      'farmx.widget.blockSummary',
      'farmx.widget.blockWeather',
      'farmx.widget.ranchDailyETC',
      'farmx.widget.ranchMap',
      'farmx.widget.ranchSummary',
      'farmx.widget.ranchWeather'
    ]);

  var appConfig = require('./app-config');
  app.config(appConfig);
  app.run(function($rootScope, $farmXApi, $state, $urlService, $trace, $transitions, $cookies, $location, $log) {
    $rootScope.$on('$locationChangeStart', function(event, next, current, newState, oldState) {
      $log.log("Start " + next);
    });

    $rootScope.$on('$locationChangeSuccess', function(event, next, current, newState, oldState) {
      $log.log("Success " + next);
    });

    $rootScope.$on('$stateChangeStart',function(event, toState, toParams, fromState, fromParams){
      $log.log('$stateChangeStart to '+toState.to+'- fired when the transition begins. toState,toParams : \n',toState, toParams);
    });

    $rootScope.$on('$stateChangeError',function(event, toState, toParams, fromState, fromParams){
      $log.log('$stateChangeError - fired when an error occurs during transition.');
      $log.log(arguments);
    });

    $rootScope.$on('$stateChangeSuccess',function(event, toState, toParams, fromState, fromParams){
      $log.log('$stateChangeSuccess to '+toState.name+'- fired once the state transition is complete.');
    });

    $rootScope.$on('$viewContentLoaded',function(event){
      $log.log('$viewContentLoaded - fired after dom rendered',event);
    });

    $rootScope.$on('$stateNotFound',function(event, unfoundState, fromState, fromParams){
      $log.log('$stateNotFound '+unfoundState.to+'  - fired when a state cannot be found by its name.');
      $log.log(unfoundState, fromState, fromParams);
    });

    $transitions.onBefore({ to: '**' }, function(trans) {
      $log.log("onBefore " + trans);
    });

    $transitions.onEnter({ to: '**' }, function(trans) {
      $log.log("onEnter " + trans);
    });

    $transitions.onError({ to: '**' }, function(trans) {
      $log.log("onError " + trans);
    });

    $transitions.onExit({ to: '**' }, function(trans) {
      $log.log("onExit " + trans);
    });

    $transitions.onFinish({ to: '**' }, function(trans) {
      $log.log("onFinish " + trans);
    });

    $transitions.onRetain({ to: '**' }, function(trans) {
      $log.log("onRetain " + trans);
    });

    $transitions.onStart({ to: '**' }, function(trans) {
      $log.log("onStart " + trans);
    });

    $transitions.onSuccess({ to: '**' }, function(trans) {
      $log.log("onSuccess " + trans);
    });
  });

  app.controller('MainController' , [ '$scope', '$rootScope', '$state', '$timeout', '$farmXApi', '$farmXEntitiesCache', '$farmxAccountSettings', '$log', '$window', '$mdDialog', '$cookies', '$location', require('../components/main.ctrl') ]);
})();
