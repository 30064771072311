(function(window, document, undefined) {
'use strict';

// Source: index.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('index.html',
    '<!DOCTYPE html>\n' +
    '<!--[if lt IE 7]>      <html lang="{{ LANGUAGE_CODE|default:"en-gb" }}" class="no-js lt-ie9 lt-ie8 lt-ie7"> <![endif]-->\n' +
    '<!--[if IE 7]>         <html lang="{{ LANGUAGE_CODE|default:"en-gb" }}" class="no-js lt-ie9 lt-ie8"> <![endif]-->\n' +
    '<!--[if IE 8]>         <html lang="{{ LANGUAGE_CODE|default:"en-gb" }}" class="no-js lt-ie9"> <![endif]-->\n' +
    '<!--[if gt IE 8]><!--> <html lang="{{ LANGUAGE_CODE|default:"en-gb" }}" class="no-js"> <!--<![endif]-->\n' +
    '\n' +
    '<html ng-app="farmx.ui">\n' +
    '  <head>\n' +
    '    <meta charset="utf-8">\n' +
    '    <meta http-equiv="Content-Security-Policy"/>\n' +
    '    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">\n' +
    '    <title>FarmX</title>\n' +
    '\n' +
    '    <link rel="apple-touch-icon" sizes="57x57" href="images/favicons/apple-touch-icon-57x57.png?v=699A9qwqWL">\n' +
    '    <link rel="apple-touch-icon" sizes="60x60" href="images/favicons/apple-touch-icon-60x60.png?v=699A9qwqWL">\n' +
    '    <link rel="apple-touch-icon" sizes="72x72" href="images/favicons/apple-touch-icon-72x72.png?v=699A9qwqWL">\n' +
    '    <link rel="apple-touch-icon" sizes="76x76" href="images/favicons/apple-touch-icon-76x76.png?v=699A9qwqWL">\n' +
    '    <link rel="apple-touch-icon" sizes="114x114" href="images/favicons/apple-touch-icon-114x114.png?v=699A9qwqWL">\n' +
    '    <link rel="apple-touch-icon" sizes="120x120" href="images/favicons/apple-touch-icon-120x120.png?v=699A9qwqWL">\n' +
    '    <link rel="apple-touch-icon" sizes="144x144" href="images/favicons/apple-touch-icon-144x144.png?v=699A9qwqWL">\n' +
    '    <link rel="apple-touch-icon" sizes="152x152" href="images/favicons/apple-touch-icon-152x152.png?v=699A9qwqWL">\n' +
    '    <link rel="apple-touch-icon" sizes="180x180" href="images/favicons/apple-touch-icon-180x180.png?v=699A9qwqWL">\n' +
    '    <link rel="icon" type="image/png" href="images/favicons/favicon-32x32.png?v=699A9qwqWL" sizes="32x32">\n' +
    '    <link rel="icon" type="image/png" href="images/favicons/favicon-194x194.png?v=699A9qwqWL" sizes="194x194">\n' +
    '    <link rel="icon" type="image/png" href="images/favicons/favicon-96x96.png?v=699A9qwqWL" sizes="96x96">\n' +
    '    <link rel="icon" type="image/png" href="images/favicons/android-chrome-192x192.png?v=699A9qwqWL" sizes="192x192">\n' +
    '    <link rel="icon" type="image/png" href="images/favicons/favicon-16x16.png?v=699A9qwqWL" sizes="16x16">\n' +
    '    <link rel="manifest" href="images/favicons/manifest.json?v=699A9qwqWL">\n' +
    '    <link rel="mask-icon" href="images/favicons/safari-pinned-tab.svg?v=699A9qwqWL" color="#5bbad5">\n' +
    '    <link rel="shortcut icon" href="images/favicons/favicon.ico?v=699A9qwqWL">\n' +
    '    <meta name="apple-mobile-web-app-title" content="FarmX">\n' +
    '    <meta name="application-name" content="FarmX">\n' +
    '    <meta name="msapplication-TileColor" content="#2d89ef">\n' +
    '    <meta name="msapplication-TileImage" content="/mstile-144x144.png?v=699A9qwqWL">\n' +
    '    <meta name="theme-color" content="#e24721">\n' +
    '\n' +
    '    <base href="/">\n' +
    '\n' +
    '    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />\n' +
    '    <!-- stylesheets -->\n' +
    '    <link rel="stylesheet" type="text/css" href="css/main.css">\n' +
    '  </head>\n' +
    '  <body style="overflow: hidden">\n' +
    '    <div id="page" flex layout-fill layout="column" layout-align="space-between stretch" ng-controller="MainController as mainCtrl">\n' +
    '      <div id="header" ng-include="\'components/header/header.content.html\'" ng-if="(\'login\' | isState) === false"></div>\n' +
    '      <div id="content" ui-view="content" flex="100" layout="row" layout-align="center center">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <script type="text/javascript" src="js/vendor.js"></script>\n' +
    '    <script type="text/javascript" src="js/app.js"></script>\n' +
    '  </body>\n' +
    '</html>');
}]);
})();

// Source: components/dashboard/dashboard.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/dashboard/dashboard.content.html',
    '<md-content md-component-id="dashboardPage"\n' +
    '  layout-fill layout="column" layout-align="start stretch">\n' +
    '  <div class="dashboard-view" flex="100" layout="row" layout-align="start stretch" ng-if="ctrl.ranch !== null">\n' +
    '    <div class="summary-view" layout="row" layout-align="start stretch" md-whiteframe="4" ng-class="{\'flex-100\': ctrl.width < 1440, \'flex-40\': ctrl.width >= 1440}">\n' +
    '      <div class="summary-overflow-view" flex="100">\n' +
    '        <div class="summary-information-header">\n' +
    '          <span>{{ ctrl.ranch.name }}</span>\n' +
    '        </div>\n' +
    '\n' +
    '              <!-- ng-if="ctrl.ranch.vfd_control != null" ng-repeat="vfd in ctrl.ranch.vfd_control"-->\n' +
    '      <div ng-if="ctrl.ranch.vfds.length" ng-repeat="vfd in ctrl.ranch.vfds" class="summary-information-info summary-vfd" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '        <div class="summary-header" layout="row" layout-align="start center">{{ "VFD Control" | i18next }} - {{ vfd.name }}</div>\n' +
    '        <div class="summary-header" layout="row" layout-align="end center"><a ng-click="ctrl.getVFDStatus()">refresh</a></div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch" style="color:crimson">\n' +
    '            <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center"><span>{{ "Message" | i18next }}:</span></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center"><span>{{ ctrl.vfdMessage }}</span></div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '        </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '              <div flex="100" layout="row" layout-align="start center"><span>{{ "Status" | i18next }}:</span></div>\n' +
    '              <div flex="100" layout="row" layout-align="start center"><span>{{ vfd.status.status }}</span></div>\n' +
    '              <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "Date Updated" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ ctrl.getDateTime(vfd.status.date_checked) }}</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '            <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "Date" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ ctrl.getDateTime(vfd.status.date) }}</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '            <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "Flow" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ ctrl.formatFlowRate(vfd.status.flow_rate) }}</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '            <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "Pressure" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ ctrl.formatPrecip(vfd.status.pressure) }} psi</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '            <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "Speed" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ vfd.status.speed }} rpm</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '            <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "Power" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ vfd.status.power }} kW</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '            <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "Output Voltage" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ vfd.status.output_volt }} V</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '            <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "Output Freq" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ vfd.status.out_freq }} Hz</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "Start Time" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ ctrl.getDateTimeSet(vfd.status.start_date_1) }}</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="10" layout="row" layout-align="center center"></div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ "End Time" | i18next }}:</div>\n' +
    '            <div flex="100" layout="row" layout-align="start center">{{ ctrl.getDateTimeSet(vfd.status.stop_date_1) }}</div>\n' +
    '            <div flex="20" layout="row" layout-align="center center"></div>\n' +
    '          </div>\n' +
    '        <br>\n' +
    '        <div class="vfd-controls" layout="row" layout-align="center center">\n' +
    '          <button class="md-raised md-warn md-button md-ink-ripple" layout="row" layout-align="center center" ng-click="ctrl.turnVFDOn(vfd)"><span ng-i18next>Start</span><span ng-if="ctrl.vfdStarting"><md-progress-circular md-mode="indeterminate" md-diameter="20px"></md-progress-circular></span></button>\n' +
    '          <button class="md-raised md-primary md-button md-ink-ripple" layout="row" layout-align="center center" ng-click="ctrl.turnVFDOff(vfd)"><span ng-i18next>Stop</span><span ng-if="ctrl.vfdStopping"><md-progress-circular md-mode="indeterminate" md-diameter="20px" class="md-warn"></md-progress-circular></span></button>\n' +
    '        </div>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '\n' +
    '      <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-irrigation-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '        <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Irrigation Status</div>\n' +
    '        <div class="irrigation-status" layout="row" layout-align="center center" ng-style="ctrl.getStateColor()">{{ ctrl.getIrrigationState() | i18next }}</div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div ng-if="ctrl.ranch.soilTableEnabled" class="soil-explorer dashboard-container">\n' +
    '        <div class="summary-header">{{ "Current Water" | i18next }} ({{ "Ranch" | i18next }})\n' +
    '          <div class="form-group">\n' +
    '            <div class=\'input-group date\' id=\'currentWaterDatetimepickerRanch\'>\n' +
    '              <input type="text" class="form-control input-sm"\n' +
    '              datetimepicker\n' +
    '              datetimepicker-options="{{ ctrl.currentWaterTimeOptions }}"\n' +
    '              placeholder="..."\n' +
    '              name="time"\n' +
    '              ng-model="ctrl.currentWaterTime">\n' +
    '              <span class="input-group-addon">\n' +
    '                  <span class="glyphicon glyphicon-calendar"></span>\n' +
    '              </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="soil-explorer-body">\n' +
    '          <div class="cont">\n' +
    '            <div class="soil-depths">\n' +
    '              <div class="soil-section soil-section-header">\n' +
    '                <div class="left-label-header" ng-i18next>Depth</div>\n' +
    '                <div class="soil-section-data-header"><span class="current-water-title" ng-i18next>Current</span> (<span class="refill-water-title" ng-i18next>refill</span>)</div>\n' +
    '                <div class="right-label-header">FC</div>\n' +
    '              </div>\n' +
    '              <div class="soil-section" ng-repeat="depth in ctrl.ranch.currentWaterStats.depthStats">\n' +
    '                <div class="left-label" ng-class="{ \'rootzone\': depth.isRootzone > 0 }">\n' +
    '                  <div class="soil-depth-label">{{ depth.depthInches }} in</div>\n' +
    '                </div>\n' +
    '                <div class="soil-section-data">\n' +
    '                  <div class="soil-section-available" ng-class="{ \'rootzone\': depth.isRootzone > 0 }" ng-style="{ width: \'\' + (depth.currentPercent | percentage : 2) }"></div>\n' +
    '                  <div class="soil-section-refill" ng-style="{ left: \'\' + ( depth.refillPercent | percentage : 2 ) }"></div>\n' +
    '                  <div class="available-water-label" ng-class="{ \'rootzone\': depth.isRootzone > 0 }">{{ depth.currentWater | positive | number : 1 }} in</div>\n' +
    '                </div>\n' +
    '                <div class="right-label">\n' +
    '                  <div class="total-water-label">{{ depth.availableWater | number : 1 }} in</div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="cont">\n' +
    '            <div class="rootzone-summary">\n' +
    '              <div class="rootzone-title" ng-i18next>Rootzone</div>\n' +
    '              <div class="rootzone-graph">\n' +
    '                <div class="rootzone-data">\n' +
    '                  <div class="available-label">{{ ctrl.ranch.currentWaterStats.rootzoneStats.currentWater | positive | number : 1 }} in</div>\n' +
    '                  <div class="rootzone-available" ng-style="{ height: \'\' + ( ctrl.ranch.currentWaterStats.rootzoneStats.currentPercent | percentage : 2 ) }" ng-if="ctrl.ranch.currentWaterStats.rootzoneStats.currentPercent"></div>\n' +
    '                  <div class="rootzone-refill" ng-style="{ bottom: \'\' + ( ctrl.ranch.currentWaterStats.rootzoneStats.refillPercent | percentage : 2 ) }"></div>\n' +
    '                </div>\n' +
    '                <div class="rootzone-labels">\n' +
    '                  <div class="total-label">{{ ctrl.ranch.currentWaterStats.rootzoneStats.availableWater | number : 1 }} in</div>\n' +
    '                  <div class="refill-label">{{ ctrl.ranch.currentWaterStats.rootzoneStats.refillWater | number : 1 }} in</div>\n' +
    '                  <div class="wilting-point-label">{{ ctrl.ranch.currentWaterStats.rootzoneStats.minWater | number : 1 }} in</div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div ng-if="ctrl.ranch.soilTableEnabled && ctrl.block && ctrl.block.soilTableEnabled" class="soil-explorer dashboard-container">\n' +
    '        <div class="summary-header">{{ "Current Water" | i18next }} ({{ "Block" | i18next }} {{ ctrl.block.name }})\n' +
    '          <div class="form-group">\n' +
    '            <div class=\'input-group date\' id=\'currentWaterDatetimepickerBlock\'>\n' +
    '              <input type="text" class="form-control input-sm"\n' +
    '              datetimepicker\n' +
    '              datetimepicker-options="{{ ctrl.currentWaterTimeOptions }}"\n' +
    '              placeholder="..."\n' +
    '              name="time"\n' +
    '              ng-model="ctrl.currentWaterTime">\n' +
    '              <span class="input-group-addon">\n' +
    '                  <span class="glyphicon glyphicon-calendar"></span>\n' +
    '              </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="soil-explorer-body">\n' +
    '          <div class="cont">\n' +
    '            <div class="soil-depths">\n' +
    '              <div class="soil-section soil-section-header">\n' +
    '                <div class="left-label-header" ng-i18next>Depth</div>\n' +
    '                <div class="soil-section-data-header"><span class="current-water-title" ng-i18next>Current</span> (<span class="refill-water-title" ng-i18next>refill</span>)</div>\n' +
    '                <div class="right-label-header">FC</div>\n' +
    '              </div>\n' +
    '              <div class="soil-section" ng-repeat="depth in ctrl.block.currentWaterStats.depthStats">\n' +
    '                <div class="left-label" ng-class="{ \'rootzone\': depth.isRootzone > 0 }">\n' +
    '                  <div class="soil-depth-label">{{ depth.depthInches }} in</div>\n' +
    '                </div>\n' +
    '                <div class="soil-section-data">\n' +
    '                  <div class="soil-section-available" ng-class="{ \'rootzone\': depth.isRootzone > 0 }" ng-style="{ width: \'{{ depth.currentPercent | percentage : 2 }}\' }"></div>\n' +
    '                  <div class="soil-section-refill" ng-style="{ left: \'{{ depth.refillPercent | percentage : 2 }}\' }"></div>\n' +
    '                  <div class="available-water-label" ng-class="{ \'rootzone\': depth.isRootzone > 0 }">{{ depth.currentWater | number : 1 }} in</div>\n' +
    '                </div>\n' +
    '                <div class="right-label">\n' +
    '                  <div class="total-water-label">{{ depth.availableWater | number : 1 }} in</div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="cont">\n' +
    '            <div class="rootzone-summary">\n' +
    '              <div class="rootzone-title" ng-i18next>Rootzone</div>\n' +
    '              <div class="rootzone-graph">\n' +
    '                <div class="rootzone-data">\n' +
    '                  <div class="available-label">{{ ctrl.block.currentWaterStats.rootzoneStats.currentWater | number : 1 }} in</div>\n' +
    '                  <div class="rootzone-available" ng-style="{ height: \'{{ ctrl.block.currentWaterStats.rootzoneStats.currentPercent | percentage : 2 }}\' }" ng-if="ctrl.block.currentWaterStats.rootzoneStats.currentPercent"></div>\n' +
    '                  <div class="rootzone-refill" ng-style="{ bottom: {{ ctrl.block.currentWaterStats.rootzoneStats.refillPercent | percentage : 2 }} }"></div>\n' +
    '                </div>\n' +
    '                <div class="rootzone-labels">\n' +
    '                  <div class="total-label">{{ ctrl.block.currentWaterStats.rootzoneStats.availableWater | number : 1 }} in</div>\n' +
    '                  <div class="refill-label">{{ ctrl.block.currentWaterStats.rootzoneStats.refillWater | number : 1 }} in</div>\n' +
    '                  <div class="wilting-point-label">{{ ctrl.block.currentWaterStats.rootzoneStats.minWater | number : 1 }} in</div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div ng-if="ctrl.ranch.schedulingTableEnabled" class="dashboard-container">\n' +
    '        <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Current Flow</div>\n' +
    '        <p>{{ ctrl.ranch.currentFlowStats.date | date:\'h:mm a MMM d\'}}</p>\n' +
    '        <table class="dashboard-table">\n' +
    '          <thead>\n' +
    '            <tr>\n' +
    '              <th ng-i18next>Block</th>\n' +
    '              <th>{{ "Pressure" | i18next }} <select name="pressureUnitSelect" id="currentPressureUnitSelect" ng-model="ctrl.currentFlowTableUnits.pressure.selected" ng-options="option.name for option in ctrl.currentFlowTableUnits.pressure.options track by option.key"></select></th>\n' +
    '              <th>{{ "Flow Rate" | i18next }} <select name="flowRateUnitSelect" id="currentFlowRateUnitSelect" ng-model="ctrl.currentFlowTableUnits.flowRate.selected" ng-options="option.name for option in ctrl.currentFlowTableUnits.flowRate.options track by option.key"></select></th>\n' +
    '              <th>{{ "On" | i18next }} / {{ "Off" | i18next }}</th>\n' +
    '            </tr>\n' +
    '          </thead>\n' +
    '          <tbody>\n' +
    '            <tr ng-repeat="blockStats in ctrl.ranch.currentFlowStats.blockStats">\n' +
    '              <th>{{ blockStats.name }}</th>\n' +
    '              <td>{{ ctrl.convertUnits(blockStats.pressure, ctrl.ranch.currentFlowStats.units.pressure, ctrl.currentFlowTableUnits.pressure.selected) }}</td>\n' +
    '              <td>{{ ctrl.convertUnits(blockStats.flowRate, ctrl.ranch.currentFlowStats.units.flowRate, ctrl.currentFlowTableUnits.flowRate.selected) }}</td>\n' +
    '              <td>{{ blockStats.on | onOff | i18next }}</td>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '          <tbody class="dashboard-table-footer">\n' +
    '            <tr>\n' +
    '              <th ng-i18next>Ranch</th>\n' +
    '              <td>{{ ctrl.convertUnits(ctrl.ranch.currentFlowStats.aggregate.pressure, ctrl.ranch.currentFlowStats.units.pressure, ctrl.currentFlowTableUnits.pressure.selected) }}</td>\n' +
    '              <td>{{ ctrl.convertUnits(ctrl.ranch.currentFlowStats.aggregate.flowRate, ctrl.ranch.currentFlowStats.units.flowRate, ctrl.currentFlowTableUnits.flowRate.selected) }}</td>\n' +
    '              <td>{{ ctrl.ranch.currentFlowStats.aggregate.on }} / {{ ctrl.ranch.currentFlowStats.blockStats.length }}</td>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </div>\n' +
    '\n' +
    '      <div ng-if="ctrl.ranch.schedulingTableEnabled" class="dashboard-container">\n' +
    '        <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Cumulative Flow</div>\n' +
    '        <div layout="row" layout-align="start center">\n' +
    '          <form class="precipitation-form" flex="100" layout="row" layout-align="start start">\n' +
    '            <div class="form-group" flex="100" layout="column" layout-align="start start">\n' +
    '              <label for="precipDate">{{ "Choose dates" | i18next }}: </label>\n' +
    '              <button flex="100" class="form-control" ng-click="ctrl.selectFlowSeasonDatesFromPicker()">\n' +
    '                {{ ctrl.flowSeasonStartDate.format(\'MMM D, YYYY\') }} - {{ ctrl.flowSeasonEndDate.format(\'MMM D, YYYY\') }}\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </form>\n' +
    '        </div>\n' +
    '        <table class="dashboard-table">\n' +
    '          <thead>\n' +
    '            <tr>\n' +
    '              <th ng-i18next>Block</th>\n' +
    '              <th>{{ "Last Week" | i18next }} <select name="flowUnitSelect" id="lastWeekFlowUnitSelect" ng-model="ctrl.cumulativeFlowTableUnits.flow.selected" ng-options="option.name for option in ctrl.cumulativeFlowTableUnits.flow.options track by option.key"></select></th>\n' +
    '              <th>{{ "This Week" | i18next }} <select name="flowUnitSelect" id="thisWeekFlowUnitSelect" ng-model="ctrl.cumulativeFlowTableUnits.flow.selected" ng-options="option.name for option in ctrl.cumulativeFlowTableUnits.flow.options track by option.key"></select></th>\n' +
    '              <th>{{ "Season" | i18next }} <select name="flowUnitSelect" id="seasonFlowUnitSelect" ng-model="ctrl.cumulativeFlowTableUnits.flow.selected" ng-options="option.name for option in ctrl.cumulativeFlowTableUnits.flow.options track by option.key"></select></th>\n' +
    '            </tr>\n' +
    '          </thead>\n' +
    '          <tbody>\n' +
    '            <tr ng-if="ctrl.cumulativeFlowStale != true" ng-repeat="blockStats in ctrl.ranch.cumulativeFlowStats.blockStats">\n' +
    '              <th>{{ blockStats.name }}</th>\n' +
    '              <td>{{ ctrl.convertUnits(blockStats.flowWeekPrev, ctrl.ranch.cumulativeFlowStats.units, ctrl.cumulativeFlowTableUnits.flow.selected) }}</td>\n' +
    '              <td>{{ ctrl.convertUnits(blockStats.flowWeek, ctrl.ranch.cumulativeFlowStats.units, ctrl.cumulativeFlowTableUnits.flow.selected) }}</td>\n' +
    '              <td>{{ ctrl.convertUnits(blockStats.flowSeason, ctrl.ranch.cumulativeFlowStats.units, ctrl.cumulativeFlowTableUnits.flow.selected) }}</td>\n' +
    '            </tr>\n' +
    '            <tr ng-if="ctrl.cumulativeFlowStale == true">\n' +
    '              <th>Loading...</th>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '          <tbody class="dashboard-table-footer">\n' +
    '            <tr>\n' +
    '              <th ng-i18next>Ranch</th>\n' +
    '              <td ng-if="ctrl.cumulativeFlowStale != true">{{ ctrl.convertUnits(ctrl.ranch.cumulativeFlowStats.aggregate.flowWeekPrev, ctrl.ranch.cumulativeFlowStats.units, ctrl.cumulativeFlowTableUnits.flow.selected) }}</td>\n' +
    '              <td ng-if="ctrl.cumulativeFlowStale != true">{{ ctrl.convertUnits(ctrl.ranch.cumulativeFlowStats.aggregate.flowWeek, ctrl.ranch.cumulativeFlowStats.units, ctrl.cumulativeFlowTableUnits.flow.selected) }}</td>\n' +
    '              <td ng-if="ctrl.cumulativeFlowStale != true">{{ ctrl.convertUnits(ctrl.ranch.cumulativeFlowStats.aggregate.flowSeason, ctrl.ranch.cumulativeFlowStats.units, ctrl.cumulativeFlowTableUnits.flow.selected) }}</td>\n' +
    '              <td ng-if="ctrl.cumulativeFlowStale == true">Loading...</td>\n' +
    '              <td ng-if="ctrl.cumulativeFlowStale == true">Loading...</td>\n' +
    '              <td ng-if="ctrl.cumulativeFlowStale == true">Loading...</td>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </div>\n' +
    '\n' +
    '        <div ng-if="ctrl.ranch.frost_forecast != null" class="summary-information-info summary-frost-forecast" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '            <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Frost Forecast</div>\n' +
    '            <div class="frost-forecast" layout="column" layout-align="start stretch" ng-repeat="forecast in ctrl.ranch.frost_forecast">\n' +
    '                <div class="summary-detail" layout="row" layout-align="start center">{{ forecast.block.name }}</div>\n' +
    '                <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '                    <div flex="100" layout="row" layout-align="center center">\n' +
    '                      <span ng-i18next>Day</span>\n' +
    '                    </div>\n' +
    '                    <div flex="100" layout="row" layout-align="center center">\n' +
    '                      <span ng-i18next>Time Min Temp</span>\n' +
    '                    </div>\n' +
    '                    <div flex="100" layout="row" layout-align="center center">\n' +
    '                      <span ng-i18next>Air Temp</span>\n' +
    '                    </div>\n' +
    '                    <div flex="100" layout="row" layout-align="center center">\n' +
    '                      <span ng-i18next>Bud Temp</span>\n' +
    '                    </div>\n' +
    '                    <div flex="100" layout="row" layout-align="center center">\n' +
    '                      <span ng-i18next>Leaf Temp</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="status-data" layout="row" layout-align="start stretch" ng-repeat="status in forecast.forecast">\n' +
    '                    <div flex="100" layout="column" layout-align="center center">\n' +
    '                      <span>{{ ctrl.getDateWeekday(status.date) }}</span>\n' +
    '                    </div>\n' +
    '                    <div flex="100" layout="column" layout-align="center center">\n' +
    '                      <span>{{ ctrl.getDateTime(status.start_time) }}</span>\n' +
    '                    </div>\n' +
    '                    <div flex="100" layout="column" layout-align="center center">\n' +
    '                      <span ng-class="ctrl.getTempClass(status.temperature_air)">{{ "%3.1f" | fmt: ctrl.c_to_f(status.temperature_air) }}&deg;F</span>\n' +
    '                    </div>\n' +
    '                    <div flex="100" layout="column" layout-align="center center">\n' +
    '                      <span ng-class="ctrl.getTempClass(status.temperature_bud)">{{ "%3.1f" | fmt: ctrl.c_to_f(status.temperature_bud) }}&deg;F</span>\n' +
    '                    </div>\n' +
    '                    <div flex="100" layout="column" layout-align="center center">\n' +
    '                      <span ng-class="ctrl.getTempClass(status.temperature_leaf)">{{ "%3.1f" | fmt: ctrl.c_to_f(status.temperature_leaf) }}&deg;F</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-air-temperature-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Current Air Temp</div>\n' +
    '          <div class="air-temperature-status" layout="column" layout-align="start center">\n' +
    '            <span class="status-data" layout="row" layout-align="center center" ng-if="ctrl.ranch.temp_summary != null">{{ "%3.1f" | fmt: ctrl.ranch.temp_summary.air_temp }}&degF {{ "at" | i18next }} {{ ctrl.getDateTime(ctrl.ranch.temp_summary.date) }}</span>\n' +
    '            <span class="status-data" layout="row" layout-align="center center" ng-if="ctrl.ranch.temp_summary === undefined" ng-i18next>Loading...</span>\n' +
    '            <span class="status-data" layout="row" layout-align="center center" ng-if="ctrl.ranch.temp_summary === null" ng-i18next>No data available</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-precipitation-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Precipitation (in.)</div>\n' +
    '          <div class="precipitation-status" layout="column" layout-align="start stretch">\n' +
    '            <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>-7</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>-6</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>-5</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>-4</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>-3</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>-2</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>-1</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Today</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="start stretch" ng-if="ctrl.ranch.precipitation !== undefined && ctrl.ranch.precipitation != null">\n' +
    '                <div flex="100" layout="row" layout-align="center center">  \n' +
    '                    <span ng-if="ctrl.ranch.precipitation[0] !== null">{{ ctrl.formatPrecip(ctrl.ranch.precipitation[0]) }}</span>  \n' +
    '                  </div>  \n' +
    '                  <div flex="100" layout="row" layout-align="center center">  \n' +
    '                      <span ng-if="ctrl.ranch.precipitation[1] !== null">{{ ctrl.formatPrecip(ctrl.ranch.precipitation[1]) }}</span>    \n' +
    '                  </div>  \n' +
    '                  <div flex="100" layout="row" layout-align="center center">  \n' +
    '                      <span ng-if="ctrl.ranch.precipitation[2] !== null">{{ ctrl.formatPrecip(ctrl.ranch.precipitation[2]) }}</span> \n' +
    '                  </div>  \n' +
    '                  <div flex="100" layout="row" layout-align="center center">  \n' +
    '                      <span ng-if="ctrl.ranch.precipitation[3] !== null">{{ ctrl.formatPrecip(ctrl.ranch.precipitation[3]) }}</span>\n' +
    '                  </div>  \n' +
    '                  <div flex="100" layout="row" layout-align="center center">  \n' +
    '                      <span ng-if="ctrl.ranch.precipitation[4] !== null">{{ ctrl.formatPrecip(ctrl.ranch.precipitation[4]) }}</span>\n' +
    '                  </div>  \n' +
    '                  <div flex="100" layout="row" layout-align="center center">  \n' +
    '                      <span ng-if="ctrl.ranch.precipitation[5] !== null">{{ ctrl.formatPrecip(ctrl.ranch.precipitation[5]) }}</span>\n' +
    '                  </div>  \n' +
    '                  <div flex="100" layout="row" layout-align="center center">  \n' +
    '                      <span ng-if="ctrl.ranch.precipitation[6] !== null">{{ ctrl.formatPrecip(ctrl.ranch.precipitation[6]) }}</span>\n' +
    '                  </div>  \n' +
    '                  <div flex="100" layout="row" layout-align="center center">  \n' +
    '                      <span ng-if="ctrl.ranch.precipitation[7] !== null">{{ ctrl.formatPrecip(ctrl.ranch.precipitation[7]) }}</span>\n' +
    '                  </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="center center" ng-if="ctrl.ranch.precipitation !== undefined && ctrl.ranch.precipitation == null">\n' +
    '                <span ng-i18next>No local data</span>\n' +
    '              </div>\n' +
    '            <div class="status-data" layout="row" layout-align="center center" ng-if="ctrl.ranch.precipitation === undefined">\n' +
    '              <span ng-i18next>No Data</span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info precipitation-cumulative" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Cumulative Precipitation</div>\n' +
    '          <div layout="row" layout-align="start center">\n' +
    '            <form class="precipitation-form" flex="100" layout="row" layout-align="start start">\n' +
    '                  <div class="form-group" flex="100" layout="column" layout-align="start start">\n' +
    '                    <label for="precipDate">{{ "Choose dates" | i18next }}: </label>\n' +
    '                    <button flex="100" class="form-control" ng-click="ctrl.selectDateFromPicker()">\n' +
    '                      {{ ctrl.precipStartDate.format(\'MMM D, YYYY\') }} - {{ ctrl.precipEndDate.format(\'MMM D, YYYY\') }}\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '            </form>\n' +
    '          </div>\n' +
    '          <div ng-if="ctrl.ranch.cumulativePrecip != null && ctrl.precipStale != true" class="ranch-precip" layout="column" layout-align="start stretch" ng-repeat="precipSummary in ctrl.ranch.cumulativePrecip">\n' +
    '              <div class="summary-detail" layout="row" layout-align="start center">{{ "Block" | i18next }}: {{ precipSummary.block.name }}</div>\n' +
    '              <span class="status-data" layout="row" layout-align="center center">{{ precipSummary.precip }} in</span>\n' +
    '          </div>\n' +
    '          <div ng-if="ctrl.precipStale == true" class="ranch-precip" layout="column" layout-align="start stretch">\n' +
    '              <span class="status-data" layout="row" layout-align="center center" ng-i18next>Loading...</span>\n' +
    '          </div>\n' +
    '          <div ng-if="ctrl.precipStale == false && ctrl.ranch.cumulativePrecip.length == 0" class="ranch-precip" layout="column" layout-align="start stretch">\n' +
    '              <span class="status-data" layout="row" layout-align="center center" ng-i18next>No Data</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-evapotranspiration-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Evapotranspiration Local</div>\n' +
    '          <div class="evapotranspiration-status" layout="column" layout-align="start stretch">\n' +
    '            <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>ETo ({{ "in/day" | i18next }})</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>Kc</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>ETc ({{ "crop" | i18next }} {{ "in/day" | i18next }})</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-if="ctrl.ranch.eto === undefined" ng-i18next>Loading...</span>\n' +
    '                <span ng-if="ctrl.ranch.eto != null">{{ ctrl.ranch.eto }}</span>\n' +
    '                <span ng-if="ctrl.ranch.eto === null && ctrl.ranch.eto !== undefined" ng-i18next>No Data</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-if="ctrl.ranch.kc === undefined" ng-i18next>Loading...</span>\n' +
    '                <span ng-if="ctrl.ranch.kc != null">{{ ctrl.ranch.kc }}</span>\n' +
    '                <span ng-if="ctrl.ranch.kc === null && ctrl.ranch.kc !== undefined" ng-i18next>No Data</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span ng-if="ctrl.ranch.kc === undefined && ctrl.ranch.kc !== null && ctrl.ranch.eto !== null" ng-i18next>Loading...</span>\n' +
    '                  <span ng-if="ctrl.ranch.kc != null && ctrl.ranch.eto != null">{{ "%2.2f" | fmt: (ctrl.ranch.eto * ctrl.ranch.kc) }}</span>\n' +
    '                  <span ng-if="ctrl.ranch.kc === null || ctrl.ranch.eto === null" ng-i18next>No Data</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="summary-information-info summary-evapotranspiration-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center">{{ "Evapotranspiration" | i18next }} Cimis</div>\n' +
    '          <div class="evapotranspiration-status" layout="column" layout-align="start stretch">\n' +
    '            <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>ETo ({{ "in/day" | i18next }})</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>Kc</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>ETc ({{ "crop" | i18next }} {{ "in/day" | i18next }})</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-if="ctrl.ranch.eto_cimis === undefined" ng-i18next>Loading...</span>\n' +
    '                <span ng-if="ctrl.ranch.eto_cimis != null">{{ ctrl.ranch.eto_cimis }}</span>\n' +
    '                <span ng-if="ctrl.ranch.eto_cimis === null && ctrl.ranch.eto_cimis !== undefined" ng-i18next>No Data</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-if="ctrl.ranch.kc === undefined" ng-i18next>Loading...</span>\n' +
    '                <span ng-if="ctrl.ranch.kc != null">{{ ctrl.ranch.kc }}</span>\n' +
    '                <span ng-if="ctrl.ranch.kc === null && ctrl.ranch.kc !== undefined" ng-i18next>No Data</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span ng-if="ctrl.ranch.kc === undefined && ctrl.ranch.kc !== null && ctrl.ranch.eto_cimis !== null" ng-i18next>Loading...</span>\n' +
    '                  <span ng-if="ctrl.ranch.kc != null && ctrl.ranch.eto_cimis != null">{{ "%2.2f" | fmt: (ctrl.ranch.eto_cimis * ctrl.ranch.kc) }}</span>\n' +
    '                  <span ng-if="ctrl.ranch.kc === null || ctrl.ranch.eto_cimis === null" ng-i18next>No Data</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="summary-information-info summary-et-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Current and Predicted ET</div>\n' +
    '          <div class="et-status" layout="column" layout-align="start stretch">\n' +
    '            <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span class="row-label" ng-i18next>Day</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>-1</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Today</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>+1</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>+2</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>+3</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>+4</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>+5</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span class="row-label">ETo</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center" ng-repeat="i in [6, 7, 8, 9, 10, 11, 12]">\n' +
    '                <span ng-if="ctrl.ranch.etpredictions.et_daily[5] != null">{{ "%2.3f" | fmt: ctrl.ranch.etpredictions.et_daily[i] }}</span>\n' +
    '                <span ng-if="ctrl.ranch.etpredictions.et_daily[5] == null">-</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span class="row-label">ETc</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center" ng-repeat="i in [6, 7, 8, 9, 10, 11, 12]">\n' +
    '                <span ng-if="ctrl.ranch.etpredictions.et_daily[5] != null">{{ "%2.3f" | fmt: (ctrl.ranch.kc * ctrl.ranch.etpredictions.et_daily[i]) }}</span>\n' +
    '                <span ng-if="ctrl.ranch.etpredictions.et_daily[5] == null">-</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="ctrl.ranch.yieldDataTable" class="summary-information-info summary-yield" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '            <div class="summary-header" layout="row" layout-align="start center">Yield Prediction</div>\n' +
    '            <div class="summary-header" layout="row" layout-align="start center">\n' +
    '              <span class="summary-header-subtitle">{{ ctrl.ranch.yieldDataTable.block }}</span>\n' +
    '              <select name="singleSelect" ng-model="ctrl.ranch.yieldDataTable.selected" ng-options="option.year for option in ctrl.ranch.yieldDataTable.yieldData track by option.year"></select>\n' +
    '            </div>\n' +
    '            <div class="yield-prediction" layout="column" layout-align="start stretch">\n' +
    '              <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span class="row-label" ng-i18next>Month</span>\n' +
    '                </div>\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span>NDVI</span>\n' +
    '                </div>\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span>NDWI</span>\n' +
    '                </div>\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span>{{ "Air Temp" | i18next }} (F)</span>\n' +
    '                </div>\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span>{{ "Yield" | i18next }} ({{ "tons/acre" | i18next }})</span>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="status-data" layout="row" layout-align="start stretch" ng-repeat="monthData in ctrl.ranch.yieldDataTable.selected.data">\n' +
    '                <div ng-if="!monthData.noData" flex="100" layout="row" layout-align="center center">\n' +
    '                  <span class="row-label">{{ monthData.month }}</span>\n' +
    '                </div>\n' +
    '                <div ng-if="monthData.noData" flex="20" layout="row" layout-align="center center">\n' +
    '                    <span class="row-label">{{ monthData.month }}</span>\n' +
    '                  </div>\n' +
    '                <div ng-if="!monthData.noData" flex="100" layout="row" layout-align="center center"">\n' +
    '                  <span>{{ monthData.NDVI }}</span>\n' +
    '                </div>\n' +
    '                <div ng-if="!monthData.noData" flex="100" layout="row" layout-align="center center"">\n' +
    '                    <span>{{ monthData.NDWI }}</span>\n' +
    '                </div>\n' +
    '                <div ng-if="!monthData.noData" flex="100" layout="row" layout-align="center center"">\n' +
    '                  <span>{{ monthData.airTemp }}</span>\n' +
    '                </div>\n' +
    '                <div ng-if="!monthData.noData" flex="100" layout="row" layout-align="center center"">\n' +
    '                  <span>{{ monthData.predictedYield }}</span>\n' +
    '                </div>\n' +
    '                <div ng-if="monthData.noData" flex="80" layout="row" layout-align="center center"">\n' +
    '                    <span>{{ monthData.message }}</span>\n' +
    '                  </div>\n' +
    '              </div>\n' +
    '              <div ng-if="ctrl.ranch.yieldDataTable.selected.actualYield" class="status-footer" layout="row" layout-align="start stretch">\n' +
    '                  <div flex="100" layout="row" layout-align="center center"">\n' +
    '                    <span class="yield-title">{{ "Actual Yield" | i18next }}:</span>\n' +
    '                  </div>\n' +
    '                  <div flex="100" layout="row" layout-align="left center"">\n' +
    '                    <span class="yield-result">{{ ctrl.ranch.yieldDataTable.selected.actualYield }}</span><span class="yield-units"> {{ "tons/acre" | i18next }}</span>\n' +
    '                  </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-soil-state-detail" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center">{{ "Soil State" | i18next }} - {{ ctrl.getSoilTypeName() }}</div>\n' +
    '          <div class="evapotranspiration-status" layout="column" layout-align="start stretch">\n' +
    '            <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Wilting Point</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Irrigation Start</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Field Capacity</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span ng-if="ctrl.ranch.state.details.all_data[0].cutoffs[0] !== undefined">{{ "%2.2f" | fmt: ctrl.ranch.state.details.all_data[0].cutoffs[0] }}</span>\n' +
    '                  <span ng-if="ctrl.ranch.state.details.all_data[0].cutoffs[0] == undefined" ng-i18next>No Data</span>\n' +
    '                </div>\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span ng-if="ctrl.ranch.state.details.all_data[0].cutoffs[1] !== undefined">{{ "%2.2f" | fmt: ctrl.ranch.state.details.all_data[0].cutoffs[1] }}</span>\n' +
    '                  <span ng-if="ctrl.ranch.state.details.all_data[0].cutoffs[1] == undefined" ng-i18next>No Data</span>\n' +
    '                </div>\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span ng-if="ctrl.ranch.state.details.all_data[0].cutoffs[1] !== undefined">{{ "%2.2f" | fmt: ctrl.ranch.state.details.all_data[0].cutoffs[2] }}</span>\n' +
    '                  <span ng-if="ctrl.ranch.state.details.all_data[0].cutoffs[1] == undefined" ng-i18next>No Data</span>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-soil-moisture-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Avg Soil Moisture (volumetric)</div>\n' +
    '          <div class="soil-moisture-status" layout="column" layout-align="start stretch" ng-if="ctrl.ranch.soil_moisture !== undefined && ctrl.ranch.soil_moisture !== null && ctrl.ranch.soil_moisture.length > 0">\n' +
    '            <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center" ng-repeat="soil_moisture in ctrl.ranch.soil_moisture">\n' +
    '                <span>{{ soil_moisture.depth }} in</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center" ng-repeat="soil_moisture in ctrl.ranch.soil_moisture">\n' +
    '                <span ng-if="soil_moisture.vwc !== undefined">{{ "%3.1f" | fmt: (soil_moisture.vwc * 100)}}</span>\n' +
    '                <span ng-if="soil_moisture.vwc === undefined">-</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="soil-moisture-status" layout="column" layout-align="start stretch" ng-if="ctrl.ranch.soil_moisture == null || ctrl.ranch.soil_moisture.length == 0">\n' +
    '              <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '                  <div flex="100" layout="row" layout-align="center center">\n' +
    '                    <span ng-i18next>No data available</span>\n' +
    '                  </div>\n' +
    '              </div>\n' +
    '          </div>\n' +
    '          <div class="soil-moisture-status" layout="column" layout-align="start stretch" ng-if="ctrl.ranch.soil_moisture == undefined">\n' +
    '              <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '                  <div flex="100" layout="row" layout-align="center center">\n' +
    '                    <span ng-i18next>Loading...</span>\n' +
    '                  </div>\n' +
    '              </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-gdd" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '            <div class="summary-header" layout="row" layout-align="start center">{{ "Growing Degree Days" | i18next }} ({{ "Since Jan 1" | i18next }})</div>\n' +
    '            <div layout="row" layout-align="start center">\n' +
    '              <form class="gdd-form" flex="100" layout="row" layout-align="start start">\n' +
    '                    <div class="form-group" flex="100" layout="column" layout-align="start start">\n' +
    '                      <label for="lowerCutoff">{{ "Lower Cutoff" | i18next }} (&deg;F)</label>\n' +
    '                      <input type="number" step="0.01" class="form-control" id="lowerCutoff"\n' +
    '                             required\n' +
    '                             ng-model="ctrl.gddLowerBound" name="lowerCutoff">\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="form-group" flex="100" layout="column" layout-align="start start">\n' +
    '                      <label for="upperCutoff">{{ "Upper Cutoff" | i18next }} (&deg;F)</label>\n' +
    '                      <input type="number" step="0.01"  class="form-control" id="upperCutoff"\n' +
    '                             required\n' +
    '                             ng-model="ctrl.gddUpperBound" name="upperCutoff">\n' +
    '                    </div>\n' +
    '                    <div class="form-group" flex="50" layout="column" layout-align="start start">\n' +
    '                      <label for="nothing"></label>\n' +
    '                      <button class="btn" ng-click="ctrl.getGDD()" ng-i18next>Update</button>\n' +
    '                    </div>\n' +
    '              </form>\n' +
    '            </div>\n' +
    '            <div ng-if="ctrl.ranch.gdd != null && ctrl.gdd_stale != true" class="ranch-gdd" layout="column" layout-align="start stretch" ng-repeat="gdd_summary in ctrl.ranch.gdd">\n' +
    '                <div class="summary-detail" layout="row" layout-align="start center">{{ gdd_summary.block.name }}</div>\n' +
    '                <span class="status-data" layout="row" layout-align="center center">{{ gdd_summary.gdd }}</span>\n' +
    '            </div>\n' +
    '            <div ng-if="ctrl.gdd_stale == true" class="ranch-gdd" layout="column" layout-align="start stretch">\n' +
    '                <span class="status-data" layout="row" layout-align="center center" ng-i18next>Loading...</span>\n' +
    '            </div>\n' +
    '            <div ng-if="ctrl.gdd_stale == false && ctrl.ranch.gdd.length == 0" class="ranch-gdd" layout="column" layout-align="start stretch">\n' +
    '                <span class="status-data" layout="row" layout-align="center center" ng-i18next>No Data</span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-chill-portions-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center">{{ "Chill Portions" | i18next }} ({{ "Since Oct 1" | i18next }})</div>\n' +
    '          <div class="summary-detail" layout="row" layout-align="start center" ng-i18next>using the Dynamic Model</div>\n' +
    '\n' +
    '              <div class="status-header" layout="row" layout-align="start stretch" >\n' +
    '                  <div flex="100" layout="row" layout-align="center center">\n' +
    '                    <span ng-i18next>Block</span>\n' +
    '                  </div>\n' +
    '                  <div flex="100" layout="row" layout-align="center center">\n' +
    '                    <span ng-i18next>Chill portions</span>\n' +
    '                  </div>\n' +
    '              </div>\n' +
    '\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch" ng-repeat="chill_portions in ctrl.ranch.chill_portions">\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span>{{ chill_portions.block }}</span>\n' +
    '                </div>\n' +
    '                <div flex="100" layout="row" layout-align="center center">\n' +
    '                  <span ng-if="chill_portions.portions != undefined">{{ "%3.1f" | fmt: (chill_portions.portions) }}</span>\n' +
    '                </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-chill-hours-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center">{{ "Chill Hours" | i18next }} ({{ "Since Sept 1" | i18next }})</div>\n' +
    '          <div class="chill-hours-status" layout="column" layout-align="start stretch">\n' +
    '            <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Block</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Below 45</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Between 32 and 45</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="start stretch" ng-repeat="chill_hours in ctrl.ranch.chill_hours">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>{{ chill_hours.block }}</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>{{ chill_hours.below_45_utah }}</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>{{ chill_hours.above_32_utah }}</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-if="!ctrl.hideSensorFields" class="summary-information-info summary-chill-hours-status" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '          <div class="summary-header" layout="row" layout-align="start center">{{ "Chill Hours" | i18next }} ({{ "Since Nov 1" | i18next }})</div>\n' +
    '          <div class="chill-hours-status" layout="column" layout-align="start stretch">\n' +
    '            <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Block</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Below 45</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Between 32 and 45</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="status-data" layout="row" layout-align="start stretch" ng-repeat="chill_hours in ctrl.ranch.chill_hours">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>{{ chill_hours.block }}</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>{{ chill_hours.below_45 }}</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span>{{ chill_hours.above_32 }}</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="ctrl.ranch.temp_forecast != null" class="summary-information-info summary-frost-forecast" layout="column" layout-align="center stretch" md-whiteframe="2">\n' +
    '        <div class="summary-header" layout="row" layout-align="start center" ng-i18next>Daily Low Air Temp Forecast</div>\n' +
    '\n' +
    '          <div class="status-header" layout="row" layout-align="start stretch">\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Day</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Time</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="row" layout-align="center center">\n' +
    '                <span ng-i18next>Air Temp</span>\n' +
    '              </div>\n' +
    '          </div>\n' +
    '          <div class="status-data" layout="row" layout-align="start stretch" ng-repeat="status in ctrl.ranch.temp_forecast">\n' +
    '              <div flex="100" layout="column" layout-align="center center">\n' +
    '                <span>{{ ctrl.getDateWeekdayPlain(status.date) }}</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="column" layout-align="center center">\n' +
    '                <span>{{ ctrl.getTime(status.date) }}</span>\n' +
    '              </div>\n' +
    '              <div flex="100" layout="column" layout-align="center center">\n' +
    '                <span ng-class="ctrl.getTempClassF(status.air_temp)">{{ "%3.1f" | fmt: status.air_temp }}&deg;F</span>\n' +
    '              </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="detail-view" flex="75" layout="column" layout-align="start stretch" ng-if="ctrl.width >= 1440">\n' +
    '      <div class="detail-map-view" md-whiteframe="4" flex="50" layout="column" layout-align="start stretch">\n' +
    '        <farmx-map-view style="flex: 1 1 100%;" layout="column" layout-align="start stretch" config="ctrl.mapViewConfig" selected="ctrl.getSelected()">\n' +
    '        </farmx-map-view>\n' +
    '      </div>\n' +
    '      <div class="detail-graph-view" flex="50" layout="column" layout-align="start stretch">\n' +
    '        <div class="detail-graph-button-view" layout="row" layout-align="end stretch">\n' +
    '          <button type="button" class="btn btn-default margin-right-5" ng-click="ctrl.filter()">\n' +
    '            <span ng-i18next>Select Dates</span>\n' +
    '            <i class="fa fa-filter"></i>\n' +
    '          </button>\n' +
    '        </div>\n' +
    '        <farmx-graph-view id="{{ ctrl.graphViewConfig.id }}" config="ctrl.graphViewConfig" layout="row">\n' +
    '        </farmx-graph-view>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div flex="100" layout="row" layout-align="center center" ng-if="ctrl.ranch === null">\n' +
    '    <span style="font-size: 24px" ng-i18next>No Ranch Selected</span>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/entityFilter/entityFilter.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/entityFilter/entityFilter.content.html',
    '<div class="layout-column layout-fill" style="padding: 5px 5px; background-color: #F0F0F0">\n' +
    '  <div layout="row" style="height: 36px; min-height: 36px">\n' +
    '    <div class="input-group" style="margin: 1px 5px 1px 0px; width: calc(100% - 90px);">\n' +
    '      <input type="text" class="form-control" placeholder="{{ \'Search\' | i18next }}" ng-model="ctrl.searchField" >\n' +
    '    </div>\n' +
    '    <button type="button" class="btn btn-default" style="margin-right: 5px">\n' +
    '      <i class="fa fa-heartbeat" aria-hidden="true"></i>\n' +
    '    </button>\n' +
    '    <button type="button" class="btn btn-default" style="margin-right: 5px">\n' +
    '      <i class="fa fa-sort-alpha-asc" aria-hidden="true"></i>\n' +
    '    </button>\n' +
    '  </div>\n' +
    '</div>');
}]);
})();

// Source: components/graph/graph.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/graph/graph.content.html',
    '<md-content id="graphPage" md-component-id="graphPage" layout="column" layout-align="start stretch" class="layout-fill" ng-class="{ mobile: ctrl.height < 800 }" ng-cloak>\n' +
    '  <uib-tabset active="ctrl.activeTab" flex="100" layout="column" layout-align="start stretch">\n' +
    '    <uib-tab ng-repeat="tab in ctrl.tabs" index="tab.id" template-url="components/graph/tab.html" select="ctrl.selectTab($index)">\n' +
    '      <uib-tab-heading>\n' +
    '        <span ng-class="{ \'uib-tab-heading-desktop\': ctrl.height >= 800, \'uib-tab-heading-mobile\': ctrl.height < 800 }">\n' +
    '          <span editable-text="tab.name" e-form="textBtnForm" buttons="no" ng-dblclick="textBtnForm.$show()" onbeforesave="ctrl.tabNameChanged($data)">&nbsp;{{ tab.name }}</span>\n' +
    '          <span ng-if="tab.graphViewConfig.modified">*</span>\n' +
    '          <div class="btn-group">\n' +
    '            <button class="btn graph-btn btn-default graph-tab-close-btn" ng-click="ctrl.closeTab(tab)" ng-if="tab.graphViewConfig.modified">\n' +
    '              <i class="fa fa-times"></i>\n' +
    '            </button>\n' +
    '            <button class="btn graph-btn btn-default graph-tab-close-btn" ng-if="tab.graphViewConfig.modified === false">\n' +
    '              <i class="fa"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </span>\n' +
    '      </uib-tab-heading>\n' +
    '      <div id="tab-{{tab.id}}" class="graph-tab-content" data-ranch-id="{{ tab.ranch.id }}">\n' +
    '        <div class="graph-title-area" layout="row" layout-xs="column" layout-align="space-between center">\n' +
    '          <div class="graph-title-name" layout="row" layout-align="start left">\n' +
    '              <span ng-if="tab.ranch && tab.ranch !== null && ctrl.width >= 768">\n' +
    '                <span ng-if="ctrl.width >= 768">{{ "Ranch" | i18next }} -</span>\n' +
    '                <span>{{ tab.ranch.name }}</span>\n' +
    '              </span>\n' +
    '              <span ng-if="tab.graphViewConfig.graphForType === \'Block\'">\n' +
    '                <span ng-if="ctrl.width >= 768">, {{ "Block" | i18next }} -</span>\n' +
    '                <span>&nbsp;{{ tab.ranch.blocks[0].name }}</span>\n' +
    '              </span>\n' +
    '              <span ng-if="tab.graphViewConfig.graphForType === \'Ranch\'">\n' +
    '                <span ng-if="ctrl.width >= 768">, {{ "Block" | i18next }} -</span>\n' +
    '                <span>&nbsp;{{ "All" | i18next }}</span>\n' +
    '              </span>\n' +
    '          </div>\n' +
    '          <div class="graph-title-filter" layout="row" layout-align="start center">\n' +
    '            <button class="btn graph-title-filter-button btn-default graph-date-nav-btn" ng-click="ctrl.previousBlock(tab)">\n' +
    '              <i class="fa fa-caret-left"></i>\n' +
    '            </button>\n' +
    '            <span class="graph-title-filter-date" ng-if="ctrl.width >= 768" ng-click="ctrl.changeFilter(tab)">\n' +
    '              {{ tab.dateRange.startDate.format(\'DD-MMMM-YYYY\') }} - {{ tab.dateRange.endDate.format(\'DD-MMMM-YYYY\') }}\n' +
    '            </span>\n' +
    '            <span class="graph-title-filter-date" ng-if="ctrl.width < 768"  ng-click="ctrl.changeFilter(tab)">\n' +
    '              {{ tab.dateRange.startDate.format(\'MM-DD-YY\') }} - {{ tab.dateRange.endDate.format(\'MM-DD-YY\') }}\n' +
    '            </span>\n' +
    '            <button class="btn graph-title-filter-button btn-default graph-date-nav-btn" ng-click="ctrl.nextBlock(tab)">\n' +
    '              <i class="fa fa-caret-right"></i>\n' +
    '            </button>\n' +
    '            <div layout="row" layout-align="start right">\n' +
    '              <button class="btn graph-title-filter-button btn-default" ng-click="ctrl.changeInterval(tab, 1, \'days\')">\n' +
    '                <span>1 {{ "DAY" | i18next }}</span>\n' +
    '              </button>\n' +
    '              <button class="btn graph-title-filter-button btn-default" ng-click="ctrl.changeInterval(tab, 7, \'days\')">\n' +
    '                <span>1 {{ "WK" | i18next }}</span>\n' +
    '              </button>\n' +
    '              <button class="btn graph-title-filter-button btn-default" ng-click="ctrl.changeInterval(tab, 14, \'days\')">\n' +
    '                <span>2 {{ "WK" | i18next }}</span>\n' +
    '              </button>\n' +
    '              <button class="btn graph-title-filter-button btn-default" ng-click="ctrl.changeInterval(tab, 1, \'months\')">\n' +
    '                <span>1 {{ "MO" | i18next }}</span>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="graph-area" flex="100" layout="column" layout-align="start stretch" style="background-color: #d9dfe9">\n' +
    '          <div class="hrow border-bottom time-line-top" layout="row" layout-align="space-between stretch" style="border-left: 1px solid #647997">\n' +
    '            <div class="time-line-top-left" ng-class="{ mobile: ctrl.width <= 415 }" style="min-width: {{ ctrl.yAxisSpace(tab) }}px; max-width: {{ ctrl.yAxisSpace(tab) }}px;">\n' +
    '              <!-- Blank Space to fit the chart yAxis -->\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="time-line" ng-class="{ mobile: ctrl.width <= 415 }" flex="100" layout="row" layout-align="start stretch">\n' +
    '              <div style="width: {{ (date.size / tab.dateRange.shiftBy.number) * 100 }}%"\n' +
    '                layout="column" layout-align="center center" ng-repeat="date in tab.dateRange.timeRanges">\n' +
    '                {{ date.date.format(\'MM/DD\')}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="time-line-top-right" ng-class="{ mobile: ctrl.width <= 415 }" style="min-width: {{ ctrl.yAxisSpaceEnd(tab) }}px; max-width: {{ ctrl.yAxisSpaceEnd(tab) }}px">\n' +
    '              <!-- Blank Space to fit the chart yAxis -->\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="charts-block">\n' +
    '            <div class="charts-area" ng-if="tab.isActive">\n' +
    '              <farmx-graph-view id="{{ tab.graphViewConfig.id }}" config="tab.graphViewConfig">\n' +
    '              </farmx-graph-view>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="hrow border-top time-line-bottom"\n' +
    '            layout="row" layout-align="space-between stretch"\n' +
    '            ng-if="ctrl.height >= 800">\n' +
    '            <div class="time-line-bottom-left" ng-class="{ mobile: ctrl.width <= 415 }"  style="min-width: {{ ctrl.yAxisSpace(tab) }}px; max-width: {{ ctrl.yAxisSpace(tab) }}px; border-right: 1px solid #CCC;">\n' +
    '              <!-- Blank Space to fit the chart yAxis -->\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="time-line" ng-class="{ mobile: ctrl.width <= 415 }" flex="100" layout="row" layout-align="start stretch">\n' +
    '              <div style="width: {{ (date.size / tab.dateRange.shiftBy.number) * 100 }}%"\n' +
    '                layout="column" layout-align="center center" ng-repeat="date in tab.dateRange.timeRanges">\n' +
    '                {{ date.date.format(\'MM/DD\')}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="time-line-bottom-right" ng-class="{ mobile: ctrl.width <= 415 }" style="min-width: {{ ctrl.yAxisSpaceEnd(tab) }}px; max-width: {{ ctrl.yAxisSpaceEnd(tab) }}px">\n' +
    '              <!-- Blank Space to fit the chart yAxis -->\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </uib-tab>\n' +
    '  </uib-tabset>\n' +
    '  <div class="tab-clear-btn-cont">\n' +
    '    <button class="btn graph-btn btn-default" ng-click="ctrl.clearAllTabs()">\n' +
    '      <span class="tab-clear-btn-text">{{ "Clear All Tabs" | i18next }}</span>\n' +
    '      <i class="fa fa-trash-o"></i>\n' +
    '    </button>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/graph/tab.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/graph/tab.html',
    '<li ng-class="[{active: active, disabled: disabled, farmxGraphFirstTab: $index === 0, classes}]" class="uib-tab nav-item">\n' +
    '  <a href ng-click="select($event)" class="nav-link" uib-tab-heading-transclude>{{heading}}</a>\n' +
    '</li>\n' +
    '');
}]);
})();

// Source: components/header/header.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/header/header.content.html',
    '<div id="mainHeader" layout="row" layout-align="center center">\n' +
    '  <div id="logoArea" layout="column" layout-align="center center">\n' +
    '    <img src="images/farmx_logo_color_40.png" />\n' +
    '  </div>\n' +
    '\n' +
    '  <div id="menu" layout="row" flex class="fill-height">\n' +
    '    <md-tabs ng-if="mainCtrl.width > 415"id="tabMenu" flex="70">\n' +
    '      <md-tab ng-repeat="tab in mainCtrl.tabs" id="{{ tab.page_id }}" md-on-deselect="mainCtrl.onTabDeselected(tab)" md-on-select="mainCtrl.onTabSelected(tab)" md-active="tab.active" md-tab-class="{{ tab.class }}">\n' +
    '        <md-tab-label>{{ tab.page_label | i18next }}</md-tab-label>\n' +
    '      </md-tab>\n' +
    '    </md-tabs>\n' +
    '\n' +
    '    <md-tabs ng-if="mainCtrl.width <= 415"id="tabMenu" flex="70">\n' +
    '      <md-tab class=\'md-icon-tab\' ng-repeat="tab in mainCtrl.tabs" id="{{ tab.page_id }}" md-on-deselect="mainCtrl.onTabDeselected(tab)" md-on-select="mainCtrl.onTabSelected(tab)" md-active="tab.active">\n' +
    '        <md-tab-label><i class="fa fa-{{ tab.icon }}"></i></md-tab-label>\n' +
    '      </md-tab>\n' +
    '    </md-tabs>\n' +
    '\n' +
    '    <div id="menuButton" layout="row"  layout-align="end center" flex="30">\n' +
    '      <md-menu>\n' +
    '        <md-button id="user" class="md-icon-button" aria-label="user" ng-click="$mdMenu.open($event)">\n' +
    '          <i class="fa fa-user" aria-hidden="true"></i>\n' +
    '        </md-button>\n' +
    '        <md-menu-content width="4">\n' +
    '          <md-menu-item>\n' +
    '            <md-button ng-click="mainCtrl.showPreferences($event)">\n' +
    '              <i class="fa fa-sliders" aria-hidden="true"></i>\n' +
    '              {{ "Account Settings" | i18next }}\n' +
    '            </md-button>\n' +
    '          </md-menu-item>\n' +
    '          <md-menu-item>\n' +
    '            <md-button ng-click="mainCtrl.logout($event)">\n' +
    '              <i class="fa fa-sign-out" aria-hidden="true"></i>\n' +
    '              {{ "Logout" | i18next }}\n' +
    '            </md-button>\n' +
    '          </md-menu-item>\n' +
    '        </md-menu-content>\n' +
    '      </md-menu>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/login/login.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/login/login.content.html',
    '<div id="loginCont" layout="column" layout-gt-sm="row" layout-fill layout-align="start center">\n' +
    '  <div id="logoArea" flex="60" layout="column" layout-align="center center">\n' +
    '    <img style="width: 80%; height:auto; max-width: 495px; max-height: 190px" src="images/farmx_logo_color.png" />\n' +
    '  </div>\n' +
    '\n' +
    '  <md-content id="loginArea" flex-gt-sm="40" flex="100" class="md-no-momentum" layout="row" style="height: 100%; width: 100%;">\n' +
    '    <div layout="row" flex="100" layout-align="center center">\n' +
    '      <div flex="50">\n' +
    '        <md-input-container class="md-icon-float md-block">\n' +
    '          <label ng-i18next>Username</label>\n' +
    '          <input id="userName" type="text" ng-model="mainCtrl.userName" ng-focus="mainCtrl.resetLoginStatus()" ng-keypress="mainCtrl.onKeyPress($event)" autocorrect="off" autocapitalize="off">\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container class="md-block">\n' +
    '          <label ng-i18next>Password</label>\n' +
    '          <input id="password" type="password" ng-model="mainCtrl.password" ng-focus="mainCtrl.resetLoginStatus()" ng-keypress="mainCtrl.onKeyPress($event)" autocorrect="off" autocapitalize="off">\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <div layout="row" layout-align="space-between center">\n' +
    '          <span>{{ "Forgot password?" | i18next }} <a href="https://map.farmx.co/account/password/reset/" ng-i18next>Reset</a></span>\n' +
    '          <button class="btn btn-primary" ng-click="mainCtrl.login()">\n' +
    '            <span ng-i18next>Login</span>\n' +
    '          </button>\n' +
    '        </div>\n' +
    '\n' +
    '        <div style="margin-top: 10px; color: red" layout="row" layout-align="center center">\n' +
    '          <span>{{ mainCtrl.getLoginStatus() | i18next }}</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </md-content>\n' +
    '</div>');
}]);
})();

// Source: components/landingPage/landingPage.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/landingPage/landingPage.content.html',
    '<md-content layout="row" layout-align="start stretch" class="layout-fill">\n' +
    '  <md-sidenav id="entityFilter" md-component-id="entityFilter" md-disable-backdrop md-whiteframe="4">\n' +
    '    <md-toolbar layout="row" layout-align="start center" class="md-menu-toolbar sz-border-bottom md-primary">\n' +
    '      <div id="searchField" class="input-group">\n' +
    '        <input type="text" class="form-control" placeholder="{{ \'Search\' | i18next }}" ng-model="ctrl.searchField" >\n' +
    '      </div>\n' +
    '      <button type="button" class="btn btn-default" ng-click="ctrl.sortedByState = !ctrl.sortedByState" ng-class="{ sortSelected: ctrl.sortedByState }">\n' +
    '        <i class="fa fa-heartbeat" aria-hidden="true"></i>\n' +
    '      </button>\n' +
    '      <button type="button" class="btn btn-default" ng-click="ctrl.sortedByName = !ctrl.sortedByName" ng-class="{ sortSelected: ctrl.sortedByName }">\n' +
    '        <i class="fa fa-sort-alpha-asc" aria-hidden="true"></i>\n' +
    '      </button>\n' +
    '    </md-toolbar>\n' +
    '\n' +
    '    <div id="entity-block">\n' +
    '      <ul id="entity-list" class="entity-list">\n' +
    '        <li id="entity-item-{{ entity.id }}" ng-repeat=\'entity in ctrl.filteredEntities\'>\n' +
    '          <div id="entity-name" class="entity-item" flex="100" layout="row" layout-align="start stretch">\n' +
    '            <span flex="100">{{ entity.name }}</span>\n' +
    '            <i style="margin-right: 5px" class="fa fa-minus-square-o" ng-click="ctrl.closeAllRanches($event, entity)"></i>\n' +
    '            <i class="fa fa-plus-square-o" ng-click="ctrl.openAllRanches($event, entity)"></i>\n' +
    '          </div>\n' +
    '\n' +
    '          <ul id="ranch-list-{{ entity.id }}" \n' +
    '            class="ranch-list">\n' +
    '            <li id="ranch-item-{{ ranch.id }}"\n' +
    '              class="ranch-entry"\n' +
    '              layout-align="start stretch"\n' +
    '              ng-class="{open: ranch.opened}"\n' +
    '              ng-repeat=\'ranch in entity.ranches\'>\n' +
    '              <div layout="row" layout-align="start stretch" flex="100">\n' +
    '                <div class="ranch-item flex-100"\n' +
    '                  ng-class="{selected: ctrl.selected.type === \'Ranch\' && ctrl.selected.value[0].id === ranch.id}"\n' +
    '                  ng-click="ctrl.onRanchClick($event, ranch)">\n' +
    '                  <div class="ranch-item-dropdown">\n' +
    '                    <i class="fa fa-caret-right" style="font-size: 20px" ng-if="ranch.blocks && ranch.blocks.length > 0 && ranch.opened === false"></i>\n' +
    '                    <i class="fa fa-caret-down" style="font-size: 20px" ng-if="ranch.blocks && ranch.blocks.length > 0 && ranch.opened === true"></i>\n' +
    '                  </div>\n' +
    '                  <span ng-if="!ctrl.is_admin">{{ ranch.name }}</span>\n' +
    '                  <span class="editable-text" ng-if="ctrl.is_admin" editable-text="ranch.name" e-form="ranchEditForm" onbeforesave="ctrl.ranchNameChanged(ranch)" e-clickable="true" buttons="no">{{ ranch.name }}<i ng-hide="ranchEditForm.$visible" class="fa fa-pencil-square-o"></i></span>\n' +
    '                </div>\n' +
    '                <div ng-style="ctrl.getStateColor(ranch.state.state)" class="status-box"></div>\n' +
    '              </div>\n' +
    '\n' +
    '              <ul class="block-list" id="block-list-{{ ranch.id }}" flex="100">\n' +
    '                <li class="block-entry" id="block-item-{{ block.id }}"\n' +
    '                  layout-align="start stretch" \n' +
    '                  ng-class="{open: block.opened}"\n' +
    '                  ng-repeat=\'block in ranch.blocks\'>\n' +
    '                  <div layout="row" layout-align="start stretch" flex="100">\n' +
    '                    <div class="block-item flex-100"\n' +
    '                      ng-class="{selected: ctrl.selected.type === \'Block\' && ctrl.selected.value[1].id === block.id}"\n' +
    '                      ng-click="ctrl.onBlockClick($event, ranch, block)">\n' +
    '                      <div class="block-item-dropdown">\n' +
    '                        <i class="fa fa-caret-right" style="font-size: 20px; display: none" ng-if="block.sensors && block.sensors.length > 0 && block.opened === false"></i>\n' +
    '                        <i class="fa fa-caret-down" style="font-size: 20px; display: none" ng-if="block.sensors && block.sensors.length > 0 && block.opened === true"></i>\n' +
    '                      </div>\n' +
    '                      <span class="flex-100" ng-if="!ctrl.is_admin">{{ block.name }}</span>\n' +
    '                      <span class="editable-text flex-100" ng-if="ctrl.is_admin" editable-text="block.name" e-form="blockEditForm" onbeforesave="ctrl.blockNameChanged(block)" e-clickable="true" buttons="no">{{ block.name }}<i ng-hide="blockEditForm.$visible" class="fa fa-pencil-square-o"></i></span>\n' +
    '                      <span class="block-item-crop-type">\n' +
    '                        <img ng-src="{{ ctrl.getBlockCropType(block) }}">\n' +
    '                      </span>\n' +
    '                    </div>\n' +
    '                    <div ng-style="ctrl.getStateColor(block.state.state)" class="status-box-small"></div>\n' +
    '                  </div>\n' +
    '                  <!--<ul class="sensor-list" id="sensor-list-{{ block.id }}" flex="100">\n' +
    '                    <li class="sensor-entry" \n' +
    '                      flex="100" layout="row" layout-align="start stretch" \n' +
    '                      ng-repeat=\'sensor in block.sensors\'>\n' +
    '                      <div layout="row" layout-align="start stretch" flex="100">\n' +
    '                        <div class="sensor-item flex-100"\n' +
    '                          ng-class="{selected: ctrl.selected.type === \'Sensor\' && ctrl.selected.value[2].uid === sensor.uid}"\n' +
    '                          ng-click="ctrl.onSensorClick($event, ranch, block, sensor)">\n' +
    '                          <span class="sensor-item-name" flex="100" ng-if="!ctrl.is_admin">{{ sensor.name }}</span>\n' +
    '                          <span class="sensor-item-name editable-text" ng-if="ctrl.is_admin" editable-text="sensor.name" e-form="sensorEditForm" onbeforesave="ctrl.sensorNameChanged(sensor)" e-clickable="true" buttons="no">{{ sensor.name.replace("_", " ") }}<i ng-hide="sensorEditForm.$visible" class="fa fa-pencil-square-o"></i></span>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </li>\n' +
    '                  </ul>-->\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '  </md-sidenav>\n' +
    '\n' +
    '  <farmx-sidenav-toggler sidenav-id="entityFilter" content-id="pageView">\n' +
    '  </farmx-sidenav-toggler>\n' +
    '\n' +
    '  <md-content id="pageView" ui-view="page">\n' +
    '  </md-content>\n' +
    '</md-content>');
}]);
})();

// Source: components/map/map.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/map/map.content.html',
    '<md-content md-component-id="mapPage" class="layout-fill">\n' +
    '  <farmx-map-view config="ctrl.mapViewConfig" selected="ctrl.getSelected()" ng-if="ctrl.isLoading() === false && ctrl.showThreeD === false">\n' +
    '  </farmx-map-view>\n' +
    '\n' +
    '  <div class="farmx-map-satellite-browser-container" ng-if="ctrl.isSatelliteBrowserOpen()" ng-cloak ng-style="ctrl.satelliteBrowserStyle">\n' +
    '    <div layout-align="end stretch" layout="row" style="min-height: 24px; background-color: #F0F0F0; border-top-left-radius: 5px; border-top-right-radius: 5px">\n' +
    '      <div style="height: 24px; line-height: 20px; padding-top: 2px; margin-right: 7px; font-size: 16px">\n' +
    '        <span class="fa fa-chevron-down" ng-click="ctrl.toggleSatelliteBrowser()">\n' +
    '        </span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <farmx-satellite-viewer selected="ctrl.getSelected()" viewerid="map1"></farmx-satellite-viewer>\n' +
    '    <farmx-satellite-viewer-selector viewerid="map1"></farmx-satellite-viewer-selector>\n' +
    '  </div>\n' +
    '\n' +
    '  <farmx-threed-view toggle="ctrl.showThreeD" block-id="{{ ctrl.getSelected().value[1].id }}" ng-if="ctrl.showThreeD">\n' +
    '  </farmx-threed-view>\n' +
    '\n' +
    '  <div class="farmx-map-satellite-browser-container-spinner">\n' +
    '    <div id="loadingSpinner" style="float: right;">\n' +
    '      <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/newdashboard/newdashboard.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/newdashboard/newdashboard.content.html',
    '<md-content md-component-id="dashboardPage" layout="column" layout-align="start stretch" class="dashboard-content">\n' +
    '  <div class="dashboard-view" flex="100" layout="column" layout-align="start stretch" ng-if="ctrl.selected.type !== undefined">\n' +
    '    <div class="dashboard-title" layout="row" layout-align="start center" ng-style="{\'background-color\': ctrl.getRanchBackgroundColor()}">\n' +
    '      <span class="dashboard-title-ranch-name" ng-style="{\'color\': ctrl.getRanchColor()}">{{ ctrl.ranch.name }}</span>\n' +
    '      <span class="dashboard-title-view-in-map" ng-style="{\'color\': ctrl.getRanchColor()}" ng-click="ctrl.viewInMap()" ng-i18next>View in map</span>\n' +
    '    </div>\n' +
    '    <adf-dashboard id="farmx-dashboard-header" collapsible="false" editable="false" adf-model="ctrl.headerModel">\n' +
    '    </adf-dashboard>\n' +
    '    <div layout="column" style="margin: 0px 15px">\n' +
    '      <md-toolbar layout="row" layout-align="start center" class="md-menu-toolbar sz-border-bottom md-primary" style="background-color: #FAFAFA; min-height: 34px">\n' +
    '        <div layout="row" layout-align="start center" flex="60">\n' +
    '          <div class="input-group" style="width: 200px">\n' +
    '            <input type="text" class="form-control" placeholder="Search" ng-model="ctrl.searchField" >\n' +
    '          </div>\n' +
    '          <button type="button" class="btn btn-default" ng-click="ctrl.sortedByName = !ctrl.sortedByName" ng-class="{ sortSelected: ctrl.sortedByName }">\n' +
    '            <i class="fa fa-sort-alpha-asc" aria-hidden="true"></i>\n' +
    '          </button>\n' +
    '          <button type="button" class="btn btn-default" ng-click="ctrl.sortedByState = !ctrl.sortedByState" ng-class="{ sortSelected: ctrl.sortedByState }">\n' +
    '            <i class="fa fa-heartbeat" aria-hidden="true"></i>\n' +
    '          </button>\n' +
    '          <button type="button" class="btn btn-default" ng-click="ctrl.resetFilter = !ctrl.resetFilter">\n' +
    '            <i class="fa fa-globe" aria-hidden="true"></i>\n' +
    '          </button>\n' +
    '\n' +
    '          <div layout="row" layout-align="center center" ng-repeat="state in ctrl.getStates()" ng-style="{ \'background-color\': state.fillColor }" style="padding: 0px 10px;border: 1px solid #000;margin: 0px 5px">\n' +
    '            <span ng-style="{ \'color\': state.textColor }">{{ ctrl.getBlockCountForState($index) }}</span>\n' +
    '          </div>\n' +
    '\n' +
    '          <div layout="row" layout-align="center center" style="margin-left: 20px; font-size: 14px">\n' +
    '            <span>{{ "Displaying" | i18next }} {{ ctrl.blocks.length }} {{ "of" | i18next }} {{ ctrl.ranch.blocks.length }}</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div layout="row" layout-align="end center" flex="40">\n' +
    '          <span class="btn btn-success" style="outline: none" ng-click="ctrl.graphSelected.summary = !ctrl.graphSelected.summary" ng-style="{ \'background-color\': ctrl.graphSelected.summary ? \'#449d44\' : \'#5cb85c\' }" ng-i18next>Summary</span>\n' +
    '          <span class="btn btn-success" style="outline: none" ng-click="ctrl.graphSelected.soil = !ctrl.graphSelected.soil" ng-style="{ \'background-color\': ctrl.graphSelected.soil ? \'#449d44\' : \'#5cb85c\' }" ng-i18next>Soil</span>\n' +
    '          <span class="btn btn-success" style="outline: none" ng-click="ctrl.graphSelected.weather = !ctrl.graphSelected.weather" ng-style="{ \'background-color\': ctrl.graphSelected.weather ? \'#449d44\' : \'#5cb85c\' }" ng-i18next>Weather</span>\n' +
    '          <span class="btn btn-success" style="outline: none" ng-click="ctrl.graphSelected.irrigation = !ctrl.graphSelected.irrigation" ng-style="{ \'background-color\': ctrl.graphSelected.irrigation ? \'#449d44\' : \'#5cb85c\' }" ng-i18next>Irrigation</span>\n' +
    '          <span class="btn btn-success" style="outline: none" ng-click="ctrl.graphSelected.map = !ctrl.graphSelected.map" ng-style="{ \'background-color\': ctrl.graphSelected.map ? \'#449d44\' : \'#5cb85c\' }" ng-i18next>Map</span>\n' +
    '        </div>\n' +
    '      </md-toolbar>\n' +
    '    </div>\n' +
    '    <div layout="column" layout-align="start stretch" style="overflow: auto">\n' +
    '      <adf-dashboard id="farmx-dashboard-footer" collapsible="false" editable="false" adf-model="ctrl.footerModel">\n' +
    '      </adf-dashboard>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div flex="100" layout="row" layout-align="center center" ng-if="ctrl.selected.type === undefined">\n' +
    '    <span style="font-size: 24px" ng-i18next>No Ranch Selected</span>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/root/initializing.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/root/initializing.content.html',
    '<div>\n' +
    '  <div style="border: 1px solid #aaa2aa; padding: 10px; border-radius: 5px">\n' +
    '    <span>{{ "Loading" | i18next }} ...</span>\n' +
    '  </div>\n' +
    '</div>');
}]);
})();

// Source: components/schedule/schedule.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/schedule/schedule.content.html',
    '<md-content md-component-id="schedulePage" class="layout-fill" ng-cloak>\n' +
    '  <div class="schedule-page layout-fill" flex="100" layout="column" layout-align="start stretch">\n' +
    '    <div id="tab-{{ctrl.tab.id}}" class="schedule-content layout-fill" data-ranch-id="{{ ctrl.tab.ranch.id }}">\n' +
    '\n' +
    '      <div class="irrigation-table-view" layout="column" layout-align="start stretch" ng-if="ctrl.tab.ranch.schedulingTableEnabled">\n' +
    '        <div class="irrigation-table-title" ng-i18next>Irrigation Schedule</div>\n' +
    '        <farmx-irrigation-table-view class="irrigation-table-cont" blocks="ctrl.tab.ranch.blocks" startdate="ctrl.tab.dateRange.startDate" enddate="ctrl.tab.dateRange.endDate"></farmx-irrigation-table-view>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="flow-table-view" layout="column" layout-align="start stretch" ng-if="ctrl.tab.ranch.schedulingTableEnabled">\n' +
    '        <div class="flow-table-title" ng-i18next>Flow and Pressure</div>\n' +
    '        <farmx-flow-table-view class="flow-table-cont" ranch="ctrl.tab.ranch" startdate="ctrl.tab.dateRange.startDate" enddate="ctrl.tab.dateRange.endDate"></farmx-flow-table-view>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="schedule-title-area" layout="row" layout-xs="column" layout-align="space-between center" ng-if="!ctrl.tab.ranch.schedulingEnabled">\n' +
    '        <h4>{{ "Scheduling not enabled for" | i18next }} {{ ctrl.tab.ranch.name }}</h4>\n' +
    '      </div>\n' +
    '      \n' +
    '      <div class="schedule-title-area" layout="row" layout-xs="column" layout-align="space-between center" ng-if="ctrl.tab.ranch.schedulingEnabled">\n' +
    '        <div class="schedule-title-name" layout="row" layout-align="start left">\n' +
    '            <span ng-if="ctrl.tab.ranch && ctrl.tab.ranch !== null && ctrl.width >= 768">\n' +
    '              <span ng-if="ctrl.width >= 768">{{ "Ranch" | i18next }} -</span>\n' +
    '              <span>{{ ctrl.tab.ranch.name }}</span>\n' +
    '            </span>\n' +
    '            <span ng-if="ctrl.tab.type === \'Block\'">\n' +
    '              <span ng-if="ctrl.width >= 768">, {{ "Block" | i18next }} -</span>\n' +
    '              <span>&nbsp;{{ ctrl.tab.ranch.blocks[0].name }}</span>\n' +
    '            </span>\n' +
    '            <span ng-if="ctrl.tab.type === \'Ranch\'">\n' +
    '              <span ng-if="ctrl.width >= 768">, {{ "Block" | i18next }} -</span>\n' +
    '              <span>&nbsp;{{ "All" | i18next }}</span>\n' +
    '            </span>\n' +
    '        </div>\n' +
    '        <div class="schedule-title-filter" layout="row" layout-align="start center">\n' +
    '          <button class="btn schedule-title-filter-button btn-default schedule-date-nav-btn" ng-click="ctrl.previousBlock(ctrl.tab)">\n' +
    '            <i class="fa fa-caret-left"></i>\n' +
    '          </button>\n' +
    '          <span class="schedule-title-filter-date" ng-if="ctrl.width >= 768" ng-click="ctrl.changeFilter(ctrl.tab)">\n' +
    '            {{ ctrl.tab.dateRange.startDate.format(\'DD-MMMM-YYYY\') }} - {{ ctrl.tab.dateRange.endDate.format(\'DD-MMMM-YYYY\') }}\n' +
    '          </span>\n' +
    '          <span class="schedule-title-filter-date" ng-if="ctrl.width < 768"  ng-click="ctrl.changeFilter(ctrl.tab)">\n' +
    '            {{ ctrl.tab.dateRange.startDate.format(\'MM-DD-YY\') }} - {{ ctrl.tab.dateRange.endDate.format(\'MM-DD-YY\') }}\n' +
    '          </span>\n' +
    '          <button class="btn schedule-title-filter-button btn-default schedule-date-nav-btn" ng-click="ctrl.nextBlock(ctrl.tab)">\n' +
    '            <i class="fa fa-caret-right"></i>\n' +
    '          </button>\n' +
    '          <div layout="row" layout-align="start right">\n' +
    '            <button class="btn schedule-title-filter-button btn-default" ng-click="ctrl.changeInterval(ctrl.tab, 1, \'days\')">\n' +
    '              <span>1 {{ "DAY" | i18next }}</span>\n' +
    '            </button>\n' +
    '            <button class="btn schedule-title-filter-button btn-default" ng-click="ctrl.changeInterval(ctrl.tab, 7, \'days\')">\n' +
    '              <span>1 {{ "WK" | i18next }}</span>\n' +
    '            </button>\n' +
    '            <button class="btn schedule-title-filter-button btn-default" ng-click="ctrl.changeInterval(ctrl.tab, 14, \'days\')">\n' +
    '              <span>2 {{ "WK" | i18next }}</span>\n' +
    '            </button>\n' +
    '            <button class="btn schedule-title-filter-button btn-default" ng-click="ctrl.changeInterval(ctrl.tab, 1, \'months\')">\n' +
    '              <span>1 {{ "MO" | i18next }}</span>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="schedule-area" layout="column" layout-align="start stretch" style="background-color: #d9dfe9" ng-if="ctrl.tab.ranch.schedulingEnabled">\n' +
    '        <div class="hrow border-bottom time-line-top" layout="row" layout-align="space-between stretch">\n' +
    '          <div class="time-line" style="margin: 0px 10px" ng-class="{ mobile: ctrl.width <= 415 }" flex="100" layout="row" layout-align="start stretch">\n' +
    '            <div class="schedule-header-begin"></div>\n' +
    '\n' +
    '            <div class="schedule-header">\n' +
    '              <div style="width: {{ (date.size / ctrl.tab.dateRange.shiftBy.number) * 100 }}%; border-right: 1px solid #999; height: 100%" layout="column" layout-align="center center" ng-repeat="date in ctrl.tab.dateRange.timeRanges">\n' +
    '                {{ date.date.format(\'MM/DD\') }}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="schedule-header-end"></div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-repeat="block in ctrl.tab.ranch.blocks" ng-if="block.scheduling_enabled">\n' +
    '          <div class="schedule-body">\n' +
    '            <farmx-schedule-view id="block.id" block="block" startdate="ctrl.tab.dateRange.startDate" enddate="ctrl.tab.dateRange.endDate" ranges="ctrl.tab.dateRange.timeRanges" click="ctrl.addEvent" layout="column" layout-align="start stretch" class="layout-fill"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-if="ctrl.tab.ranch.blocks.length == 1 && !ctrl.tab.ranch.blocks[0].scheduling_enabled">\n' +
    '            <div class="schedule-body">  \n' +
    '                <h4 style="padding:20px" ng-i18next>Scheduling is not enabled for this block</h4>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/satellite/satellite.content.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/satellite/satellite.content.html',
    '<md-content md-component-id="satellitePage" layout-align="start stretch" layout="column" class="layout-fill" style="padding: 10px 10px">\n' +
    '  <div style="height: 46px; padding: 0px 0px 10px 0px" layout-align="start stretch" layout="row">\n' +
    '    <div flex="50" layout-align="start stretch" layout="row" style="margin: 0px 0px 0px 10px">\n' +
    '      <div layout-align="start stretch" layout="row" flex="50">\n' +
    '        <md-select ng-model="ctrl.selectedAnomaly" class="md-no-underline" style="margin: 0px 0px; width: 300px" placeholder="Select Anomaly">\n' +
    '          <md-option style="font-size: 12px; height: 32px" ng-if="ctrl.getAnomalyList() === undefined"><em ng-i18next>Loading</em></md-option>\n' +
    '          <md-option style="font-size: 12px; height: 32px" ng-repeat="anomaly in ctrl.getAnomalyList()" ng-value="anomaly"><em>{{ anomaly.name }} - {{ anomaly.anomaly_type | i18next }}</em></md-option>\n' +
    '        </md-select>\n' +
    '      </div>\n' +
    '\n' +
    '      <div layout-align="end stretch" layout="row" flex="50">\n' +
    '        <md-button aria-label="Clear" class="md-button-custom" ng-if="ctrl.selectedAnomaly != null" ng-click="ctrl.clearAnomaly()">\n' +
    '          <div>\n' +
    '            <span style="margin: 0px 5px 0px 0px" ng-i18next>Clear</span>\n' +
    '            <i class="fa fa-eraser"></i>\n' +
    '          </div>\n' +
    '        </md-button>\n' +
    '        <md-button ari-label="Save" class="md-button-custom" ng-if="ctrl.editMode === true" ng-click="ctrl.saveEdit()">\n' +
    '          <div>\n' +
    '            <span style="margin: 0px 5px 0px 0px" ng-i18next>Save</span>\n' +
    '            <i class="fa fa-save"></i>\n' +
    '          </div>\n' +
    '        </md-button>\n' +
    '\n' +
    '        <md-button ari-label="Cancel" class="md-button-custom"  ng-if="ctrl.editMode === true" ng-click="ctrl.cancelEdit()">\n' +
    '          <div>\n' +
    '            <span style="margin: 0px 5px 0px 0px" ng-i18next>Cancel</span>\n' +
    '            <i class="fa fa-ban"></i>\n' +
    '          </div>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div flex="50" layout-align="end stretch" layout="row">\n' +
    '      <md-button ari-label="New Anomaly" ng-class="{ \'md-button-custom\': ctrl.width > 480, \'md-icon-button\': ctrl.width <= 480 }" ng-if="ctrl.editMode === false" ng-disabled="ctrl.selected == null || ctrl.selected.type == null || ctrl.selected.type != \'Block\'" ng-click="ctrl.createAnomaly()">\n' +
    '        <div>\n' +
    '          <span style="margin: 0px 5px 0px 0px" ng-if="ctrl.width > 480" ng-i18next>New</span>\n' +
    '          <i class="fa fa-plus"></i>\n' +
    '        </div>\n' +
    '      </md-button>\n' +
    '      <md-button ari-label="Edit Anomaly" ng-class="{ \'md-button-custom\': ctrl.width > 480, \'md-icon-button\': ctrl.width <= 480 }" ng-if="ctrl.editMode === false" ng-disabled="ctrl.selectedAnomaly == null" ng-click="ctrl.editAnomaly()">\n' +
    '        <div>\n' +
    '          <span style="margin: 0px 5px 0px 0px" ng-if="ctrl.width > 480" ng-i18next>Edit</span>\n' +
    '          <i class="fa fa-edit"></i>\n' +
    '        </div>\n' +
    '      </md-button>\n' +
    '      <md-button ari-label="Delete Anomaly" ng-class="{ \'md-button-custom\': ctrl.width > 480, \'md-icon-button\': ctrl.width <= 480 }" ng-if="ctrl.editMode === false" ng-disabled="ctrl.selectedAnomaly == null" ng-click="ctrl.deleteAnomaly()">\n' +
    '        <div>\n' +
    '          <span style="margin: 0px 5px 0px 0px" ng-if="ctrl.width > 480" ng-i18next>Delete</span>\n' +
    '          <i class="fa fa-trash"></i>\n' +
    '        </div>\n' +
    '      </md-button>\n' +
    '\n' +
    '      <md-button ari-label="Zoom" ng-class="{ \'md-button-custom\': ctrl.width > 480, \'md-icon-button\': ctrl.width <= 480 }" ng-if="ctrl.editMode === true" ng-click="ctrl.zoomMap()">\n' +
    '        <div>\n' +
    '          <span style="margin: 0px 5px 0px 0px" ng-if="ctrl.width > 480" ng-i18next>Zoom</span>\n' +
    '          <i class="fa fa-search"></i>\n' +
    '        </div>\n' +
    '      </md-button>\n' +
    '\n' +
    '      <md-button ari-label="Navigate" ng-class="{ \'md-button-custom\': ctrl.width > 480, \'md-icon-button\': ctrl.width <= 480 }" class="md-button-custom" ng-if="ctrl.editMode === false" ng-disabled="ctrl.selectedAnomaly == null" ng-click="ctrl.navigateMap()">\n' +
    '        <div>\n' +
    '          <span style="margin: 0px 5px 0px 0px" ng-if="ctrl.width > 480" ng-i18next>Navigate</span>\n' +
    '          <i class="fa fa-location-arrow"></i>\n' +
    '        </div>\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div layout-align="start stretch" flex="100" ng-class="{ \'layout-row\': ctrl.width > 480, \'layout-column\': ctrl.width <= 480 }">\n' +
    '    <div layout-align="start stretch" layout="column" flex="100">\n' +
    '      <farmx-satellite-viewer viewerid="map1" selected="ctrl.getSelected()"></farmx-satellite-viewer>\n' +
    '      <farmx-satellite-map-view flex="100" selected="ctrl.getSelected()" viewerid="map1" drawable="true" style="margin: 2px 2px 2px 0px; position: relative;"></farmx-satellite-map-view>\n' +
    '      <farmx-satellite-viewer-selector viewerid="map1"></farmx-satellite-viewer-selector>\n' +
    '    </div>\n' +
    '    <div style="width: 40px">\n' +
    '    </div>\n' +
    '    <div layout-align="start stretch" layout="column" flex="100">\n' +
    '      <farmx-satellite-viewer viewerid="map2" selected="ctrl.getSelected()"></farmx-satellite-viewer>\n' +
    '      <farmx-satellite-map-view flex="100" selected="ctrl.getSelected()" viewerid="map2" style="margin: 2px 2px 2px 0px; position: relative;"></farmx-satellite-map-view>\n' +
    '      <farmx-satellite-viewer-selector viewerid="map2"></farmx-satellite-viewer-selector>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: fonts/farmx-sensor-icons/demo.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('fonts/farmx-sensor-icons/demo.html',
    '<!doctype html>\n' +
    '<html>\n' +
    '<head>\n' +
    '    <meta charset="utf-8">\n' +
    '    <title>IcoMoon Demo</title>\n' +
    '    <meta name="description" content="An Icon Font Generated By IcoMoon.io">\n' +
    '    <meta name="viewport" content="width=device-width, initial-scale=1">\n' +
    '    <link rel="stylesheet" href="demo-files/demo.css">\n' +
    '    <link rel="stylesheet" href="style.css"></head>\n' +
    '<body>\n' +
    '    <div class="bgc1 clearfix">\n' +
    '        <h1 class="mhmm mvm"><span class="fgc1">Font Name:</span> farmx-sensor-icons <small class="fgc1">(Glyphs:&nbsp;33)</small></h1>\n' +
    '    </div>\n' +
    '    <div class="clearfix mhl ptl">\n' +
    '        <h1 class="mvm mtn fgc1">Grid Size: Unknown</h1>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-radio-tower">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-radio-tower</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="61" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x61;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-pagelines">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-pagelines</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="62" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x62;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-leaf">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-leaf</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="63" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x63;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-bolt">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-bolt</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="64" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x64;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-gauge">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-gauge</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="65" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x65;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-radio-waves">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-radio-waves</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="66" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x66;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-tree">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-tree</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="67" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x67;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-tree-1">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-tree-1</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="68" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x68;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-park2">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-park2</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="69" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x69;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-thermometer">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-thermometer</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="6a" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x6a;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-tint">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-tint</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="6b" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x6b;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-ios-rainy-outline">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-ios-rainy-outline</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="6c" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x6c;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-ios-rainy">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-ios-rainy</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="6d" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x6d;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-waterdrop">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-waterdrop</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="6e" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x6e;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-water">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-water</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="6f" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x6f;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-air">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-air</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="70" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x70;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-camera">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-camera</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="71" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x71;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-waves">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-waves</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="72" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x72;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-cardiac-pulse">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-cardiac-pulse</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="73" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x73;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-radar-2">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-radar-2</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="74" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x74;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-rss">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-rss</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="75" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x75;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-ruby">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-ruby</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="76" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x76;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-sound">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-sound</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="77" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x77;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-eyedropper">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-eyedropper</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="78" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x78;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-leaf-1">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-leaf-1</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="79" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x79;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-thermometer-1">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-thermometer-1</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="7a" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x7a;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-cloud-sun">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-cloud-sun</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="41" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x41;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-tachometer">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-tachometer</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="42" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x42;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-ios-speedometer">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-ios-speedometer</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="43" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x43;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-speedometer">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-speedometer</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="44" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#x44;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs1">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-ranch">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-ranch</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="e900" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#xe900;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="clearfix mhl ptl">\n' +
    '        <h1 class="mvm mtn fgc1">Grid Size: 16</h1>\n' +
    '        <div class="glyph fs2">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-block">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-block</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="e901" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#xe901;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="glyph fs2">\n' +
    '            <div class="clearfix bshadow0 pbs">\n' +
    '                <span class="icon-home">\n' +
    '                \n' +
    '                </span>\n' +
    '                <span class="mls"> icon-home</span>\n' +
    '            </div>\n' +
    '            <fieldset class="fs0 size1of1 clearfix hidden-false">\n' +
    '                <input type="text" readonly value="e908" class="unit size1of2" />\n' +
    '                <input type="text" maxlength="1" readonly value="&#xe908;" class="unitRight size1of2 talign-right" />\n' +
    '            </fieldset>\n' +
    '            <div class="fs0 bshadow0 clearfix hidden-true">\n' +
    '                <span class="unit pvs fgc1">liga: </span>\n' +
    '                <input type="text" readonly value="home, house" class="liga unitRight" />\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!--[if gt IE 8]><!-->\n' +
    '    <div class="mhl clearfix mbl">\n' +
    '        <h1>Font Test Drive</h1>\n' +
    '        <label>\n' +
    '            Font Size: <input id="fontSize" type="number" class="textbox0 mbm"\n' +
    '            min="8" value="48" />\n' +
    '            px\n' +
    '        </label>\n' +
    '        <input id="testText" type="text" class="phl size1of1 mvl"\n' +
    '        placeholder="Type some text to test..." value=""/>\n' +
    '        <div id="testDrive" class="icon-">&nbsp;\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <!--<![endif]-->\n' +
    '    <div class="bgc1 clearfix">\n' +
    '        <p class="mhl">Generated by <a href="https://icomoon.io/app">IcoMoon</a></p>\n' +
    '    </div>\n' +
    '\n' +
    '    <script src="demo-files/demo.js"></script>\n' +
    '</body>\n' +
    '</html>\n' +
    '');
}]);
})();

// Source: dateNavigator/dateNavigator.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dateNavigator/dateNavigator.template.html',
    '<md-content class="dateNavigator" class="date-navigator" md-component-id="dateNavigator" layout="column" layout-align="start stretch" ng-class="{ mobile: ctrl.height < 800 }" ng-cloak>\n' +
    '    <div style="background-color: #E2E2E2" class="graph-title-area" layout="row" layout-align="space-between center">\n' +
    '      <div class="button-cont">\n' +
    '        <button style="background-color: #E2E2E2;" type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.datePrevYear()">\n' +
    '          <i class="fa fa-fast-backward" style="line-height: 20px"></i>\n' +
    '        </button>\n' +
    '        <button style="background-color: #E2E2E2;" type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.datePrevMonth()">\n' +
    '          <i class="fa fa-backward" style="line-height: 20px"></i>\n' +
    '        </button>\n' +
    '        <button style="background-color: #E2E2E2;" type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.datePrev()">\n' +
    '          <i class="fa fa-caret-left" style="font-size: 20px"></i>\n' +
    '        </button>\n' +
    '        <button style="background-color: #E2E2E2;" type="button" class="btn btn-default stretch-button" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.selectDateFromPicker()">\n' +
    '          <span ng-if="ctrl.width >= 768">&nbsp;{{ state.date.format(\'D MMMM YYYY\') }}</span>\n' +
    '          <span ng-if="ctrl.width < 768">&nbsp;{{ state.date.format(\'D MMM YY\') }}</span>\n' +
    '        </button>\n' +
    '        <button style="background-color: #E2E2E2;" type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.dateNext()">\n' +
    '          <i class="fa fa-caret-right" style="font-size: 20px"></i>\n' +
    '        </button>\n' +
    '        <button style="background-color: #E2E2E2;" type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.dateNextMonth()">\n' +
    '          <i class="fa fa-forward" style="line-height: 20px"></i>\n' +
    '        </button>\n' +
    '        <button style="background-color: #E2E2E2;" type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.dateNextYear()">\n' +
    '            <i class="fa fa-fast-forward" style="line-height: 20px"></i>\n' +
    '          </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: irrigation/flowTable.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('irrigation/flowTable.template.html',
    '\n' +
    '<table class="dashboard-table">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th>{{ "Block" | i18next }}</th>\n' +
    '        <th>{{ "Pressure" | i18next }} <select name="pressureUnitSelect" id="pressureUnitSelect" ng-model="ctrl.units.pressure.selected" ng-options="option.name for option in ctrl.units.pressure.options track by option.key"></select></th>\n' +
    '        <th>{{ "Flow Rate" | i18next }} <select name="flowRateUnitSelect" id="flowRateUnitSelect" ng-model="ctrl.units.flowRate.selected" ng-options="option.name for option in ctrl.units.flowRate.options track by option.key"></select></th>\n' +
    '        <th>{{ "On" | i18next }} / {{ "Off" | i18next }}</th>\n' +
    '        <th>{{ "Last Week" | i18next }} <select name="flowUnitSelect" id="lastWeekFlowUnitSelect" ng-model="ctrl.units.flow.selected" ng-options="option.name for option in ctrl.units.flow.options track by option.key"></select></th>\n' +
    '        <th>{{ "This Week" | i18next }} <select name="flowUnitSelect" id="thisWeekFlowUnitSelect" ng-model="ctrl.units.flow.selected" ng-options="option.name for option in ctrl.units.flow.options track by option.key"></select></th>\n' +
    '        <th>{{ "Season" | i18next }} <select name="flowUnitSelect" id="seasonFlowUnitSelect" ng-model="ctrl.units.flow.selected" ng-options="option.name for option in ctrl.units.flow.options track by option.key"></select></th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '    <tr ng-repeat="blockStats in ctrl.blockStats">\n' +
    '        <th>{{ blockStats.name }}</th>\n' +
    '        <td>{{ ctrl.convertUnits(blockStats.pressure, ctrl.ranch.currentFlowStats.units.pressure, ctrl.units.pressure.selected) }}</td>\n' +
    '        <td>{{ ctrl.convertUnits(blockStats.flowRate, ctrl.ranch.currentFlowStats.units.flowRate, ctrl.units.flowRate.selected) }}</td>\n' +
    '        <td>{{ blockStats.on | onOff }}</td>\n' +
    '        <td>{{ ctrl.convertUnits(blockStats.flowWeekPrev, ctrl.ranch.cumulativeFlowStats.units, ctrl.units.flow.selected) }}</td>\n' +
    '        <td>{{ ctrl.convertUnits(blockStats.flowWeek, ctrl.ranch.cumulativeFlowStats.units, ctrl.units.flow.selected) }}</td>\n' +
    '        <td>{{ ctrl.convertUnits(blockStats.flowSeason, ctrl.ranch.cumulativeFlowStats.units, ctrl.units.flow.selected) }}</td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '    <tbody class="dashboard-table-footer">\n' +
    '    <tr>\n' +
    '        <th>{{ "Ranch" | i18next }}</th>\n' +
    '        <td>{{ ctrl.convertUnits(ctrl.ranch.currentFlowStats.aggregate.pressure, ctrl.ranch.currentFlowStats.units.pressure, ctrl.units.pressure.selected) }}</td>\n' +
    '        <td>{{ ctrl.convertUnits(ctrl.ranch.currentFlowStats.aggregate.flowRate, ctrl.ranch.currentFlowStats.units.flowRate, ctrl.units.flowRate.selected) }}</td>\n' +
    '        <td>{{ ctrl.ranch.currentFlowStats.aggregate.on }} / {{ ctrl.ranch.currentFlowStats.blockStats.length }}</td>\n' +
    '        <td>{{ ctrl.convertUnits(ctrl.ranch.cumulativeFlowStats.aggregate.flowWeekPrev, ctrl.ranch.cumulativeFlowStats.units, ctrl.units.flow.selected) }}</td>\n' +
    '        <td>{{ ctrl.convertUnits(ctrl.ranch.cumulativeFlowStats.aggregate.flowWeek, ctrl.ranch.cumulativeFlowStats.units, ctrl.units.flow.selected) }}</td>\n' +
    '        <td>{{ ctrl.convertUnits(ctrl.ranch.cumulativeFlowStats.aggregate.flowSeason, ctrl.ranch.cumulativeFlowStats.units, ctrl.units.flow.selected) }}</td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

// Source: irrigation/irrigationTable.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('irrigation/irrigationTable.template.html',
    '<table class="irrigation-table" ng-if=\'ctrl.blocks.length\'>\n' +
    '    <thead>\n' +
    '        <tr>\n' +
    '            <th>{{ "Block" | i18next }}</th>\n' +
    '            <th>{{ "Sensors" | i18next }}</th>\n' +
    '            <th>{{ "Acres" | i18next }}</th>\n' +
    '            <th>{{ "Soil Type" | i18next }}</th>\n' +
    '            <th>{{ "Wilting Point" | i18next }}</th>\n' +
    '            <th>{{ "Field Capacity" | i18next }}</th>\n' +
    '            <th>{{ "Refill Point" | i18next }}</th>\n' +
    '            <th>VWC (%)</th>\n' +
    '            <th>{{ "Refill Water" | i18next }} (in)</th>\n' +
    '            <th>{{ "Available Water" | i18next }} (in)</th>\n' +
    '            <th>{{ "Current Water" | i18next }} (in)</th>\n' +
    '            <th>{{ "ETc forecast" | i18next }} (in/7d)</th>\n' +
    '            <th>{{ "Water needed" | i18next }} (in)</th>\n' +
    '            <th>{{ "Acre in needed" | i18next }}</th>\n' +
    '            <th>{{ "Gallons needed" | i18next }}</th>\n' +
    '            <th>{{ "Application rate" | i18next }} (in/hr)</th>\n' +
    '            <th>{{ "Runtime" | i18next }} (hr)</th>\n' +
    '        </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '        <tbody class="block-data" ng-repeat-start=\'block in ctrl.blocks\'>\n' +
    '            <tr class="block-row" ng-click="expandSensors($event)">\n' +
    '                <td>{{ block.name }}</td>\n' +
    '                <td>{{ block.irrigationStats.sensors_used }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.size_acres }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.soil_type[0] | noDashes | titleCase }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.wilting_point | percentage : 0 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.field_capacity | percentage : 0 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.refill_point | percentage : 0 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.vwc | percentage : 0 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.refill_water | number : 2 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.available_water | number : 2 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.current_water | number : 2 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.et_forecast | number : 2 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.total_water_needed | number : 2 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.total_acre_in_needed | number : 1 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.total_gallons_needed | number : 0 }}</td>\n' +
    '                <td>{{ block.irrigationStats.aggregate.application_rate | number : 4 }}</td>\n' +
    '                <td class="runtime">{{ block.irrigationStats.aggregate.runtime_needed | number : 1 }}</td>\n' +
    '            </tr>\n' +
    '        </tbody>\n' +
    '        <tbody class="sensor-data hide" ng-repeat-end>\n' +
    '            <tr class="sensor-row" ng-repeat=\'sensor in block.irrigationStats.sensor_stats\'>\n' +
    '                <td></td>\n' +
    '                <td>{{ sensor.name }}</td>\n' +
    '                <td>{{ sensor.size_acres }}</td>\n' +
    '                <td>{{ sensor.soil_type | noDashes | titleCase }}</td>\n' +
    '                <td>{{ sensor.wilting_point | percentage : 0 }}</td>\n' +
    '                <td>{{ sensor.field_capacity | percentage : 0 }}</td>\n' +
    '                <td>{{ sensor.refill_point | percentage : 0 }}</td>\n' +
    '                <td>{{ sensor.vwc | percentage : 0 }}</td>\n' +
    '                <td>{{ sensor.refill_water | number : 2 }}</td>\n' +
    '                <td>{{ sensor.available_water | number : 2 }}</td>\n' +
    '                <td>{{ sensor.current_water | number : 2 }}</td>\n' +
    '                <td>{{ sensor.et_forecast | number : 2 }}</td>\n' +
    '                <td>{{ sensor.total_water_needed | number : 2 }}</td>\n' +
    '                <td>{{ sensor.total_acre_in_needed | number : 1 }}</td>\n' +
    '                <td>{{ sensor.total_gallons_needed | number : 0 }}</td>\n' +
    '                <td>{{ sensor.application_rate | number : 4 }}</td>\n' +
    '                <td class="runtime">{{ sensor.runtime_needed | number : 1 }}</td>\n' +
    '            </tr>\n' +
    '        </tbody>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

// Source: dataExplorer/dataExplorer.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dataExplorer/dataExplorer.template.html',
    '<md-content id="dataExplorer" md-component-id="dataExplorer" layout="column" layout-align="start stretch" class="layout-fill" ng-class="{ mobile: ctrl.height < 800 }" ng-cloak>\n' +
    '    <div class="graph-title-area" layout="row" layout-align="space-between center">\n' +
    '      <div>\n' +
    '        <button type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.dateBackward()">\n' +
    '          <i class="fa fa-backward"></i>\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.datePrev()">\n' +
    '          <i class="fa fa-caret-left" style="font-size: 20px"></i>\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.changeFilter()">\n' +
    '          <span ng-if="ctrl.width >= 768">&nbsp;{{ ctrl.state.dateRange.startDate.format(\'DD MMMM YYYY\') }} - {{ ctrl.state.dateRange.endDate.format(\'DD MMMM YYYY\') }}</span>\n' +
    '          <span ng-if="ctrl.width < 768">&nbsp;{{ ctrl.state.dateRange.startDate.format(\'MM DD YY\') }} - {{ ctrl.state.dateRange.endDate.format(\'MM DD YY\') }}</span>\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.dateNext()">\n' +
    '          <i class="fa fa-caret-right" style="font-size: 20px"></i>\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.dateForward()">\n' +
    '          <i class="fa fa-forward"></i>\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.setDateWeek(1)">\n' +
    '          <span>1 {{ "WK" | i18next }}</span>\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.setDateWeek(2)">\n' +
    '          <span>2 {{ "WK" | i18next }}</span>\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-default" ng-class="{ \'btn-xs\': ctrl.width < 500 }" ng-click="ctrl.setDateWeek(4)">\n' +
    '          <span>1 {{ "MO" | i18next }}</span>\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="graph-area" flex="100" layout="column" layout-align="start stretch" style="background-color: #d9dfe9">\n' +
    '      <div class="hrow border-bottom time-line-top"\n' +
    '        layout="row" layout-align="space-between stretch">\n' +
    '\n' +
    '        <div class="time-line" ng-class="{ mobile: ctrl.width <= 415 }" flex="100" layout="row" layout-align="start stretch">\n' +
    '          <div class="time-line-item" style="width: {{ (date.size / ctrl.state.dateRange.shiftBy.number) * 100 }}%"\n' +
    '            layout="column" layout-align="center center" ng-repeat="date in ctrl.state.dateRange.timeRanges">\n' +
    '            <span ng-if="ctrl.getWidth() / ctrl.state.dateRange.timeRanges.length >= 50">{{ date.date.format(\'ddd D\')}}</span>\n' +
    '            <span ng-if="ctrl.getWidth() / ctrl.state.dateRange.timeRanges.length < 50">{{ date.date.format(\'D\')}}</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="charts-block">\n' +
    '        <div class="charts-area">\n' +
    '          <farmx-satellite-browser date-range="ctrl.state.dateRange" block="ctrl.state.block" ranch="ctrl.state.ranch"></farmx-satellite-browser>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: irrigationSchedule/irrigationSchedule.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('irrigationSchedule/irrigationSchedule.html',
    '<div id="{{tab.id}}-irrigation-schedule" style="background-color: #FFFFFF;"" ng-if="ctrl.showIrrigation" \n' +
    '  class="margin-bottom-5px"\n' +
    '  layout="row" layout-align="start stretch" \n' +
    '  ng-if="tab.showIrrigationSchedule">\n' +
    '  <div style="width: {{ ctrl.yAxisSpace(tab) }}px; height: {{ 115 * tab.blocks.length }}px;"\n' +
    '    class="scheduler-label-areas" \n' +
    '    layout="column">\n' +
    '    <div class="scheduler-label-area srow" \n' +
    '      ng-repeat="block in tab.ranch.blocks" layout="column" layout-align="start stretch">\n' +
    '      <div class="scheduler-block-label-area" style="width: {{ ctrl.totalSize() }}px;">\n' +
    '        {{ "Irrigation Schedule" | i18next }} - {{ block.name }}\n' +
    '      </div>\n' +
    '      <div class="ssrow border-right"\n' +
    '        style="width: {{ ctrl.yAxisSpace(tab) }}px"\n' +
    '        layout="row" layout-align="end stretch">\n' +
    '        <span>farmX</span>\n' +
    '      </div>\n' +
    '      <div class="ssrow border-right" \n' +
    '        style="width: {{ ctrl.yAxisSpace(tab) }}px"\n' +
    '        layout="row" layout-align="end stretch">\n' +
    '        <span ng-i18next>Planned</span>\n' +
    '      </div>\n' +
    '      <div class="ssrow border-right" \n' +
    '        style="width: {{ ctrl.yAxisSpace(tab) }}px"\n' +
    '        layout="row" layout-align="end stretch">\n' +
    '        <span ng-i18next>Actual</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="scheduler-data-areas" \n' +
    '    flex="100" layout="column" layout-align="start stretch">\n' +
    '    <div style="width: 100%"\n' +
    '      class="scheduler-data-area-block" \n' +
    '      layout="column" layout-align="start stretch">\n' +
    '      <div id="scheduler-{{ block.id }}" \n' +
    '        data-block-id="{{ block.id }}"\n' +
    '        class="scheduler-data-element srow" layout="column" \n' +
    '        layout-align="start stretch" \n' +
    '        ng-repeat="block in tab.ranch.blocks">\n' +
    '        <div class="scheduler-block-data-blank-area">\n' +
    '          <!-- Blank Space -->\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="scheduler-data-area" flex="100" layout="column" layout-align="start stretch">\n' +
    '          <div class="scheduler-data-area-highlighters">\n' +
    '            <div class="scheduler-data-area-highlighter" flex="100" layout="row" layout-align="start stretch">\n' +
    '              <div style="width: calc(100% / {{ tab.dateRange.timeRanges.length }})" \n' +
    '                ng-repeat="date in tab.dateRange.timeRanges">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div id="scheduler-{{ block.id }}-recommended-data-area" class="scheduler-block-recommended-data-area" layout="row">\n' +
    '            <div id="scheduler-{{ schedule.id }}-recommended-schedule-area"\n' +
    '              class="scheduler-block-recommended-schedule-area"\n' +
    '              style="left: {{ schedule.leftPos }}px; width: {{ schedule.width }}px; position: absolute;" \n' +
    '              layout-fill layout="row" layout-align="start stretch" \n' +
    '              ng-repeat="schedule in ctrl.getRecommendedEventsForBlock(tab, block)">\n' +
    '              <div class="schedule-handle-left"></div>\n' +
    '              <div class="schedule" \n' +
    '                flex="100" \n' +
    '                data-toggle="tooltip" data-placement="right" \n' +
    '                title="{{ schedule.start.format(\'MM/DD hh:mmA\') }} - {{ schedule.end.format(\'MM/DD hh:mmA\') }}"></div>\n' +
    '              <div class="schedule-handle-right"></div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div id="scheduler-{{ block.id }}-planned-data-area" class="scheduler-block-planned-data-area" layout="row">\n' +
    '            <div id="scheduler-{{ schedule.id }}-planned-schedule-area"\n' +
    '              class="scheduler-block-planned-schedule-area"\n' +
    '              style="left: {{ schedule.planned.leftPos }}px; width: {{ schedule.planned.width }}px; position: absolute; font-size: {{ schedule.planned.fontSize }}px" \n' +
    '              layout-fill layout="row" layout-align="start stretch"\n' +
    '              ng-if="schedule.start !== undefined"\n' +
    '              data-schedule-id="{{ schedule.id }}"\n' +
    '              ng-repeat="schedule in ctrl.getScheduledEventsForBlock(tab, block)">\n' +
    '              <div class="schedule-handle-left" layout="column" layout-align="center center">\n' +
    '                <i class="fa fa-ellipsis-v"></i>\n' +
    '              </div>\n' +
    '              <div class="schedule" \n' +
    '                flex="100" layout="column" layout-align="center center"\n' +
    '                data-schedule="{{ schedule }}">\n' +
    '                  <div layout="row" layout-align="center center" >\n' +
    '                    <span ng-if="schedule.planned.showDate">{{ schedule.planned.start.format(\'MM/DD\') }}</span>\n' +
    '                    <span>&nbsp;{{ schedule.planned.start.format(\'hh:mmA\') }}</span>\n' +
    '                  </div>\n' +
    '                  <div layout="row" layout-align="center center">\n' +
    '                    <span ng-if="schedule.planned.showDate">{{ schedule.planned.end.format(\'MM/DD \') }}</span>\n' +
    '                    <span>&nbsp;{{ schedule.planned.end.format(\'hh:mmA \') }}</span>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              <div class="schedule-handle-right" layout="column" layout-align="center center">\n' +
    '                <i class="fa fa-ellipsis-v"></i>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div id="scheduler-{{ block.id }}-actual-data-area" class="scheduler-block-actual-data-area" layout="row">\n' +
    '            <div id="scheduler-{{ schedule.id }}-actual-schedule-area"\n' +
    '              class="scheduler-block-actual-schedule-area"\n' +
    '              style="left: {{ schedule.actual.leftPos }}px; width: {{ schedule.actual.width }}px; position: absolute;" \n' +
    '              layout-fill layout="row" layout-align="start stretch" \n' +
    '              ng-if="schedule.actual.start !== undefined"\n' +
    '              ng-repeat="schedule in ctrl.getPastEventsForBlock(tab, block)">\n' +
    '              <div class="schedule-handle-left"></div>\n' +
    '              <div class="schedule" \n' +
    '                flex="100" \n' +
    '                data-toggle="tooltip" data-placement="right" \n' +
    '                title="{{ schedule.actual.start.format(\'MM/DD hh:mmA\') }} - {{ schedule.actual.end.format(\'MM/DD hh:mmA\') }}"></div>\n' +
    '              <div class="schedule-handle-right"></div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div style="min-width: 12px; max-width: 12px; border-right: 1px solid #CCC;">\n' +
    '    <!-- Blank Space to fit the chart yAxis -->\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: map/map.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('map/map.template.html',
    '<leaflet event-broadcast="events" defaults="mapDefaults" controls="mapControls" layers="mapLayers" map-id="mapId" layout-fill></leaflet>\n' +
    '');
}]);
})();

// Source: ngHierarchicalSelector/hierarchical-selector.tpl.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('ngHierarchicalSelector/hierarchical-selector.tpl.html',
    '<div class="hierarchical-control">\n' +
    '  <div class="control-group">\n' +
    '    <button type="button" ng-if="showButton" class="pull-down" ng-click="onButtonClicked($event)">\n' +
    '      <div class="arrow-down"></div>\n' +
    '    </button>\n' +
    '    <div class="hierarchical-input form-control" ng-class="{\'with-btn\': showButton}" ng-click="onControlClicked($event)">\n' +
    '      <div ng-if="selectedItems.length == 0" class="selected-items">\n' +
    '        <span>{{placeholder}}</span>\n' +
    '      </div>\n' +
    '      <div ng-if="selectedItems.length > 0" class="selected-items">\n' +
    '        <span class="selected-item">{{getSelectedItemsString()}}</span>\n' +
    '      </div>\n' +
    '      <span class="select-icon"></span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="tree-view-outer" ng-show="showTree" layout="column" layout-align="start stretch">\n' +
    '  <div class="tree-view" ng-show="showTree">\n' +
    '    <ul>\n' +
    '      <tree-item class="top-level" ng-repeat="item in data track by $index" item="item" select-only-leafs="selectOnlyLeafs" use-can-select-item="useCanSelectItemCallback" can-select-item="canSelectItem" multi-select="multiSelect" item-selected="itemSelected(item)" on-active-item="onActiveItem(item)" load-child-items="loadChildItems" async="isAsync" item-has-children="hasChildren(parent)" async-child-cache="asyncChildCache" />\n' +
    '    </ul>\n' +
    '  </div>\n' +
    '  <div class="tree-view-bottom" layout="row" layout-align="end stretch" style="height: 88px; border-top: 1px solid #c4c4c4">\n' +
    '    <md-button ng-click="close()" ng-i18next>Done</md-button>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: ngHierarchicalSelector/tree-item.tpl.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('ngHierarchicalSelector/tree-item.tpl.html',
    '<li>\n' +
    '  <div class="item-container" ng-class="{active: metaData.isActive, selected: metaData.selected}" ng-mouseover="onMouseOver($event)" ng-click="clickSelectItem(item, $event)">\n' +
    '    <span ng-if="showExpando(item)" class="expando" ng-class="{\'expando-opened\': metaData.isExpanded}" ng-click="onExpandoClicked(item, $event)"></span>\n' +
    '    <span ng-if="showExpando(item) === false" style="padding: 9px 9px" />\n' +
    '    <div class="item-details">\n' +
    '      <span class="tree-checkbox" ng-if="item.children.length > 0" style="">\n' +
    '        <i class="fa fa-square-o" style="font-size: 14px; line-height: 14px; min-height: 14px" ng-if="noChildrenSelected(item, metaData)" />\n' +
    '        <i class="fa fa-minus-square-o" style="font-size: 14px; line-height: 14px; min-height: 14px" ng-if="someChildrenSelected(item, metaData)" />\n' +
    '        <i class="fa fa-check-square-o" style="font-size: 14px; line-height: 14px; min-height: 14px" ng-if="allChildrenSelected(item, metaData)" />\n' +
    '      </span>\n' +
    '      <span class="tree-checkbox" ng-if="item.children == null || item.children.length === 0" style="margin-right: 8px">\n' +
    '        <i class="fa fa-check-square-o" style="font-size: 14px; line-height: 14px; min-height: 14px" ng-if="isSelected(item, metaData)" />\n' +
    '        <i class="fa fa-square-o" style="font-size: 14px; line-height: 14px; min-height: 14px" ng-if="isNotSelected(item, metaData)" />\n' +
    '      </span>\n' +
    '      <span>{{item.name}}</span>\n' +
    '  </div>\n' +
    '\n' +
    '  <ul ng-repeat="child in theChildren" ng-if="metaData.isExpanded">\n' +
    '    <div ng-if="child.placeholder" class="loading" ng-i18next>Loading...</div>\n' +
    '    <tree-item ng-if="!child.placeholder" item="child" item-selected="subItemSelected(item)" select-only-leafs="selectOnlyLeafs" use-can-select-item="useCanSelectItem" can-select-item="canSelectItem" multi-select="multiSelect" on-active-item="activeSubItem(item, $event)" load-child-items="loadChildItems" async="async" async-child-cache="asyncChildCache" />\n' +
    '  </ul>\n' +
    '</li>\n' +
    '');
}]);
})();

// Source: satelliteBrowser/anomalyBrowser.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('satelliteBrowser/anomalyBrowser.template.html',
    '<div>\n' +
    '    <md-button class="md-raised" ng-if="!ctrl.editing && !ctrl.creating && selected.type == \'Block\'" ng-click="ctrl.createAnomaly()">New</md-button>\n' +
    '    <md-input-container ng-if="!ctrl.editing && !ctrl.creating">\n' +
    '        <label ng-i18next>Select Anomaly</label>\n' +
    '        <md-select class="anomaly-select" ng-model="ctrl.selectedAnomaly">\n' +
    '            <md-option ng-if="ctrl.getAnomalyList() === undefined"><em ng-i18next>Loading</em></md-option>\n' +
    '            <md-option ng-repeat="anomaly in ctrl.getAnomalyList()" ng-value="anomaly"><em>{{ anomaly.name }} - {{ anomaly.anomaly_type }}</em></md-option>\n' +
    '        </md-select>\n' +
    '    </md-input-container>\n' +
    '    <md-button class="md-raised" ng-if="ctrl.selectedAnomaly != null && ctrl.editing == false" ng-click="ctrl.editAnomaly()" ng-i18next>Edit</md-button>\n' +
    '    <md-button class="md-raised" ng-if="ctrl.editing" ng-click="ctrl.cancelEditing()" ng-i18next>Cancel</md-button>\n' +
    '    <md-button class="md-raised" ng-if="ctrl.editing || ctrl.creating" ng-click="ctrl.finishEditing()" ng-i18next>Done</md-button>\n' +
    '    <md-button class="md-raised" ng-if="ctrl.selectedAnomaly != null" ng-click="ctrl.toggleZoom()" ng-i18next>Zoom</md-button>\n' +
    '    <md-button class="md-raised navigate-button" ng-if="ctrl.selectedAnomaly != null" ng-click="ctrl.openDirections()">{{ "Navigate" | i18next }} <i class="fa fa-crosshairs" aria-hidden="true"></i>\n' +
    '    </md-button>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: satelliteBrowser/satelliteBrowser.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('satelliteBrowser/satelliteBrowser.template.html',
    '<div class="satellite-nav-cont" ng-class="ctrl.width > 768 ? \'nav-cont-large\' : \'nav-cont-small\'">\n' +
    '    <farmx-date-navigator state="ctrl.state"></farmx-date-navigator>\n' +
    '    <div class="satellite-controls">\n' +
    '        <span>{{ "Imagery Type" | i18next }}: </span>\n' +
    '        <select class="select" ng-init="dataType = ctrl.dataTypes[0].code" ng-model="ctrl.state.dataType" ng-options="option.code as option.name for option in ctrl.dataTypes">\n' +
    '        </select>\n' +
    '        <!--<md-select class="select" ng-init="dataType = ctrl.dataTypes[0].code" ng-model="dataType">\n' +
    '            <md-option ng-value"option.code" ng-repeat="option in ctrl.dataTypes">{{ option.name }}</md-option>\n' +
    '        </md-select>-->\n' +
    '        <md-button class="satellite-toggle" ng-click="ctrl.toggleSatellite()">{{ ctrl.visible ? "Hide Imagery" : "Show Imagery" | i18next }}</md-button>\n' +
    '    </div>\n' +
    '    <farmx-satellite-picker class="satellite-imagery-picker" state="ctrl.state"></farmx-satellite-picker>\n' +
    '</div>');
}]);
})();

// Source: satelliteBrowser/satelliteCompare.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('satelliteBrowser/satelliteCompare.template.html',
    '<div class="satellite-cont">\n' +
    '    <div class="satellite-viewer" ng-hide="ctrl.width < 1400 && !ctrl.first">\n' +
    '        <farmx-satellite-browser class="satellite-compare-browser" selected="selected" satelliteid="map1"></farmx-satellite-browser>\n' +
    '        <farmx-satellite-map-view class="satellite-compare-map-cont" selected="selected" satelliteid="map1"></farmx-satellite-map-view>\n' +
    '    </div>\n' +
    '    <div class="satellite-compare-divider" ng-hide="ctrl.width < 1400"></div>\n' +
    '    <div class="satellite-viewer" ng-hide="ctrl.width < 1400 && ctrl.first">\n' +
    '        <farmx-satellite-browser class="satellite-compare-browser" selected="selected" satelliteid="map2"></farmx-satellite-browser>\n' +
    '        <farmx-satellite-map-view class="satellite-compare-map-cont" selected="selected" satelliteid="map2"></farmx-satellite-map-view>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<farmx-anomaly-browser selected="selected" ng-i18next>Anomaly</farmx-anomaly-browser>\n' +
    '<md-button\n' +
    '    ng-click="ctrl.toggleTab()"\n' +
    '    ng-class="{ \'active\': ctrl.first, \'disable\': !ctrl.first}" ng-if="ctrl.width < 1400" ng-i18next>View other tab</md-button>\n' +
    '');
}]);
})();

// Source: satelliteBrowser/satelliteMap.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('satelliteBrowser/satelliteMap.template.html',
    '<leaflet event-broadcast="events" defaults="mapDefaults" controls="mapControls" layers="mapLayers" map-id="mapId" layout-fill></leaflet>\n' +
    '<div class="yield-card" ng-if="ctrl.yieldData">\n' +
    '    <div class=\'yield-title\'>{{ "Predicted Yield" | i18next }}:</div>\n' +
    '    <div>\n' +
    '        <span class=\'yield-data\' ng-style="ctrl.yieldData.yieldStyle">{{ ctrl.yieldData.yieldPrediction }}</span><span class="yield-units"> {{ "tons/acre" | i18next }}</span>\n' +
    '    </div>\n' +
    '    <div ng-if="ctrl.yieldData.yieldActual" class=\'yield-actual\'>\n' +
    '        <span class=\'yield-actual-title\'>{{ "Actual Yield" | i18next }}: </span><span class=\'yield-actual-data\' ng-style="ctrl.yieldData.yieldActualStyle">{{ ctrl.yieldData.yieldActual }}</span>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: satelliteBrowser/satellitePicker.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('satelliteBrowser/satellitePicker.template.html',
    '<div class="satellite-imagery-picker-item" ng-class="{ current: image.selected }" ng-repeat="image in ctrl.imagery" ng-click="ctrl.itemClicked(image)">\n' +
    '    <div class="satellite-imagery-title">{{ ctrl.getTitle(image) }}</div>\n' +
    '    <div class="satellite-imagery-thumb">\n' +
    '        <img ng-if="image.url" src="{{ image.url }}" alt="ctrl.getAlt(image)">\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

// Source: satelliteViewer/satelliteCompare.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('satelliteViewer/satelliteCompare.template.html',
    '<div class="satellite-cont">\n' +
    '    <div class="satellite-viewer" ng-hide="ctrl.width < 1400 && !ctrl.first">\n' +
    '      <farmx-satellite-viewer selected="ctrl.getSelected()" satelliteid="map1"></farmx-satellite-viewer>\n' +
    '      <farmx-satellite-map-view class="satellite-compare-map-cont" selected="selected" satelliteid="map1"></farmx-satellite-map-view>\n' +
    '      <farmx-satellite-viewer-selector></farmx-satellite-viewer-selector>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="satellite-compare-divider" ng-hide="ctrl.width < 1400"></div>\n' +
    '\n' +
    '    <div class="satellite-viewer" ng-hide="ctrl.width < 1400 && ctrl.first">\n' +
    '        <farmx-satellite-browser class="satellite-compare-browser" selected="selected" satelliteid="map2"></farmx-satellite-browser>\n' +
    '        <farmx-satellite-map-view class="satellite-compare-map-cont" selected="selected" satelliteid="map2"></farmx-satellite-map-view>\n' +
    '        <farmx-satellite-plotter style="padding: 0px 2px" state="ctrl.state"></farmx-satellite-picker>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: satelliteViewer/satellitePlotter.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('satelliteViewer/satellitePlotter.template.html',
    '<div class=\'satellite-plotter-container\'>\n' +
    '    <div flex="20" ng-repeat="image in ctrl.imagery" ng-click="ctrl.itemSelected(image)" ng-class="{ \'farmx-satellite-plotter-selected\' : image.selected, \'farmx-satellite-plotter-item\' : image.selected === false }">\n' +
    '        <div class="farmx-satellite-plotter-header">{{ ctrl.getTitle(image) }}</div>\n' +
    '        <div class="farmx-satellite-plotter-footer" ng-class="ctrl.getImageClass(image)"></div>\n' +
    '        <div class="farmx-satellite-plotter-image-block">\n' +
    '            <img class="farmx-satellite-plotter-image" ng-if="image.url" src="{{ image.url }}" alt="ctrl.getTitle(image)">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="farmx-imagery-key">\n' +
    '    <img class="farmx-imagery-key-image" ng-if="ctrl.dataKey" src="{{ ctrl.dataKey.url }}" alt="{{ \'Data Key\' | i18next }}">\n' +
    '    <div class="farmx-imagery-key-labels">\n' +
    '        <div class="farmx-imagery-key-min" ng-if="ctrl.dataKey">{{ ctrl.dataKey.minValue }} ({{ "low" | i18next }})</div>\n' +
    '        <div class="farmx-imagery-key-mid" ng-if="ctrl.dataKey">{{ (ctrl.dataKey.minValue + ctrl.dataKey.maxValue) / 2 }}</div>\n' +
    '        <div class="farmx-imagery-key-max" ng-if="ctrl.dataKey">{{ ctrl.dataKey.maxValue }} ({{ "high" | i18next }})</div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: satelliteViewer/satelliteViewer.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('satelliteViewer/satelliteViewer.template.html',
    '<div>\n' +
    '  <farmx-date-navigator state="ctrl.state"></farmx-date-navigator>\n' +
    '\n' +
    '  <farmx-satellite-plotter style="padding: 0px 2px" state="ctrl.state"></farmx-satellite-plotter>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: satelliteViewer/satelliteViewerSelector.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('satelliteViewer/satelliteViewerSelector.template.html',
    '<div layout="row" layout-align="start center" style="border-top: 1px solid #e2e2e2">\n' +
    '  <md-select aria-label="dataType" md-container-class="satelliteViewerDataTypeSelect" ng-model="dataType" md-selected-text="dataType.title | i18next" class="md-no-underline" style="text-align: right; margin: 0px 0px; font-size: 12px" flex="100" >\n' +
    '    <md-option style="font-size: 12px; height: 32px"  ng-value="dataType" ng-repeat="dataType in ctrl.$$dataTypes">{{ dataType.label | i18next }}</md-option>\n' +
    '  </md-select>\n' +
    '  <md-select aria-label="dataSource" md-container-class="satelliteViewerDataSourceSelect" ng-model="dataSource" class="md-no-underline" style="text-align: right; margin: 0px 0px; width: 120px; font-size: 12px" multiple>\n' +
    '    <md-option style="font-size: 12px; height: 32px"  ng-value="dataSource" ng-repeat="dataSource in ctrl.$$dataSources">{{ dataSource.label | i18next }}</md-option>\n' +
    '  </md-select>\n' +
    '  <md-button aria-label="showImagery" style="margin: 0px 0px; font-size: 12px; text-transform: capitalize; min-height: 30px; height: 30px; line-height: 30px; font-weight: normal; border-radius: 0px" ng-click="ctrl.toggleShow()">\n' +
    '    <div>\n' +
    '      <span ng-if="visible === false" ng-i18next>Show</span>\n' +
    '      <i class="fa fa-eye" ng-if="visible === false"></i>\n' +
    '\n' +
    '      <span ng-if="visible" ng-i18next>Hide</span>\n' +
    '      <i class="fa fa-eye-slash" ng-if="visible"></i>\n' +
    '    </div>\n' +
    '  </md-button>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: schedule/schedule.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('schedule/schedule.template.html',
    '<div class="schedule-block">\n' +
    '  <div class="left-panel">\n' +
    '    <div class="schedule-block-name">\n' +
    '      <span>{{ ctrl.block.name }}</span>\n' +
    '      <button class="btn schedule-block-add-button btn-default" ng-click="ctrl.addEvent({\'data\': { \'blockId\': ctrl.block.id }})">\n' +
    '        <span ng-i18next>Add</span>\n' +
    '      </button>\n' +
    '      <div ng-if="ctrl.selectDepth">\n' +
    '        <span class="depth-label">{{ "Irrigate to depth" | i18next }}: </span>\n' +
    '        <select ng-options="option.name for option in ctrl.depthOptions track by option.id" ng-model="ctrl.selectedDepth"></select>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div id="block-{{ block.id }}-schedule-area" class="schedule-block-data">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="right-panel">\n' +
    '    <div class="schedule-stat">\n' +
    '      <div class="schedule-stat-header">\n' +
    '        <span ng-i18next>Total Hours</span>\n' +
    '      </div>\n' +
    '      <div class="schedule-stat-body">\n' +
    '        <div class="schedule-stat-info">{{ ctrl.block.schedule_stats.recommended_stats.period.total_hours.toFixed(1) }}</div>\n' +
    '        <div class="schedule-stat-info">{{ ctrl.block.schedule_stats.scheduled_stats.period.total_hours.toFixed(1) }}</div>\n' +
    '        <div class="schedule-stat-info">{{ ctrl.block.schedule_stats.past_stats.period.total_hours.toFixed(1) }}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="schedule-stat">\n' +
    '      <div class="schedule-stat-header">\n' +
    '        <span ng-i18next>Acre in.</span>\n' +
    '      </div>\n' +
    '      <div class="schedule-stat-body">\n' +
    '        <div class="schedule-stat-info">{{ ctrl.block.schedule_stats.recommended_stats.period.acre_in.toFixed(1) }}</div>\n' +
    '        <div class="schedule-stat-info">{{ ctrl.block.schedule_stats.scheduled_stats.period.acre_in.toFixed(1) }}</div>\n' +
    '        <div class="schedule-stat-info">{{ ctrl.block.schedule_stats.past_stats.period.acre_in.toFixed(1) }}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="schedule-stat">\n' +
    '      <div class="schedule-stat-header">\n' +
    '        <span>{{ "Acre in." | i18next }} ({{ "year" | i18next }})</span>\n' +
    '      </div>\n' +
    '      <div class="schedule-stat-body">\n' +
    '        <div class="schedule-stat-info">{{ ctrl.block.schedule_stats.recommended_stats.season.acre_in.toFixed(1) }}</div>\n' +
    '        <div class="schedule-stat-info">{{ ctrl.block.schedule_stats.scheduled_stats.season.acre_in.toFixed(1) }}</div>\n' +
    '        <div class="schedule-stat-info">{{ ctrl.block.schedule_stats.past_stats.season.acre_in.toFixed(1) }}</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: sidenavToggler/sidenavToggler.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('sidenavToggler/sidenavToggler.template.html',
    '<button type="button" class="btn btn-default sidenav-toggler sidenav-toggler-{{ ctrl.vposition }}-{{ ctrl.hposition }}" ng-click="ctrl.toggle()">\n' +
    '  <i class="fa fa-caret-{{ ctrl.open ? \'left\' : \'right\' }}"  aria-hidden="true"></i>\n' +
    '</button>');
}]);
})();

// Source: soilBrowser/soilBrowser.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('soilBrowser/soilBrowser.template.html',
    '<!--<div class="tooltip-target" tooltip-follow-cursor>-->\n' +
    '\n' +
    '<image class="aquacheck-sensor-data" ng-if="ctrl.imageSrc != null" src="{{ ctrl.imageSrc }}" ng-mousemove="ctrl.showDetails($event)" ng-mouseleave="ctrl.hideDetails($event)">\n' +
    '<div class="img-placeholder" ng-if="ctrl.imageSrc == null" ng-i18next>Loading...</div>\n' +
    '    <!--<span class="graph-tooltip">{{ ctrl.dataValue }}</span>\n' +
    '</div>-->\n' +
    '\n' +
    '<div class="soil-browser-label">\n' +
    '    <div class="soil-data-label">{{ ctrl.dataValue }}</div>\n' +
    '    <div class="soil-date-label">{{ ctrl.dataDate }}</div>\n' +
    '</div>\n' +
    '\n' +
    '<rzslider class="soil-slider" rz-slider-model="ctrl.cutoffMin" rz-slider-high="ctrl.cutoffMax" rz-slider-options="ctrl.sliderOptions"></rzslider>\n' +
    '\n' +
    '<div class="soil-cutoff-info" ng-if="ctrl.wiltingPoint && ctrl.saturationPoint">\n' +
    '        <md-button class="soil-popout-button" ng-click="ctrl.resetCutoffs()" ng-i18next>Reset cutoffs</md-button>\n' +
    '        <span>{{ "Wilting point" | i18next }}: <span class="data-value-label">{{ ctrl.wiltingPoint | number: 2 }}</span></span>\n' +
    '        <span>{{ "Saturation point" | i18next }}: <span class="data-value-label">{{ ctrl.saturationPoint | number: 2 }}</span></span>\n' +
    '</div>');
}]);
})();

// Source: threed/threed.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('threed/threed.template.html',
    '<div layout-fill style="background-color: #000">\n' +
    '  <div id="threed-container" layout-fill style="background: black">\n' +
    '    <script id="vertexShader" type="x-shader/x-vertex">\n' +
    '        varying vec2 vUv;\n' +
    '        varying float x;\n' +
    '        varying float y;\n' +
    '        varying float z;\n' +
    '        varying vec3 worldPos;\n' +
    '        varying vec3 fadeCenter;\n' +
    '        void main()\n' +
    '        {\n' +
    '            vUv = uv;\n' +
    '            vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );\n' +
    '            vec4 worldPosition = modelMatrix * vec4( position, 1.0 );\n' +
    '            worldPos = worldPosition.xyz;\n' +
    '            gl_Position = projectionMatrix * mvPosition;\n' +
    '            x = position.x;\n' +
    '            y = position.y;\n' +
    '            z = position.z;\n' +
    '        }\n' +
    '    </script>\n' +
    '    <script id="fragment_shader_test" type="x-shader/x-fragment">\n' +
    '    varying vec2 vUv;\n' +
    '    varying float x;\n' +
    '    varying float y;\n' +
    '\n' +
    '\n' +
    '    void main(void)\n' +
    '    {\n' +
    '        float red = 1.0;\n' +
    '        float green = 1.0;\n' +
    '        float blue = 0.0;\n' +
    '        gl_FragColor = vec4( red, green, blue, 1.0 );\n' +
    '    }\n' +
    '    </script>\n' +
    '    <script id="fragmentShaderTexture" type="x-shader/x-fragment">\n' +
    '        uniform sampler2D texture1;\n' +
    '        uniform float saturation;\n' +
    '        uniform float fade;\n' +
    '        uniform float scale;\n' +
    '        uniform float opacity;\n' +
    '        uniform vec3 fadeCenter;\n' +
    '        varying vec2 vUv;\n' +
    '        varying vec3 worldPos;\n' +
    '\n' +
    '        vec3 hsv2rgb(vec3 c)\n' +
    '        {\n' +
    '            vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);\n' +
    '            vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);\n' +
    '            return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);\n' +
    '        }\n' +
    '\n' +
    '        vec3 rgb2hsv(vec3 c)\n' +
    '        {\n' +
    '            vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);\n' +
    '            vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));\n' +
    '            vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));\n' +
    '\n' +
    '            float d = q.x - min(q.w, q.y);\n' +
    '            float e = 1.0e-10;\n' +
    '            return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);\n' +
    '        }\n' +
    '\n' +
    '        void main() {\n' +
    '            vec3 pos = fadeCenter;//vec3(5514, -12836, 0.);\n' +
    '            //pos.y = -fadeCenter.y;\n' +
    '            vec3 diff = worldPos - pos;\n' +
    '            float dist = length(diff);\n' +
    '            dist = distance(worldPos.xy, pos.xy);\n' +
    '            dist = dist / 3. / scale;\n' +
    '            dist = 0.7 - log(dist);\n' +
    '            vec4 color = texture2D(texture1, vUv);\n' +
    '            //color.b *= saturation;\n' +
    '            vec3 hsv = rgb2hsv(color.rgb);\n' +
    '            hsv.y *= saturation;\n' +
    '            gl_FragColor.rgb = hsv2rgb(hsv);\n' +
    '            gl_FragColor.a = opacity;\n' +
    '            if (fade > 0.5) gl_FragColor.a = dist * opacity;//2. * (0.5 - dist / 1.0);\n' +
    '        }\n' +
    '    </script>\n' +
    '    <script id="interpolate" type="x-shader/x-fragment">\n' +
    '      precision highp float;\n' +
    '      uniform sampler2D data;\n' +
    '      //uniform vec2 wh;\n' +
    '      uniform float power;\n' +
    '      uniform float time;\n' +
    '      uniform int sensors;\n' +
    '      uniform int times;\n' +
    '      varying float x;\n' +
    '      varying float y;\n' +
    '\n' +
    '      void main(void)\n' +
    '      {\n' +
    '        vec4 d = vec4(1.0);\n' +
    '        //vec2 t = texCoord * wh;\n' +
    '        vec2 t = vec2(x, y);\n' +
    '        float vsum = 0.0;\n' +
    '        float wsum = 0.0;\n' +
    '        float cutoff_dist = 0.6;\n' +
    '        float min_dist = cutoff_dist + 1.0;\n' +
    '        for (int i = 0; i < 3; ++i) //SIZE\n' +
    '        {\n' +
    '          // Interpolate time from center of first to center of last pixel\n' +
    '          float xi = 0.0;//(0.5 + time * float(times - 1)) / float(times);\n' +
    '          float yi = (float(i) +  0.5) / float(sensors); // (float(i) + 0.5) / float(sensors);\n' +
    '          d = texture2D(data, vec2(xi, yi));\n' +
    '          float dist = distance(t, d.xy);// * wh);\n' +
    '\n' +
    '          if (dist < min_dist) {\n' +
    '            min_dist = dist;\n' +
    '          }\n' +
    '          float weight = pow(dist, power);\n' +
    '          //if (weight < 0.1) weight = 0.0;\n' +
    '          vsum += weight * d.z;//* d.w;\n' +
    '          wsum += weight;\n' +
    '        }\n' +
    '        //if (vsum > 0.5) vsum = 0.0;\n' +
    '        if (min_dist >= cutoff_dist) {\n' +
    '            //vsum = 0.0;\n' +
    '        }\n' +
    '        float v = wsum == 0.0 ? 0.0 : vsum / wsum;\n' +
    '\n' +
    '        float a = 1.0-pow(.9*min_dist, 10.0);\n' +
    '\n' +
    '        gl_FragColor = vec4(1.0, v, 0.0, a); //vec4(v, v, d.z, d.w);\n' +
    '      }\n' +
    '    </script>\n' +
    '    <script id="interpolate-3d" type="x-shader/x-fragment">\n' +
    '      precision highp float;\n' +
    '      uniform sampler2D data;\n' +
    '      //uniform vec2 wh;\n' +
    '      uniform float power;\n' +
    '      uniform float time;\n' +
    '      uniform int sensors;\n' +
    '      uniform int times;\n' +
    '      varying float x;\n' +
    '      varying float y;\n' +
    '      varying float z;\n' +
    '      uniform float depth;\n' +
    '      varying vec2 vUv;\n' +
    '      uniform float minValue;\n' +
    '      uniform float maxValue;\n' +
    '\n' +
    '      vec3 hsv2rgb(vec3 c)\n' +
    '    {\n' +
    '        vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);\n' +
    '        vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);\n' +
    '        return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);\n' +
    '    }\n' +
    '\n' +
    '      void main(void)\n' +
    '      {\n' +
    '        vec4 d = vec4(1.0);\n' +
    '        //vec2 t = texCoord * wh;\n' +
    '        vec2 t = vec2(x, y);\n' +
    '        float vsum = 0.0;\n' +
    '        float wsum = 0.0;\n' +
    '        float cutoff_dist = 0.6;\n' +
    '        float min_dist = cutoff_dist + 1.0;\n' +
    '\n' +
    '        float depth_percent = 1.0 - (z / depth);\n' +
    '\n' +
    '        float nearest_val = 0.0;\n' +
    '        float max_weight = -1.0;\n' +
    '\n' +
    '        for (int i = 0; i < SENSOR_DATA_SIZE; ++i) //SIZE\n' +
    '        {\n' +
    '          if (i == sensors) break;\n' +
    '          // Interpolate time from center of first to center of last pixel\n' +
    '          float yi = (float(time) +  0.5) / float(times);//(0.5 + time * float(times - 1)) / float(times);\n' +
    '          float xi = (float(i) +  0.5) / float(sensors); // (float(i) + 0.5) / float(sensors);\n' +
    '          d = texture2D(data, vec2(xi, yi));\n' +
    '          float dist = distance(t, d.xy);// * wh);\n' +
    '\n' +
    '          float weight_z = 1.0 - abs(d.z-depth_percent);\n' +
    '\n' +
    '          if (dist < min_dist) {\n' +
    '            min_dist = dist;\n' +
    '            nearest_val = d.w;\n' +
    '          }\n' +
    '\n' +
    '          nearest_val = d.w;\n' +
    '          //dist = dist * dist2;\n' +
    '\n' +
    '          float weight = pow(dist, power);\n' +
    '          //if (dist > 0.5) weight = 0.0;\n' +
    '\n' +
    '          weight = weight_z * weight;\n' +
    '\n' +
    '          if (weight > max_weight) {\n' +
    '            max_weight = weight;\n' +
    '          }\n' +
    '\n' +
    '          //weight = weight * dist2;\n' +
    '          //if (weight < 0.1) weight = 0.0;\n' +
    '          vsum += weight * d.w;//* d.w;\n' +
    '          wsum += weight;\n' +
    '        }\n' +
    '\n' +
    '        //if (vsum > 0.5) vsum = 0.0;\n' +
    '        if (min_dist >= cutoff_dist) {\n' +
    '            //vsum = 0.0;\n' +
    '        }\n' +
    '        float v = wsum == 0.0 ? 0.0 : vsum / wsum;  // calculate v as quotient of sums\n' +
    '\n' +
    '        float a = 1.0-pow(.9*min_dist, 10.0);\n' +
    '\n' +
    '        // adjust the value to new range [0.3, 0.4]\n' +
    '        if (v < minValue) {\n' +
    '          float low = 0.0;\n' +
    '          float high = minValue;\n' +
    '          v = (v - low) / (high-low);\n' +
    '          // clamp to [0,1]\n' +
    '          if (v < 0.0) v = 0.0;\n' +
    '          if (v > 1.0) v = 1.0;\n' +
    '          vec3 hsv = vec3(0, 0.8, v);\n' +
    '          vec3 rgb = hsv2rgb(hsv);\n' +
    '          gl_FragColor.rgb = rgb;\n' +
    '        } else if (v > maxValue) {\n' +
    '          float low = maxValue;\n' +
    '          float high = 1.0;\n' +
    '          v = (v - low) / (high-low);\n' +
    '          // clamp to [0,1]\n' +
    '          if (v < 0.0) v = 0.0;\n' +
    '          if (v > 1.0) v = 1.0;\n' +
    '          vec3 hsv = vec3(v/3. + .333, 0.8, 1.);\n' +
    '          vec3 rgb = hsv2rgb(hsv);\n' +
    '          gl_FragColor.rgb = rgb;\n' +
    '        } else {\n' +
    '          float low = minValue;\n' +
    '          float high = maxValue;\n' +
    '          v = (v - low) / (high-low);\n' +
    '          // clamp to [0,1]\n' +
    '          if (v < 0.0) v = 0.0;\n' +
    '          if (v > 1.0) v = 1.0;\n' +
    '\n' +
    '          float hue = v;\n' +
    '          v = clamp(v, 0.0, 1.0);\n' +
    '          vec3 hsv = vec3(v/3., 0.8, 1.);\n' +
    '          vec3 rgb = hsv2rgb(hsv);\n' +
    '          gl_FragColor.rgb = rgb;\n' +
    '        }\n' +
    '    }\n' +
    '    </script>\n' +
    '    <div id="notificationText" class="layout-row layout-align-center-center layout-fill" style="color: white; font-size: 3em" ng-i18next>Loading 3D Data</div>\n' +
    '  </div>\n' +
    '  <div style="position: absolute;right: 0;top: 0;bottom: 0;background: rgba(0,0,0,0.2)" class="layout-column layout-align-start-stretch">\n' +
    '    <div layout="column" layout-align="start stretch">\n' +
    '      <button style="color: #FFFFFF" class="btn graph-btn btn-default" ng-click="ctrl.close()">\n' +
    '        <i class="fa fa-times"></i>\n' +
    '      </button>\n' +
    '      <button style="color: #FFFFFF" class="btn graph-btn btn-default" ng-click="ctrl.showSettings()">\n' +
    '        <i class="fa fa-cog"></i>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '    <span flex="100"></span>\n' +
    '    <div id="compass" layout="column" layout-align="center center" style="margin-bottom: 10px; height: 30px">\n' +
    '      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">\n' +
    '        <path id="svg_1" d="m8,0.5l-2.5,7l2.5,-1.55664l2.5,1.55664l-2.5,-7zm-2.5293,2.62109c-1.62195,0.84364 -2.76157,2.4726 -2.93554,4.37891l-2.03516,0l0,1l2.02539,0c0.23843,2.63491 2.3397,4.73618 4.97461,4.97461l0,2.02539l1,0l0,-2.02539c2.63491,-0.23843 4.73618,-2.3397 4.97461,-4.97461l2.02539,0l0,-1l-2.03516,0c-0.17397,-1.90631 -1.31359,-3.53527 -2.93554,-4.37891l0.58789,1.64649c0.849,0.819 1.38281,1.96142 1.38281,3.23242c0,2.481 -2.019,4.5 -4.5,4.5c-2.481,0 -4.5,-2.019 -4.5,-4.5c0,-1.271 0.53381,-2.41342 1.38281,-3.23242l0.58789,-1.64649z" fill="#FFF"></path>\n' +
    '        <text xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="4" id="svg_2" y="10.83984" x="6.5625" stroke-width="0" stroke="#000" fill="#FFF">N</text>\n' +
    '      </svg>\n' +
    '    </div>\n' +
    '    <div layout="column" layout-align="start stretch" style="position: absolute;top: 36px;right: 40px;width: 200px;color: white;border: 1px solid #FFF;padding: 5px;border-radius: 5px;background: rgba(0, 0, 0, 0.7)" ng-if="settingsEnabled">\n' +
    '      <div ng-i18next>Select Date Range</div>\n' +
    '      <div style="font-size: 12px; margin-left: 5px" ng-i18next>Start</div>\n' +
    '\n' +
    '      <md-datepicker id="startDatePicker" ng-model="startDate" ng-change="ctrl.onStartDateChange()"></md-datepicker>\n' +
    '      <div style="font-size: 12px; margin-left: 5px" ng-i18next>End</div>\n' +
    '      <md-datepicker id="endDatePicker" ng-model="endDate" ng-change="ctrl.onEndDateChange()"></md-datepicker>\n' +
    '\n' +
    '      <div style="height: 10px; width: 100%"></div>\n' +
    '\n' +
    '      <div layout="row" layout-align="start stretch">\n' +
    '        <div id="time-label" style="min-width: 70%">\n' +
    '          <div>{{ currentTime.format(\'MMMM Do, YYYY\') }}</div>\n' +
    '          <div>{{ currentTime.format(\'h:mm:ss a\') }}</div>\n' +
    '        </div>\n' +
    '        <span class="layout-fill">\n' +
    '        </span>\n' +
    '        <div style="width: 40px; height: 40px">\n' +
    '          <button style="color: #FFFFFF" class="btn graph-btn btn-default" ng-click="ctrl.pause()">\n' +
    '            <i ng-if="paused === false" class="fa fa-pause" aria-hidden="true"></i>\n' +
    '            <i ng-if="paused === true" class="fa fa-play" aria-hidden="true"></i>\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <rzslider id="time-position" class="time-position" rz-slider-model="timePosition.value" rz-slider-options="timePosition.options"></rzslider>\n' +
    '\n' +
    '      <div ng-i18next>Depth</div>\n' +
    '      <div style="margin: 0px 10px 0px 4px">\n' +
    '        <rzslider id="depth-slider" class="depth-slider" rz-slider-model="depth.value" rz-slider-options="depth.options"></rzslider>\n' +
    '      </div>\n' +
    '\n' +
    '      <div style="height: 10px; width: 100%"></div>\n' +
    '\n' +
    '      <div ng-i18next>Adjust Color Range</div>\n' +
    '      <div style="margin: 5px 15px 5px 15px; height: 56px">\n' +
    '        <div id="key-slider"></div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: graph/graph.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('graph/graph.template.html',
    '  <div id="{{chartGroup.id}}-chart-area"\n' +
    '    style="min-height: {{ chartGroup.height }}px; height: {{ chartGroup.height }}px;"\n' +
    '    class="farmx-graph-group"\n' +
    '    ng-repeat="chartGroup in config.chartGroups">\n' +
    '    <!-- Expanded -->\n' +
    '    <div class="farmx-graph-window"\n' +
    '      ng-if="chartGroup.expanded">\n' +
    '      <div\n' +
    '        style="height: {{ ctrl.graphHeight }}px; min-height: {{ ctrl.graphHeight }}px;"\n' +
    '        class="farmx-graph-highchart-div"\n' +
    '        ng-repeat="chartBlock in chartGroup.chart.ranch.blocks" >\n' +
    '        <highchart style="display: block"\n' +
    '          id="{{chartGroup.id}}"\n' +
    '          config="chartGroup.chart.generatedChartConfig[chartBlock.id]"\n' +
    '          ng-if="chartGroup.chart.generatedChartConfig[chartBlock.id].farmxDataLength > 0">\n' +
    '        </highchart>\n' +
    '        <div class="layout-fill" layout="row" layout-align="center center"\n' +
    '          ng-if="chartGroup.chart.generatedChartConfig[chartBlock.id].farmxDataLength === 0">\n' +
    '          <span style="font-size: 24px">{{ "No Data for" | i18next }} {{ chartBlock.name }}</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="farmx-graph-window"\n' +
    '      ng-if="chartGroup.expanded === false">\n' +
    '      <div\n' +
    '        style="height: {{ ctrl.graphHeight * chartGroup.heightFactor }}px; min-height: {{ ctrl.graphHeight * chartGroup.heightFactor }}px;"\n' +
    '        class="farmx-graph-highchart-div">\n' +
    '        <highchart style="display: block;"\n' +
    '          id="{{chartGroup.id}}"\n' +
    '          config="chartGroup.chart.generatedChartConfig"\n' +
    '          >\n' +
    '        </highchart>\n' +
    '        <div style="z-index: 50; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: transparent; height: {{ ctrl.graphHeight * chartGroup.heightFactor }}px" ng-if="chartGroup.loading === true">\n' +
    '          <div class="layout-fill" layout="row" layout-align="center center" style="z-index: 50">\n' +
    '            <span style="font-size: 24px;font-family: \'Helvetica Neue\';" ng-i18next>Loading Data</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="farmx-graph-group-controls">\n' +
    '      <div layout="row" layout-align="end center">\n' +
    '        <div uib-dropdown on-toggle="toggled(open)" ng-if="chartGroup.chart.generatedChartConfig.farmxDataLength > 0">\n' +
    '          <a href uib-dropdown-toggle>\n' +
    '            <i class="fa fa-bars" style="margin-top: 3px; margin-right: 5px"></i>\n' +
    '          </a>\n' +
    '          <ul class="dropdown-menu dropdown-menu-right" uib-dropdown-menu>\n' +
    '            <li scale="dropdown-item" ng-click="ctrl.scaleUpGraph(chartGroup)">\n' +
    '              <a ng-i18next>Zoom In</a>\n' +
    '            </li>\n' +
    '            <li scale="dropdown-item" ng-click="ctrl.scaleDownGraph(chartGroup)">\n' +
    '              <a ng-i18next>Zoom Out</a>\n' +
    '            </li>\n' +
    '            <li scale="dropdown-item" ng-click="ctrl.downloadCsv(chartGroup)">\n' +
    '              <a ng-i18next>Download</a>\n' +
    '            </li>\n' +
    '            <li class="dropdown-item" ng-click="ctrl.removeChartGroup(chartGroup)">\n' +
    '              <a ng-i18next>Close</a>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '        <div class="btn-group" ng-if="chartGroup.chart.generatedChartConfig.farmxDataLength === 0">\n' +
    '          <button class="btn graph-btn btn-default"\n' +
    '            data-toggle="dropdown"\n' +
    '            aria-haspopup="true"\n' +
    '            aria-expanded="false"\n' +
    '            ng-click="ctrl.removeChartGroup(chartGroup)">\n' +
    '            <i class="fa fa-times"></i>\n' +
    '          </button>\n' +
    '        </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div id="{{ config.id }}-add-graph"\n' +
    '    style="min-height: {{ ctrl.graphHeight }}px; height: {{ ctrl.graphHeight }}px; width: calc(100% - 10px); border: 1px dashed #000000; margin: 5px 5px"\n' +
    '    class="farmx-graph-add-graph"\n' +
    '    layout="row" layout-align="center center">\n' +
    '    <button type="button" style="min-height: {{ ctrl.graphHeight - 2 }}px; height: {{ ctrl.graphHeight - 2 }}px;" flex="100" class="btn btn-default" ng-click="ctrl.addChartGroup()">\n' +
    '      <span style="font-size: 40px; color: #CCCCCC" ng-i18next>Add Graph</span>\n' +
    '    </button>\n' +
    '  </div>\n' +
    '');
}]);
})();

// Source: accountSettings/accountSettings.template.js
(function(module) {
try {
  module = angular.module('farmx-templates');
} catch (e) {
  module = angular.module('farmx-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('accountSettings/accountSettings.template.html',
    '<md-dialog style="width: 50%; height: 50%" aria-label="Preferences">\n' +
    '  <md-toolbar style="min-height: 48px; max-height: 48px">\n' +
    '    <div class="md-toolbar-tools" style="min-height: 48px; max-height: 48px">\n' +
    '      <span flex></span>\n' +
    '      <md-button class="md-icon-button" style="font-size: 16px" ng-click="cancel()">\n' +
    '        <i class="fa fa-close"></i>\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '  </md-toolbar>\n' +
    '\n' +
    '  <md-dialog-content flex="100" layout="row" layout-align="start stretch">\n' +
    '    <div style="width: 200px; margin-left: 5px" layout="column" layout-align="start stretch">\n' +
    '      <div id="changePasswordBlock" data-selected="changePassword" layout="row" class="acBlock" ng-click="selected($event)">\n' +
    '        <span flex="100" class="acBlockSpan" ng-i18next>Change Password</span>\n' +
    '      </div>\n' +
    '      <div id="profileBlock" data-selected="profile" layout="row" class="acBlock" ng-click="selected($event)">\n' +
    '        <span flex="100" class="acBlockSpan acSelected" ng-i18next>Profile</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div layout="row" layout-align="start stretch" flex="100" style="border-left: 1px solid lightgray">\n' +
    '      <div style="width: 10px">\n' +
    '        <div style="height: 40px; width: 10px; margin-top: 5px">\n' +
    '          <div id="changePassword" tyle="height: 40px; width: 10px">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div style="height: 40px; width: 10px; margin-top: 5px">\n' +
    '          <div id="profile" tyle="height: 40px; width: 10px" class="acSelectedArrow">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div id="changePasswordPage" flex="100" layout="column" layout-align="start stretch" class="acHidePage">\n' +
    '        <div layout="row" layout-align="center center" flex="100">\n' +
    '          <form flex="75">\n' +
    '            <md-input-container class="md-block" style="margin-bottom: 0px">\n' +
    '              <label ng-i18next>New Password</label>\n' +
    '              <input ng-model="password.new_password" type="password">\n' +
    '            </md-input-container>\n' +
    '            <md-input-container class="md-block" style="margin-bottom: 0px">\n' +
    '              <label ng-i18next>Old Password</label>\n' +
    '              <input ng-model="password.old_password" type="password">\n' +
    '            </md-input-container>\n' +
    '          </form>\n' +
    '        </div>\n' +
    '        <div layout="row" layout-align="end stretch" style="width: 100%; height: 48px">\n' +
    '          <md-button class="md-raised" ng-click="saveChangePassword()" ng-i18next>Save</md-button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div id="profilePage" flex="100" layout="column" layout-align="start stretch" class="acShowPage">\n' +
    '        <div layout="row" layout-align="center center" flex="100">\n' +
    '          <form name="userForm" flex="75">\n' +
    '            <md-input-container class="md-block" style="margin-bottom: 0px">\n' +
    '              <label ng-i18next>Email Address</label>\n' +
    '              <input md-maxlength="30" ng-model="profile.email" type="email">\n' +
    '              <div ng-messages="profileForm.email.$error">\n' +
    '                <div ng-message="required" ng-i18next>Name is required.</div>\n' +
    '              </div>\n' +
    '            </md-input-container>\n' +
    '            <md-input-container class="md-block" style="margin-bottom: 0px">\n' +
    '              <label ng-i18next>Phone Number</label>\n' +
    '              <input name="phone_number" ng-model="profile.phone_number" ng-pattern="/^[+]1[0-9]{10}$/" />\n' +
    '              <div class="acPhoneNumberHint">+16501234567</div>\n' +
    '            </md-input-container>\n' +
    '            <md-input-container class="md-block" style="margin-bottom: 0px">\n' +
    '              <md-switch class="md-primary" name="special" ng-model="profile.email_notifications" required style="margin: 0px 0px">\n' +
    '                {{ "Enable Email Notifications" | i18next }}.\n' +
    '              </md-switch>\n' +
    '            </md-input-container>\n' +
    '            <md-input-container class="md-block" style="margin-bottom: 0px">\n' +
    '              <md-switch class="md-primary" name="special" ng-model="profile.text_notifications" required style="margin: 0px 0px">\n' +
    '                {{ "Enable Text Notifications" | i18next }}.\n' +
    '              </md-switch>\n' +
    '            </md-input-container>\n' +
    '          </form>\n' +
    '        </div>\n' +
    '        <div layout="row" layout-align="end stretch" style="width: 100%; height: 48px">\n' +
    '          <md-button class="md-raised" ng-click="saveProfile()" ng-i18next>Save</md-button>\n' +
    '          <md-button class="md-raised" ng-click="cancelProfile()" ng-i18next>Cancel</md-button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </md-dialog-content>\n' +
    '</md-dialog>\n' +
    '');
}]);
})();

})(window, document);
