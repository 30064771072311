try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXDataExplorerController.$inject = ["$window", "$rootScope", "$scope", "$timeout", "$farmXApi", "$farmXEntitiesCache", "$farmXSensorInfo", "$farmXDataExplorerService", "$farmXUtilities", "$log"];
FarmXDataExplorerService.$inject = ["$rootScope", "$mdDialog", "$farmXSensorInfo"];

angular
  .module('farmx-directives-sidenav')
  .directive('farmxDataExplorer', FarmXDataExplorerDirective)
  .controller('fzDataExplorerController', FarmXDataExplorerController)
  .service('$farmXDataExplorerService', FarmXDataExplorerService);


function FarmXDataExplorerDirective() {
  return {
    restrict: 'E',
    scope: {
      config:     '=config',
    },
    templateUrl: 'dataExplorer/dataExplorer.template.html',
    controller: 'fzDataExplorerController',
    controllerAs: 'ctrl',
    link: function link(scope, element, attrs, controller, transcludeFn) {
      controller.getWidth = function () {
        var styles = window.getComputedStyle(element[0]);
        var padding = parseFloat(styles.paddingLeft) +
                parseFloat(styles.paddingRight);
        return element[0].clientWidth - padding;
      };
    },
  };
}

function FarmXDataExplorerController($window, $rootScope, $scope, $timeout, $farmXApi, $farmXEntitiesCache, $farmXSensorInfo, $farmXDataExplorerService, $farmXUtilities, $log) {
  var ctrl = this;
  var scope = $scope;

  ctrl.visible = false;

  ctrl.changeFilter = _changeFilter;
  ctrl.dateBackward = _dateBackward;
  ctrl.datePrev = _datePrev;
  ctrl.dateNext = _dateNext;
  ctrl.dateForward = _dateForward;
  ctrl.setDateWeek = _setDateWeek;

  ctrl.entities = undefined;
  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;

  ctrl.state = {
    "dateRange": {
      "startDate": moment().subtract(27, 'days').hour(0).minute(0).second(0).millisecond(0),
      "endDate": moment().hour(23).minute(59).second(59).millisecond(999),
      "shiftBy": {
        "number": 28,
        "units": 'days'
      }
    },
    "block": null,
    "ranch": null,
    //"graphViewConfig": {},
  };

  angular.element($window).bind('resize', function() {
    scope.$apply(function () {
      ctrl.width = $window.innerWidth;
      ctrl.height = $window.innerHeight;
      $updateGraphWindow();
    });
  });

  function _hideSatellite() {
    $rootScope.$broadcast('farmx.satellite.deselected', {
      "type": "SatelliteData",
      "value": null
    });
  }

  $scope.$on('farmx.map.selected', function(event, selected) {
    _hideSatellite();
    if (selected.type == "Block")
      _selectBlock(angular.copy(selected.value[1]));
    else if (selected.type == "Ranch")
      _selectRanch(angular.copy(selected.value[0]));
    else
      _selectNone(null);
  });

  $scope.$on('farmx.sidenav.selected', function(event, selected) {
    $timeout(function() {
      _hideSatellite();
      if (selected.type == "Block")
        _selectBlock(selected.value[1]);
      else if (selected.type == "Ranch")
        _selectRanch(selected.value[0]);
      else
        _selectNone(null);
    }, 0);
  });

  function _selectBlock(selected) {
    ctrl.state.block = selected;
    ctrl.state.ranch = null;
    ctrl.visible = (selected !== null);
  }

  function _selectRanch(selected) {
    ctrl.state.ranch = selected;
    ctrl.state.block = null;
    ctrl.visible = (selected !== null);
  }

  function _selectNone(selected) {
    ctrl.visible = false;
    ctrl.state.block = null;
    ctrl.state.ranch = null;
  }

  function _changeFilter() {
    $farmXDataExplorerService.showDateFilter(ctrl.state).then(
      function(config) {
        ctrl.state = config;
        $updateGraphWindow();
        $rootScope.$broadcast("farmx.graph.config.changed", true);
      },
      function(error) {
      }
    );
  }

  function _setDateWeek(weeks) {
    var numDays = weeks * 7;
    ctrl.state.dateRange.endDate = moment().hour(23).minute(59).second(59).millisecond(999);
    ctrl.state.dateRange.startDate = moment().subtract(numDays-1, 'days').hour(0).minute(0).second(0).millisecond(0);
    ctrl.state.dateRange.shiftBy.number = numDays;
    $updateGraphWindow();
  }

  function _dateForward() {
    var config = ctrl.state;
    var now = moment();
    var startDate = moment(config.dateRange.startDate).add(1, 'years').hour(0).minute(0).second(0).millisecond(0);
    var endDate = moment(config.dateRange.endDate).add(1, 'years').hour(now.hour()).minute(now.minute()).second(now.second()-10).millisecond(0);

    if (endDate.isBefore(moment())) {
      config.dateRange.endDate = endDate;
      config.dateRange.startDate = startDate;

      ctrl.selectedIndex = null;
      $rootScope.$broadcast('farmx.satellite.selected', {
        "type": null,
        "value": null
      });
    }

    $updateGraphWindow();
  }

  function _dateNext() {
    var config = ctrl.state;
    var numDays = config.dateRange.endDate.diff(config.dateRange.startDate, 'days');
    var startDate = moment(config.dateRange.endDate).add(1, 'days').hour(0).minute(0).second(0).millisecond(0);
    var endDate = moment(startDate).add(numDays, 'days').hour(23).minute(59).second(59).millisecond(999);
    config.dateRange.endDate = endDate;
    config.dateRange.startDate = startDate;

    ctrl.selectedIndex = null;
    $rootScope.$broadcast('farmx.satellite.selected', {
      "type": null,
      "value": null
    });

    $updateGraphWindow();
  }

  function _datePrev() {
    var config = ctrl.state;
    var numDays = config.dateRange.endDate.diff(config.dateRange.startDate, 'days');
    var endDate = moment(config.dateRange.startDate).subtract(1, 'days').second(59).millisecond(999);
    var startDate = moment(endDate).subtract(numDays, 'days').hour(0).minute(0).second(0).millisecond(0);
    config.dateRange.endDate = endDate;
    config.dateRange.startDate = startDate;

    ctrl.selectedIndex = null;
    $rootScope.$broadcast('farmx.satellite.selected', {
      "type": null,
      "value": null
    });

    $updateGraphWindow();
  }

  function _dateBackward() {
    var config = ctrl.state;
    var startDate = moment(config.dateRange.startDate).subtract(1, 'years').hour(0).minute(0).second(0).millisecond(0);
    var endDate = moment(config.dateRange.endDate).subtract(1, 'years').hour(23).minute(59).second(59).millisecond(999);
    config.dateRange.endDate = endDate;
    config.dateRange.startDate = startDate;

    ctrl.selectedIndex = null;
    $rootScope.$broadcast('farmx.satellite.selected', {
      "type": null,
      "value": null
    });

    $updateGraphWindow();
  }

  function $updateGraphWindow() {
      var tab = ctrl.state;
      var width = ctrl.width - 108;
      var dateWidth = width / tab.dateRange.shiftBy.number;
      var startTime = tab.dateRange.startDate.clone();
      var skipCount = 1;

      tab.dateRange.timeRanges = [];

      while(startTime.isSameOrBefore(tab.dateRange.endDate)) {
        var newStartDate = startTime.clone();
        var dateCount = tab.dateRange.endDate.diff(newStartDate, 'days') + 1;

        if (dateCount < skipCount) {
          tab.dateRange.timeRanges.push(
            {
              "date": startTime.clone(),
              "size": dateCount
            }
          );
        } else {
          tab.dateRange.timeRanges.push(
            {
              "date": startTime.clone(),
              "size": skipCount
            }
          );
        }

        startTime.add({'days': skipCount });
      }
  }

  this.$onInit = function() {
    ctrl.entities = $farmXEntitiesCache.getEntities();

    if (ctrl.entities.length > 0) {
      var parentSelected = scope.$parent.ctrl.getSelected();
      if (parentSelected.type === "Ranch") {
        ctrl.state.ranch = parentSelected.value[0];
      } else if (parentSelected.type === "Block") {
        ctrl.state.block = parentSelected.value[1];
      }
    }

    $updateGraphWindow();
  };
}

function FarmXDataExplorerService($rootScope, $mdDialog, $farmXSensorInfo) {
  this.showDateFilter = function(config) {
    return $mdDialog.show({
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        "config": config,
      },
      template: '<md-dialog style="width: 256px; height: 324px;" layout="column" layout-align="start stretch">' +
                '  <md-toolbar>' +
                '    <div class="md-toolbar-tools">' +
                '      <h2>Select Dates</h2>' +
                '    </div>' +
                '  </md-toolbar>' +
                '  <md-dialog-content flex="100">' +
                '    <div class="md-dialog-content" style="padding: 10px">' +
                '      <div flex="100" layout="column" layout-align="start stretch">' +
                '        <div flex="100" layout="column" style="margin-top: 10px; margin-bottom: 20px">' +
                '          <div flex="100" layout="row" layout-align="start center">' +
                '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">From</label>' +
                '            <md-datepicker ng-model="startDate" md-placeholder="Enter Start Date" md-open-on-focus></md-datepicker>' +
                '          </div>' +
                '          <div flex="100" layout="row" layout-align="start center">' +
                '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">To</label>' +
                '            <md-datepicker ng-model="endDate" md-placeholder="Enter End Date" md-open-on-focus></md-datepicker>' +
                '          </div>' +
                '        </div>' +
                '        <div flex="100" layout="column" layout-align="start stretch">' +
                '          <div flex="100" layout="row" layout-align="start stretch" style="margin-bottom: 10px">' +
                '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="today()">' +
                '              <span>Today</span>' +
                '            </button>' +
                '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px;" ng-click="yesterday()">' +
                '              <span>Yesterday</span>' +
                '            </button>' +
                '          </div>' +
                '          <div flex="50" layout="row" layout-align="start stretch" style="margin-bottom: 10px">' +
                '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="lastDays(6)">' +
                '              <span>Last 7 Days</span>' +
                '            </button>' +
                '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px;" ng-click="lastDays(30)">' +
                '              <span>Last 30 Days</span>' +
                '            </button>' +
                '          </div>' +
                '        </div>' +
                '      </div>' +
                '    </div>' +
                '  </md-dialog-content>' +
                '  <md-dialog-actions layout="row">' +
                '    <span flex></span>' +
                '    <md-button ng-click="submit(true)">' +
                '      Filter' +
                '    </md-button>' +
                '  </md-dialog-actions>' +
                '</md-dialog>',
      controller: FarmXGraphSelectDate
    });
  };

  function FarmXGraphSelectDate($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $log, config) {
    $scope.$startDate = config.dateRange.startDate;
    $scope.$endDate = config.dateRange.endDate;
    $scope.startDate = config.dateRange.startDate.toDate();
    $scope.endDate = config.dateRange.endDate.toDate();

    $scope.submit = function() {
      config.dateRange.startDate = moment($scope.startDate);
      config.dateRange.endDate = moment($scope.endDate);
      config.dateRange.shiftBy.number = config.dateRange.endDate.diff(config.dateRange.startDate, 'days') + 1;

      $mdDialog.hide(config);
    };

    $scope.today = function() {
      $scope.$startDate = moment().hour(0).minute(0).second(0).millisecond(0);
      $scope.$endDate = moment().hour(23).minute(59).second(59).millisecond(999);
      $scope.startDate = $scope.$startDate.toDate();
      $scope.endDate = $scope.$endDate.toDate();
    };

    $scope.yesterday = function() {
      $scope.$startDate = moment().subtract(1, 'days').hour(0).minute(0).second(0).millisecond(0);
      $scope.$endDate = moment().subtract(1, 'days').hour(23).minute(59).second(59).millisecond(999);
      $scope.startDate = $scope.$startDate.toDate();
      $scope.endDate = $scope.$endDate.toDate();
    };

    $scope.lastDays = function(days) {
      $scope.$startDate = moment().subtract(days, 'days').hour(0).minute(0).second(0).millisecond(0);
      $scope.$endDate = moment().hour(23).minute(59).second(59).millisecond(999);
      $scope.startDate = $scope.$startDate.toDate();
      $scope.endDate = $scope.$endDate.toDate();
    };
  }
}
